// https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone/57527009#57527009
// ios devices behave differently to others, so we have to specifically detect
// ios to add maximum-scale=1.0 so we disable text input zoom in
// but do not disable pinch zoom

export function iosViewportFix() {
  const viewportTag = document.querySelector(`meta[name=viewport]`)
  const isIos = /iPad|iPhone|iPod/.test(window.navigator.userAgent) && !window.MSStream

  if (viewportTag && isIos) {
    let content = viewportTag.getAttribute(`content`)
    viewportTag.setAttribute(`content`, [content, `maximum-scale=1.0`].join(`, `))
  }
}
