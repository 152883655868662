import { useEffect, useRef } from "react"
import debounce from 'lodash/debounce'

export const useStickyProductDetails = () => {
  const ref = useRef()

  useEffect(() => {
    let scrollTop = 0
    const obv = new ResizeObserver(debounce(calculateOffset, 50))

    function calculateOffset() {
      if (ref.current) {
        setTop(((scrollTop * 2) - ref.current.getBoundingClientRect().height))
      }
    }

    function disconnect() {
      try {
        // sometimes ref or obv no longer available
        obv.unobserve(ref.current)
      } catch(e) {
        // noop
      }
    }

    function init() {
      const { top = 0 } = ref.current.getBoundingClientRect()

      scrollTop = top
      calculateOffset()
      obv.observe(ref.current)
    }

    function setTop(num = 0) {
      ref.current.style.top = `${num}px`
    }

    init()
    return disconnect
  }, [])

  return ref
}
