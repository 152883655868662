import React from 'react'
import classNames from 'classnames'

import { updateGoogleAnalytics } from 'services/analytics'
import { Layout } from 'components/Layout'

import styles from './ProductTrayTabs.css'

export const ProductTrayTabs = ({
  activeTab,
  marginBottom = `small`,
  position = `center`,
  setActiveTab,
  tabs,
  tabGap = `3rem`,
  tabFontSize,
}) => {
  return (
    <Layout
      data-testid="product-tray-tabs-layout"
      layout={{ marginBottom }}
    >
      <div
        className={classNames(styles.container, {
          [styles.left]: position === `left`,
          [styles.center]: position === `center`,
          [styles.right]: position === `right`,
        })}
        data-testid="product-tray-tabs"
        style={{ '--tab-gap': tabGap, '--tab-font-size': tabFontSize }}
      >
        {tabs.map(({
          title,
          analyticsTitle,
        }, idx) => (
          <div
            className={styles.tab}
            key={title}
          >
            <button
              aria-label={title}
              className={classNames({
                [styles.activeTab]: idx === activeTab,
              })}
              name={title}
              onClick={() => {
                analyticsTitle && updateGoogleAnalytics(`clickProductTrayMenu`, { analyticsTitle })
                setActiveTab(idx)
              }}
            >
              {title}
            </button>
          </div>
        ))}
      </div>
    </Layout>
  )
}
