import React from 'react'
import isEqual from 'lodash/isEqual'

export function useDeepCompare(deps) {
  const prevDeps = React.useRef(deps)
  const signalRef = React.useRef(0)

  if (!isEqual(deps, prevDeps.current)) {
    signalRef.current += 1
  }

  prevDeps.current = deps
  return React.useMemo(() => prevDeps.current, [signalRef.current])
}
