import React from 'react'
import get from 'lodash/get'
import { useSelector } from 'react-redux'

import { currencyFormatted } from 'utils'
import { I18n } from 'utils/i18n'
import { useFilters } from 'hooks/useFilters'
import { algoliaFiltersMap } from 'services/algolia'
import { FiltersSelectedButton } from 'components/FiltersSelectedButton'

import { webLabels } from './fixtures'
import styles from './FiltersSelectedOptions.css'

export const FiltersSelectedOptions = ({
  name,
  scrollHook = ``,
}) => {
  const activeLanguage = useSelector(state => state.language.active)
  const allFilters = useSelector(state => state.listing.allFilters)[activeLanguage]
  const selectedFilters = useSelector(state => state.listing.selectedFilters)[name]
  const loadedLanguages = useSelector(state => state.listing.loadedLanguages)
  const { onFilterSelect } = useFilters()

  const {
    currency,
    filterType,
  } = algoliaFiltersMap.get()[name]

  if (filterType === `numeric`) {
    const min = selectedFilters[0]
    const max = selectedFilters[1]

    return (
      <FiltersSelectedButton
        aria-label={I18n.t(webLabels.removeFilter, {
          replace: {
            name: I18n.t(webLabels.price),
          },
        })}
        onClick={() => onFilterSelect(name, undefined, scrollHook)}
      >
        <span>
          {renderNumber(min)}
        </span>
        {!max
          ? (
            <span>
            +
            </span>
          )
          : (
            <>
              <span className={styles.separator}>
                -
              </span>
              <span>
                {renderNumber(max)}
              </span>
            </>
          )
        }
      </FiltersSelectedButton>
    )
  }

  if (loadedLanguages.includes(activeLanguage)) {
    return selectedFilters.map(renderItem)
  }

  return selectedFilters.map(renderSkeleton)

  function renderNumber(value) {
    if (currency) {
      return currencyFormatted(value)
    }
  }

  function renderItem(option) {
    const value = get(allFilters, `${name}.${option}.value`, option)

    return (
      <FiltersSelectedButton
        aria-label={I18n.t(webLabels.removeFilter, {
          replace: {
            name: value,
          },
        })}
        key={option}
        onClick={() => onFilterSelect(name, option, scrollHook)}
      >
        {value}
      </FiltersSelectedButton>
    )
  }

  function renderSkeleton(option) {
    return (
      <FiltersSelectedButton key={option}>
        <span style={{ opacity: 0 }}>
          {option}
        </span>
      </FiltersSelectedButton>
    )
  }
}
