import React from 'react'

import { Modal } from 'components/Modal'
import { useIsMounted } from 'hooks/useIsMounted'

export function useModal({ onHide } = {}) {
  const isMounted = useIsMounted()
  const [isVisible, setIsVisible] = React.useState(false)

  const RenderModal = React.useCallback(({
    children,
    mobileFullScreen,
  }) => {
    if (isVisible) {
      return (
        <Modal
          closeModal={hide}
          mobileFullScreen={mobileFullScreen}
        >
          {children}
        </Modal>
      )
    }

    return null
  }, [isVisible])

  return {
    show,
    hide,
    isVisible,
    RenderModal,
  }

  function show() {
    setIsVisible(true)
  }

  function hide(response) {
    onHide && onHide(response)
    isMounted.current && setIsVisible(false)
  }
}
