import {
  ADD_MESSAGE,
  CLEAR_MESSAGES,
} from 'state/actions'

export const addMessage = ({
  type,
  messageCode,
}) => ({
  type: ADD_MESSAGE,
  payload: {
    type,
    messageCode,
  },
})

export const clearMessages = ({ type }) => ({
  type: CLEAR_MESSAGES,
  payload: {
    type,
  },
})
