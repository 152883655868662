import { getHost } from 'global-content/config'
import { getPreviewSetting } from 'global-content/preview'
import { checkExceptionsList } from 'exceptions/checkExceptions'
import { ErrorAdditional, SENTRY_TAGS } from 'utils/ErrorAdditional'

export function getMetaDataV2() {
  const host = getHost()
  const folder = checkExceptionsList(host, `metadataFolder`)
  const fileName = `metadata-v2.json`
  const [hostNoFolder] = host.split(`/`)

  let target = window.$COUNTRYFOLDER
    ? `https://${hostNoFolder}/${hostNoFolder}-current${window.$COUNTRYFOLDER}/metadata-v2.json?cb=${Math.random()}`
    : `https://${host}/${folder}-current/${fileName}?cb=${Math.random()}`

  if (getPreviewSetting(`active`)) {
    target = getPreviewTarget(fileName)
  }

  // NOTE(Kevin): If this is a feature deployment, then metadata file is saved
  // in the root dist folder, there is no need to fetch it from another host.
  if (window.$DEPLOYFEATURE) {
    target = `/metadata-v2.json`
  }

  return doFetch(target).catch(e => {
    const isRefetch = Boolean(window.$META)
    const errTitle = isRefetch ? `Problem refetching metadata-v2` : `Problem fetching metadata-v2`
    const severity = isRefetch ? 0 : 2

    const error = ErrorAdditional({
      severity,
      title: errTitle,
      message: e.message,
      originalError: e,
      tags: {
        [SENTRY_TAGS.SOURCE_FILE]: `src/utils/getMetaData.js`,
        [SENTRY_TAGS.FETCH_URL]: target,
      },
    })

    if (isRefetch) {
      console.error(error)
      return
    }
    throw new error
  })
}

// export function getMetaData() {
//   const host = getHost()
//   const folder = checkExceptionsList(host, `metadataFolder`)
//   const fileName = `metadata.json`
//   let target = `https://${host}/${folder}-current/${fileName}?cb=${Math.random()}`

//   if (window.$PORTHOSTMAP) {
//     target = `/${fileName}`
//   }

//   if (getPreviewSetting(`active`)) {
//     target = getPreviewTarget(fileName)
//   }

//   return doFetch(target).catch(e => {
//     throw new ErrorAdditional(
//       {
//         severity: 0,
//         title: `Problem fetching metadata-v1`,
//         message: e.message,
//       },
//     )
//   })
// }

function getPreviewTarget(fileName) {
  const client = getPreviewSetting(`client`)
  const country = getPreviewSetting(`country`)
  const host = getPreviewSetting(`host`)
  const hostParts = host.split(`.`)
  const hostNoKey = hostParts.length === 4 ? [hostParts[1], hostParts[2], hostParts[3]].join(`.`) : host
  return `https://${host}/${hostNoKey}-current/${client?.toLowerCase()}-${country?.toLowerCase()}/${fileName}?cb=${window.$cacheBust}`
}

export function getConfigData() {
  const fetchUrl = `${window.$COUNTRYFOLDER || ``}/themes/config.json?cb=${window.$cacheBust}`
  return (
    doFetch(fetchUrl)
      .catch(e => {
        throw new ErrorAdditional(
          {
            severity: 0,
            title: `Problem fetching config`,
            message: e.message,
            originalError: e,
            tags: {
              [SENTRY_TAGS.SOURCE_FILE]: `src/utils/getMetaData.js`,
              [SENTRY_TAGS.FETCH_URL]: fetchUrl,
            },
          },
        )
      })
  )
}

function doFetch(...args) {
  return (
    fetch(...args)
      .then(data => data.text())
      .then(body => {
        try {
          return JSON.parse(body)
        } catch (e) {
          throw new Error(`Can not parse metadata json`)
        }
      })
  )
}
