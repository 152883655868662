"use strict";
exports.__esModule = true;
exports.getSessionStorage = exports.setSessionStorage = void 0;
function setSessionStorage(key, value) {
    if (window.sessionStorage) {
        window.sessionStorage.setItem(key, String(value));
    }
}
exports.setSessionStorage = setSessionStorage;
function getSessionStorage(key) {
    if (window.sessionStorage) {
        return window.sessionStorage.getItem(key);
    }
    return null;
}
exports.getSessionStorage = getSessionStorage;
