export const ACCOUNT_ROUTE = `/account`
export const ACCOUNT_CREATE_ROUTE = `${ACCOUNT_ROUTE}/create`
export const ACCOUNT_ADDRESSES_ROUTE = `${ACCOUNT_ROUTE}/addresses`
export const ACCOUNT_VERIFICATION_ROUTE = `${ACCOUNT_ROUTE}/verify`
export const ACCOUNT_SIGNIN_ROUTE = `${ACCOUNT_ROUTE}/sign-in`
export const ACCOUNT_FORGOTTEN_PASSWORD_ROUTE = `${ACCOUNT_ROUTE}/forgot-password`
export const ACCOUNT_NEW_PASSWORD_ROUTE = `${ACCOUNT_ROUTE}/set-password`
export const ACCOUNT_PAYMENT_METHODS_ROUTE = `${ACCOUNT_ROUTE}/payment-methods`
export const CHECKOUT_ROUTE = `/checkout/:step?`
export const COMPLETE_ROUTE = `/complete`
export const FAVORITES_ROUTE = `/my-favorites`
export const ORDER_HISTORY_ROUTE = `/orders`
export const ORDER_DETAILS_ROUTE = `${ORDER_HISTORY_ROUTE}/:orderReference`
export const ORDER_STATUS_ROUTE = `${ORDER_HISTORY_ROUTE}/order-status`
export const PRODUCT_ROUTE = `/product/:slug/:id?`
export const RECREATE_CART_ROUTE = `/recreateCart`
export const REDIRECT_ROUTE = `/redirect`
export const SEARCH_ROUTE = `/search`
export const TRACKING_ROUTE = `/tracking`
export const BRAND_ROUTE = `/:l1?/BRANDS_PATHNAME/:brand`
export const BRANDS_INDEX = `/:l1?/BRANDS_PATHNAME`
