import React from 'react'

import { unversionedStorage } from 'utils/storage'
import { reportMessage } from "services/reportMessage"
import { I18n } from 'utils/i18n'
import { reportError } from 'services/reportError'
import { Heading } from 'components/Heading'
import { Layout } from 'components/Layout'
import { Loader } from 'components/Loader'
import { Modal } from 'components/Modal'
import { ModalError } from 'components/ModalError'
import { RefreshButton } from 'components/RefreshButton'

import { webLabels } from './fixtures'
import styles from './ModalUpdate.css'

export const ModalUpdate = ({
  error,
  retryRef,
}) => {
  const message = error.message
  const tryReload = retryRef && !unversionedStorage.get(retryRef)

  React.useEffect(() => {
    if (tryReload) {
      unversionedStorage.set(retryRef, message)
      reportMessage(`Forced user to reload because of failed lazy module load`, {
        extra: { message },
      })

      setTimeout(() => {
        window.location.reload(true)
      }, 2000)
    } else {
      reportError(error)
    }
  }, [])

  if (!tryReload) {
    return (
      <ModalError />
    )
  }

  return (
    <Modal>
      <Layout
        className={styles.container}
        data-testid="updateModal"
        layout={{
          container: `clientDefault`,
        }}
      >
        <div className={styles.wrapper}>
          <div>
            <div className={styles.loaderWrapper}>
              <Loader />
            </div>
            <div>
              <Heading
                content={{
                  html: I18n.t(webLabels.title),
                }}
              />
              <Layout
                layout={{
                  marginBottom: `xxs`,
                }}
              >
                {I18n.t(webLabels.desc)}
              </Layout>
              <RefreshButton />
            </div>
          </div>
        </div>
      </Layout>
    </Modal>
  )
}
