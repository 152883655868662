export const webLabels = {
  accountAddressesLabel: `WEB.ACCOUNTS.ADDRESSES.TITLE`,
  accountPaymentMethodsLabel: `WEB.ACCOUNTS.PAYMENT-METHODS.TITLE`,
  createAccountLabel: `WEB.ACCOUNTS.CREATE-ACCOUNT.TITLE`,
  setPasswordLabel: `WEB.ACCOUNTS.NEW-PASSWORD.TITLE`,
  signInLabel: `WEB.ACCOUNTS.SIGN-IN`,
  signOutLabel: `WEB.ACCOUNTS.SIGNOUT`,
  trackOrderLabel: `WEB.ORDER-STATUS.TITLE`,
  orderHistoryLabel: `WEB.ORDER-HISTORY.TITLE`,
}
