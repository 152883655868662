import React from 'react'
import { useProductMedia } from 'hooks/useProductMedia'
import { Factory } from 'components/Factory'

import styles from './ProductImageGrid.css'

export const ProductImageGrid = () => {
  const items = useProductMedia()
  return (
    <div
      className={items.length > 2 ? styles.containerMore : styles.containerLess}
    >
      {items.map(item => (
        <div
          className={styles.image}
          key={item.id}
        >
          <Factory items={[item]}/>
        </div>
      ))}
    </div>
  )
}
