import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import merge from 'lodash/merge'
import { dotObject } from 'utils/dotObject'
import { loadSchema } from 'state/actions/schemas'
import { getMetaOptions, getConfigOptions } from 'global-content/config'
import { updateGoogleAnalytics } from 'services/analytics'
import { getSiteName, getCountry, getCountryName } from 'utils/i18n'
import { getSourceFile } from 'utils/getSourceFile'
import { useDataFetching } from 'hooks/useDataFetching'

export function useContactForm() {
  const activeLanguage = useSelector(state => state.language.active)
  const locale = useSelector(state => state.language.locale)
  const folder = `/data`
  const source = getSourceFile(`contact-us-form`, activeLanguage)
  const [dataValues] = useDataFetching({ folder, source })
  const [submitState, setSubmitState] = React.useState()

  const dispatch = useDispatch()
  const schema = useSelector(state => state.schemas.contactUs)

  React.useEffect(() => {
    dispatch(loadSchema(`/data/contact-us-schema_webLabels.json`, `contactUs`))
  }, [])

  let headerValue
  let contentValue

  if (submitState === `success`) {
    headerValue = dataValues.success.header
    contentValue = dataValues.success.content
  }

  if (submitState === `failure`) {
    headerValue = dataValues.failure.header
    contentValue = dataValues.failure.content
  }

  return {
    dataValues,
    schema,
    handleSubmit: sendEnquiry,
    submitState,
    buttonValue: dataValues?.enquire,
    headerValue,
    contentValue,
  }

  function sendEnquiry({
    values,
    valid,
  }) {
    if (valid) {
      setSubmitState(`submitting`)

      const base64Auth = window.btoa(dataValues.authToken)
      const options = {
        method: `POST`,
        headers: {
          Authorization: `Basic ${base64Auth}`,
        },
        body: getTicketPayload(),
        resolveWithFullResponse: true,
      }

      return fetch(dataValues.apiURL, options)
        .then(response => {
          if (response.status === 201) {
            setSubmitState(`success`)
            updateGoogleAnalytics(`contactUs`)
          } else {
            console.warn(`Error: Freshdesk API, response status: `, response.status)
            setSubmitState(`failure`)
          }
        })
    }

    function getCountryField() {
      if (!getConfigOptions(`components.contactForm.includeCountryField`)){
        return {}
      }

      return {
        custom_fields: {
          cf_country: countryLookup(getCountry(), getCountryName()),
        },
      }
    }

    function getProductId() {
      const id = parseInt(getMetaOptions(`integrations.freshdesk.productId`), 10)
      return id > 0
        ? { product_id: id }
        : { }
    }

    /**
     * Workaround to match Freshdesk's country names (sample response from Aug 26th)
     *
     * "errors": [
        {
            "field": "custom_fields.cf_country",
            "message": "It should be one of these values: 'Australia,Austria,Canada,Bahrain,Belarus,Belgium,Bulgaria,Croatia,Cyprus,Czechia,Denmark,Estonia,Finland,France,Germany,Great Britain,Greece,Hong Kong,Hungary,Ireland,Israel,Italy,Japan,Korea,Latvia,Lithuania,Luxembourg,Malaysia,Malta,Mexico,Monaco,Morocco,Netherlands,New Zealand,Norway,Oman,Poland,Portugal,Qatar,Romania,Saudi Arabia,Slovakia,Slovenia,Singapore,Spain,Sweden,Switzerland,Taiwan,Turkey,United Arab Emirates,USA,Middle East'",
            "code": "invalid_value"
        }
    ]
     */
    function countryLookup(countryCode, countryName) {
      const countryNameLookup = {
        AU: `Australia`,
        AT: `Austria`,
        CA: `Canada`,
        BH: `Bahrain`,
        BY: `Belarus`,
        BE: `Belgium`,
        BG: `Bulgaria`,
        HR: `Croatia`,
        CY: `Cyprus`,
        CZ: `Czechia`,
        DK: `Denmark`,
        EE: `Estonia`,
        FI: `Finland`,
        FR: `France`,
        DE: `Germany`,
        GB: `Great Britain`,
        GR: `Greece`,
        HK: `Hong Kong`,
        HU: `Hungary`,
        IE: `Ireland`,
        IL: `Israel`,
        IT: `Italy`,
        JP: `Japan`,
        KR: `Korea`,
        LV: `Latvia`,
        LT: `Lithuania`,
        LU: `Luxembourg`,
        MY: `Malaysia`,
        MT: `Malta`,
        MX: `Mexico`,
        MC: `Monaco`,
        MA: `Morocco`,
        NL: `Netherlands`,
        NZ: `New Zealand`,
        NO: `Norway`,
        OM: `Oman`,
        PL: `Poland`,
        PT: `Portugal`,
        QA: `Qatar`,
        RO: `Romania`,
        SA: `Saudi Arabia`,
        SK: `Slovakia`,
        SI: `Slovenia`,
        SG: `Singapore`,
        ES: `Spain`,
        SE: `Sweden`,
        CH: `Switzerland`,
        TW: `Taiwan`,
        TR: `Turkey`,
        AE: `United Arab Emirates`,
        US: `USA`,
      }
      return countryNameLookup[countryCode] || countryName
    }

    function getTicketPayload() {
      const cfLanguage = {
        es: locale === `es-ES` ? `es` : `es-LA`,
        zh: locale === `zh-TW` ? `zh-TW` : `zh-CN`,
        sv: `sv-SE`,
        ru: `ru-RU`,
        pt: locale === `pt-BR` ? `pt-BR` : `pt-PT`,
        nb: `nb-NO`,
        ja: `ja-JP`,
        lv: `lv-LV`,
      }[activeLanguage] || activeLanguage

      const defaultValues = {
        description: ``,
        subject: getSiteName(),
        email: ``,
        priority: 3,
        status: 2,
        type: ``,

        custom_fields: {
          cf_language: cfLanguage,
        },
      }

      const mergedFormValues = merge(
        defaultValues,
        Object.assign({}, dotObject(values)),
        getCountryField(),
        getProductId()
      )

      !mergedFormValues.attachment && delete mergedFormValues.attachment

      const formData = new FormData()

      for (const key in mergedFormValues) {
        if (key === `attachment` && mergedFormValues.attachment?.length) {
          const attachmentsArray = [...mergedFormValues[key]]
          attachmentsArray.forEach(value => {
            formData.append(`attachments[]`, value)
          })
        } else if (key === `custom_fields`) {
          Object.keys(mergedFormValues[key]).forEach(cf => {
            formData.append(`${key}[${cf}]`, mergedFormValues[key][cf])
          })
        } else {
          formData.append(key, mergedFormValues[key])
        }
      }

      return formData
    }
  }
}
