import React, { useContext } from 'react'
import { ModernFactoryComponent } from '../ModernFactoryComponent'
import { NavigationContext } from './Navigation'

export const NavigationButton = ModernFactoryComponent(({
  children,
  Tag,
  data: { navigationIndex } = {},
  ...props
}, ref) => {

  const {
    currentNav,
    setCurrentNav,
  } = useContext(NavigationContext)

  const handleClick = () => {
    setCurrentNav(navigationIndex)
  }

  const isSelected = currentNav === navigationIndex

  return (
    <Tag
      {...props}
      aria-expanded={isSelected}
      onClick={handleClick}
      ref={ref}
    >
      {children}
    </Tag>
  )
})
