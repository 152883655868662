import React from 'react'

export const Loader = ({ size = 60 }) => {
  return (
    <div
      /*  Deliberately absolute reference */
      className="css3Loader"
      data-testid="loader"
      style={{
        width: size,
        height: size,
      }}
    >
      <svg
        fill="none"
        height="16"
        viewBox="0 0 16 16"
        width="16"
      >
        <circle
          cx="8"
          cy="8"
          r="7"
          strokeWidth="0.6"
        />
      </svg>
    </div>
  )
}
