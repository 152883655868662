import React from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { measureNodes } from 'utils/measure-nodes'
import { overlayOff, overlayOn } from 'state/actions/overlay'
import { useRegisterKeyHandlers } from 'hooks/useRegisterKeyHandlers'
import { Factory } from 'components/Factory'
import { Layout } from 'components/Layout'
import { MegamenuColumn } from 'components/MegamenuColumn'
import {
  clickNavItem,
  getNavItemTargetId,
  getNavMenuTargetId,
  keyCaller,
  moveToNextNavItem,
  moveToNextNavMenu,
  moveToPreviousNavItem,
  moveToPreviousNavMenu,
} from 'utils/navigation-utils'

import { webLabels } from './fixtures'
import styles from './NavigationMegamenu.css'

const testId = `navigation-megamenu`

export const NavigationMegamenu = ({
  config,
  layout,
  navItems,
  parentMenuIndexes,
  positioning = {},
  posterItems = [],
  styling = {},
  navMenuIndex,
}) => {
  const {
    showLevelDown,
  } = config

  const {
    columns = {},
    innerLayout,
    itemHeight,
    itemPadding,
    justifyContent,
  } = positioning

  const {
    activeState,
    boxShadow,
    headingFontSize,
    headingColor,
    headingFontWeight,
    hoverState,
    type,
  } = styling

  const {
    columnsGap,
    columnsPerRow,
    evenWidthColumns,
    maxItemsPerColumn,
  } = columns

  const absolute = navMenuIndex > 0
  const dispatch = useDispatch()
  const langDir = useSelector(state => state.language.langDir)
  const focussedMenu = useSelector(state => state.site.focussedNavMenu)
  const navUsesOverlay = useSelector(state => state.overlay.navUsesOverlay)
  const parentMenuIndex = parentMenuIndexes[parentMenuIndexes.length - 1]
  const [horizontalOffset, setHorizontalOffset] = React.useState(null)
  const isLTR = langDir === `ltr`
  const ref = React.useRef()
  const [linkWidth, setLinkWidth] = React.useState()

  const getOffset = React.useCallback(() => {
    if (ref.current) {
      const x = ref.current.getBoundingClientRect().x
      setHorizontalOffset(x - (x * 2))
    }
  }, [])

  React.useEffect(() => {
    getOffset()
  }, [ref.current])

  React.useEffect(() => {
    window.addEventListener(`resize`, getOffset)

    return () => {
      window.removeEventListener(`resize`, getOffset)
    }
  }, [])

  React.useEffect(() => {
    if (navItems.length && navUsesOverlay) {
      dispatch(overlayOn(`megamenu`))
    } else {
      dispatch(overlayOff(`megamenu`))
    }
  }, [navItems.length, navUsesOverlay])

  React.useEffect(() => {
    if (navItems.length && evenWidthColumns) {
      setTimeout(() => {
        const renderedMenuItems = document.querySelectorAll(`[data-testid=${testId}] [role=menuitem]`)
        getLongestWidth(renderedMenuItems)
      }, 0)
    }
  }, [parentMenuIndex])

  useRegisterKeyHandlers({
    condition: focussedMenu >= navMenuIndex,
    event: `keydown`,
    handlers,
  })

  return (
    <div
      ref={node => {
        ref.current = node
        getOffset()
      }}
    >
      {Boolean(navItems.length) && horizontalOffset !== null &&
        <Layout
          aria-label={webLabels.openMenu}
          className={classNames(styles.megamenu, {
            [styles.absolute]: absolute,
          })}
          data-testid={testId}
          layout={layout}
          role="menubar"
          style={{
            boxShadow,
            left: horizontalOffset,
          }}
          wrap
        >
          <Layout
            className={styles.contentWrapper}
            layout={{
              ...innerLayout,
            }}
          >
            {renderNavItems()}
            {renderNavContent()}
          </Layout>
        </Layout>
      }
    </div>
  )


  function renderNavContent() {
    return posterItems.map(item => {
      return (
        <Factory
          items={item.items}
          key={JSON.stringify(item.data.config.navigationIndex)}
        />
      )
    })
  }

  function renderNavItems() {
    const columnProps = {
      activeState,
      columnsGap,
      columnsPerRow,
      hoverState,
      itemHeight,
      itemPadding,
      maxItemsPerColumn,
      navMenuIndex,
      parentMenuIndexes,
      showLevelDown,
      type,
      width: linkWidth,
    }
    if (showLevelDown) {
      return (
        <div
          style={{
            display: `flex`,
            gap: columnsGap,
            justifyContent,
          }}
        >
          {navItems.map((heading, columnIndex) => {
            return (
              <MegamenuColumn
                columnIndex={columnIndex}
                heading={heading}
                headingColor={headingColor}
                headingFontSize={headingFontSize}
                headingFontWeight={headingFontWeight}
                items={heading.items}
                key={heading.path || heading.label}
                {...columnProps}
              />
            )
          })}
        </div>
      )
    }

    return (
      <MegamenuColumn
        columnIndex={0}
        items={navItems}
        justifyContent={justifyContent}
        {...columnProps}
      />
    )
  }

  function getLongestWidth(menuItems) {
    const { width } = measureNodes(menuItems)
    setLinkWidth(width)
  }

  function handlers(e) {
    const keyMap = {
      ArrowUp: handleUp,
      ArrowDown: handleDown,
      ArrowLeft: isLTR ? moveToPreviousNavItem : moveToNextNavItem,
      ArrowRight: isLTR ? moveToNextNavItem : moveToPreviousNavItem,
      Enter: clickNavItem,
      Escape: moveToPreviousNavMenu,
    }

    if (keyMap[e.key]) {
      e.preventDefault()

      keyCaller({
        dispatch,
        navMenuIndex,
        handler: keyMap[e.key],
      })
    }
  }
}

function handleUp(args) {
  if (getNavItemTargetId(args.focussedNavItems, `previous`)) {
    return moveToPreviousNavItem(args)
  } else {
    return moveToPreviousNavMenu(args)
  }
}

function handleDown(args) {
  if (getNavMenuTargetId(args.focussedNavItems, `next`)) {
    moveToNextNavMenu(args)
  } else {
    moveToNextNavItem(args)
  }
}
