import { matchPath } from 'react-router-dom'
import queryString from 'query-string'
import isString from 'lodash/isString'

import {
  CANCEL_ACTION,
  CLEAR_REDIRECT,
  LOAD_PRODUCTS,
  LOAD_FULL_FILTER_SET,
  LOAD_PAGE_FILTERS,
  ROUTE_CHANGE,
  UPDATE_FILTER_OPTIONS,
  UPDATE_PENDING_FILTER_OPTIONS,
} from 'state/actions'
import { getConfigOptions } from 'global-content/config'
import { algoliaFiltersMap } from 'services/algolia'
import { toResultsSummary, toPageFacets, toPageFilters, toUserData } from 'models/results'
import { PRODUCT_ROUTE } from 'Router/fixtures'

export const INITIAL_PAGE = 1

const resetRouteState = {
  loading: true,
  pageFacets: {},
  pageFilters: {},
  pendingFilters: {},
  selectedFilters: {},
  page: INITIAL_PAGE,
  query: undefined,
  selectedSort: `bestMatch`,
  defaultSort: `bestMatch`,
  featuredProductIds: undefined,
  featuredCollections: [],
  products: [],
  resultsSummary: {
    approximateTotal: false,
    currentPage: 0,
    facets: {},
    numericFilterStats: {},
    pages: 0,
    hitsPerPage: 0,
    nbHits: 0,
  },
  userData: {
    redirect: null,
  },
}

const initialState = {
  allFilters: {},
  cancelledActions: [],
  loadedLanguages: [],
  ...resetRouteState,
}

const listing = (state = initialState, action) => {
  const sortOptions = getConfigOptions(`sortOptions`)

  switch (action.type) {
  case CANCEL_ACTION: {
    return {
      ...state,
      cancelledActions: [...state.cancelledActions, action.payload],
    }
  }
  case UPDATE_PENDING_FILTER_OPTIONS: {
    return {
      ...state,
      pendingFilters: action.payload,
    }
  }
  case UPDATE_FILTER_OPTIONS:
    return {
      ...state,
      selectedFilters: action.payload.filters || state.selectedFilters,
      page: action.payload.page || state.page,
      query: action.payload.query || state.query,
      selectedSort: action.payload.sortBy || state.selectedSort,
    }
  case `${LOAD_PAGE_FILTERS}_FULFILLED`:
    return {
      ...state,
      pageFacets: toPageFacets(action.payload.results.facets),
      pageFilters: toPageFilters(action.payload.results.facets, state.allFilters[action.payload.language]),
    }
  case `${LOAD_FULL_FILTER_SET}_FULFILLED`: {
    const { language } = action.payload

    return {
      ...state,
      loadedLanguages: [...state.loadedLanguages, language],
      allFilters: {
        ...state.allFilters,
        [language]: organizeFilters(action.payload.filters),
      },
    }
  }
  case ROUTE_CHANGE:
    return {
      ...state,
      ...getUrlOptions(action.payload, sortOptions),
    }
  case `${LOAD_PRODUCTS}_PENDING`:
    return {
      ...state,
      loading: true,
      products: [],
    }
  case `${LOAD_PRODUCTS}_REJECTED`:
    return {
      ...state,
      loading: false,
    }
  case `${LOAD_PRODUCTS}_FULFILLED`:
    if (!state.cancelledActions.includes(action.payload.actionId)) {
      const { results } = action.payload

      return {
        ...state,
        loading: false,
        resultsSummary: toResultsSummary(results),
        products: results?.hits,
        userData: toUserData(results),
      }

    } else {
      return state
    }
  case CLEAR_REDIRECT:
    return {
      ...state,
      userData: {
        redirect: null,
      },
    }
  default:
    return state
  }
}

function extractDefaultSort(sortOptions, path) {
  for (const sortOption of sortOptions.filter(option => option.default === true) || []) {
    const { onlyAppear } = sortOption
    if (onlyAppear) {
      if (onlyAppear.includes(path)) {
        return sortOption.value
      }
    } else {
      return sortOption.value
    }
  }
}

function getUrlOptions(location, sortOptions) {
  // Check for valid sortBy
  const {
    pathname,
    search,
  } = location
  const matcher = routeMatcher(pathname, PRODUCT_ROUTE)

  // PDP shares some url params with listing and these get used
  // this stops the PDP from inteferring with the PLP

  if (matcher) {
    return {}
  }

  const parsedUrlSearch = queryString.parse(decodeURIComponent(search), {
    arrayFormat: `comma`,
  })

  const {
    sortBy,
    page,
    query,
    featuredProductIds,
    featuredCollections,
  } = parsedUrlSearch

  const FILTERSMAP = algoliaFiltersMap.get()
  let selectedFilters = {}

  Object.keys(FILTERSMAP).forEach(filterKey => {
    if (parsedUrlSearch[filterKey]) {
      selectedFilters[filterKey] = formatValues(parsedUrlSearch[filterKey])
    }
  })

  return {
    defaultSort: extractDefaultSort(sortOptions, pathname),
    page: page || INITIAL_PAGE,
    pendingFilters: selectedFilters,
    query,
    selectedFilters,
    selectedSort: getSelectedSortName(sortBy, sortOptions, pathname),
    featuredProductIds: isString(featuredProductIds) ? [featuredProductIds] : featuredProductIds,
    featuredCollections: isString(featuredCollections) ? [featuredCollections] : featuredCollections,
  }
}

function formatValues(value) {
  if (Array.isArray(value)) {
    return value.map(decodeURIComponent)
  }

  return [decodeURIComponent(value)]
}

function getSelectedSortName(toFind, sortOptions, pathname) {
  const sortOption = sortOptions.find(option => option.value === toFind)

  if (
    (sortOption && !sortOption.onlyAppear) ||
    (sortOption && sortOption.onlyAppear.includes(pathname))
  ) {
    return sortOption.value
  }

  return extractDefaultSort(sortOptions, pathname)
}

function organizeFilters(filters) {
  let build = {}

  if (!filters.length) {
    return build
  }

  filters.forEach(filter => {
    const {
      filterType,
      index,
      properties,
      tag,
      value,
    } = filter
    const {
      colorGroup,
      rgb,
      sizeGroupLabel,
      subSizeGroupLabel,
      swatchURL,
    } = properties
    const filterObj = {
      category: colorGroup || sizeGroupLabel,
      subCategory: subSizeGroupLabel,
      index,
      rgb,
      tag,
      value,
      swatchURL,
    }

    const filterTypeLower = filterType.toLowerCase()

    // First one starts array
    if (!build[filterTypeLower]) {
      build[filterTypeLower] = {
        [tag]: filterObj,
      }
    } else {
      // add to array
      build[filterTypeLower][tag] = filterObj
    }
  })

  return build
}

function routeMatcher(pathname, routeToCheck) {
  return matchPath(pathname, {
    path: routeToCheck,
    exact: true,
    strict: true,
  })
}

export default listing
