import mergeWith from 'lodash/mergeWith'
import merge from 'lodash/merge'

import { sortByPriority } from 'utils/sortByPriority'

export function augmentation(originalItems, ...args) {
  if (!args.filter(Boolean).length) {
    return originalItems
  }

  const build = mergeWith([], originalItems, tagV1ToV2)

  args.forEach(augmentItems => {
    const normalized = mergeWith([], augmentItems, augmentV1ToV2)

    normalized.forEach(item => {
      findId(
        build,
        item
      )
    })
  })

  return build
}

function findId(
  build,
  item
) {
  const {
    method,
    target,
  } = item.data?.augmentation || {}
  let found = false

  if (Array.isArray(build)) {
    const foundIndex = build.findIndex(itm => itm.tag === target)

    if (foundIndex > -1) {
      found = true

      build[foundIndex] = operate({
        item,
        method,
        target: build[foundIndex],
      })
    } else {
      for (let i = 0; i < build.length; i++) {
        if (found) {
          break
        }

        findId(build[i].items, item)
      }
    }
  }
}

export function operate({
  item,
  method,
  target,
}) {
  if (method === `remove`) {
    const { id } = target

    return { id }
  }

  if (method === `replace`) {
    return item
  }

  if (method === `update`) {
    return merge({}, target, item)
  }

  if (method === `append`) {
    return {
      ...target,
      items: [
        ...target.items,
        ...(item.items || []),
      ].sort((a, b) => sortByPriority(a.data?.config, b.data?.config)),
    }
  }

  return target
}

function tagV1ToV2(objValue, srcValue) {
  if (srcValue) {
    const {
      data = {},
      ...restOfItem
    } = srcValue
    const {
      augmentId,
      ...restOfData
    } = data

    if (augmentId) {
      return {
        ...restOfItem,
        data: {
          ...restOfData,
        },
        tag: augmentId,
      }
    }
  }
}

function augmentV1ToV2(objValue, srcValue) {
  if (srcValue) {
    const {
      data = {},
      ...restOfItem
    } = srcValue
    const {
      augmentId,
      method = `add`,
      ...restOfData
    } = data

    if (augmentId) {
      return {
        ...restOfItem,
        data: {
          ...restOfData,
          augmentation: {
            target: augmentId,
            method: v1ToV2MethodMap(method),
          },
        },
      }
    }
  }
}

function v1ToV2MethodMap(method) {
  if (method === `add`) {
    return `update`
  }

  if (method === `addItem`) {
    return `append`
  }

  return method
}
