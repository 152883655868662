
import {
  ADD_ADDRESS,
  DELETE_ADDRESS,
  LIST_ALL_ADDRESSES,
  LIST_BILLING_ADDRESSES,
  LIST_SHIPPING_ADDRESSES,
  MATCH_BILLING_AND_SHIPPING_ADDRESS,
  REMOVE_ADDRESS_ERROR,
  UPDATE_ADDRESS,
  UPDATE_ENCOURAGED_FIELDS_VALUES,
  UPDATE_ENCOURAGED_FIELDS_ERRORS,
  UPDATE_SAVE_SHIPPING_ADDRESS,
  UPDATE_SELECTED_BILLING_ADDRESS,
  UPDATE_SELECTED_SHIPPING_ADDRESS,
  UPDATE_SELECTED_SHIPPING_VALIDATION,
} from 'state/actions'
import { loadAddressSchema } from 'state/actions/schemas'
import { loadCartContents } from 'state/actions/cart'
import { utilizeAjv } from 'global-content/utils'
import { getMetaOptions } from 'global-content/config'
import { getLanguage } from 'utils'
import {
  ADDRESS_SOURCE_CART_API,
  ADDRESS_SOURCE_ADDRESSBOOKS_KEY,
} from 'utils/constants'
import * as apiAddresses from 'services/api/addresses'
import * as api from 'services/api'

export const listShippingAddresses = (shippingAddresses, selectDefault) => (dispatch) => {
  dispatch({
    type: LIST_SHIPPING_ADDRESSES,
    payload: shippingAddresses,
  })

  if (selectDefault) {
    dispatch(updateSelectedAddress({
      address: shippingAddresses.find(address => address.default),
      source: ADDRESS_SOURCE_ADDRESSBOOKS_KEY,
      type: `shipping`,
    }))
  }
}

export const listBillingAddresses = (billingAddresses, selectDefault) => (dispatch) => {
  dispatch({
    type: LIST_BILLING_ADDRESSES,
    payload: billingAddresses,
  })

  if (selectDefault) {
    dispatch(updateSelectedAddress({
      address: billingAddresses.find(address => address.default),
      source: ADDRESS_SOURCE_ADDRESSBOOKS_KEY,
      type: `billing`,
    }))
  }
}

export const listAddresses = ({
  selectDefault,
  type,
}) => (dispatch) => {
  dispatch({
    type: LIST_ALL_ADDRESSES,
    payload: apiAddresses.listAddresses().then(addresses => {
      if (type === `billing`) {
        let billingAddresses = addresses.filter(address => address.type === `shipping`)
        dispatch(listBillingAddresses(billingAddresses, selectDefault))
      }

      if (type === `shipping`) {
        let shippingAddresses = addresses.filter(address => address.type === `shipping`)
        dispatch(listShippingAddresses(shippingAddresses, selectDefault))
      }

      return addresses
    }),
  })
}

export const addAddress = ({
  address,
  type,
}) => ({
  type: ADD_ADDRESS,
  payload: apiAddresses.addAddress({ address, type }),
})

export const updateAddress = ({
  address,
  id,
}) => ({
  type: UPDATE_ADDRESS,
  payload: apiAddresses.updateAddress({ address, id }),
})

export const deleteAddress = ({ id }) => ({
  type: DELETE_ADDRESS,
  payload: apiAddresses.deleteAddress({ id }),
})

export const updateSelectedBillingAddress = ({
  address,
  source,
}) => ({
  type: UPDATE_SELECTED_BILLING_ADDRESS,
  payload: {
    address,
    source,
  },
})

export const resetAddresses = () => (dispatch, getState) => {
  const state = getState()
  const isUserSignedIn = state.account.isUserSignedIn
  const shippingAddresses = state.addresses.shippingAddresses || []
  const billingAddresses = state.addresses.shippingAddresses || []

  let billingAddress
  let billingAddressSource
  let shippingAddress
  let shippingAddressSource

  if (isUserSignedIn) {
    const defaultShippingAddress = shippingAddresses.find(a => a.default)
    const defaultBillingAddress = billingAddresses.find(a => a.default)

    if (defaultBillingAddress) {
      billingAddress = defaultBillingAddress
      billingAddressSource = ADDRESS_SOURCE_ADDRESSBOOKS_KEY
    }

    if (defaultShippingAddress) {
      shippingAddress = defaultShippingAddress
      shippingAddressSource = ADDRESS_SOURCE_ADDRESSBOOKS_KEY
    }
  }

  dispatch(updateSelectedAddress({
    address: billingAddress,
    source: billingAddressSource,
    type: `billing`,
  }))

  dispatch(updateSelectedAddress({
    address: shippingAddress,
    source: shippingAddressSource,
    type: `shipping`,
  }))
}

export const resetDefaultShippingAddress = () => async(dispatch, getState) => {
  const state = getState()
  const locale = state.language.locale
  const isUserSignedIn = state.account.isUserSignedIn
  const shippingAddresses = (state.addresses.shippingAddresses || []).filter(address => {
    return [`en-JP`, `ja-JP`].includes(locale) && locale === address.locale
  })
  const defaultShippingAddress = shippingAddresses.find(a => a.default)

  if (isUserSignedIn && shippingAddresses.length && !defaultShippingAddress) {
    const shippingAddress = shippingAddresses[0]
    state.addresses.shippingAddresses.forEach(address => address.default = false)
    shippingAddress.default = true
    await apiAddresses.updateAddress({
      address: shippingAddress,
      id: shippingAddress.reference,
    })

    dispatch(listShippingAddresses(state.addresses.shippingAddresses, false))
  }
}

export const updateSelectedShippingAddress = ({
  address,
  source,
}) => (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_SHIPPING_ADDRESS,
    payload: {
      address,
      source,
    },
  })

  dispatch(validateShippingAddress(address, source))
}

export const validateShippingAddress = (addressProvided, source) => async(dispatch, getState) => {
  if (!addressProvided) {
    return
  }

  const {
    language,
    schemas,
  } = getState()
  const locale = addressProvided?.locale || language.locale || `${getLanguage()}-${(getMetaOptions(`country.code`)).toUpperCase()}`

  const address = {
    ...addressProvided,
    locale,
  }

  const schema = schemas.shipping[language.active]
  let promises = [utilizeAjv()]

  promises.push(schema
    ? Promise.resolve({ value: { schema } })
    : dispatch(loadAddressSchema(language.active, `shipping`))
  )

  const [Ajv, shippingSchema] = await Promise.all(promises)

  const ajv = new Ajv({
    strict: false,
    allErrors: true,
    verbose: true,
    localize: true,
  })

  const validate = ajv.compile(shippingSchema.value.schema)
  const valid = validate(address)

  // console.log(validate.errors)

  if (
    valid &&
    source !== ADDRESS_SOURCE_CART_API
  ) {
    await api.setCartAddress({ address })
    dispatch(loadCartContents())
  }

  return dispatch({
    type: UPDATE_SELECTED_SHIPPING_VALIDATION,
    payload: valid,
  })
}

export const updateSelectedAddress = ({
  address,
  source,
  type,
}) => {
  if (type === `shipping`) {
    return updateSelectedShippingAddress({
      address,
      source,
    })
  }

  return updateSelectedBillingAddress({
    address,
    source,
  })
}

export const removeAddressError = () => ({
  type: REMOVE_ADDRESS_ERROR,
})

export const matchBillingAndShippingAddress = (payload) => ({
  type: MATCH_BILLING_AND_SHIPPING_ADDRESS,
  payload,
})

export const updateSaveShippingAddress = (payload) => ({
  type: UPDATE_SAVE_SHIPPING_ADDRESS,
  payload,
})

export const updateEncouragedFields = ({
  values,
  errors,
}) => (dispatch) => {
  dispatch({
    type: UPDATE_ENCOURAGED_FIELDS_VALUES,
    payload: values,
  })

  dispatch({
    type: UPDATE_ENCOURAGED_FIELDS_ERRORS,
    payload: errors,
  })
}
