import React from 'react'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { I18n } from 'utils/i18n'
import { ORDERABLE_STATES } from 'utils/constants'

import { SelectableBox } from 'components/SelectableBox'
import { SwatchButton } from 'components/SwatchButton'

import { webLabels } from './fixtures'
import styles from './Swatches.css'

export const Swatches = ({
  'data-testid': testId,
  limit,
  onSelect,
  options,
  selectedColor,
  showNonSwatches,
  swatchSize,
  swatchType,
}) => {
  let optionsUpToLimit = options
  if (typeof limit === `number`) {
    optionsUpToLimit = options.slice(0, limit)
  }

  const anyWithoutSwatch = !!optionsUpToLimit.filter(option => isEmpty(option.color.hex) && isEmpty(option.color.swatch)).length
  const isNumbered = options.some(({ productId: optionProductId }) => {
    return !!optionProductId
  })

  return (
    <div className={classNames(styles.swatchSet)}>
      {renderColors()}
      {limit < options.length && options.length > 1 &&
          <div className={styles.additional}>
            {isNumbered ? I18n.t(webLabels.more) : I18n.t(webLabels.additional, {
            // {isNumbered ? ` . . .` : I18n.t(webLabels.additional, {
              replace: {
                number: options.length - limit,
              },
            })}
          </div>
      }
    </div>
  )

  function renderColors() {
    return optionsUpToLimit.map((option, i) => {
      const {
        availability,
        color,
        slug,
        product,
        productSlug,
      } = option

      const {
        hex,
        name,
        swatch,
      } = color

      const buttonTestId = `${testId}-${i}`

      return (
        <div
          className={classNames(styles.wrapper, {
            [styles.xs]: swatchSize === `xs` || anyWithoutSwatch,
          })}
          key={String(productSlug || name).concat(`-${slug}`)}
        >
          {anyWithoutSwatch && showNonSwatches &&
            <SelectableBox
              data-testid={buttonTestId}
              disabled={!ORDERABLE_STATES.includes(availability)}
              label={name}
              onClick={() => onSelect(slug, product)}
              selected={product && productSlug ? productSlug === `${product.id}.${selectedColor}` : selectedColor === slug}
            />
          }

          {!anyWithoutSwatch &&
            <SwatchButton
              data-testid={buttonTestId}
              disabled={!ORDERABLE_STATES.includes(availability)}
              label={name}
              onClick={() => onSelect(slug, product)}
              selected={product && productSlug ? productSlug === `${product.id}.${selectedColor}` : selectedColor === slug}
              swatchHex={hex}
              swatchPath={swatch}
              swatchSize={swatchSize}
              swatchTag={slug}
              swatchType={swatchType}
            />
          }
        </div>
      )
    })
  }
}
