import queryString from 'query-string'
import merge from 'lodash/merge'

import { getLanguage } from 'utils'
import { postRedirect } from 'utils/postRedirect'
import { pushRedirect } from 'utils/pushRedirect'
import {
  BRANDCODE_PAYPAL_EXPRESS,
  TAX_NOT_CALCULATED,
  TAX_REJECTION_ERROR,
  AVAILABILITY_OUTOFSTOCK,
  UNABLE_TO_SHIP_ERROR,
} from 'utils/constants'
import { getConfigOptions } from 'global-content/config'

import {
  ACCOUNT_SET_VALUES,
  ADD_CART_ITEM,
  APPLY_VOUCHER,
  DISABLE_CHECKOUT_SUBMIT,
  ENABLE_CHECKOUT_SUBMIT,
  FINGERPRINT_DEVICE,
  LOAD_CART_CONTENTS,
  LOAD_COUNTRIES,
  LOADED_DEPENDENCIES,
  PLACE_ORDER,
  RECREATE_CART,
  REMOVE_REJECTION_ERROR,
  REMOVE_VOUCHER_MESSAGE,
  REMOVE_VOUCHER,
  RESTART_SESSION,
  ROUTE_CHANGE,
  SAVE_SHIPPING_SELECTION,
  SET_TAX_ERROR,
  SET_TAX_LOADING,
  SET_UNABLE_TO_SHIP_ERROR,
  START_EXTERNAL_PAYMENT_METHOD,
  UPDATE_AGREEMENT,
  UPDATE_CART_ITEM,
  UPDATE_CONSUMER_DETAILS,
} from 'state/actions'

const initialState = {
  agreements: {},
  consumer: {
    accountId: null,
    reference: null,
    typeTag: `NEW`,
    properties: {
      firstName: undefined,
      lastName: undefined,
      phoneNumber: undefined,
      email: undefined,
    },
  },
  cartSummary: {
    totalItemCost: undefined,
    totalCost: undefined,
    totalTax: undefined,
    totalDiscount: undefined,
    totalDiscountProduct: undefined,
    totalDiscountShipping: undefined,
    totalItemCount: 0,
    deliveryCost: undefined,
    voucherCode: undefined,
    voucherName: undefined,
    voucherMessage: undefined,
  },
  countries: [],
  deviceFingerprint: null,
  disabledSubmit: false,
  hasLoadedCartContents: false,
  hasProvidedVoucher: false,
  isUpdatePending: false,
  isUpdatingVoucher: false,
  isTaxLoading: false,
  items: [],
  lastAction: {
    type: undefined,
  },
  orderReference: null, // necessary for finalizing paypal express checkout
  rejectionError: undefined,
  shipping: {
    options: [],
    selected: undefined,
  },
  atcAction: null,
  unavailableItems: [],
  taxCalculated: TAX_NOT_CALCULATED,
  failedCheckoutAttempts: 0,
}

const cart = (state = initialState, action) => {
  switch (action.type) {
  case `${RESTART_SESSION}_FULFILLED`:
    return {
      ...initialState,
      agreements: getAgreements(),
    }
  case ENABLE_CHECKOUT_SUBMIT: {
    return {
      ...state,
      disabledSubmit: false,
    }
  }
  case DISABLE_CHECKOUT_SUBMIT: {
    return {
      ...state,
      disabledSubmit: true,
    }
  }
  case UPDATE_AGREEMENT: {
    return {
      ...state,
      agreements: {
        ...state.agreements,
        [action.payload.key]: action.payload.value,
      },
    }
  }
  case ACCOUNT_SET_VALUES: {
    return {
      ...state,
      consumer: {
        ...state.consumer,
        properties: {
          ...state.consumer.properties,
          firstName: state.consumer.properties.firstName || action.payload.userGivenName,
          lastName: state.consumer.properties.lastName || action.payload.userFamilyName,
          phoneNumber: state.consumer.properties.phoneNumber || action.payload.userPhoneNumber,
          email: state.consumer.properties.email || action.payload.userEmail,
        },
      },
    }
  }
  case LOADED_DEPENDENCIES: {
    const params = queryString.parse(window.location.search)

    return {
      ...state,
      agreements: getAgreements(),
      orderReference: storeOrderReference(params),
    }
  }
  case REMOVE_REJECTION_ERROR: {
    return {
      ...state,
      rejectionError: undefined,
    }
  }
  case ROUTE_CHANGE: {
    return {
      ...state,
      rejectionError: false,
      hasProvidedVoucher: false,
    }
  }
  case `${FINGERPRINT_DEVICE}_FULFILLED`: {
    return {
      ...state,
      deviceFingerprint: action.payload,
    }
  }
  case `${RECREATE_CART}_PENDING`:
  case `${LOAD_CART_CONTENTS}_PENDING`:
    return {
      ...state,
      disabledSubmit: true,
    }
  case `${RECREATE_CART}_FULFILLED`:
    return {
      ...state,
      ...cartValues(action.payload),
      lastAction: {
        type: `recreate`,
      },
      disabledSubmit: false,
      hasLoadedCartContents: true,
    }
  case `${LOAD_CART_CONTENTS}_FULFILLED`:
    return {
      ...state,
      ...cartValues(action.payload),
      lastAction: {
        type: `load`,
      },
      disabledSubmit: false,
      hasLoadedCartContents: true,
    }
  case `${LOAD_CART_CONTENTS}_REJECTED`:
    console.warn(`Failed to load cart contents`)
    return {
      ...state,
      disabledSubmit: false,
      hasLoadedCartContents: true,
    }
  case `${LOAD_COUNTRIES}_FULFILLED`:
    return {
      ...state,
      countries: action.payload.filter(country => country.code).sort((a, b) => {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0
      }),
    }
  case `${SAVE_SHIPPING_SELECTION}_PENDING`:
    return {
      ...state,
      isUpdatePending: true,
      disabledSubmit: true,
    }
  case `${SAVE_SHIPPING_SELECTION}_REJECTED`:
    return {
      ...state,
      isUpdatePending: false,
      disabledSubmit: false,
    }
  case `${SAVE_SHIPPING_SELECTION}_FULFILLED`:
    return {
      ...state,
      isUpdatePending: false,
      disabledSubmit: false,
    }
  case `${ADD_CART_ITEM}_PENDING`:
    return {
      ...state,
      isUpdatePending: true,
      disabledSubmit: true,
      errorCode: undefined,
      atcAction: action.meta.type,
    }
  case `${ADD_CART_ITEM}_FULFILLED`:
    return {
      ...state,
      ...cartValues(action.payload.cartResponse),
      lastAction: {
        type: `add`,
        sku: action.payload.skuOptions.sku,
        time: new Date().getTime(),
      },
      atcAction: null,
      isUpdatePending: false,
      disabledSubmit: false,
    }
  case `${ADD_CART_ITEM}_REJECTED`:
    return {
      ...state,
      isUpdatePending: false,
      disabledSubmit: false,
      errorCode: action.payload.errorCode,
    }
  case `${UPDATE_CART_ITEM}_PENDING`:
    return {
      ...state,
      isUpdatePending: true,
      disabledSubmit: true,
      atcAction: action.meta.type,
      errorCode: undefined,
    }
  case `${UPDATE_CART_ITEM}_FULFILLED`:
    return {
      ...state,
      ...cartValues(action.payload.cartResponse),
      lastAction: {
        type: `update`,
        sku: action.payload.cartItem.sku,
        time: new Date().getTime(),
      },
      isUpdatePending: false,
      disabledSubmit: false,
      atcAction: null,
    }
  case `${UPDATE_CART_ITEM}_REJECTED`:
    return {
      ...state,
      isUpdatePending: false,
      disabledSubmit: false,
      errorCode: action.payload.errorCode,
    }
  case SET_TAX_LOADING:
    return {
      ...state,
      isTaxLoading: action.payload,
    }
  case SET_TAX_ERROR:
    return {
      ...state,
      rejectionError: action.payload ? TAX_REJECTION_ERROR : false,
    }
  case SET_UNABLE_TO_SHIP_ERROR:
    return {
      ...state,
      rejectionError: action.payload ? UNABLE_TO_SHIP_ERROR : false,
    }
  case `${APPLY_VOUCHER}_PENDING`:
    return {
      ...state,
      hasProvidedVoucher: true,
      isUpdatingVoucher: true,
      isUpdatePending: true,
      disabledSubmit: true,
    }
  case `${APPLY_VOUCHER}_REJECTED`:
    return {
      ...state,
      voucher: null,
      isUpdatingVoucher: false,
      isUpdatePending: false,
      disabledSubmit: false,
    }
  case `${APPLY_VOUCHER}_FULFILLED`:
    return {
      ...state,
      ...cartValues(action.payload),
      isUpdatingVoucher: false,
      isUpdatePending: false,
      disabledSubmit: false,
    }
  case `${REMOVE_VOUCHER}_PENDING`:
    return {
      ...state,
      isUpdatingVoucher: true,
    }
  case `${REMOVE_VOUCHER}_REJECTED`:
    return {
      ...state,
      isUpdatingVoucher: false,
    }
  case `${REMOVE_VOUCHER}_FULFILLED`:
    return {
      ...state,
      ...cartValues(action.payload),
      hasProvidedVoucher: false,
      isUpdatingVoucher: false,
    }
  case REMOVE_VOUCHER_MESSAGE:
    return {
      ...state,
      hasProvidedVoucher: false,
    }
  case `${START_EXTERNAL_PAYMENT_METHOD}_PENDING`:
  case `${PLACE_ORDER}_PENDING`:
    return {
      ...state,
      disabledSubmit: true,
      rejectionError: false,
    }
  case `${PLACE_ORDER}_FULFILLED`:
    redirect(action.payload, state.orderReference)
    return {
      ...state,
      // disabledSubmit: false // wait for redirect
    }
  case `${START_EXTERNAL_PAYMENT_METHOD}_REJECTED`:
  case `${PLACE_ORDER}_REJECTED`:
    return {
      ...state,
      disabledSubmit: false,
      rejectionError: rejectionError(action.payload?.status),
      failedCheckoutAttempts: state.failedCheckoutAttempts + 1,
    }
  case `${START_EXTERNAL_PAYMENT_METHOD}_FULFILLED`:
    redirect(action.payload)
    return state
  case UPDATE_CONSUMER_DETAILS:
    return {
      ...state,
      consumer: merge({}, state.consumer, action.payload),
    }
  default:
    return state
  }
}

function cartValues({
  items,
  ...data
}) {
  const unavailableItems = getUnavailableItems(items)

  return {
    ...data,
    items: items.reverse(),
    unavailableItems,
  }
}

function rejectionError(err) {
  if (String(err).startsWith(5)) {
    return `500`
  }

  return err
}

function getUnavailableItems(items) {
  return items.filter(item => item.availability === AVAILABILITY_OUTOFSTOCK)
}

function redirect(orderResponse, utr) {
  if (orderResponse && orderResponse.code === `SUCCESS`) {
    const params = {
      Authorization: orderResponse.rawToken,
      Account: orderResponse.siteTag,
      uat: orderResponse.uat,
      utt: orderResponse.utt,
      utr: utr || orderResponse.utr,
      paymentMethod: orderResponse.paymentMethod,
    }

    const asQueryString = Object.keys(params).map(param => {
      return `${param}=${params[param]}`
    }).join(`&`)

    if (orderResponse.redirectURL) {
      postRedirect({
        params,
        url: orderResponse.redirectURL,
      })
    } else {
      let subfolder = window.$COUNTRYFOLDER ? `${window.$COUNTRYFOLDER}` : ``
      pushRedirect(`${subfolder}/${getLanguage()}/complete?${asQueryString}`)
    }
  } else {
    console.error(`Told to redirect after an order but there was either no order response or it was not successful`, orderResponse)
  }
}

export default cart

function storeOrderReference({
  utr,
  pgTransactionId,
  paymentMethod,
  statusCode,
}) {
  if (
    utr &&
    pgTransactionId !== `null` &&
    paymentMethod?.toLowerCase() === BRANDCODE_PAYPAL_EXPRESS &&
    statusCode !== `ADRESULT_CANCELLED`
  ) {
    return utr
  }
}

function getAgreements() {
  const agreements = getConfigOptions(`checkout.agreements`)
  let build = {}

  if (agreements) {
    agreements.forEach(agreement => {
      build[agreement.id] = false
    })
  }

  return build
}
