import { useSelector } from 'react-redux'

import { getMetaOptions } from 'global-content/config'
import { BRANDCODE_APPLEPAY, BRANDCODE_APPLEPAY_EXPRESS } from 'utils/constants'

export function usePaymentMethods(key) {
  const showTestPayments = useSelector(state => state.userPreferences.showTestPayments)
  const paymentMethods = getMetaOptions(`paymentOptions`).filter(filterMethods)

  return paymentMethods

  function filterMethods(method) {
    if (method.inTestMode && !showTestPayments) {
      return false
    }

    if (key) {
      if (
        (key === `expressPayment` && method.brandCode === BRANDCODE_APPLEPAY_EXPRESS) ||
        (key === `showAtPaymentStep` && method.brandCode === BRANDCODE_APPLEPAY)
      ) {
        return window.ApplePaySession?.canMakePayments()
      }

      if (method[key]) {
        return true
      }

      return false
    }

    return true
  }
}
