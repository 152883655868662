import { useMediaQuery } from 'react-responsive'
import mediaSizes from 'css/media-sizes.json'

export const Responsive = ({
  children,
  deviceRange = [],
}) => {
  const [largest, smallest] = deviceRange

  const isInDeviceSelection = useMediaQuery({
    minWidth: (mediaSizes[smallest] || 0) + 1,
    maxWidth: mediaSizes[largest],
  })

  return isInDeviceSelection ? children : null
}
