import React from 'react'

import { utilizeTerritories } from 'global-content/utils'
import { getMetaOptions } from 'global-content/config'

export const InputCountrySelect = React.forwardRef(function InputCountrySelect(props, ref) {
  const [countries, setCountries] = React.useState([])

  React.useEffect(() => {
    utilizeTerritories().then(territories => {
      setCountries(territories)

      if (!props.value) {
        props.onChange({
          target: {
            value: getMetaOptions(`country.alpha3Code`),
          },
        })
      }
    })
  }, [])

  return (
    <select
      data-testid="country-select"
      ref={ref}
      {...props}
    >
      {countries.map(option => {
        return (
          <option
            key={option.code}
            value={option.code}
          >
            {option.name}
          </option>
        )
      })}
    </select>
  )
})
