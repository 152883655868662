import React from 'react'
import { useSelector } from 'react-redux'

import { getAlgoliaIndex } from 'global-content/algolia'
import * as algolia from 'services/algolia'
import { getSeenProducts } from 'utils/seenProducts'
import { matchPath, useLocation } from 'react-router-dom'
import {
  PRODUCT_ROUTE,
  CHECKOUT_ROUTE,
} from 'Router/fixtures'
import { isTracking } from 'utils/tracking'

export function useRecentlyViewedTray({
  analyticsTitle,
  algoliaIndex,
  items,
}) {
  const { pathname } = useLocation()
  const activeLanguage = useSelector(state => state.language.active)
  const currentProductId = useSelector(state => state.details.product?.id)
  const cartProductIds = useSelector(state => state.cart.items ? state.cart.items.map(item => item.productId) : [])
  const [products, setProducts] = React.useState([])

  const header = items.find(item => item.data.component === `trayHeader`)
  const footer = items.find(item => item.data.component === `trayFooter`)

  React.useEffect(() => {
    setProducts([])
    const recentlyViewed = getSeenProducts(
      checkRouteIs(PRODUCT_ROUTE)
        ? [currentProductId] : checkRouteIs(CHECKOUT_ROUTE)
          ? cartProductIds : []
    )

    if (recentlyViewed.length) {
      algolia.getProducts({
        analytics: isTracking(),
        clickAnalytics: isTracking(),
        language: activeLanguage,
        index: getAlgoliaIndex(algoliaIndex),
        options: {
          distinct: 1,
          analyticsTags: [
            `RecentlyViewedTray`,
            ...(analyticsTitle ? [analyticsTitle] : []),
          ],
          filters: {
            productId: {
              includes: recentlyViewed,
            },
          },
        },
      }).then(data => {
        setProducts(sortProductHits(data.hits, recentlyViewed))
      })
    }
  }, [currentProductId])

  return {
    products,
    showTray: products.length > 0,
    header,
    footer,
  }

  function sortProductHits(hits = [], recentlyViewed) {
    return hits.sort((a, b) => recentlyViewed.indexOf(a.productId) - recentlyViewed.indexOf(b.productId)).map((product, index) => {
      return {
        ...product,
        offsettedPosition: index + 1,
      }
    })
  }

  function checkRouteIs(path) {
    return Boolean(matchPath(pathname, { path }))
  }
}
