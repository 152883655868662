import React, { useMemo, forwardRef } from 'react'
import { ModernFactoryComponent } from '../ModernFactoryComponent'

export const LokaTranslateComponent = forwardRef(({
  Tag,
  data: {
    lokaKey,
    ...variables
  },
  ...rest
}, ref) => {
  const output = useMemo(() => {
    return lokaKey.replace(/\{([^}]+)\}/g, (match, key) => {
      return variables[key] || match
    })
  }, [lokaKey, variables])

  return (
    <Tag
      ref={ref}
      {...rest}
    >
      {output}
    </Tag>
  )
})

export const LokaTranslate = ModernFactoryComponent(LokaTranslateComponent)
