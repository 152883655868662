import React from 'react'

import { I18n } from 'utils/i18n'
import { Button } from 'factory-components/Button'

import { webLabels } from './fixtures'

export const RefreshButton = () => {
  return (
    <Button
      data={{
        content: {
          html: I18n.t(webLabels.button),
        },
        positioning: {
          buttonHeight: `medium`,
          buttonWidth: `medium`,
        },
        styling: {
          type: `primary`,
        },
      }}
      onClick={() => window.location.reload(true)}
    />
  )
}
