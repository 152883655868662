import React from 'react'
import { ButtonGroup as ComponentButtonGroup } from 'components/ButtonGroup'

export const ButtonGroup = ({
  data,
  items,
}) => {
  const {
    config = {},
  } = data

  const {
    matchButtonWidths,
    orientation,
  } = config

  return (
    <ComponentButtonGroup
      buttons={items}
      matchButtonWidths={matchButtonWidths}
      orientation={orientation}
    />
  )
}
