import { useSelector } from 'react-redux'

import { remapTextAlign } from 'utils/remapTextAlign'

export function useRemapTextAlign(dir, rtlSupport) {
  const langDir = useSelector(state => state.language.langDir)
  if(rtlSupport) {
    return remapTextAlign(dir, langDir)
  } else {
    return dir
  }
}
