import React from 'react'
import classNames from 'classnames'

import shared from 'components/Skeleton/Skeleton.css'
import styles from './SkeletonButton.css'

export const SkeletonButton = ({
  margin,
  type,
  width,
}) => (
  <div
    className={classNames(shared.skeleton, styles[type])}
    style={{
      margin,
      width,
    }}
  />
)
