import React from 'react'
import { useDispatch } from 'react-redux'

import { updateCartItem } from 'state/actions/cart'
import { I18n } from 'utils/i18n'
import { useSkuComponents } from 'hooks/useSkuComponents'

import { webLabels } from './fixtures'
import styles from './MiniCartItem.css'
import { AVAILABILITY_GIFTONLY } from 'utils/constants'

export const MiniCartItem = ({
  item,
  index,
}) => {
  const dispatch = useDispatch()
  const displayDetails = item.availability !== AVAILABILITY_GIFTONLY
  const {
    Image,
    Title,
    Price,
    Discounts,
    Color,
    Quantity,
    SizeType,
    Size,
  } = useSkuComponents({
    item,
    testId: `minicart`,
    index,
  })

  const name = I18n.t(webLabels.removeAria, {
    replace: {
      title: item.name,
    },
  })

  return (
    <div className={styles.item}>
      <div className={styles.image}>
        <Image />
      </div>
      <div className={styles.details}>
        <div className={styles.title}>
          <Title />
        </div>
        {displayDetails && (
          <>
            <div className={styles.aboutItem}>
              <Price />
              <Color />
              <Quantity />
              <SizeType />
              <Size />
              <Discounts />
            </div>
            <button
              aria-label={name}
              className={styles.remove}
              name={name}
              onClick={() => {
                dispatch(updateCartItem({
                  cartItem: item,
                  quantity: 0,
                  atcAction: `primary`,
                }))
              }}
            >
              {I18n.t(webLabels.remove)}
            </button>
          </>
        )}
      </div>
    </div>
  )
}
