"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.KlaviyoEmbedForm = void 0;
var react_1 = __importStar(require("react"));
var Layout_1 = require("components/Layout");
var ErrorAdditional_1 = require("utils/ErrorAdditional");
var reportError_1 = require("services/reportError");
var config_1 = require("global-content/config");
var KlaviyoEmbedForm = function (_a) {
    var data = _a.data, tag = _a.tag;
    var _b = data.content, content = _b === void 0 ? {} : _b, layout = data.layout, customClassName = data.customClassName;
    var formId = content.formId;
    var iframeRef = (0, react_1.useRef)(null);
    var timeoutRef = (0, react_1.useRef)(-1);
    var klaviyoCompanyId = (0, config_1.getMetaOptions)("integrations.klaviyo.publicApiKey");
    (0, react_1.useEffect)(function () {
        var _a, _b;
        if (((_b = (_a = iframeRef.current) === null || _a === void 0 ? void 0 : _a.style) === null || _b === void 0 ? void 0 : _b.height) === "0px") {
            retryWithExponentialBackoff({
                func: setIframeHeight,
                finalCallbackFunc: checkForErrorLoading,
                maxWaitTimeMs: 20000
            });
        }
        // Clear any timeouts when this component unmounts.
        return cleanUpTimeout;
    }, [iframeRef.current]);
    if (Boolean(klaviyoCompanyId) === false) {
        // Return null component if klaviyo company id is not configured
        return null;
    }
    function setIframeHeight() {
        var IFRAME_PADDING = 16; // Default padding inside iframe content used to calculate container's height
        var newHeight = getIframeBodyHeight();
        if (newHeight > 10 && iframeRef.current) {
            iframeRef.current.style.height = "".concat(newHeight + IFRAME_PADDING, "px");
        }
    }
    function getIframeBodyHeight() {
        var _a;
        try {
            if ((_a = iframeRef === null || iframeRef === void 0 ? void 0 : iframeRef.current) === null || _a === void 0 ? void 0 : _a.contentWindow) {
                return iframeRef.current.contentWindow.document.body.scrollHeight;
            }
            else {
                return 0;
            }
        }
        catch (err) {
            // Note: There might be an error when trying to get the iframe height
            // if its already been unmounted.
            return 0;
        }
    }
    function checkForErrorLoading(totalTimePast) {
        var iframeHeight = getIframeBodyHeight();
        if (iframeHeight <= 10) {
            reportIframeLoadingError(totalTimePast, iframeHeight);
        }
    }
    function reportIframeLoadingError(totalTimePast, iframeHeight) {
        var _a;
        (0, reportError_1.reportError)(new ErrorAdditional_1.ErrorAdditional({
            title: "Failed to load Klaviyo Embed form",
            message: "Could not load FormID ".concat(formId, " after waiting ").concat(totalTimePast, " milliseconds."),
            additional: {
                formId: formId,
                totalTimePast: totalTimePast,
                iframeHeight: iframeHeight
            },
            tags: (_a = {},
                _a[ErrorAdditional_1.SENTRY_TAGS.SOURCE_FILE] = "src/factory-components/KlaviyoEmbedForm/KlaviyoEmbedForm.tsx",
                _a)
        }));
    }
    // Increase timeout internval by 150% on every retry
    // Milliseconds retry steps: 10, 15, 23, 35, 53, 80, 120, 180, 270, 405, ...
    function retryWithExponentialBackoff(_a) {
        var func = _a.func, finalCallbackFunc = _a.finalCallbackFunc, maxWaitTimeMs = _a.maxWaitTimeMs;
        var initialInterval = 10; // milliseconds
        var interval = initialInterval;
        var totalTimePast = 0;
        function execute() {
            func();
            totalTimePast += interval;
            if (totalTimePast < maxWaitTimeMs) {
                interval = Math.ceil(interval * 1.5); // Increase exponentially
                timeoutRef.current = window.setTimeout(execute, interval);
            }
            else {
                finalCallbackFunc(totalTimePast);
            }
        }
        execute();
    }
    function cleanUpTimeout() {
        clearTimeout(timeoutRef.current);
    }
    // Note: the iframe window.location.href is equal to "about:srcdoc".
    // This needs to be added to URL exclusions for all other Klaviyo forms.
    var htmlContent = "\n  <html>\n    <head>\n      <script async type=\"text/javascript\" src=\"https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=".concat(klaviyoCompanyId, "\"></script>\n    </head>\n    <body>\n      <div class=\"").concat(formId, "\"></div>\n    </body>\n  </html>\n  ");
    return (react_1["default"].createElement("div", { "data-testid": tag },
        react_1["default"].createElement(Layout_1.Layout, { className: customClassName, layout: layout },
            react_1["default"].createElement("iframe", { ref: iframeRef, srcDoc: htmlContent, style: { border: "none", height: "0px" }, title: "Embed Form", width: "100%" }))));
};
exports.KlaviyoEmbedForm = KlaviyoEmbedForm;
