import {
  LOAD_ADDRESS_SCHEMA,
  LOAD_CHECKOUT_SCHEMA,
  LOAD_SCHEMA,
} from 'state/actions'

const initialState = {
  checkout: {},
  shipping: {},
  billing: {},
}

const schemas = (state = initialState, action) => {
  switch (action.type) {
  case `${LOAD_SCHEMA}_PENDING`:
    return state
  case `${LOAD_SCHEMA}_REJECTED`:
    return state
  case `${LOAD_SCHEMA}_FULFILLED`:
    return {
      ...state,
      [action.payload.name]: action.payload.schema,
    }
  case `${LOAD_CHECKOUT_SCHEMA}_FULFILLED`:
    return {
      ...state,
      checkout: {
        [action.payload.language]: action.payload.schema,
      },
    }
  case `${LOAD_ADDRESS_SCHEMA}_FULFILLED`:
    return {
      ...state,
      [action.payload.type]: {
        [action.payload.language]: action.payload.schema,
      },
    }
  default:
    return state
  }
}

export default schemas
