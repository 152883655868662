import React from 'react'
import classNames from 'classnames'

import styles from './TabButton.css'

export const TabButton = ({
  active,
  children,
  onClick,
  tabStyle,
}) => {
  return (
    <button
      className={classNames(styles.button, {
        [styles.defaultStyle]: !tabStyle,
        [styles.active]: !tabStyle && active,

        // absolute
        [`tabStyle--${tabStyle}`]: tabStyle,
        active: tabStyle && active,
      })}
      onClick={onClick}
    >
      <div className={styles.innerWrapper}>
        {children}
      </div>
    </button>
  )
}
