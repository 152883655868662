import React from 'react'
import { Heading } from 'components/Heading'
import { Subtitle } from 'components/Subtitle'
import styles from './EmailSignupSplashPrompt.css'

export const EmailSignupSplashPrompt = ({
  content = {},
  positioning = {},
  styling = {},
}) => (
  <div data-testid="email-signup-splash-prompt">
    {content.title &&
      <div
        className={styles.header}
        data-testid="email-signup-splash-prompt-header-wrapper"
        style={{ ...positioning.title }}
      >
        <Heading
          config={{
            elementTag: `h2`,
          }}
          content={{
            ...content.title,
          }}
          data-testid="email-signup-splash-prompt-header"
          styling={{
            color: `black`,
            textAlign: `center`,
            fontSize: `large`,
            fontWeight: 400,
            ...styling.title,
          }}
        />
      </div>
    }
    {content.subtitle &&
      <div
        className={styles.subtitle}
        data-testid="email-signup-splash-prompt-subtitle-wrapper"
        style={{ ...positioning.subtitle }}
      >
        <Subtitle
          content={{
            ...content.subtitle,
          }}
          data-testid="email-signup-splash-prompt-subtitle"
          styling={{
            color: `black`,
            textAlign: `center`,
            fontSize: `small`,
            fontWeight: 400,
            ...styling.subtitle,
          }}
        />
      </div>
    }
  </div>
)
