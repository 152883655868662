import store from 'state/reducers'
import { getMetaOptions } from 'global-content/config'
import { currencyFormatted } from 'utils'
import { reportError } from 'services/reportError'
import { ErrorAdditional, SENTRY_TAGS } from 'utils/ErrorAdditional'

import { webLabels } from './fixtures'

export const I18n = {
  get(language) {
    return fetch(`${getMetaOptions(`countryFolder`)}/language/${language}.json?cb=${window.$cacheBust}`)
      .then(response => {
        return response.json()
      })
      .then(json => {
        window.$I18n.languageMap[language] = json
        window.$I18n.replaceMap[language] = {
          Localised: `Localised`,
          clientName: getClientName(),
          country: getCountryName(language),
          freeShipThreshold: getMetaOptions(`freeShipThreshold`),
          localisedAddress: getMetaOptions(`localisedEntity.address`),
          localisedCountry: getMetaOptions(`localisedEntity.country`),
          localisedEntity: getMetaOptions(`localisedEntity.name`),
          returnAddress: getMetaOptions(`returns.address`),
          returnShippingPrice: getReturnShippingPrice(),
          returnsPeriod: getMetaOptions(`returns.window`),
          shoppingSessionId: window.$shoppingSessionId.value,
          siteName: getSiteName(language),
          name: getSiteName(language),
          timeToShip: getMetaOptions(`supportPages.timeToShip`),
        }
      }).catch(() => {
        return {}
      })
  },

  t(i18nKey, options = {}) {
    const {
      returnKey = true,
      language = store.getState().language.active,
    } = options

    if (!window.$I18n?.languageMap?.[language]) {
      return i18nKey
    }

    const value = window.$I18n.languageMap[language][i18nKey]

    if (value === undefined && returnKey) {
      return i18nKey
    }

    return this.interpolate(value, options)
  },

  interpolate(value, options = {}) {
    if (value) {
      const {
        language = store.getState().language.active,
        logMissing = true,
        replace = {},
      } = options

      const regEx = /{{(.+?)}}/gi
      const interpolated = value.replace(regEx, (match, p1) => {
        if (Object.prototype.hasOwnProperty.call(replace, p1) || Object.prototype.hasOwnProperty.call(window.$I18n.replaceMap[language] || {}, p1)) {
          return replace[p1] || window.$I18n.replaceMap[language][p1] || ``
        } else {
          if (logMissing) {
            reportError(new ErrorAdditional({
              title: `Missing interpolation key`,
              message: `${match} for ${value}`,
              severity: 2,
              tags: {
                [SENTRY_TAGS.SOURCE_FILE]: `src/utils/i18n.js`,
              },
            }))
          }

          return match
        }
      })

      return interpolated.replace(/\\n/g, `\n`) // removing extra escape characters in front of line breaks in fetched text
    }

    return ``
  },
}

export function getCountry() {
  return getMetaOptions(`country.code`)
}

export function getCountryName(language) {
  // note: country name is only specify in english and local language
  const countryCode = getMetaOptions(`country.code`)
  return I18n.t(webLabels.country.replace(`*`, countryCode), {
    language,
  })
}

export function getClientName() {
  // always in english
  return getMetaOptions(`clientName`)
}

export function getSiteName(language) {
  // returns the language specific name in LOKA if available, otherwise concats
  // the client name in English with the country name in local language
  const siteName = I18n.t(webLabels.siteName, language)

  if (siteName !== webLabels.siteName && siteName !== ``) {
    return siteName
  } else {
    return [getMetaOptions(`clientName`), getCountryName(language)].join(` `)
  }
}

export function getReturnShippingPrice() {
  return currencyFormatted(getMetaOptions(`returns.shippingPrice`))
}
