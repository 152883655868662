import mergeWith from 'lodash/mergeWith'
import get from 'lodash/get'

import { customizer } from 'utils/mergeCustomizer'

export function setComponents() {
  window.$content.components = mergeWith({}, defaultComponentConfigs, window.$CONFIG.components, customizer)
}

export function getComponentOptions(key) {
  return get(window.$content.components, key)
}

export const defaultComponentConfigs = {
  productCardOptions: {
    descriptionPadding: `1rem 0 2rem`,
    favIconSize: `2rem`,
    imageBackgroundColor: undefined,
    imagePadding: undefined,
    rankingSize: `xs`,
    showBrand: true,
    showFavoriteButton: true,
    showLowStock: false,
    showPrice: true,
    showRanking: false,
    showSwatches: true,
    showTitle: true,
    swatchLimit: 5,
    swatchSize: `small`,
    textAlign: `left`,
  },
  checkout: {
    showEmailSignUp: false,
  },
  favoritesLink: {
    size: `3.2rem`,
  },
  filterMenuButton: {
    content: {
      areas: {
        dropdown: {
          fontSize: `xs`,
          open: {
            iconRef: `angle_up`,
          },
          close: {
            iconRef: `angle_down`,
          },
        },
        accordion: {
          fontSize: `xs`,
          open: {
            iconRef: `angle_up`,
          },
          close: {
            iconRef: `angle_down`,
          },
        },
      },
    },
  },
  header: {
    components: {
      search: {
        config: {},
        styling: {
          mobileToggle: {
            iconSize: `3.4rem`,
          },
        },
      },
    },
    container: `medium`,
    mobileNavigationPositionRight: false,
    layout: {},
    config: {
      showIconLabels: false,
    },
    menuIconSize: `2.5rem`,
  },
  linkSet: {
    size: `xs`,
  },
  productTray: {
    innerLayout: undefined,
  },
  optionHeader: {
    size: undefined,
  },
  paginator: {
    size: `regular`,
  },
  price: {
    showFrom: false,
  },
  product: {
    gallery: {
      removeFromBeginningOfGallery: 0,
    },
  },
  scrollUp: {
    size: `2.8rem`,
  },
  sideNavLink: {
    config: {
      activeState: 1,
      hoverState: 1,
    },
  },
  swatch: {
    type: `square`,
  },
}
