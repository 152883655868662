import React from 'react'
import { useSelector } from 'react-redux'

import { I18n } from 'utils/i18n'
import { webLabels } from './fixtures'

import styles from './AccountWelcome.css'

export const AccountWelcome = () => {
  const userGivenName = useSelector(state => state.account.userGivenName)

  return (
    <div className={styles.container}>
      {I18n.t(webLabels.myAccountLabel, {
        replace: {
          name: userGivenName,
        },
      })}
    </div>
  )
}
