import React from 'react'
import { useSelector } from 'react-redux'
import { SectionLabel } from 'ae-components/PDPSectionLabel'
import { ProductOptionsGeneric } from 'components/ProductOptionsGeneric'

export const ProductSizeType = ({
  data,
}) => {
  const {
    config = {},
    customClassName,
  } = data

  const {
    buttonType,
    sizeTypeOptions,
  } = config

  const optionLevels = useSelector((state) => state.details.product.optionLevels)
  const productCollections = useSelector((state) => new Set(state.details.product.collections.map((collection) => collection.tag)))
  const sizeTypeOption = sizeTypeOptions.find((option) => productCollections.has(option.collection)) || sizeTypeOptions[0]

  return optionLevels.includes(`sizeType`) && (
    <SectionLabel
      data={{
        content: {
          text: sizeTypeOption.label,
        },
        customClassName,
      }}
    >
      <ProductOptionsGeneric
        config={{ hideHeader: true, buttonType }}
        level="sizeType"
      />
    </SectionLabel>
  )
}
