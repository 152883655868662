import { getMetaOptions } from 'global-content/config'
import { unversionedStorage } from 'utils/storage'
import { getLanguage } from 'utils'
import { LABELS_LANGUAGE } from 'utils/constants'

export function calculatePrefix(countryFolder, country, languages, languagePrefix, initial) {

  const acceptablePrefixList = [
    `${LABELS_LANGUAGE}-${country}`,
    ...languages.map(lang => `${lang}-${country}`),
  ]

  // This code need some major rewrite but to reduce risk, I leave it as-is and enhanced it for countryFolder
  const [, startingPrefix, ...rest] = initial.split(`/`)
  if (countryFolder) {
    const [secondPrefix, ...countryFolderRest] = rest
    if (startingPrefix === country && languages.includes(secondPrefix)) {
      return `/${startingPrefix}/${secondPrefix}/${countryFolderRest.join(`/`)}`
    } else if (isUppercaseCountryCode(startingPrefix)) {
      return `/${startingPrefix.toLowerCase()}/${secondPrefix}/${countryFolderRest.join(`/`)}`
    } else if (!acceptablePrefixList.includes(`${secondPrefix}-${startingPrefix}`)) {
      const initialCleaned = initial && initial.startsWith(`/${country}`) ? initial.substring(3) : initial
      return `/${country}/${languagePrefix}${initialCleaned}`
    }
  } else {
    if (languages.includes(startingPrefix)) {
      return `/${startingPrefix}-${country}/${rest.join(`/`)}`
    } else if (isUppercaseCountryCode(startingPrefix)) {
      return `/${startingPrefix.toLowerCase()}/${rest.join(`/`)}`
    } else if (!acceptablePrefixList.includes(startingPrefix)) {
      return `/${languagePrefix}-${country}${initial}`
    }
  }

}

export function setPrefixedUrl() {
  const country = getMetaOptions(`country.code`).toLowerCase()
  const languages = getMetaOptions(`languages`)
  const initial = `${window.location.pathname}${window.location.search}`
  const languagePrefix = getLanguagePrefix()

  const newPrefix = calculatePrefix(getMetaOptions(`countryFolder`), country, languages, languagePrefix, initial)
  if (newPrefix) {
    window.history.replaceState({}, ``, newPrefix)
  }

  // https://www.w3schools.com/tags/ref_language_codes.asp
  document.querySelector(`html`).setAttribute(`lang`, languagePrefix)
  document.querySelector(`html`).className = languagePrefix
}

export function getLanguagePrefix() {
  const clientTag = getMetaOptions(`clientTag`)
  const country = getMetaOptions(`country.code`).toLowerCase()
  const defaultLanguage = getMetaOptions(`defaultLanguage`)
  const languages = getMetaOptions(`languages`)

  // if only one language, return that early
  if (languages.length === 1) {
    return languages[0]
  }

  let languagePrefix = defaultLanguage
  languagePrefix = getBrowserLanguage(languagePrefix)
  languagePrefix = getLocalstorageLang(languagePrefix)

  if (languages.includes(getLanguage())) {
    languagePrefix = getLanguage()
  }

  return languagePrefix

  function getBrowserLanguage(lang) {
    const browserLanguage = getNavigatorLanguage()
    if (
      languages.includes(browserLanguage) &&
      getMetaOptions(`languageDetection`)
    ) {
      lang = browserLanguage
    }

    return lang
  }

  function getLocalstorageLang(lang) {
    const preChosenLanguages = unversionedStorage.get(`chosenLanguages`) || {}
    const preChosenLang = preChosenLanguages[`${clientTag}-${country}`]

    if (languages.includes(preChosenLang)) {
      lang = preChosenLang
    }

    return lang
  }
}

function getNavigatorLanguage() {
  let navigatorLanguage = navigator.userLanguage || navigator.language || navigator.browserLanguage || `en`

  if (navigator.languages && navigator.languages.length) {
    navigatorLanguage = navigator.languages[0]
  }

  return navigatorLanguage.substr(0, 2)
}

export function isUppercaseCountryCode(str) {
  const country = getMetaOptions(`country.code`)?.toUpperCase()

  if (!country) {
    return false
  }

  const re = new RegExp(getMetaOptions(`countryFolder`)
    ? `(^${country}$)`
    : `(^[a-z]{2}\-${country}$)`)

  // NOTE:
  // matches "en-CA" or "CA"
  return re.test(str)
}
