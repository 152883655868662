import React from 'react'
import classNames from 'classnames'

import styles from './Scrollable.css'

export const Scrollable = ({
  children,
  className,
  ...rest
}) => {
  return (
    <div
      className={classNames(styles.scrollable, {
        [className]: className,
      })}
      {...rest}
    >
      {children}
    </div>
  )
}
