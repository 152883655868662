import React from 'react'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'

import { useOnClickOutside } from 'hooks/useOnClickOutside'
import mediaSizes from 'css/media-sizes.json'
import { I18n } from 'utils/i18n'
import { scrollToElement } from 'utils/scrollToElement'

import { webLabels } from './fixtures'
import styles from './HeaderDropdown.css'

export const HeaderDropdown = ({
  arrowColor,
  ButtonContent,
  buttonDataTestId,
  buttonOnClick,
  children,
  dropdownDataTestId,
  dropdownWidth,
  openWhen,
  closeWhen,
}) => {
  const [expanded, setExpanded] = React.useState()
  const [offset, setOffset] = React.useState({})
  const [dropdownOnLeft, setDropdownOnLeft] = React.useState(false)
  const dropdownRef = React.useRef()
  const expandedRef = React.useRef()

  useOnClickOutside(dropdownRef, () => setExpanded(false), true)
  const { pathname } = useLocation()

  const getOffset = React.useCallback(() => {
    const dropdownCoords = dropdownRef.current.getBoundingClientRect()

    if (dropdownRef.current) {
      const isMobile = !window.matchMedia(`(min-width: ${mediaSizes.mobileLarge + 1}px)`).matches
      const windowHalfwayPoint = window.innerWidth / 2
      const containerMidwayWidth = dropdownCoords.width / 2
      const arrowOffset = containerMidwayWidth / 2
      const isDropdownOnLeft = windowHalfwayPoint > dropdownCoords.left

      let pushedIn = dropdownCoords.right - dropdownWidth + containerMidwayWidth

      if (isDropdownOnLeft) {
        pushedIn = dropdownCoords.left - containerMidwayWidth
      }

      setOffset({
        '--arrowOffset': `${arrowOffset}px`,
        top: dropdownCoords.bottom,
        left: isMobile ? 0 : pushedIn,
      })

      setDropdownOnLeft(isDropdownOnLeft)
    }
  }, [])

  React.useEffect(() => {
    window.addEventListener(`resize`, getOffset)

    return () => {
      window.removeEventListener(`resize`, getOffset)
    }
  }, [])

  React.useEffect(() => {
    if (openWhen) {
      openWhen(() => {
        setExpanded(true)
        scrollToElement(expandedRef.current)
      })
    }
  }, [openWhen])

  React.useEffect(() => {
    if (closeWhen && expanded) {
      closeWhen(() => setExpanded(false))
    }
  }, [closeWhen])

  React.useEffect(() => {
    getOffset()
  }, [expanded])

  React.useEffect(() => {
    setExpanded(false)
  }, [pathname])

  return (
    <div
      className={styles.dropdown}
      ref={dropdownRef}
    >
      <button
        aria-label={I18n.t(webLabels.heading)}
        data-testid={buttonDataTestId}
        name={I18n.t(webLabels.heading)}
        onClick={handleToggle}
      >
        <ButtonContent />
      </button>
      <div
        className={styles.expandedWrapper}
        ref={expandedRef}
        style={offset}
      >
        {
          expanded &&
            <div
              className={classNames(styles.card, {
                [styles.dropdownOnLeft]: dropdownOnLeft,
                [styles.dropdownOnRight]: !dropdownOnLeft,
              })}
              data-testid={dropdownDataTestId}
              style={{
                '--arrowColor': arrowColor,
                // bottom: offset,
                width: dropdownWidth,
              }}
            >
              {children}
            </div>
        }
      </div>
    </div>
  )

  function handleToggle() {
    buttonOnClick && buttonOnClick(expanded)
    setExpanded(!expanded)
  }
}
