"use strict";
exports.__esModule = true;
exports.moveFocus = void 0;
// https://github.com/whatwg/html/issues/5326#issuecomment-1150090942
function moveFocus(elem) {
    var originalTabindex = elem.getAttribute("tabindex"); // Store the original tabindex
    elem.setAttribute("tabindex", "-1");
    elem.focus({
        preventScroll: true
    });
    elem.blur();
    if (originalTabindex !== null) {
        elem.setAttribute("tabindex", originalTabindex); // Restore the original tabindex
    }
    else {
        elem.removeAttribute("tabindex"); // Remove tabindex if it wasn't set initially
    }
}
exports.moveFocus = moveFocus;
