/**
 * @Note
 * Very basic check, used to eliminate "value ? <component /> : null" blocks
 */

export function VisibleWhen({
  value,
  children,
}) {
  return value ? children: null
}
