import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { InputValidState } from 'components/InputValidState'

import styles from './InputWrapper.css'

export const InputWrapper = ({
  children,
  className,
  dir,
  disableBorder,
  hasFocus,
  prefix,
  readOnly,
  suffix,
  suffixClassname,
  size,
  type,
  validationStatus,
}) => {
  const langDir = useSelector(state => state.language.langDir)
  const [prefixWidth, setPrefixWidth] = React.useState(0)
  const [suffixWidth, setSuffixWidth] = React.useState(0)

  return (
    <div
      className={classNames(styles.container, className, {
        [styles.readOnly]: readOnly,
        [styles.tel]: type === `tel`,
        [styles.email]: type === `email`,
        [styles.file]: type === `file`,
        [styles.number]: type === `number`,
        [styles.password]: type === `password`,
        [styles.select]: type === `select` || type === `country`,
        [styles.text]: type === `text`,
        [styles.textarea]: type === `textarea`,
        [styles.phoneFormat]: type === `phoneFormat`,
        [styles.small]: size === `small`,
        [styles.invalid]: validationStatus === `invalid`,
        [styles.valid]: validationStatus === `valid`,
        [styles.noBorder]: disableBorder,
        [styles.hasFocus]: hasFocus,
      })}
      data-validationstatus={validationStatus}
      dir={dir || langDir}
      style={{
        '--padding': Boolean(prefix) && getInputPadding(),
      }}
    >
      {renderPrefix()}
      {children}
      {renderSuffix()}
    </div>
  )

  function renderPrefix() {
    if (prefix) {
      return (
        <div
          className={classNames(styles.prefix, {
            [styles.small]: size === `small`,
          })}
          ref={node => {
            if (node !== null && node.getBoundingClientRect().width) {
              setPrefixWidth(node.getBoundingClientRect().width)
            }
          }}
        >
          {prefix}
        </div>
      )
    }
  }

  function renderSuffix() {
    if (suffix || validationStatus) {
      return (
        <div
          className={classNames(styles.suffix, {
            [suffixClassname]: Boolean(suffixClassname),
          })}
          ref={node => {
            if (node !== null && node.getBoundingClientRect().width) {
              setSuffixWidth(node.getBoundingClientRect().width)
            }
          }}
        >
          {suffix}
          {validationStatus &&
            <InputValidState validationStatus={validationStatus} />
          }
        </div>
      )
    }
  }

  function getInputPadding() {
    let paddingSize = 20

    if (size === `small`) {
      paddingSize = 10
    }

    return dir === `ltr` || langDir === `ltr`
      ? ltrPaddingMap({ suffixWidth, prefixWidth, paddingSize, type })
      : rtlPaddingMap({ suffixWidth, prefixWidth, paddingSize, type })
  }
}

function ltrPaddingMap({
  suffixWidth,
  prefixWidth,
  paddingSize,
  type,
}) {
  const paddingMap = {
    text: `0 ${suffixWidth || paddingSize}px 0 ${prefixWidth || paddingSize}px`,
    tel: `0 ${suffixWidth || paddingSize}px 0 ${prefixWidth || paddingSize}px`,
    password: `0 ${suffixWidth || paddingSize}px 0 ${prefixWidth || paddingSize}px`,
    number: `0 ${suffixWidth || 10}px 0 ${prefixWidth || 10}px`,
    select: `0 ${suffixWidth || 10}px 0 ${prefixWidth || 10}px`,
    textArea: `20px ${suffixWidth || 10}px 20px ${prefixWidth || 20}px`,
  }

  return paddingMap[type] || paddingMap.text
}

function rtlPaddingMap({
  suffixWidth,
  prefixWidth,
  paddingSize,
  type,
}) {
  const paddingMap = {
    text: `0 ${prefixWidth || paddingSize}px 0 ${suffixWidth || paddingSize}px`,
    tel: `0 ${prefixWidth || paddingSize}px 0 ${suffixWidth || paddingSize}px`,
    password: `0 ${prefixWidth || paddingSize}px 0 ${suffixWidth || paddingSize}px`,
    number: `0 ${prefixWidth || 10}px 0 ${suffixWidth || 10}px`,
    select: `0 ${prefixWidth || 10}px 0 ${suffixWidth || 10}px`,
    textArea: `20px ${prefixWidth || 10}px 20px ${suffixWidth || 20}px`,
  }

  return paddingMap[type] || paddingMap.text
}
