import { determineHeaderOffset } from 'utils/determineHeaderOffset'
import { moveFocus } from 'utils/moveFocus'

// https://stackoverflow.com/a/55686711
export function scrollToElement(element) {
  const offset = determineHeaderOffset(element)
  window.addEventListener(`scroll`, onScroll)
  onScroll()

  window.scrollTo({
    top: offset,
    // behavior: `smooth`,
  })

  function onScroll() {
    const fixedOffset = offset.toFixed()
    const pageYOffset = window.pageYOffset.toFixed()

    if (pageYOffset === fixedOffset || fixedOffset < 0) {
      window.removeEventListener(`scroll`, onScroll)
      moveFocus(element)
    }
  }
}