"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.calculatePricingData = exports.calculateItemObjectData = exports.getAssociations = void 0;
var getAssociations = function (_a) {
    var cartContents = _a.cartContents, plpAssociations = _a.plpAssociations;
    var initialAccumulator = {
        associationFilters: [],
        associationsAfterSearch: [],
        associationsWithoutSearch: []
    };
    var result = cartContents.reduce(function (acc, product) {
        var _a;
        var association = plpAssociations[product.productId];
        if (association) {
            var associatedPlpWithCartItem = __assign(__assign({}, association), { product: product });
            if (associatedPlpWithCartItem.queryID) {
                acc.associationsAfterSearch.push(associatedPlpWithCartItem);
            }
            else {
                acc.associationsWithoutSearch.push(associatedPlpWithCartItem);
            }
            (_a = acc.associationFilters).push.apply(_a, associatedPlpWithCartItem.filters);
        }
        return acc;
    }, initialAccumulator);
    return {
        associationFilters: result.associationFilters,
        associationsAfterSearch: result.associationsAfterSearch,
        associationsWithoutSearch: result.associationsWithoutSearch
    };
};
exports.getAssociations = getAssociations;
var calculateItemObjectData = function (_a) {
    var listingPrice = _a.listingPrice, salePrice = _a.salePrice, quantity = _a.quantity;
    var _b = (0, exports.calculatePricingData)({
        listingPrice: listingPrice,
        salePrice: salePrice
    }), discount = _b.discount, price = _b.price;
    return {
        discount: discount,
        price: price,
        quantity: quantity
    };
};
exports.calculateItemObjectData = calculateItemObjectData;
var calculatePricingData = function (_a) {
    var listingPrice = _a.listingPrice, salePrice = _a.salePrice;
    var onSale = salePrice !== listingPrice;
    var discountValue = undefined;
    if (onSale) {
        discountValue = listingPrice - salePrice; // This is the actual money saved
    }
    var selectedPrice = onSale ? salePrice : listingPrice;
    return {
        discount: discountValue,
        price: selectedPrice
    };
};
exports.calculatePricingData = calculatePricingData;
