import { ErrorAdditional, SENTRY_TAGS } from 'utils/ErrorAdditional'
import { reportError } from 'services/reportError'
export const storageFurrealz = {
  get: (key) => {
    // TODO: Remove this in future builds, to remain only JSON.parse
    try {
      return JSON.parse(window.localStorage.getItem(key) || null)
    } catch (e) {
      console.warn(`Failed to parse from local storage: ${key}`)
      console.warn(e)
      return window.localStorage.getItem(key)
    }
  },
  reportLocalStorageError({
    localStorage,
    error,
  }) {
    const keys = Object.keys(localStorage)
    const sizes = keys.map(key => ({ key, size: getLocalStorageSize(key) }))
    reportError(new ErrorAdditional({
      additional: { sizes },
      originalError: error,
      message: error.message,
      tags: {
        [SENTRY_TAGS.SOURCE_FILE]: `src/utils/storage.js`,
      },
      title: `Local Storage Error`,
    }))
  },
  set(key, value) {
    try {
      window.localStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      this.reportLocalStorageError({
        error,
        localStorage: window.localStorage,
      })
    }
  },
  remove: (key) => {
    return window.localStorage.removeItem(key)
  },
}

function getLocalStorageSize(key) {
  const value = localStorage.getItem(key)
  return value ? value.length : 0
}

const keyForThisBuild = (key) => `${key}_${window.$TIMESTAMP}`

export const storage = {
  get: key => storageFurrealz.get(keyForThisBuild(key)),
  set: (key, value) => storageFurrealz.set(keyForThisBuild(key), value),
  remove: key => storageFurrealz.remove(keyForThisBuild(key)),
}

// NOTE: if you use this version of storage, be sure to
// validate and protect against stored data before you use it!
export const unversionedStorage = {
  get: key => storageFurrealz.get(key),
  set: (key, value) => storageFurrealz.set(key, value),
  remove: key => storageFurrealz.remove(key),
}
