import React from 'react'
import classNames from 'classnames'

import { Svg } from 'components/Svg'

import styles from './IconAnimated.css'

export const IconAnimated = ({
  animating = false,
  animation,
  color,
  icon,
  size,
}) => {
  return (
    <div
      className={styles.container}
      style={{ width: size }}
    >
      <div className={styles.center}>
        <div
          className={classNames(styles.svgWrapper, {
            [styles[animation]]: animating,
          })}
        >
          <Svg
            block
            color={color}
            icon={icon}
            size={size}
          />
        </div>
      </div>
    </div>
  )
}
