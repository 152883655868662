import { useSelector } from 'react-redux'

import { sortByPriority } from 'utils/sortByPriority'
import { isPromotionValid } from 'utils/promotion-filtering'
import { getContent } from 'global-content/content'

export function usePromotions() {
  const language = useSelector(state => state.language.active)
  const campaigns = useSelector(state => state.campaigns.active?.[language]) || []

  return getPromotions

  function getPromotions(product, selectedSKU) {
    return getPromotionAreas({
      campaigns,
      language,
      product,
      selectedSKU,
    })
  }
}
export function getPromotionAreas({
  campaigns,
  language,
  product,
  selectedSKU,
}) {
  const promotionsContent = getContent(`promotions`, language)
  const productPromotions = promotionsContent?.products || []

  const validPromotions = [
    ...productPromotions.map(v1ToV2Promotion).filter(promotion => isLocationValid(promotion, product, selectedSKU)),
    ...campaigns.filter(campaign => isLocationValid(campaign, product, selectedSKU)),
  ]

  const orderedPromotions = orderPromotions(validPromotions)
  const areas = promotionAreas(orderedPromotions)

  return areas
}

export function v1ToV2Promotion({
  filters,
  ...promo
}) {
  if (!filters) {
    return promo
  }

  if (filters) {
    let inclusions = {}
    let exclusions = {}

    const filterKeys = Object.keys(filters)
    filterKeys.forEach(filterKey => {
      if (filterKey === `onSale` && filters.onSale) {
        inclusions.sale = `ONSALE`
      }

      if (filters[filterKey].excludes) {
        exclusions[filterKey] = filters[filterKey].excludes
      }

      if (filters[filterKey].includes) {
        inclusions[filterKey] = filters[filterKey].includes
      }
    })

    return {
      promotion: {
        conditions: {
          inclusions,
          exclusions,
        },
      },
      ...promo,
    }
  }
}

export function isLocationValid(
  {
    promotion,
    locations,
  } = {},
  product,
  selectedSKU
) {
  if (locations) {
    return isPromotionValid(promotion, product, selectedSKU)
  }
}

function promotionAreas(promotions) {
  let build = {}

  promotions.forEach(({ locations }, i) => {
    locations.forEach((locationContent, l) => {
      const key = `${i}${l}`
      // if key already exists add to it
      if (build[locationContent.config.location]) {
        build[locationContent.config.location].push({
          ...locationContent,
          key,
        })
      // else make a new key and array
      } else {
        build[locationContent.config.location] = [{
          ...locationContent,
          key,
        }]
      }
    })
  })

  return build
}

export function orderPromotions(promotions) {
  return [...promotions].sort((a, b) => sortByPriority(a?.promotion, b?.promotion))
}
