import React from 'react'
import classNames from 'classnames'

import styles from './MegamenuGrid.css'

export const MegamenuGrid = ({
  children,
  columnsPerRow,
  gap,
  justifyContent,
  width,
}) => {
  let gridTemplateColumns = `repeat(auto-fit, ${width}px)`

  if (columnsPerRow) {
    gridTemplateColumns = `repeat(${columnsPerRow}, 1fr)`
  }

  const displayGrid = Boolean(width) || Boolean(columnsPerRow)

  return (
    <div
      className={classNames({
        [styles.grid]: displayGrid,
        [styles.flex]: !displayGrid,
      })}
      style={{
        '--gap': gap,
        gridTemplateColumns,
        justifyContent,
      }}
    >
      {children}
    </div>
  )
}
