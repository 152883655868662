import React from 'react'
import classNames from 'classnames'

import { Grid } from 'components/Grid'

import styles from './InputCode.css'

export const InputCode = ({
  maxLength,
  value,
  ...rest
}) => {
  const ref = React.useRef()
  const [selectionStart, setSelectionStart] = React.useState()
  const [selectionEnd, setSelectionEnd] = React.useState()

  return (
    <div
      className={styles.wrapper}
    >
      <Grid
        columns={maxLength}
        gap="10px"
        itemSize="40px"
      >
        {generateDecorativeInputs()}
      </Grid>
      <input
        {...rest}
        className={styles.codeInput}
        maxLength={maxLength}
        name={rest.id}
        onBlur={() => {
          setSelectionStart()
          setSelectionEnd()
        }}
        onFocus={(e) => {
          setSelectionStart(e.target.selectionStart)
          setSelectionEnd(e.target.selectionEnd)
        }}
        onKeyUp={(e) => {
          let start = e.target.selectionStart
          let end = e.target.selectionEnd

          if (e.key === `ArrowLeft` && value[e.target.selectionStart]) {
            start = e.target.selectionStart - 1
          } else if (value[e.target.selectionEnd]) {
            end = e.target.selectionEnd + 1
          }

          setSelectionStart(start)
          setSelectionEnd(end)
          ref.current.setSelectionRange(start, end)
        }}
        onMouseUp={(e) => {
          setSelectionStart(e.target.selectionStart)
          setSelectionEnd(e.target.selectionEnd)
        }}
        ref={ref}
        type="text"
        value={value}
      />
    </div>
  )

  function generateDecorativeInputs() {
    let build = []

    for (let i = 0; i < maxLength; i++) {
      build.push(
        <DecorativeInput
          focussed={isFocussed(i)}
          key={i}
          onClick={e => {
            e.preventDefault()
            let start = 0
            let end = 0

            if (value[i]) {
              start = i
              end = i + 1
            }

            ref.current.focus()
            ref.current.setSelectionRange(start, end)
            setSelectionStart(start)
            setSelectionEnd(end)
          }}
          value={value[i]}
        />
      )
    }

    return build
  }

  function isFocussed(i) {
    if (selectionStart === selectionEnd) {
      if (
        i >= selectionStart &&
        i <= selectionEnd
      ) {
        return true
      }
    }

    if (
      i >= selectionStart &&
      i <= selectionEnd - 1
    ) {
      return true
    }
  }
}

const DecorativeInput = ({
  focussed,
  onClick,
  value,
}) => {
  return (
    <button
      className={classNames(styles.decorativeInput, {
        [styles.focussed]: focussed,
      })}
      onClick={onClick}
      tabIndex={-1}
    >
      {value}
    </button>
  )
}
