import React from 'react'

import { LazyModuleWrapper } from 'components/LazyModuleWrapper'

const LazyOrderDetails = (
  React.lazy(() => import(/* webpackChunkName: "OrderDetails" */ `./OrderDetails`))
)

export const OrderDetails = props => (
  <LazyModuleWrapper>
    <LazyOrderDetails {...props} />
  </LazyModuleWrapper>
)
