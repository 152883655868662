import {
  ENABLE_TEST_PAYMENTS,
  SET_INCHES,
} from 'state/actions'

const initialState = {
  inches: false,
  showTestPayments: false,
}

const userPreferences = (state = initialState, action) => {
  switch (action.type) {
  case ENABLE_TEST_PAYMENTS:
    return {
      ...state,
      showTestPayments: true,
    }
  case SET_INCHES:
    return {
      ...state,
      inches: action.payload,
    }
  default:
    return state
  }
}

export default userPreferences
