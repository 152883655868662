import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { overlayOff, overlayOn } from 'state/actions/overlay'
import { setActiveTooltip } from 'state/actions/site'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { Svg } from 'components/Svg'
import { Heading } from 'components/Heading'
import { HtmlText } from 'components/HtmlText'
import mediaSizes from 'css/media-sizes.json'

import styles from './Tooltip.css'

export const Tooltip = ({
  image,
  name,
  positioning = [`top`, `start`],
  title,
  value,
}) => {
  const [calculatedTooClose, setCalculatedTooClose] = React.useState(false)
  const [tooClose, setTooClose] = React.useState({})

  const [tooltipId, setTooltipId] = React.useState(name)
  const dispatch = useDispatch()
  const activeTooltip = useSelector(state => state.site.activeTooltip)
  const active = activeTooltip === tooltipId
  const [positioningVertical, positioningHorizontal] = positioning
  const ref = React.useRef()
  const toolTipRef = React.useRef()
  useOnClickOutside(ref, closeTooltip, true)

  const getOffset = React.useCallback(() => {
    if (!toolTipRef.current) {
      return
    }

    const tooltipCoords = toolTipRef.current.getBoundingClientRect()
    const isTabletMedium = window.matchMedia(`(max-width: ${mediaSizes.tabletMedium}px)`).matches

    if (isTabletMedium) {
      setCalculatedTooClose(true)
      setTooClose({})
      return
    }

    if (!calculatedTooClose) {
      const toTop = tooltipCoords.top < 0
      const toLeft = tooltipCoords.left - tooltipCoords.width < 0
      const toRight = tooltipCoords.right > window.innerWidth
      const toBottom = tooltipCoords.bottom > window.innerHeight

      setCalculatedTooClose(true)
      setTooClose({
        toTop,
        toLeft,
        toRight,
        toBottom,
      })
    }
  }, [active, calculatedTooClose])

  const resizeHandler = React.useCallback(() => {
    setCalculatedTooClose(false)
    getOffset()
  }, [])

  React.useEffect(() => {
    if (!name) {
      setTooltipId(Math.random())
    }
  }, [])

  React.useEffect(() => {
    getOffset()
  }, [active])

  React.useEffect(() => {
    window.addEventListener(`resize`, resizeHandler)

    return () => {
      window.removeEventListener(`resize`, resizeHandler)
    }
  }, [])

  return (
    <div
      className={styles.container}
      ref={ref}
    >
      <button
        data-testid={`tooltip-toggle-${tooltipId}`}
        onClick={toggleTooltip}
        type="button"
      >
        <Svg
          icon="info_sign"
          size="1.4rem"
        />
      </button>
      {active &&
        <div
          className={classNames(styles.tooltip, styles.override, {
            [styles.top]: positioningVertical === `top` && !tooClose.toTop,
            [styles.bottom]: positioningVertical === `bottom`,
            [styles.start]: positioningHorizontal === `start`,
            [styles.end]: positioningHorizontal === `end`,
          })}
          data-testid='tooltip'
          ref={toolTipRef}
          style={{
            opacity: calculatedTooClose ? 1 : 0,
            right: tooClose.toRight && `100%`,
            left: tooClose.toLeft && `100%`,
            top: tooClose.toTop && `100%`,
            bottom: tooClose.toBottom && `100%`,
          }}
        >
          <button
            className={styles.close}
            data-testid={`tooltip-close-${tooltipId}`}
            onClick={closeTooltip}
            type="button"
          >
            <Svg icon="close" />
          </button>
          {title &&
            <Heading
              content={{
                html: title,
              }}
              styling={{
                fontFamily: `primary`,
                fontSize: `xs`,
                universalColor: true,
              }}
            />
          }
          {image &&
            <img src={image} />
          }
          {value &&
            <div data-testid="tooltip-value">
              <HtmlText html={value} />
            </div>
          }
        </div>
      }
    </div>
  )

  function closeTooltip() {
    dispatch(overlayOff(`tooltip`))
    dispatch(setActiveTooltip(undefined))
  }

  function toggleTooltip() {
    if (!active) {
      dispatch(overlayOn(`tooltip`))
      dispatch(setActiveTooltip(tooltipId))
    }
  }
}
