import React from 'react'
import { incrementView } from 'components/ModalSplash/ModalSplash'
import { I18n } from 'utils/i18n'

export function ModalSplashLink(props) {
  return (
    <button
      aria-controls="modal"
      aria-haspopup
      className="modal-splash-link"
      onClick={handleClick}
    >
      {I18n.t(props?.data?.label)}
    </button>
  )

  function handleClick(){
    incrementView(0)
  }
}