export function remapTextAlign(alignment, langDir) {
  if (langDir === `ltr`) {
    return alignment
  }

  const map = {
    left: `right`,
    right: `left`,
  }

  return map[alignment] || alignment
}
