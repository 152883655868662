import {
  OVERLAY_OFF,
  OVERLAY_ON,
  NAV_USES_OVERLAY,
} from 'state/actions'

export const overlayOff = overlayType => ({
  type: OVERLAY_OFF,
  payload: overlayType,
})

export const overlayOn = overlayType => ({
  type: OVERLAY_ON,
  payload: overlayType,
})

export const navUsesOverlay = () => ({
  type: NAV_USES_OVERLAY,
})
