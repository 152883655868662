import React from 'react'

import { Svg } from 'components/Svg'
import { Image } from 'components/Image'
import { HtmlText } from 'components/HtmlText'

import styles from './Highlight.css'

export const Highlight = ({
  content = {},
  styling = {},
}) => {
  const {
    html,
    iconRef,
    imageSrc,
    text,
  } = content

  const {
    iconSize = `2rem`,
    ...additionalStyles
  } = styling

  return (
    <span
      className={styles.itemWrapper}
      style={additionalStyles}
    >
      {iconRef &&
        <div className={styles.iconWrapper}>
          <Svg
            block
            icon={iconRef}
            size={iconSize}
          />
        </div>
      }

      {imageSrc &&
        <div className={styles.imageWrapper}>
          <Image src={imageSrc} />
        </div>
      }

      {(html || text) &&
        <HtmlText html={html || text} />
      }
    </span>
  )
}
