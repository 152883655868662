import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { I18n } from 'utils/i18n'
import { IconLabel } from 'components/IconLabel'
import { Svg } from 'components/Svg'
import { HtmlText } from 'components/HtmlText'

import { webLabels } from './fixtures'
import styles from './CartCount.css'

export const CartCount = ({
  config = {},
  positioning = {},
  styling = {},
}) => {
  const {
    showWhenEmpty,
    showFullCart,
  } = config

  const {
    iconSize,
    numberPosition,
    numberHeight,
    numberPadding,
    numberTop,
    numberLeft,
    numberMargin,
  } = positioning

  const {
    iconColor,
    numberColor,
    numberBackgroundColor,
    numberFont = {},
  } = styling

  const {
    fontSize,
    fontWeight,
  } = numberFont

  const totalItemCount = useSelector(state => state.cart.cartSummary.totalItemCount)

  return (
    <>
      <div className={styles.container}>
        {showFullCart && totalItemCount > 0 ? (
          <Svg
            color={iconColor}
            icon="basket-full"
            size={iconSize}
          />
        ) : (
          <Svg
            color={iconColor}
            icon="basket"
            size={iconSize}
          />
        )}
        {shouldShow() &&
          <span
            className={classNames(styles.cartCount, {
              [styles.center]: numberPosition === `center`,
              [styles.right]: numberPosition === `right`,
              [styles.onTop]: numberPosition === `onTop`,
            })}
            style={{
              backgroundColor: numberBackgroundColor,
              color: numberColor,
              height: numberHeight,
              padding: numberPadding,
              top: numberTop,
              left: numberLeft,
              margin: numberMargin,
            }}
          >
            <HtmlText
              fontSize={fontSize}
              fontWeight={fontWeight}
              html={String(totalItemCount || `0`)}
            />
          </span>
        }
      </div>
      <IconLabel iconLabel={I18n.t(webLabels.label)} />
    </>
  )

  function shouldShow() {
    if (showWhenEmpty || totalItemCount) {
      return true
    }
  }
}
