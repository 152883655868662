import React from 'react'

import { LazyModuleWrapper } from 'components/LazyModuleWrapper'

const LazyComplete = (
  React.lazy(() => import(/* webpackChunkName: "Complete" */ `./Complete`))
)

export const Complete = props => (
  <LazyModuleWrapper>
    <LazyComplete {...props} />
  </LazyModuleWrapper>
)
