export function getLangDir(languageCode) {
  const rtlList = [
    `ar`, // arabic
    `iw`, // hebrew
    `he`, // hebrew modern
    `dv`, // dhivehi | thaana?
    `ku`, // Kurdish
    `fa`, // Persian
    `ur`, // Urdu
    // ``, // Rohingya
    // ``, // syriac
    // ``, // mandaic
    // ``, // samaritan
    // ``, // mende kikakui
    // ``, // N'Ko
    // ``, // Garay
    // ``, // Adlam
    // ``, // Hanifi Rohingya
    // ``, // yezidi
  ]

  if (rtlList.includes(languageCode)) {
    return `rtl`
  }

  return `ltr`
}
