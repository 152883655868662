import React from 'react'

import { Layout } from 'components/Layout'
import { Heading } from 'components/Heading'
import { NavItem } from 'components/NavItem'
import { MegamenuGrid } from 'components/MegamenuGrid'
import { MegamenuNavGroup } from 'components/MegamenuNavGroup'

import styles from './MegamenuColumn.css'

export const MegamenuColumn = ({
  activeState,
  columnIndex,
  columnsGap,
  heading,
  headingColor,
  headingFontSize = `xs`,
  headingFontWeight,
  hoverState,
  itemHeight,
  itemPadding,
  items,
  justifyContent,
  maxItemsPerColumn,
  navMenuIndex,
  parentMenuIndexes,
  showLevelDown,
  type,
  width,
}) => {
  const chunks = chunkItems(items, maxItemsPerColumn)
  return (
    <div className={styles.container}>
      {showLevelDown &&
        <Layout
          layout={{
            marginBottom: `xxs`,
          }}
        >
          <NavItem
            index={columnIndex}
            itemPadding={itemPadding}
            navMenuIndex={navMenuIndex}
            parentMenuIndexes={parentMenuIndexes}
            target={heading.target}
            to={heading.path}
            value={heading}
          >
            <Heading
              content={{
                html: heading.label,
              }}
              layout={{
                marginBottom: `medium`,
              }}
              styling={{
                fontSize: headingFontSize,
                fontWeight: headingFontWeight,
                color: headingColor,
              }}
            />
          </NavItem>
        </Layout>
      }
      <MegamenuGrid
        columnsPerRow={chunks.length}
        gap={columnsGap}
        justifyContent={justifyContent}
        width={width}
      >
        <MegamenuNavGroup
          activeState={activeState}
          chunks={chunks}
          columnIndex={columnIndex}
          hoverState={hoverState}
          itemHeight={itemHeight}
          itemPadding={itemPadding}
          navMenuIndex={showLevelDown ? navMenuIndex + 1 : navMenuIndex}
          parentMenuIndexes={showLevelDown ? [...parentMenuIndexes, columnIndex] : parentMenuIndexes}
          type={type}
          width={width}
        />
      </MegamenuGrid>
    </div>
  )
}

function chunkItems(myArray, chunkSize = 100) {
  let arrayLength = myArray.length
  let build = []

  for (let index = 0; index < arrayLength; index += chunkSize) {
    const myChunk = myArray.slice(index, index + chunkSize)

    build.push(myChunk)
  }

  return build
}
