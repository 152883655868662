import React from 'react'

import { LazyModuleWrapper } from 'components/LazyModuleWrapper'

const LazyAccountVerification = (
  React.lazy(() => import(/* webpackChunkName: "AccountVerification" */ `./AccountVerification`))
)

export const AccountVerification = props => (
  <LazyModuleWrapper>
    <LazyAccountVerification {...props} />
  </LazyModuleWrapper>
)
