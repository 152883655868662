// helper function for legacy components
export function v1FactoryToV2({
  order,
  components,
}) {
  if (order) {
    return order.map(id => formatComponent(components, components[id], id))
  }

  return Object.entries(components).map(([id, component]) => formatComponent(components, component, id))
}

function formatComponent(components, component, id) {
  if (component.data || component.items) {
    return component
  }

  return {
    id,
    data: {
      ...components[id],
    },
  }
}
