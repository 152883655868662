import queryString from 'query-string'
import get from 'lodash/get'
import { matchPath } from 'react-router-dom'

import { generateRouteLink } from 'utils'
import { sanitize } from 'utils/sanitize'
import { filtersMatcher } from 'utils/filtersMatcher'
import { unversionedStorage } from 'utils/storage'
import { I18n } from 'utils/i18n'
import { setSeoData } from 'utils/setSeoData'

import { getConfigOptions, getMetaOptions } from 'global-content/config'
import { getContent } from 'global-content/content'

import {
  ROUTE_CHANGE,
  UPDATE_NAVIGATION,
} from 'state/actions'

import {
  ACCOUNT_ROUTE,
  ACCOUNT_ADDRESSES_ROUTE,
  ACCOUNT_CREATE_ROUTE,
  ACCOUNT_PAYMENT_METHODS_ROUTE,
  ACCOUNT_VERIFICATION_ROUTE,
  ACCOUNT_SIGNIN_ROUTE,
  ACCOUNT_FORGOTTEN_PASSWORD_ROUTE,
  ACCOUNT_NEW_PASSWORD_ROUTE,
  CHECKOUT_ROUTE,
  COMPLETE_ROUTE,
  FAVORITES_ROUTE,
  PRODUCT_ROUTE,
  RECREATE_CART_ROUTE,
  REDIRECT_ROUTE,
  SEARCH_ROUTE,
  TRACKING_ROUTE,
  ORDER_HISTORY_ROUTE,
  ORDER_DETAILS_ROUTE,
  ORDER_STATUS_ROUTE,
} from 'Router/fixtures'

import {
  getBrandRoute,
  getBrandsIndex,
} from 'Router/utils'

import { webLabels } from './fixtures'

export const updateNavigation = ({
  language,
  pathname,
  search,
}) => (dispatch, getState) => {
  const activeLanguageFilters = getState().listing.allFilters[language]
  const product = getState().details.product
  let secondaryContext = getState().navigation.secondaryContext
  const productNotFound = getState().details.notFound

  const breadcrumbs = getBreadcrumbs({
    activeLanguageFilters,
    language,
    pathname,
    product,
    productNotFound,
    search,
  })

  const currentPage = breadcrumbs[breadcrumbs.length - 1] || {}

  const navContext = getNavContext({
    product,
    pathname,
  })

  secondaryContext = getSecondaryContext({
    secondaryContext,
    product,
    pathname,
  })

  const {
    metadata = {},
    h1,
  } = currentPage

  setSeoData({
    headline: metadata.title || h1 || getSeoData(pathname, `title`, language),
    description: metadata.description || getSeoData(pathname, `description`, language),
    search,
    product,
  })

  dispatch({
    type: UPDATE_NAVIGATION,
    payload: {
      breadcrumbs,
      currentPage,
      navContext,
      secondaryContext,
    },
  })
}

export const routeChange = ({
  language,
  pathname,
  search,
}) => ({
  type: ROUTE_CHANGE,
  payload: { language, pathname, search },
})

function getBreadcrumbs({
  activeLanguageFilters,
  language,
  pathname,
  product,
  productNotFound,
  search,
}) {
  const navTree = getContent(`navTree`, language)
  const clpList = getContent(`routes.list`)

  const definedRoute = checkForDefinedRoute({
    activeLanguageFilters,
    clpList,
    language,
    navTree,
    pathname,
    product,
    productNotFound,
    search,
  })

  if (definedRoute) {
    return definedRoute
  }

  return checkInNavTree({ pathname, navTree })
}

function getNavContext({
  product,
  pathname,
}) {
  if (routeMatcher(pathname, PRODUCT_ROUTE)) {
    const productBrandContextMap = getConfigOptions(`navigation.productBrandContextMap`) || {}
    const brandName = get(product, `brand.name`)
    const brandContext = productBrandContextMap[brandName]
    return brandContext
  }
}

function getSecondaryContext({
  product,
  pathname,
  secondaryContext,
}) {
  if (routeMatcher(pathname, SEARCH_ROUTE)) {
    return secondaryContext
  } else if (routeMatcher(pathname, PRODUCT_ROUTE) && product) {
    if (product.gender === `female`) {
      return `women`
    } else if (product.gender === `male`) {
      return `men`
    }
  } else {
    if(/(?:^|\/)women(?:\/|$)/.test(pathname)) {
      return `women`
    } else if (/(?:^|\/)men(?:\/|$)/.test(pathname)) {
      return `men`
    }
  }
  return ``
}

function checkForDefinedRoute({
  activeLanguageFilters,
  language,
  navTree,
  pathname,
  product,
  productNotFound,
  search,
  clpList,
}) {
  const seoData = getContent(`seoData`, language)
  const BRANDS_INDEX_ROUTE = getBrandsIndex()
  const BRAND_ROUTE = getBrandRoute()
  const brandsPathname = getConfigOptions(`brandsPathname`)

  const routeMap = {
    ...accountRoutes(),
    brand: {
      matcher: BRAND_ROUTE,
      breadcrumbs: ({
        l1,
        brand,
      }) => [
        l1 && {
          h1: navTree.l1[l1].label,
          path: `/${l1}`,
          slug: formatSlug(l1),
        },
        {
          h1: I18n.t(webLabels.brandTitle),
          path: generateRouteLink(brandsPathname, [l1]),
          slug: formatSlug(brandsPathname),
        },
        {
          h1: getBrandName(activeLanguageFilters, brand),
          filters: {
            brand: [brand],
            ...get(navTree, `l1.${l1}.filters`, {}),
          },
          path: generateRouteLink(brand, [l1, brandsPathname]),
          slug: formatSlug(brand),
        },
      ].filter(Boolean),
    },
    brandsIndex: {
      matcher: BRANDS_INDEX_ROUTE,
      breadcrumbs: ({ l1 }) => [{
        h1: I18n.t(webLabels.brandTitle),
        path: generateRouteLink(brandsPathname, [l1]),
        slug: formatSlug(brandsPathname),
      }],
    },
    checkout: {
      matcher: CHECKOUT_ROUTE,
      breadcrumbs: ({ step }) => [{
        h1: getCheckoutH1(step),
        path: generateRouteLink(step, [`checkout`]),
        slug: formatSlug(step),
      }],
    },
    complete: {
      matcher: COMPLETE_ROUTE,
      breadcrumbs: () => [{
        h1: I18n.t(webLabels.favoritesTitle),
        path: COMPLETE_ROUTE,
        slug: formatSlug(COMPLETE_ROUTE),
      }],
    },
    favorites: {
      matcher: FAVORITES_ROUTE,
      breadcrumbs: () => [{
        h1: I18n.t(webLabels.favoritesTitle),
        filters: {
          productId: {
            includes: getFavorites(),
          },
        },
        isBSC: false,
        path: FAVORITES_ROUTE,
        slug: formatSlug(FAVORITES_ROUTE),
        description: seoData.routes[`/my-favorites`],
      }],
    },
    product: {
      matcher: PRODUCT_ROUTE,
      breadcrumbs: () => getProductBreadcrumb({
        navTree,
        product,
        productNotFound,
      }),
    },
    recreate: {
      matcher: RECREATE_CART_ROUTE,
      breadcrumbs: () => [{
        h1: `Recreating cart`,
        path: RECREATE_CART_ROUTE,
        slug: formatSlug(RECREATE_CART_ROUTE),
      }],
    },
    redirect: {
      matcher: REDIRECT_ROUTE,
      breadcrumbs: () => [{
        h1: `Redirecting`,
        path: REDIRECT_ROUTE,
        slug: formatSlug(REDIRECT_ROUTE),
      }],
    },
    search: {
      matcher: SEARCH_ROUTE,
      breadcrumbs: () => [{
        h1: getSearchH1(search),
        slug: formatSlug(SEARCH_ROUTE),
        description: seoData.routes[`/search`],
      }],
    },
    tracking: {
      matcher: TRACKING_ROUTE,
      breadcrumbs: () => [{
        h1: I18n.t(webLabels.trackingTitle),
        path: TRACKING_ROUTE,
        slug: formatSlug(TRACKING_ROUTE),
      }],
    },
    orderHistory: {
      matcher: ORDER_HISTORY_ROUTE,
      breadcrumbs: () => [
        {
          h1: I18n.t(webLabels.orderHistoryTitle),
          path: ORDER_HISTORY_ROUTE,
          slug: formatSlug(ORDER_HISTORY_ROUTE),
        },
      ],
    },
    orderStatus: {
      matcher: ORDER_STATUS_ROUTE,
      breadcrumbs: () => [
        {
          h1: I18n.t(webLabels.orderStatusTitle),
          path: ORDER_STATUS_ROUTE,
          slug: formatSlug(ORDER_STATUS_ROUTE),
        },
      ],
    },
    orderDetails: {
      matcher: ORDER_DETAILS_ROUTE,
      breadcrumbs: () => [
        {
          h1: I18n.t(webLabels.orderHistoryTitle),
          path: ORDER_HISTORY_ROUTE,
          slug: formatSlug(ORDER_HISTORY_ROUTE),
        },
        {
          h1: I18n.t(webLabels.orderDetailsTitle),
          path: ORDER_DETAILS_ROUTE,
          slug: formatSlug(ORDER_DETAILS_ROUTE),
        },
      ],
    },
  }

  const entries = Object.values(routeMap)

  for (let i = 0; i < entries.length; i++) {
    const {
      breadcrumbs,
      matcher,
    } = entries[i]
    const match = routeMatcher(pathname, matcher)

    if (match) {
      return breadcrumbs(match.params)
    }
  }

  // if no match, check for clp match now
  for (let i = 0; i < clpList.length; i++) {
    const matcher = clpList[i]
    const match = routeMatcher(pathname, `/${matcher}`)
    const navTreeBreadcrumbs = clpNavTreeCheck({ pathname, navTree })
    const navTreeCurrentPage = navTreeBreadcrumbs[navTreeBreadcrumbs.length - 1]

    if (match) {
      const {
        isBSC,
        filters,
        h1,
        metadata = {},
      } = get(window.$cache[`${pathname}-${language}`], `file.data`, {})

      return [
        ...navTreeBreadcrumbs.slice(0, navTreeBreadcrumbs.length - 1),
        {
          isBSC,
          metadata,
          filters: filters || navTreeCurrentPage.filters,
          h1: h1 || navTreeCurrentPage.h1,
          path: pathname,
          slug: formatSlug(pathname),
        },
      ]
    }
  }
}

function accountRoutes() {
  if (getMetaOptions(`integrations.cognito.enabled`)) {
    return {
      accountMyAccount: {
        matcher: ACCOUNT_ROUTE,
        breadcrumbs: () => [{
          h1: I18n.t(webLabels.accountMyAccountTitle),
          path: ACCOUNT_ROUTE,
          slug: formatSlug(ACCOUNT_ROUTE),
        }],
      },
      accountAddresses: {
        matcher: ACCOUNT_ADDRESSES_ROUTE,
        breadcrumbs: () => [{
          h1: I18n.t(webLabels.accountAddressesTitle),
          path: ACCOUNT_ADDRESSES_ROUTE,
          slug: formatSlug(ACCOUNT_ADDRESSES_ROUTE),
        }],
      },
      accountPaymentMethods: {
        matcher: ACCOUNT_PAYMENT_METHODS_ROUTE,
        breadcrumbs: () => [{
          h1: I18n.t(webLabels.accountPaymentMethodsTitle),
          path: ACCOUNT_PAYMENT_METHODS_ROUTE,
          slug: formatSlug(ACCOUNT_PAYMENT_METHODS_ROUTE),
        }],
      },
      accountCreation: {
        matcher: ACCOUNT_CREATE_ROUTE,
        breadcrumbs: () => [{
          h1: I18n.t(webLabels.accountCreationTitle),
          path: ACCOUNT_CREATE_ROUTE,
          slug: formatSlug(ACCOUNT_CREATE_ROUTE),
        }],
      },
      accountVerification: {
        matcher: ACCOUNT_VERIFICATION_ROUTE,
        breadcrumbs: () => [{
          h1: I18n.t(webLabels.accountVerifyTitle),
          path: ACCOUNT_VERIFICATION_ROUTE,
          slug: formatSlug(ACCOUNT_VERIFICATION_ROUTE),
        }],
      },
      accountSignIn: {
        matcher: ACCOUNT_SIGNIN_ROUTE,
        breadcrumbs: () => [{
          h1: I18n.t(webLabels.signInTitle),
          path: ACCOUNT_SIGNIN_ROUTE,
          slug: formatSlug(ACCOUNT_SIGNIN_ROUTE),
        }],
      },
      accountForgottenPassword: {
        matcher: ACCOUNT_FORGOTTEN_PASSWORD_ROUTE,
        breadcrumbs: () => [{
          h1: I18n.t(webLabels.accountForgottenPasswordTitle),
          path: ACCOUNT_FORGOTTEN_PASSWORD_ROUTE,
          slug: formatSlug(ACCOUNT_FORGOTTEN_PASSWORD_ROUTE),
        }],
      },
      accountNewPassword: {
        matcher: ACCOUNT_NEW_PASSWORD_ROUTE,
        breadcrumbs: () => [{
          h1: I18n.t(webLabels.accountNewPasswordTitle),
          path: ACCOUNT_NEW_PASSWORD_ROUTE,
          slug: formatSlug(ACCOUNT_NEW_PASSWORD_ROUTE),
        }],
      },
    }
  }

  return {}
}

function clpNavTreeCheck({
  pathname,
  navTree,
}) {
  const navTreeEntry = checkInNavTree({ pathname, navTree, clpCheck: true })

  if (navTreeEntry.length) {
    return navTreeEntry
  }

  return []
}

function checkInNavTree({
  pathname,
  navTree,
  clpCheck,
}) {
  const levels = pathname.slice(1).split(`/`)

  let build = []
  const l1 = navTree.l1[levels[0]]

  if (l1) {
    walkTree(l1, levels[0])
  }

  function walkTree(routeEntry, path, i = 0) {
    const childLevel = `l${i + 2}`
    build.push(formatBreadcrumb(routeEntry, path, levels, i))

    const key = levels[i + 1]
    if (routeEntry[childLevel] && routeEntry[childLevel][key]) {
      walkTree(routeEntry[childLevel][key], key, i + 1)
    }
  }

  // if nothing has matched above and the build is less than the
  // amount of levels length, we can safely assume the page is not found

  if (build.length < levels.filter(Boolean).length) {
    if (clpCheck) {
      build.push({
        h1: I18n.t(webLabels.loading),
      })
    } else {
      build.push({
        h1: I18n.t(webLabels.notFoundTitle),
      })
    }
  }

  return build
}

function getFavorites() {
  const favorites = unversionedStorage.get(`favorites`) || [`this-product-id-does-not-exist-so-query-will-return-empty`]

  if (favorites.length) {
    return favorites.map(favorite => favorite.id)
  }

  return []
}

function getCheckoutH1(step) {
  return I18n.t(webLabels[step])
}

function getSearchH1(search) {


  return I18n.t(webLabels.searchTitle, {
    replace: {
      q: sanitize(queryString.parse(search).query),
    },
  })
}

function getProductBreadcrumb({
  product,
  productNotFound,
  navTree,
}) {
  let build = []

  if (product) {
    walkTree(navTree.l1)
  }

  if (productNotFound) {
    build.push({
      h1: I18n.t(webLabels.notFoundTitle),
    })
  }

  return build

  function walkTree(level, levels = [], idx = 0) {
    const entries = Object.entries(level)

    for (let i = 0; i < entries.length; i++) {
      const [slug, routeEntry] = entries[i]

      if (!slugExceptions(slug) && filtersMatcher(routeEntry, product)) {
        levels.push(slug)
        build.push(formatBreadcrumb(routeEntry, slug, levels, idx))
        const childLevel = `l${idx + 2}`

        if (routeEntry[childLevel]) {
          walkTree(routeEntry[childLevel], levels, idx + 1)
        }

        break
      }
    }
  }
}

function formatBreadcrumb(routeEntry, slug, levels, i) {
  const {
    path,
    label,
    filters,
    isBSC,
  } = routeEntry

  return {
    h1: label,
    filters,
    isBSC,
    path: path || generateRouteLink(slug, levels.slice(0, i)),
    slug,
  }
}

function slugExceptions(slug) {
  if ([`shop-all`, `view-all`, `all-toys`].includes(slug)) { // TODO include a flag at the content level to ignore certain slugs
    return true
  }
}

function getBrandName(activeLanguageFilters, tag) {
  if (activeLanguageFilters && activeLanguageFilters.brand[tag]) {
    return activeLanguageFilters.brand[tag].value
  }

  return tag
}

function routeMatcher(pathname, routeToCheck) {
  return matchPath(pathname, {
    path: routeToCheck,
    exact: true,
  })
}

function formatSlug(path) {
  let build = path

  if (path.startsWith(`/`)) {
    build = build.substr(1)
  }

  return build.split(`?`)[0]
}

function getSeoData(pathname, key, language) {
  const seoData = getContent(`seoData`, language)
  const pagesData = seoData[pathname]?.pages?.[key]

  if (pagesData) {
    return pagesData
  }

  return seoData.routes[`/:l1/:l2?/:l3?/:l4?`]?.[key]
}
