import React from 'react'

import { LazyModuleWrapper } from 'components/LazyModuleWrapper'

const LazyAccountPaymentMethods = (
  React.lazy(() => import(/* webpackChunkName: "AccountPaymentMethods" */ `./AccountPaymentMethods`))
)

export const AccountPaymentMethods = props => (
  <LazyModuleWrapper>
    <LazyAccountPaymentMethods {...props} />
  </LazyModuleWrapper>
)
