"use strict";
exports.__esModule = true;
exports.determineHeaderOffset = void 0;
var fixtures_1 = require("components/Scaffold/fixtures");
function determineHeaderOffset(element) {
    if (isElementInHeader(element)) {
        return window.pageYOffset;
    }
    var elementOffset = element.offsetTop;
    var stickyHeader = document.querySelector("#".concat(fixtures_1.HEADER_ID));
    var headerOffset = (stickyHeader === null || stickyHeader === void 0 ? void 0 : stickyHeader.getBoundingClientRect().height) || 0;
    var offset = elementOffset - headerOffset;
    return offset > 0 ? offset : 0;
}
exports.determineHeaderOffset = determineHeaderOffset;
function isElementInHeader(el) {
    if (!el) {
        return false;
    }
    if (el.id === fixtures_1.HEADER_ID) {
        return true;
    }
    return isElementInHeader(el.parentElement);
}
