import React from 'react'
import { useSelector } from 'react-redux'

import { useFilters } from 'hooks/useFilters'
import { FiltersCategoryHeading } from 'components/FiltersCategoryHeading'
import { FiltersOptionsCategoryColumns } from 'components/FiltersOptionsCategoryColumns'
import { FiltersOptionsColumns } from 'components/FiltersOptionsColumns'
import { FiltersOptionsGrid } from 'components/FiltersOptionsGrid'
import { FiltersOptionsList } from 'components/FiltersOptionsList'
import { FiltersRangeSelector } from 'components/FiltersRangeSelector'
import { FiltersSortBy } from 'components/FiltersSortBy'
import { FiltersSubcategoryHeading } from 'components/FiltersSubcategoryHeading'
import { ImpressionObserver } from 'components/ImpressionObserver'
import { SelectableBox } from 'components/SelectableBox'
import { SwatchButton } from 'components/SwatchButton'
import { TickableButton } from 'components/TickableButton'
import { useEmitViewFilterEvent, useEmitClickFilterEvent } from '../../../design-system/features/Events'
import { algoliaFiltersMap } from 'services/algolia'

const optionsMap = {
  categoryColumns: FiltersOptionsCategoryColumns,
  columns: FiltersOptionsColumns,
  grid: FiltersOptionsGrid,
  list: FiltersOptionsList,
  rangeSelector: FiltersRangeSelector,
}

const buttonMap = {
  selectableBox: SelectableBox,
  tickableOption: TickableButton,
  swatch: SwatchButton,
}

export const FiltersOptionsContainer = ({
  filter,
  filtersPadding,
  onApply,
  usePending,
  scrollHook,
}) => {
  const {
    buttonType = `selectableBox`,
    categorize,
    name,
    showSwatch,
    subcategorize,
    swatchType,
    swatchSize,
    type = `columns`,
  } = filter

  const { onFilterSelect } = useFilters(usePending)
  const options = useSelector(state => state.listing.pageFilters[name])
  const selectedFilters = useSelector(state => state.listing.selectedFilters)
  const pendingFilters = useSelector(state => state.listing.pendingFilters)
  const emitViewFilterEvent = useEmitViewFilterEvent()
  const emitClickFilterEvent = useEmitClickFilterEvent()

  const OptionsComponent = optionsMap[type]
  const ButtonComponent = buttonMap[buttonType]

  const data = buildData({
    subcategorize,
    categorize,
    options,
  })

  if (name === `sortBy`) {
    return (
      <FiltersSortBy
        filtersPadding={filtersPadding}
        onClick={onApply}
        type={buttonType}
      />
    )
  }

  return (
    <ImpressionObserver
      onUniqueImpression={() => {
        // this is not technically correct but good enough for now
        // maybe implement something similar to how products are viewed
        const filterNames = getFilterNames(name)
        filterNames.forEach(filterName => {
          emitViewFilterEvent({
            filterName,
          })
        })
      }}
    >
      <OptionsComponent
        data={data}
        name={name}
        renderButton={renderButton}
        renderHeading={renderHeading}
        scrollHook={scrollHook}
        uiOptions={filter}
        usePending={usePending}
      />
    </ImpressionObserver>
  )

  function renderButton({
    rgb,
    tag,
    value,
    swatchURL,
  }) {
    if (value) {
      const selected = checkActive(tag)

      return (
        <ButtonComponent
          aria-label={value}
          key={tag}
          label={value}
          name={value}
          nowrap
          onClick={() => {
            onFilterSelect(name, tag, scrollHook)
            if (!selected) {
              emitClickFilterEvent({
                filterName: `${name}:${tag}`,
              })
            }
          }}
          selected={selected}
          showSwatch={showSwatch}
          size="small"
          swatchHex={[rgb]}
          swatchPath={swatchURL}
          swatchSize={swatchSize}
          swatchTag={tag}
          swatchType={swatchType}
        />
      )
    }

    return null
  }

  function checkActive(tag) {
    if (usePending) {
      return pendingFilters[name] && pendingFilters[name].includes(tag)
    }

    return selectedFilters[name] && selectedFilters[name].includes(tag)
  }

  function renderHeading(html, subcategory) {
    if (subcategory) {
      return <FiltersSubcategoryHeading html={html} />
    }

    if (categorize) {
      return <FiltersCategoryHeading html={html} />
    }

    return null
  }

  function getFilterNames(filterName) {
    const filtersMap = algoliaFiltersMap.get()
    const {
      nameInAlgolia,
      filterType,
    } = filtersMap[filterName]

    if (filterType === `facet`) {
      return options.map(option => `${nameInAlgolia}:${option.tag}`)
    }
    return []
  }
}

function buildData({
  categorize,
  options,
  subcategorize,
}) {
  if (!categorize && !subcategorize) {
    return options
  }

  if (subcategorize) {
    return buildSubCategories(options)
  }

  if (categorize || subcategorize) {
    return buildCategories(options)
  }
}

function buildCategories(options) {
  let build = {}

  options.forEach(filter => {
    const { category } = filter

    if (build[category]) {
      build[category].push(filter)
    } else {
      build[category] = [filter]
    }
  })

  return build
}

function buildSubCategories(options) {
  let build = {}

  options.forEach(option => {
    const {
      category,
      subCategory,
    } = option

    if (build[category]) {
      if (build[category][subCategory]) {
        build[category][subCategory].push(option)
      } else {
        build[category][subCategory] = [option]
      }
    } else {
      build[category] = {
        [subCategory]: [option],
      }
    }
  })

  return build
}
