import React from 'react'
import classNames from 'classnames'

import styles from './Grid.css'

export const Grid = ({
  className,
  children,
  columns,
  gap,
  inlineGrid,
  itemSize = `1fr`,
  style = {},
}) => {
  let gridTemplateColumns = columns

  if (typeof columns === `number`) {
    gridTemplateColumns = `repeat(${columns}, ${itemSize})`
  }

  return (
    <div
      className={classNames(styles.grid, {
        [className]: className,
        [styles.inlineGrid]: inlineGrid,
      })}
      style={{
        gridTemplateColumns,
        gridGap: gap,
        ...style,
      }}
    >
      {children}
    </div>
  )
}
