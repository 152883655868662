"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
exports.__esModule = true;
exports.useEmitClickFilterEvent = void 0;
var useEmitClickFilterEvent_1 = require("./useEmitClickFilterEvent");
__createBinding(exports, useEmitClickFilterEvent_1, "useEmitClickFilterEvent");
