import React from 'react'

import { LazyModuleWrapper } from 'components/LazyModuleWrapper'

const LazyAccountSignIn = (
  React.lazy(() => import(/* webpackChunkName: "AccountSignIn" */ `./AccountSignIn`))
)

export const AccountSignIn = props => (
  <LazyModuleWrapper>
    <LazyAccountSignIn {...props} />
  </LazyModuleWrapper>
)
