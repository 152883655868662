import React from 'react'
import classNames from 'classnames'

import styles from './ToggleSwitch.css'

export const ToggleSwitch = ({
  checked,
  id,
  onClick,
}) => (
  <label
    className={styles.switch}
    htmlFor={id}
  >
    <input
      checked={checked}
      id={id}
      onChange={onClick}
      type="checkbox"
    />
    <div className={classNames(styles.slider, styles.round)} />
  </label>
)
