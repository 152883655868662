import { constructUrl } from 'models/utils'
import { getMetaOptions } from 'global-content/config'

export function normalizeCartResponse(data) {
  const cartSummary = normalizeCartSummary(data.cartSummary)
  const normalized = {
    ...data,
    items: data.items ? data.items.map(item => normalizeCartItem(item)) : [],
    cartSummary,
  }

  return normalized
}

function normalizeCartSummary(cartSummary) {
  const {
    totalDiscountShipping,
    deliveryCost,
    deliveryNetCost,
  } = cartSummary
  const calculateTaxAtCheckout = getMetaOptions(`calculateTaxAtCheckout`)
  const shippingPrice = calculateTaxAtCheckout ? deliveryNetCost : deliveryCost

  const shippingCost = shippingPrice - totalDiscountShipping
  const isDiscounted = shippingCost !== shippingPrice
  const isFree = shippingCost === 0
  return {
    ...cartSummary,
    isDiscounted,
    isFree,
    shippingCost,
    shippingPrice,
  }
}

function normalizeCartItem(item) {
  const {
    productId,
    slug,
  } = item

  return {
    ...item,
    productId,
    slug,
    url: constructUrl({ productId, slug }),
  }
}
