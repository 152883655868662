"use strict";
exports.__esModule = true;
exports.useEmitClickFilterEvent = void 0;
var events_1 = require("../../../services/events");
var useEmitClickFilterEvent = function () {
    return function (_a) {
        var filterName = _a.filterName;
        return events_1.Events.emitClickFilter({
            filterName: filterName
        });
    };
};
exports.useEmitClickFilterEvent = useEmitClickFilterEvent;
