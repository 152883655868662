"use strict";
/* eslint-disable */
exports.__esModule = true;
exports.EmitEventType = void 0;
var EmitEventType;
(function (EmitEventType) {
    EmitEventType["emitStartedCheckout"] = "emitStartedCheckout";
    EmitEventType["emitCompleteCheckout"] = "emitCompleteCheckout";
    EmitEventType["emitAddToCart"] = "emitAddToCart";
    EmitEventType["emitViewProduct"] = "emitViewProduct";
    EmitEventType["emitViewProductDetails"] = "emitViewProductDetails";
    EmitEventType["emitClickProduct"] = "emitClickProduct";
    EmitEventType["emitClickFilter"] = "emitClickFilter";
    EmitEventType["emitViewFilter"] = "emitViewFilter";
    EmitEventType["emitIdentifyUserSession"] = "emitIdentifyUserSession";
    EmitEventType["emitResetUserSession"] = "emitResetUserSession";
    EmitEventType["emitLanguageSwitched"] = "emitLanguageSwitched";
})(EmitEventType = exports.EmitEventType || (exports.EmitEventType = {}));
