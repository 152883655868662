import store from 'state/reducers'
import { setFocussedNavItem } from 'state/actions/site'

export function keyCaller({
  handler,
  navMenuIndex,
  ...rest
}) {
  const {
    focussedNavItems,
    focussedNavItem,
  } = store.getState().site

  handler({
    ...rest,
    focussedNavItems,
    focussedNavItem,
    navMenuIndex,
  })
}

export function moveToNextNavItem({
  dispatch,
  focussedNavItems,
}) {
  const id = getNavItemTargetId(focussedNavItems, `next`)

  if (id) {
    handleFocus(dispatch, id)
  }
}

export function moveToPreviousNavItem({
  dispatch,
  focussedNavItems,
}) {
  const id = getNavItemTargetId(focussedNavItems, `previous`)

  if (id) {
    handleFocus(dispatch, id)
  }
}

export function clickNavItem({
  focussedNavItem,
}) {
  const el = document.getElementById(`navItem_${focussedNavItem}`)

  if (el) {
    el.click()
  }
}

export function moveToPreviousNavMenu({
  dispatch,
  focussedNavItems,
}) {
  const id = getNavMenuTargetId(focussedNavItems, `previous`)

  if (id) {
    handleFocus(dispatch, id)
  }
}

export function moveToNextNavMenu({
  dispatch,
  focussedNavItems,
}) {
  const id = getNavMenuTargetId(focussedNavItems, `next`)

  if (id) {
    handleFocus(dispatch, id)
  }
}

export function getNavItemTargetId(focussedNavItems, direction) {
  const directionMap = {
    next: 1,
    previous: -1,
  }

  const parts = focussedNavItems.slice(0, focussedNavItems.length - 1)

  const id = [
    ...parts,
    focussedNavItems[focussedNavItems.length - 1] + directionMap[direction],
  ].join(`_`)
  if (document.getElementById(`navItem_${id}`)) {
    return id
  }
}

export function getNavMenuTargetId(focussedNavItems, direction) {
  const directionMap = {
    next: [
      ...focussedNavItems,
      0,
    ],
    previous: [
      ...focussedNavItems.slice(0, focussedNavItems.length - 1),
    ],
  }

  const id = directionMap[direction].join(`_`)

  if (document.getElementById(`navItem_${id}`)) {
    return id
  }
}

function handleFocus(dispatch, id) {
  dispatch(setFocussedNavItem(id))
}
