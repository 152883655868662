import { AVAILABILITY_FLAGS, SALE_STATES } from 'utils/constants'

export function cartItemToDetailsItem(cartItem) {
  return {
    availability: cartItem.availability,
    brand: {
      name: cartItem.brand,
    },
    collections: [],
    id: cartItem.productId,
    name: cartItem.name,
    priceRange: {
      listMin: cartItem.price.list.total,
      listMax: cartItem.price.list.total,
      saleMin: cartItem.price.sale.total,
      saleMax: cartItem.price.sale.total,
    },
    slug: cartItem.slug,
    categories: cartItem.categories,
    objectID: undefined,
    optionLevels: [`color`],
    availabilityFlag: AVAILABILITY_FLAGS[cartItem.availability],
    color: cartItem.options.color,
    options: [{
      slug: cartItem.options.color.slug,
      color: {
        name: cartItem.options.color.name,
      },
      availability: cartItem.availability,
      mainImage: cartItem.thumbnailUrl,
      media: {
        large: [
          cartItem.thumbnailUrl,
        ],
        video: [],
      },
    }],
    mainImage: cartItem.thumbnailUrl,
    price: cartItem.price,
    productAvailability: cartItem.availability,
    onSale: SALE_STATES.findIndex(saleState => saleState === cartItem.sale),
    sale: cartItem.sale,
    size: {
      tag: [],
    },
    sku: [
      cartItem.sku,
    ],
    url: cartItem.url,
    promotions: {},
  }
}
