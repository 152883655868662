import React from 'react'
import { useDispatch } from 'react-redux'
import merge from 'lodash/merge'

import { addComponents } from 'state/actions/components'
import { updateGoogleAnalytics } from 'services/analytics'
import { getComponentOptions } from 'global-content/components'
import { useDeepCompare } from 'hooks/useDeepCompare'
import { Carousel } from 'components/Carousel'
import { SkeletonCard } from 'components/SkeletonCard'
import { ProductCardContainer } from 'components/ProductCardContainer'
import { getIndexName } from 'global-content/algolia'

export const ProductCardCarousel = ({
  algoliaIndex,
  analyticsTitle,
  carouselOptions = {},
  itemsPerRow = 4,
  itemsToShow = 4,
  posters = [],
  productCardGap = `2rem`,
  productCardOptions,
  favoritePage,
  styling = {},
  positioning = {},
  skeleton,
  products = [],
}) => {
  const deepCheck = useDeepCompare(products)
  const dispatch = useDispatch()
  const defaultProductCardOptions = getComponentOptions(`productCardOptions`)
  const combinedProductCardOptions = merge({}, defaultProductCardOptions, productCardOptions)
  const indexName = getIndexName(algoliaIndex)

  React.useEffect(() => {
    dispatch(addComponents({
      skeletonCard: SkeletonCard,
      productCard: ProductCardContainer,
    }))
  }, [])

  React.useEffect(() => {
    if (products.length) {
      updateGoogleAnalytics(`productImpression`, {
        items: products,
        listLocation: analyticsTitle,
      })
    }
  }, [deepCheck])

  return (
    <Carousel
      config={{
        centerMode: carouselOptions.centerMode,
        infinite: carouselOptions.infinite,
        slidesToScroll: carouselOptions.itemsToScroll,
        swipeToSlide: carouselOptions.swipeToSlide,
        slidesToShow: itemsPerRow,
      }}
      items={getProductCardList()}
      positioning={{
        gap: productCardGap,
        controlsDots: carouselOptions.controlsDots,
        controlsArrows: carouselOptions.controlsArrows,
        ...positioning,
      }}
      styling={styling}
    />
  )

  function getProductCardList() {
    if (skeleton) {
      return generateSkeleton()
    }
    const items = generateItems()

    return items.slice(0, itemsToShow).map((item, i) => {
      if (item?.data?.component === `posterCard`) {
        return item
      }
      return {
        id: item.objectID,
        data: {
          component: `productCard`,
          analyticsTitle,
          indexName,
          productCardOptions: combinedProductCardOptions,
          favoritePage,
          position: i + 1,
          product: item,
        },
      }
    })
  }

  function generateSkeleton() {
    const skeletonItems = []

    for (let i = 0; i < itemsToShow; i++) {
      skeletonItems.push({
        id: i,
        data: {
          component: `skeletonCard`,
          productCardOptions: combinedProductCardOptions,
        },
      })
    }

    return skeletonItems
  }

  function generateItems() {
    const items = [...products]

    posters.forEach(poster => {
      items.splice(poster.data.config.index, 0, poster)
    })

    return items
  }
}
