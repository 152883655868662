import React from 'react'
import { Factory } from 'components/Factory'
import { useStickyProductDetails } from './useStickyProductDetails'
import styles from './StickyProductDetails.css'

export const StickyProductDetails = ({
  items,
}) => {
  const ref = useStickyProductDetails()

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.inner}
        ref={ref}
      >
        <Factory items={items}/>
      </div>
    </div>
  )
}
