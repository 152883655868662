import merge from 'lodash/merge'
import omitBy from 'lodash/omitBy'

import { getMetaOptions } from 'global-content/config'
import {
  BRANDCODE_PAYPAL,
  BRANDCODE_CREDITCARD,
} from 'utils/constants'

export function constructOrderPayload({
  billingAddress,
  brandCode,
  cardDetails,
  consumer,
  deviceFingerprint,
  locale,
  shippingAddress,
  storePaymentMethod,
  thirdPartyData,
}) {
  let build = merge(
    {},
    thirdPartyData,
    {
      addresses: {
        billingAddress: billingAddress && constructBillingAddress({ ...billingAddress, locale }),
        shippingAddress: constructShippingAddress({ ...shippingAddress, locale }),
      },
      consumer: constructConsumer(consumer, shippingAddress),
      paymentMethod: brandCode,
      deviceFingerprint,
      userAgent: window.navigator.userAgent,
    },
  )

  if (brandCode === BRANDCODE_CREDITCARD) {
    build.paymentMethod = cardDetails.paymentMethod

    if (cardDetails.cardReference) {
      build.cardToken = cardDetails.cardReference
    } else {
      build.cardHolderName = cardDetails.cardHolderName
      build.encryptedCardNumber = cardDetails.encryptedCardNumber
      build.encryptedExpiryMonth = cardDetails.encryptedExpiryMonth
      build.encryptedExpiryYear = cardDetails.encryptedExpiryYear
      build.encryptedSecurityCode = cardDetails.encryptedSecurityCode
      build.storeCard = storePaymentMethod
    }
  }

  if (brandCode === BRANDCODE_PAYPAL) {
    delete build.addresses.billingAddress
  }

  return build
}

export function constructConsumer(consumer, shippingAddress) {
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    ...consumerProperties
  } = consumer.properties

  return {
    ...consumer,
    properties: {
      firstName: firstName || shippingAddress?.firstName || undefined,
      lastName: lastName || shippingAddress?.lastName || undefined,
      email: email || shippingAddress?.email || undefined,
      phoneNumber: phoneNumber || shippingAddress?.phoneNumber || undefined,
      ...consumerProperties,
    },
  }
}

function constructBillingAddress({
  // remove from payload
  default: defaultProp, // eslint-disable-line no-unused-vars
  reference, // eslint-disable-line no-unused-vars
  firstName, // eslint-disable-line no-unused-vars
  lastName, // eslint-disable-line no-unused-vars
  ...rest
}) {
  return omitBy({
    territoryCode: getMetaOptions(`country.alpha3Code`),
    ...rest,
  }, isSoftEmpty)
}

function constructShippingAddress({
  // remove from payload
  default: defaultProp, // eslint-disable-line no-unused-vars
  reference, // eslint-disable-line no-unused-vars
  paymentAttached, // eslint-disable-line no-unused-vars
  ...rest
}) {
  return omitBy({
    territoryCode: getMetaOptions(`country.alpha3Code`),
    ...rest,
  }, isSoftEmpty)
}

function isSoftEmpty(value) {
  if (value === `` || value === undefined) {
    return true
  }

  return false
}
