import queryString from 'query-string'

import { CAPTCHA_TRIGGER } from 'utils/constants'
import { getOptions } from 'services/api/utils'
import {
  baseUrl,
  SESSION_ID_NAME,
} from 'services/api/routes'
import * as apiRoutes from 'services/api/routes'
import { createSession } from 'services/api/session'
import { refreshSession, accountSignOut } from 'services/api/account'
import store from 'state/reducers'
import { setAccountValues } from 'state/actions/account'
import { ErrorAdditional, SENTRY_TAGS } from 'utils/ErrorAdditional'
import includes from 'lodash/includes'
import isObject from 'lodash/isObject'
import { getMetaOptions } from 'global-content/config'

export const FAILED_ATTEMPTS_KEY = `$apiFailedAttempts`

const maxAttempts = 3
window[FAILED_ATTEMPTS_KEY] = {}

// user agnostic call. Utility function.
export function call(args, callOptions) {
  const {
    path,
    params,
  } = withSiteTag(args)

  const options = getOptions(callOptions)
  const stringifiedParams = queryString.stringify(params)
  const append = stringifiedParams ? `?${stringifiedParams}` : ``
  const endpoint = `${path}${append}`
  const pathname = `${baseUrl()}${endpoint}`
  const sessionName = sessionStorage.getItem(SESSION_ID_NAME)
  addAttempt(pathname)

  if (window[FAILED_ATTEMPTS_KEY][pathname] > maxAttempts) {
    // reset failed attempts in two seconds so client-led retries are allowed
    // but we don't end up in an infinite loop when retrying
    setTimeout(() => {
      resetAttempt(pathname)
    }, 2000)

    throw new ErrorAdditional({
      severity: 1,
      title: `Exceeded max attempts`,
      message: matchApiRoute(path) || path,
      additional: {
        path,
        params,
      },
      tags: {
        [SENTRY_TAGS.SOURCE_FILE]: `src/services/api/call.js`,
        [SENTRY_TAGS.FETCH_URL]: pathname,
      },
    })
  }

  if (!sessionName) {
    return newSessionAndRetry({ path, params }, options)
  }

  return measureCallTime(() => {
    return fetch(pathname, options)
      .then(response => {
        if (response.status === 401) {
          return newSessionAndRetry({ path, params }, callOptions).catch(() => {
            return parseError(response)
          })
        }

        if (response.status === 428) {
          resetAttempt(pathname)
          return CAPTCHA_TRIGGER
        }

        if (response.status >= 400) {
          return parseError(response)
        }

        resetAttempt(pathname)
        return response
      })
      .then(response => {
        return parseResponse(response)
      })
  })
}

function newSessionAndRetry({
  path,
  params,
}, originalOptions) {
  return (
    checkUserSession()
      .then(() => createSession())
      .then(() => {
        const options = getOptions(originalOptions)
        return call({ path, params }, options)
      })
      //eslint-disable-next-line
      .catch(async () => {
        await accountSignOut()
        throw new Error(`401 User Session Failed To Validate`)
      })
  )
}

async function checkUserSession() {
  const { isUserSignedIn } = store.getState().account

  if (isUserSignedIn) {
    return refreshSession().then(data => {
      store.dispatch(setAccountValues({
        userJwt: data.accessToken.jwtToken,
      }))

      return data
    }).catch(() => {
      return Promise.reject()
    })
  }

  return Promise.resolve()
}

export function parseError(response) {
  if (!response?.text) {
    return Promise.reject(response)
  }

  // unreliable application type headers from API
  // fetch always as text, then parse
  return response.text().then((data) => {
    return JSON.parse(data)
  }).catch(() => {
    throw response
  }).then((json) => {
    throw {
      status: response?.status,
      ...json,
    }
  })
}

function parseResponse(response) {
  if (typeof response.text === `function`) {
    return response.text()
      .then(text => {
        try {
          return JSON.parse(text || `{}`)
        } catch {
          return text
        }
      })
  }

  return response
}

function measureCallTime(makeCall) {
  return makeCall()
    .then(response => {
      return response
    })
}

function addAttempt(pathname) {
  if (window[FAILED_ATTEMPTS_KEY][pathname]) {
    window[FAILED_ATTEMPTS_KEY][pathname]++
  } else {
    window[FAILED_ATTEMPTS_KEY][pathname] = 1
  }
}

function resetAttempt(pathname) {
  window[FAILED_ATTEMPTS_KEY][pathname] = 0
}

function matchApiRoute(path) {
  let match
  const routesArr = Object.values(apiRoutes).filter(val => typeof val === `string`)

  for (let i = 0; i < routesArr.length; i++) {
    if (path.startsWith(routesArr[i])) {
      match = routesArr[i]
      break
    }
  }

  return match
}

export function withSiteTag(xs = {}){
  const siteTag = getMetaOptions(`siteTag`)

  let {
    path,
    params,
  } = xs

  if (includes(path, `/api`)) {
    if (isObject(params)) {
      params.siteTag = siteTag
    } else {
      params = {
        siteTag: siteTag,
      }
    }
  }

  return {
    ...xs,
    path,
    params,
  }
}
