import { useEffect } from "react"

export const useYotpoScript = ({
  appKey,
  productId,
}) => {
  useEffect(() => {
    if (appKey) {
      if (window.yotpo) {
        window.yotpo.initWidgets()
      } else {
        const script = document.createElement(`script`)
        script.src = `https://staticw2.yotpo.com/${appKey}/widget.js`
        script.async = true
        document.body.appendChild(script)
      }
    }
  }, [appKey, productId])
}
