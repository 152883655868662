import React from 'react'
import classNames from 'classnames'

import styles from './InputMessages.css'

export const InputMessages = ({
  id,
  messages = [],
}) => {
  if (messages.length) {
    return (
      <div
        id={`input-messages-${id}`}
        role="alert"
      >
        {messages.map(message => {
          return (
            <div
              className={classNames(styles.message, {
                [styles.success]: message.type === `success`,
                [styles.error]: message.type === `error`,
                [styles.warning]: message.type === `warning`,
              })}
              data-testid={`input-message-${id}`}
              key={message.key}
            >
              {message.children}
            </div>
          )
        })}
      </div>
    )
  }

  return null
}
