import React from 'react'

import { Loader } from 'components/Loader'

import styles from './LoaderOverlay.css'

export const LoaderOverlay = ({ size }) => {
  return (
    <div className={styles.container}>
      <Loader size={size} />
    </div>
  )
}
