import React from 'react'

import { ProductCardBanner } from 'components/ProductCardBanner'
import { ProductCardBadge } from 'components/ProductCardBadge'
import { HighlightBox } from 'components/HighlightBox'
import { Highlight } from 'components/Highlight'

export const Promotions = ({
  promotions,
  type,
}) => {
  const typeMap = {
    banner: ProductCardBanner,
    badge: ProductCardBadge,
    text: Highlight,
    box: HighlightBox,
  }

  const Component = typeMap[type]

  if (promotions) {
    return promotions.map(promotion => (
      <Component
        key={promotion.content.html}
        {...promotion}
      />
    ))
  }

  return null
}
