import React from 'react'
import classNames from 'classnames'

import { getComponentOptions } from 'global-content/components'
import { getMetaOptions } from 'global-content/config'

import styles from './Swatch.css'

export const Swatch = ({
  swatchHex,
  swatchPath,
  swatchSize,
  swatchTag,
  swatchType = getComponentOptions(`swatch.type`),
}) => {
  return (
    <div
      className={classNames(
        styles.swatch,
        styles[swatchType], {
          [styles.small]: swatchSize === `xs`,
          [styles.small]: swatchSize === `small`,
          [styles.xmedium]: swatchSize === `xmedium`,
          [styles.large]: swatchSize === `large`,
          [styles.fill]: swatchSize === `fill`,
          [styles.circle]: swatchType === `circle`,
        }
      )}
      style={inlineSwatchStyle()}
    />
  )

  function inlineSwatchStyle() {
    if (swatchPath) {
      return {
        backgroundImage: swatchPath && `url(${swatchPath.startsWith(`http`)
          ? ``
          : getMetaOptions(`countryFolder`)}${swatchPath})`,
      }
    }

    if (swatchHex && swatchHex[0]) {
      return {
        background: swatchHex.length > 1 ? generateGradient() : `${formatHex(swatchHex[0])}`,
      }
    }

    if (swatchTag && isValidColor(swatchTag)) {
      return {
        background: swatchTag,
      }
    }

    const stripeSize = 2.5
    return {
      background: `repeating-linear-gradient(-45deg, rgba(186, 218, 85, 1), rgba(186, 218, 85, 1) ${stripeSize}px, black ${stripeSize}px, black ${stripeSize * 2}px)`,
    }
  }

  function generateGradient() {
    let colors = swatchHex.map(hex => `${formatHex(hex)} ${100 / swatchHex.length}%`).join(`,`)

    return `linear-gradient(-45deg, ${colors})`
  }

  function formatHex(hex) {
    if (hex.charAt(0) === `#`) {
      return hex
    }

    return `#${hex}`
  }
}

function isValidColor(strColor) {
  const style = new Option().style
  style.color = strColor
  return style.color === strColor
}
