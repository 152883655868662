import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getMetaOptions } from 'global-content/config'
import { captchaAvailable, fetchV3CaptchaToken } from 'state/actions/site'
import { Script } from 'factory-components/Script'
import { findElementWhenReady } from 'utils/findElementWhenReady'

export const Captcha = () => {
  const dispatch = useDispatch()
  const isCaptchaAvailable = useSelector(state => state.site.captchaAvailable)
  const seenCaptcha = useSelector(state => state.site.seenCaptcha)

  const {
    enabled,
    v3Key,
    allowedActions,
  } = getMetaOptions(`integrations.captcha`) || {}

  React.useEffect(() => {
    if (isCaptchaAvailable) {
      dispatch(fetchV3CaptchaToken({ v3Key, allowedActions: allowedActions.split(`,`) }))

      setInterval(() => {
        dispatch(fetchV3CaptchaToken({ v3Key, allowedActions: allowedActions.split(`,`) }))
      }, 30000)
    }
  }, [isCaptchaAvailable])

  if (enabled && seenCaptcha && !isCaptchaAvailable) {
    return (
      <Script
        data={{
          content: {
            scripts: [`https://www.google.com/recaptcha/enterprise.js?render=${v3Key}`],
          },
        }}
        onLoad={() => {
          findElementWhenReady({
            element: `.grecaptcha-badge`,
            giveupAfter: 5000,
          }).then(brandingBadge => {
            brandingBadge.style.visibility = `hidden`
            dispatch(captchaAvailable())
          })
        }}
      />
    )
  }

  return null
}
