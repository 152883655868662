import React from 'react'
import { ModernFactoryComponent } from '../ModernFactoryComponent'
import { getMetaOptions } from 'global-content/config'

export const FactorySvg = ModernFactoryComponent(({
  icon,
  ...props
}, ref) => {
  delete props.data

  const countryFolder = getMetaOptions(`countryFolder`)

  return (
    <svg
      {...props}
      ref={ref}
    >
      <use
        href={`${countryFolder}/themes/icons/sprites.svg?cb=${window.$cacheBust}#${icon}`}
      />
    </svg>
  )
})
