import React, { useMemo, useState } from 'react'
import { ModernFactoryComponent } from '../ModernFactoryComponent'
import { useSelector } from 'react-redux'

export const ContextNavigationContext = React.createContext({})

export const ContextNavigation = ModernFactoryComponent(({
  Tag,
  children,
  ...props
}, ref) => {
  delete props.data
  const activeNavContext = useSelector(state => state.navigation.navContext)
  const [tabNavContext, setTabNavContext] = useState(activeNavContext)

  const valueState = useMemo(() => ({
    activeNavContext,
    tabNavContext,
    setTabNavContext,
  }), [activeNavContext, tabNavContext])

  return (
    <ContextNavigationContext.Provider
      value={valueState}
    >
      <Tag
        {...props}
        ref={ref}
      >
        {children}
      </Tag>
    </ContextNavigationContext.Provider>
  )
})
