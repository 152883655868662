import React from 'react'
import classNames from 'classnames'

import { capitalize } from 'utils/capitalize'

import shared from 'components/Skeleton/Skeleton.css'
import styles from './SkeletonText.css'

export const SkeletonText = ({
  lineHeight = `normal`,
  margin,
  size = `bodyRegular`,
  width,
}) => (
  <div
    className={classNames(shared.animate, styles[size], styles[`lineHeight${capitalize(lineHeight)}`])}
    style={{
      margin,
      width,
    }}
  />
)
