// Important to note we use Google Tag Manager and NOT analytics.js [ga()] or gtag.js
// https://developers.google.com/tag-manager/devguide
import { getMetaOptions } from 'global-content/config'

const GTM_LOADED_EVENT = `GTM_LOADED_EVENT`

export function analytics() {
  const { id } = getMetaOptions(`integrations.googleAnalytics`)

  if (
    [`production`, `staging`].includes(getMetaOptions(`environment`)) &&
    !window.localStorage.getItem(`disableTracking`)
  ) {
    /* eslint-disable */
    (function(w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({ 'gtm.start':
    new Date().getTime(),
      event: 'gtm.js' }); let f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
    'https://www.googletagmanager.com/gtm.js?id=' + i + dl;

    // (Kevin): dispatch a custom event that indicates that all GTM scripts have been loaded
    // see blog post for more details: https://www.simoahava.com/analytics/notify-page-google-tag-manager-loaded/
    j.addEventListener('load', function() {
      var _ge = new CustomEvent(GTM_LOADED_EVENT, { bubbles: true });
      d.dispatchEvent(_ge);
    });

    f.parentNode.insertBefore(j, f)
    })(window, document, 'script', 'dataLayer', id)

    window.document.body.insertAdjacentHTML('afterbegin', `
      <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=${id}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
      </noscript>
    `)
    /* eslint-enable */
  }
}

export function withGtmLoaded(callback) {
  if (window) {
    if (window.google_tag_manager) {
      callback()
    } else {
      window.addEventListener(GTM_LOADED_EVENT, callback, { once: true })
    }
  }
}
