import { ErrorAdditional, SENTRY_TAGS } from "utils/ErrorAdditional"

export function handleLazyLoadModuleFailure(e) {
  const {
    message,
    moduleRef,
  } = getHashAndCleanFailureMessage(e.message)
  const storageHashKey = `module_${moduleRef}`

  throw new ErrorAdditional({
    title: e.name,
    message,
    severity: 2,
    additional: {
      storageHashKey,
      originalError: e.message,
    },
    originalError: e,
    tags: {
      [SENTRY_TAGS.SOURCE_FILE]: `src/utils/handleLazyLoadModuleFailure.js`,
      [SENTRY_TAGS.FETCH_URL]: storageHashKey,
    },
  })
}

function getHashAndCleanFailureMessage(message) {
  if (message.startsWith(`Cannot find module`)) {
    const parts = message.split(`/`)
    const moduleName = parts[parts.length - 1]
    const moduleRef = moduleName.slice(0, -1)

    return {
      moduleRef,
      message,
    }
  }

  const [problemAndFile, hashAndExtension = ``] = (
    message
      .replace(window.location.origin, ``)
      .split(`_`)
  )

  const [moduleRef, extension] = hashAndExtension.split(`.`)

  return {
    moduleRef,
    message: [problemAndFile, extension].join(`.`),
  }
}
