import { filterData } from './filterData'
import { filterKeysByString } from './filterKeysByString'
import { updateGoogleAnalytics } from 'services/analytics'
import once from 'lodash/once'

export function applyAnalytics({
  htmlNode,
  analyticsData,
}) {
  if (!htmlNode) {
    return () => {}
  }
  const clearClick = applyClickAnalytics({ analyticsData, htmlNode })
  const clearHover = applyHoverAnalytics({ analyticsData, htmlNode })
  const clearView = applyViewAnalytics({ analyticsData, htmlNode })

  return () => {
    clearClick()
    clearHover()
    clearView()
  }
}

function applyClickAnalytics({
  htmlNode,
  analyticsData,
}) {
  const {
    click: clickEvent,
    ...attributeParameters
  } = filterKeysByString(`click`, analyticsData)

  const { clickAttrData: options } = filterData(attributeParameters, `clickAttr`)
  if (clickEvent) {
    const clickFunction = () => {
      updateGoogleAnalytics(clickEvent, { ...options })
    }
    htmlNode.addEventListener(`click`, clickFunction)
    return () => {
      htmlNode.removeEventListener(`click`, clickFunction)
    }
  }
  return () => {}
}

function applyHoverAnalytics({
  htmlNode,
  analyticsData,
}) {
  const {
    hover: hoverEvent,
    ...attributeParameters
  } = filterKeysByString(`hover`, analyticsData)

  const { hoverAttrData: options } = filterData(attributeParameters, `hoverAttr`)
  if (hoverEvent) {
    const clickFunction = () => {
      updateGoogleAnalytics(hoverEvent, { ...options })
    }
    htmlNode.addEventListener(`mouseover`, clickFunction)
    return () => {
      htmlNode.removeEventListener(`mouseover`, clickFunction)
    }
  }
  return () => {}
}

function applyViewAnalytics({
  htmlNode,
  analyticsData,
}) {
  const {
    view: viewEvent,
    ...attributeParameters
  } = filterKeysByString(`view`, analyticsData)

  const {
    viewAttrData: options,
    viewConfigAttrData,
  } = filterData(attributeParameters, `viewAttr`, `viewConfigAttr`)
  if (viewEvent && window.IntersectionObserver) {
    const analyicsCallback = once(() => updateGoogleAnalytics(viewEvent, { ...options }))

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          analyicsCallback()
        }
      })
    }, {
      threshold: 0.5,
      ...viewConfigAttrData,
    })

    observer.observe(htmlNode)
    return () => {
      observer.disconnect()
    }
  }
  return () => {}
}



