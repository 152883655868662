import React from 'react'
import classNames from 'classnames'

import { HtmlText } from 'components/HtmlText'
import { Tooltip } from 'components/Tooltip'

import styles from './WithLabel.css'

export const WithLabel = ({
  children,
  htmlFor,
  label,
  size,
  tooltip,
}) => {
  return (
    <div>
      <div className={styles.labelContainer}>
        {label &&
          <label
            className={classNames(styles.label, {
              [styles.small]: size === `small`,
            })}
            htmlFor={htmlFor}
          >
            <HtmlText html={label} />
          </label>
        }
        {tooltip &&
          <Tooltip
            image={tooltip.image}
            name={htmlFor}
            positioning={[`bottom`, `end`]}
            title={tooltip.header}
            value={tooltip.description}
          />
        }
      </div>
      {children}
    </div>
  )
}
