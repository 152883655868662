import React, { useContext } from 'react'
import { ModernFactoryComponent } from '../ModernFactoryComponent'
import { ContextNavigationContext } from './ContextNavigation'
import { NavigationContext } from '../Navigation/Navigation'

export const ContextNavigationTab = ModernFactoryComponent(({
  children,
  data: {
    navContext,
  },
  Tag,
  ...props
}, ref) => {
  delete props.Tag
  const {
    tabNavContext,
    setTabNavContext,
  } = useContext(ContextNavigationContext)

  const {
    clearCurrentNav,
  } = useContext(NavigationContext)


  const isSelected = tabNavContext === navContext

  return (
    <Tag
      {...props}
      aria-selected={isSelected}
      onClick={() => {
        setTabNavContext(navContext)
        clearCurrentNav && clearCurrentNav()
      }}
      ref={ref}
      tabIndex={isSelected ? -1 : 0}
    >
      {children}
    </Tag>
  )
})
