import {
  ENABLE_TEST_PAYMENTS,
  SET_INCHES,
} from "state/actions"

export const setInches = (inches) => ({
  type: SET_INCHES,
  payload: inches,
})

export const enableTestPayments = () => ({
  type: ENABLE_TEST_PAYMENTS,
})
