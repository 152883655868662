import { FAVORITE_ADD, FAVORITE_REMOVE } from "state/actions"

export const favoriteAdd = productId => ({
  type: FAVORITE_ADD,
  payload: productId,
})

export const favoriteRemove = productId => ({
  type: FAVORITE_REMOVE,
  payload: productId,
})
