import findKey from 'lodash/findKey'
import get from 'lodash/get'

import { algoliaFiltersMap } from 'services/algolia'

export function toResultsSummary(results) {
  const {
    exhaustiveNbHits,
    facets_stats, // eslint-disable-line camelcase
    hitsPerPage,
    nbHits,
    nbPages,
    page,
  } = results

  return {
    approximateTotal: !exhaustiveNbHits,
    currentPage: page,
    numericFilterStats: facets_stats,
    pages: nbPages,
    hitsPerPage,
    nbHits,
  }
}

export function toPageFacets(facets) {
  const FILTERSMAP = algoliaFiltersMap.get()
  let build = {}

  Object.keys(facets).forEach(facetKey => {
    const niceName = findKey(FILTERSMAP, [`nameInAlgolia`, facetKey])
    build[niceName] = facets[facetKey]
  })

  return build
}

export function toPageFilters(facets, allFilters) {
  const FILTERSMAP = algoliaFiltersMap.get()
  let build = {}

  Object.keys(facets).forEach(facetKey => {
    const niceName = findKey(FILTERSMAP, [`nameInAlgolia`, facetKey])
    const { filterType } = FILTERSMAP[niceName]

    build[niceName] = (
      Object.keys(facets[facetKey])
        .map(option => buildOption(option, niceName, filterType, allFilters))
        .filter(Boolean)
        .sort((a, b) => sortOptions(a, b, niceName))
    )
  })

  return build
}

function buildOption(option, niceName, filterType, allFilters) {
  if (filterType === `numeric`) {
    return {
      value: option,
    }
  }

  return get(allFilters, `${niceName}.${option}`)
}

function sortOptions(optionA, optionB, niceName) {
  if (niceName === `brand`) {
    if (optionA.tag < optionB.tag) return -1
    if (optionA.tag > optionB.tag) return 1
    return 0
  }

  return optionA.index - optionB.index
}

/*
    When manual rule is used, this is the data structure expected

    "userData": [
          {
              "redirect": "/american-eagle/jeans"
          }
      ]

    When visual rule is used, this is the data structure expected

    "renderingContent": {
        "redirect": {
            "url": "/american-eagle/sale"
        }
    }

  */
export function toUserData(results) {
  const userData = results?.userData || []

  // Move renderingContent into userData if it exists
  if (results?.renderingContent?.redirect) {
    const { redirect } = results.renderingContent
    userData.push({ redirect })
  }

  if (!userData.length) return {}

  return userData.reduce((acc, curr) => {
    if (curr.redirect) {
      // may come formatted as either
      acc.redirect = curr.redirect?.url || curr.redirect
    }
    return acc
  }, {})
}