import React from 'react'

import { LazyModuleWrapper } from 'components/LazyModuleWrapper'

const LazyAccountNewPassword = (
  React.lazy(() => import(/* webpackChunkName: "AccountNewPassword" */ `./AccountNewPassword`))
)

export const AccountNewPassword = props => (
  <LazyModuleWrapper>
    <LazyAccountNewPassword {...props} />
  </LazyModuleWrapper>
)
