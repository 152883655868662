import { addToEmailList } from 'services/api'
import { updateGoogleAnalytics } from 'services/analytics'
import { storage } from 'utils/storage'
import { utilizeEmailValidation } from 'global-content/utils'
import { emailStorageValue } from 'factory-components/EmailSignupBar/fixtures'
import { ErrorAdditional, SENTRY_TAGS } from 'utils/ErrorAdditional'
import { reportError } from 'services/reportError'
import { heapAnalytics } from 'services/heap-analytics'

/**
 * @NOTE
 * Email signup no longer a part of redux.
 * Please see <EmailSignup /> for context API.
 */

export const submitEmail = async({
  email,
  interests,
}) => {
  try {
    await checkEmailValidity()
    const resp = await addToEmailList(email, interests)
    processSuccess()
    return resp
  } catch (error) {
    checkResponseForConflict(error)
    processError(error)
  }

  return {
    $checkEmailValidity: checkEmailValidity,
    $checkResponseForConflict: checkResponseForConflict,
    $processError: processError,
    $processSuccess: processSuccess,
  }

  async function checkEmailValidity() {
    const emailValidator = await utilizeEmailValidation()
    if (!emailValidator.isValid(email)) {
      const e = new Error(e)
      e.message = `Failed client validation`
      e.status = 422
      throw e
    }
  }

  function checkResponseForConflict(error) {
    if (error.status === 422) {
      throw new Error(`errorFormat`)
    }

    if (error.status === 409) {
      throw new Error(`emailExists`)
    }
  }

  function processError(error) {
    reportError(new ErrorAdditional({
      severity: 1,
      title: `Mailchimp error`,
      message: error?.message,
      originalError: error,
      tags: {
        [SENTRY_TAGS.SOURCE_FILE]: `src/state/actions/emailSignup.js`,
      },
    }))

    throw new Error(`errorGeneric`)
  }

  function processSuccess() {
    updateGoogleAnalytics(`emailSignup`)
    storage.set(emailStorageValue, true)
    heapAnalytics.setIdentity({ email })
  }
}
