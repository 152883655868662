import React from 'react'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { updateGoogleAnalytics } from 'services/analytics'
import { setFocussedNavItem } from 'state/actions/site'
import { LinkText } from 'components/LinkText'

import styles from './NavItem.css'

export const NavItem = ({
  activeState,
  children,
  hoverState,
  index,
  itemHeight,
  itemPadding,
  itemTextAlign,
  navMenuIndex,
  parentMenuIndexes = [],
  sublabelsPresent,
  type,
  value,
}) => {
  const { pathname } = useLocation()
  const hoverRef = React.useRef()
  const accessibilityMode = useSelector(state => state.site.accessibilityMode)
  const focussedNavItem = useSelector(state => state.site.focussedNavItem)
  const dispatch = useDispatch()
  const id = getId()
  const focussedId = focussedSection()

  return (
    <LinkText
      active={value.path?.split(`?`)[0] === pathname}
      activeState={activeState}
      className={classNames(styles.navItem, {
        [styles.focus]: accessibilityMode && id === focussedId,
        [styles.sublabelsPresent]: sublabelsPresent,
        [value.className]: Boolean(value.className),
      })}
      hover={id === focussedId}
      hoverState={hoverState}
      id={`navItem_${id}`}
      onClick={(e) => {
        e.stopPropagation()
        updateGoogleAnalytics(`clickCategoryNavLink`, { url: value.path })
        dispatch(setFocussedNavItem())
      }}
      onMouseEnter={() => {
        hoverRef.current = setTimeout(() => {
          dispatch(setFocussedNavItem(id))
        }, focussedNavItem ? 0 : 250)
      }}
      onMouseLeave={() => {
        clearTimeout(hoverRef.current)
      }}
      rel={value.target === `_blank` ? `noreferrer` : undefined}
      role="menuitem"
      style={{
        color: value.color,
        height: itemHeight,
        padding: itemPadding,
      }}
      tabIndex={-1}
      target={value.target}
      textAlign={itemTextAlign}
      to={value.path}
      type={type}
    >
      {children}
    </LinkText>
  )

  function getId() {
    if (parentMenuIndexes.length) {
      const parentIndexes = parentMenuIndexes.join(`_`)
      return `${parentIndexes}_${index}`
    }

    return `${index}`
  }

  function focussedSection() {
    if (focussedNavItem) {
      return focussedNavItem.split(`_`).slice(0, navMenuIndex + 1).join(`_`)
    }
  }
}
