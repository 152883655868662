import { getConfigOptions } from 'global-content/config'
import { useLocation } from 'react-router'
import { get } from 'lodash'

export function useRouterRedirectList() {
  const { pathname } = useLocation()
  return get(
    getConfigOptions(`redirects`),
    pathname,
    null
  )
}
