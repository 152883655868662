import React from "react"
import { webLabels } from "./fixtures"
import { useDate } from "hooks/useDate"
import { I18n } from 'utils/i18n'

export const BadgePreorder = (props) => {
  const { shippingDateRange } = props
  const formatDateFunction = useDate({
    month: `short`,
    day: `numeric`,
  })

  if (Boolean(shippingDateRange) === false) {
    return null
  }

  const {
    minRange,
  } = shippingDateRange

  const textOutput = `${I18n.t(webLabels.preorderShipsAfterMessage)} ${formatDateFunction(minRange * 1000)}`
  return (
    <div>
      {textOutput}
    </div>
  )
}
