import React from 'react'
import { Video } from 'components/Video'

export const VideoEmbed = ({
  data,
  'data-testid': testId,
}) => {

  const {
    config = {},
    positioning = {},
  } = data

  const {
    src,
    play,
    metadata,
    poster,
    hasAudio,
    hasControls,
  } = config

  const {
    fullWidth,
    width,
    height,
  } = positioning

  return (
    <Video
      data-testid={testId}
      fullWidth={fullWidth}
      hasAudio={hasAudio}
      hasControls={hasControls}
      height={height}
      metadata={metadata}
      muted
      play={play}
      poster={poster}
      src={src}
      width={width}
    />
  )
}
