import { getLocale } from 'utils'

export const getSelectedShippingAddress = (state) => {
  const locale = state.language.locale || getLocale(state.language.active)
  const localeFilter = [`en-JP`, `ja-JP`]
  const address = state.addresses.selectedShippingAddress
  if (localeFilter.includes(locale) && locale !== address?.locale) {
    return undefined
  }
  return address
}
