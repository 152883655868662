"use strict";
exports.__esModule = true;
exports.someContains = exports.getFromFirst = void 0;
var lodash_1 = require("lodash");
function getFromFirst(xs, path) {
    return (0, lodash_1.get)((0, lodash_1.first)(xs), path);
}
exports.getFromFirst = getFromFirst;
function someContains(xs, path, expectedValue) {
    return expectedValue
        ? (0, lodash_1.some)(xs, function (item) { return (0, lodash_1.get)(item, path) === expectedValue; })
        : false;
}
exports.someContains = someContains;
