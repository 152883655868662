import uniq from 'lodash/uniq'

import {
  ADMIN_MODE,
  APP_IS_READY,
  AJV_AVAILABLE,
  CAPTCHA_AVAILABLE,
  CHANGE_LANGUAGE,
  DETECTED_COUNTRY,
  EMAIL_VALIDATION_AVAILABLE,
  FORM_VALIDATION_AVAILABLE,
  LOADED_DEPENDENCIES,
  PHONE_LIBRARY_AVAILABLE,
  PRETOGGLE_LANGUAGE,
  SEEN_CAPTCHA,
  SET_ACTIVE_TOOLTIP,
  SET_CAPTCHA_TOKEN_V3,
  SET_FOCUSSED_NAV_ITEM,
  SHOW_V2_CAPTCHA,
  TERRITORIES_AVAILABLE,
  USED_TAB,
  USER_AUTH_AVAILABLE,
} from 'state/actions'

const initialState = {
  accessibilityMode: false,
  activeTooltip: ``,
  adminMode: false,
  ajvAvailable: false,
  appReady: false,
  captchaAvailable: false,
  captchaV2Id: undefined,
  captchaV3Token: {},
  detectedCountry: {
    isLoading: false,
    isError: false,
    countryCode: ``,
  },
  emailValidationAvailable: false,
  focussedNavItem: undefined,
  focussedNavItems: [],
  focussedNavMenu: undefined,
  formValidationAvailable: false,
  languagesViewed: [], // to check if any more content needs to be loaded
  phoneLibraryAvailable: false,
  preToggleLanguage: `en`,
  seenCaptcha: false,
  territoriesAvailable: false,
  userAuthAvailable: false,
}

const site = (state = initialState, action) => {
  switch (action.type) {
  case ADMIN_MODE:
    return {
      ...state,
      adminMode: true,
    }
  case APP_IS_READY:
    return {
      ...state,
      appReady: true,
    }
  case SET_ACTIVE_TOOLTIP:
    return {
      ...state,
      activeTooltip: action.payload,
    }
  case LOADED_DEPENDENCIES:
    return {
      ...state,
      languagesViewed: [action.payload],
    }
  case CHANGE_LANGUAGE:
    return {
      ...state,
      languagesViewed: uniq([...state.languagesViewed, action.payload]),
    }
  case SET_FOCUSSED_NAV_ITEM: {
    const focussedNavItem = action.payload

    //  Use to debug megamenu

    // if (!focussedNavItem) {
    //   return state
    // }

    return {
      ...state,
      focussedNavItems: setFocussedNavItems(focussedNavItem, state.focussedNavItems),
      focussedNavItem,
      focussedNavMenu: setFocussedNavMenu(focussedNavItem),
    }
  }
  case PRETOGGLE_LANGUAGE:
    return {
      ...state,
      preToggleLanguage: action.payload,
    }

  case USED_TAB:
    return {
      ...state,
      accessibilityMode: true,
    }
  case EMAIL_VALIDATION_AVAILABLE:
    return {
      ...state,
      emailValidationAvailable: true,
    }
  case AJV_AVAILABLE:
    return {
      ...state,
      ajvAvailable: true,
    }
  case PHONE_LIBRARY_AVAILABLE:
    return {
      ...state,
      phoneLibraryAvailable: true,
    }
  case TERRITORIES_AVAILABLE:
    return {
      ...state,
      territoriesAvailable: true,
    }
  case USER_AUTH_AVAILABLE:
    return {
      ...state,
      userAuthAvailable: true,
    }
  case FORM_VALIDATION_AVAILABLE:
    return {
      ...state,
      formValidationAvailable: true,
    }
  case SEEN_CAPTCHA:
    return {
      ...state,
      seenCaptcha: true,
    }
  case CAPTCHA_AVAILABLE:
    return {
      ...state,
      captchaAvailable: true,
    }
  case SHOW_V2_CAPTCHA:
    return {
      ...state,
      captchaV2Id: action.payload,
    }
  case SET_CAPTCHA_TOKEN_V3:
    return {
      ...state,
      captchaV3Token: action.payload,
    }
  case `${DETECTED_COUNTRY}_FULFILLED`:
    return {
      ...state,
      detectedCountry: {
        ...action.payload,
        isLoading: false,
        isError: false,
      },
    }
  case `${DETECTED_COUNTRY}_PENDING`:
    return {
      ...state,
      detectedCountry: {
        countryCode: ``,
        isLoading: true,
        isError: false,
      },
    }
  case `${DETECTED_COUNTRY}_REJECTED`:
    return {
      ...state,
      detectedCountry: {
        countryCode: ``,
        isLoading: false,
        isError: true,
      },
    }
  default:
    return state
  }
}

// eslint-disable-next-line no-unused-vars
function setFocussedNavItems(id, current) {
  if (!id) {
    // return current // -- useful for debugging
    return []
  }

  return id.split(`_`).map(num => parseInt(num))
}

function setFocussedNavMenu(focussedNavItem) {
  if (focussedNavItem) {
    const levels = focussedNavItem.split(`_`)
    return levels.slice(0, levels.length - 1).join(`_`).replace(/_/g, ``).length
  }
}

export default site
