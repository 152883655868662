import {
  ACCOUNT_SIGN_OUT,
  ADD_ADDRESS,
  DELETE_ADDRESS,
  LIST_ALL_ADDRESSES,
  LIST_BILLING_ADDRESSES,
  LIST_SHIPPING_ADDRESSES,
  MATCH_BILLING_AND_SHIPPING_ADDRESS,
  REMOVE_ADDRESS_ERROR,
  UPDATE_ADDRESS,
  UPDATE_ENCOURAGED_FIELDS_ERRORS,
  UPDATE_ENCOURAGED_FIELDS_VALUES,
  UPDATE_SAVE_SHIPPING_ADDRESS,
  UPDATE_SELECTED_BILLING_ADDRESS,
  UPDATE_SELECTED_SHIPPING_ADDRESS,
  UPDATE_SELECTED_SHIPPING_VALIDATION,
} from 'state/actions'

import {
  ADDRESS_SOURCE_MATCH_SHIPPING,
  ADDRESS_SOURCE_FORM_KEY,
  STATUSCODE_ADDRESS_UNABLE_TO_DELETE,
  STATUSCODE_ADDRESS_UNABLE_TO_LIST,
  STATUSCODE_ADDRESS_UNAUTHORIZED,
  STATUSCODE_ADDRESS_UNABLE_TO_UPDATE,
  STATUSCODE_ADDRESS_UNABLE_TO_ADD,
} from 'utils/constants'

const ADDRESSBOOK_ADDRESS_LIMIT = 20

const initialState = {
  addressActionError: undefined,
  addressActionPending: false,
  allAddresses: undefined,
  billingAddresses: undefined,
  billingMatchShipping: true,
  canAddNewAddress: true,
  encouragedFieldsErrors: {},
  encouragedFieldsValues: {},
  hasTriedValidation: false,
  saveShippingAddress: true,
  selectedBillingAddress: undefined,
  selectedShippingAddress: undefined,
  isShippingAddressValid: false,
  shippingAddresses: undefined,
  addressSource: {
    shipping: undefined,
    billing: undefined,
  },
}

const addresses = (state = initialState, action) => {
  switch (action.type) {
  case `${ADD_ADDRESS}_PENDING`:
  case `${UPDATE_ADDRESS}_PENDING`:
  case `${LIST_ALL_ADDRESSES}_PENDING`:
  case `${DELETE_ADDRESS}_PENDING`:
    return {
      ...state,
      addressActionError: undefined,
      addressActionPending: true,
    }

  case `${DELETE_ADDRESS}_FULFILLED`:
  case `${UPDATE_ADDRESS}_FULFILLED`:
  case `${ADD_ADDRESS}_FULFILLED`:
    return {
      ...state,
      addressActionPending: false,
    }

  case `${DELETE_ADDRESS}_REJECTED`:
    return {
      ...state,
      addressActionError: STATUSCODE_ADDRESS_UNABLE_TO_DELETE,
      addressActionPending: false,
    }
  case `${UPDATE_ADDRESS}_REJECTED`:
    return {
      ...state,
      addressActionError: STATUSCODE_ADDRESS_UNABLE_TO_UPDATE,
      addressActionPending: false,
    }
  case `${ADD_ADDRESS}_REJECTED`:
    return {
      ...state,
      addressActionError: STATUSCODE_ADDRESS_UNABLE_TO_ADD,
      addressActionPending: false,
    }
  case `${LIST_ALL_ADDRESSES}_REJECTED`:
    return {
      ...state,
      addressActionError: action.payload.status === 401 ? STATUSCODE_ADDRESS_UNAUTHORIZED : STATUSCODE_ADDRESS_UNABLE_TO_LIST,
      addressActionPending: false,
    }
  case `${LIST_ALL_ADDRESSES}_FULFILLED`:
    return {
      ...state,
      addressActionPending: false,
      allAddresses: action.payload,
      canAddNewAddress: action.payload.length < ADDRESSBOOK_ADDRESS_LIMIT,
    }
  case LIST_SHIPPING_ADDRESSES:
    return {
      ...state,
      shippingAddresses: action.payload,
    }
  case LIST_BILLING_ADDRESSES:
    return {
      ...state,
      billingAddresses: action.payload,
    }
  case REMOVE_ADDRESS_ERROR:
    return {
      ...state,
      addressActionError: undefined,
    }
  case UPDATE_ENCOURAGED_FIELDS_ERRORS:
    return {
      ...state,
      encouragedFieldsErrors: action.payload,
    }
  case UPDATE_ENCOURAGED_FIELDS_VALUES:
    return {
      ...state,
      encouragedFieldsValues: {
        ...state.encouragedFieldsValues,
        ...action.payload,
      },
    }
  case UPDATE_SELECTED_BILLING_ADDRESS:
    return {
      ...state,
      billingMatchShipping: action.payload.address ? false : state.billingMatchShipping,
      selectedBillingAddress: action.payload.address,
      addressSource: {
        ...state.addressSource,
        billing: action.payload.source,
      },
    }
  case UPDATE_SELECTED_SHIPPING_ADDRESS:
    return {
      ...state,
      selectedShippingAddress: action.payload.address,
      addressSource: {
        ...state.addressSource,
        shipping: action.payload.source,
      },
    }
  case UPDATE_SAVE_SHIPPING_ADDRESS:
    return {
      ...state,
      saveShippingAddress: action.payload,
    }
  case ACCOUNT_SIGN_OUT:
    return {
      ...initialState,
    }
  case MATCH_BILLING_AND_SHIPPING_ADDRESS:
    return {
      ...state,
      billingMatchShipping: action.payload,
      addressSource: {
        ...state.addressSource,
        billing: action.payload ? ADDRESS_SOURCE_MATCH_SHIPPING : ADDRESS_SOURCE_FORM_KEY,
      },
    }
  case UPDATE_SELECTED_SHIPPING_VALIDATION:
    return {
      ...state,
      hasTriedValidation: true,
      isShippingAddressValid: action.payload,
    }
  default:
    return state
  }
}

export default addresses
