import { I18n } from 'utils/i18n'
import { normalPromotion } from './normal-promotion'
import { tierPromotion } from './tier-promotion'

const DEFAULT_SEPARATOR = ` | `

export function promoInterpolation(campaign, language) {
  const {
    data = {},
    items,
    promotions,
  } = campaign
  const {
    promotionFormatting,
    promotionLocations = [],
  } = data

  if (!promotions.length) {
    return campaign
  }

  const formats = createPromotionFormats(promotions[0], promotionFormatting, language)

  return {
    ...campaign,
    items,
    promotionFormats: formats.promotionFormats,
    locations: promotionLocations.map(location => formatLocation(location, formats, language)),
  }
}

export function createPromotionFormats(promotion, promotionFormatting, language) {
  const content = getContent(promotion, promotionFormatting, language)
  const sanitized = content.map(p => p.content)

  let promotionFormats = {
    PROMOTION: sanitized.join(DEFAULT_SEPARATOR),
  }

  if (promotionFormatting?.tierSeparator) {
    Object.entries(promotionFormatting.tierSeparator).forEach(([key, value]) => {
      promotionFormats[key] = sanitized.join(value)
    })
  }

  return {
    promotionFormats,
    content,
  }
}

function getContent(promotion, promotionFormatting, language) {
  const {
    conditions,
    discount,
    messageId,
    tierDiscount,
  } = promotion

  if (tierDiscount) {
    return tierPromotion({
      language,
      messageId,
      promotionFormatting,
      tierDiscount,
    })
  }

  if (discount) {
    return normalPromotion({
      conditions,
      discount,
      language,
      messageId,
      promotionFormatting,
    })
  }
}

export function formatLocation(location, formats, language) {
  const {
    content,
    promotionFormats,
  } = formats
  const html = location.content.html
  const regEx = /\[\[(.+?)\]\]/

  return {
    ...location,
    content: { // we're assuming all promotion locations use this shape: location.content.html
      ...location.content,
      html: getHtml(),
    },
  }

  // this is to support custom web labels with interpolation at the content level
  function getHtml() {
    if (!html.startsWith(`[[`)) {
      const tierSeparator = location.config.tierSeparator || DEFAULT_SEPARATOR

      return content.map(({
        discount,
        min,
      }) => {
        return (
          I18n.t(html, {
            language,
            replace: {
              discount,
              min,
            },
          })
        )
      }).join(tierSeparator)
    }

    return html.replace(regEx, (match, p1) => promotionFormats[p1])
  }
}
