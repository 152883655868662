import React from 'react'

import { I18n } from 'utils/i18n'
import { Factory } from 'components/Factory'
import { Heading } from 'components/Heading'
import { Svg } from 'components/Svg'

import styles from './AccordionExpansionPanel.css'

export const AccordionExpansionPanel = ({
  expanded,
  icon,
  item,
  label,
  onClick,
  styling = {},
}) => {
  const {
    border,
    borderTop,
    borderBottom,
    fontSize = `xs`,
  } = styling
  const heading = I18n.t(label)

  return (
    <div
      style={{
        border,
        borderTop,
        borderBottom,
      }}
    >
      <button
        aria-label={I18n.t(heading)}
        className={styles.button}
        name={I18n.t(heading)}
        onClick={onClick}
      >
        {icon &&
          <span className={styles.iconWrapper}>
            <Svg
              icon={icon}
              size="3.5rem"
            />
          </span>
        }
        <Heading
          content={{
            html: heading,
          }}
          styling={{
            fontFamily: `primary`,
            fontSize,
            universalColor: true,
          }}
        />
        <span className={styles.angleWrapper}>
          <Svg icon={expanded ? `angle_up` : `angle_down`} />
        </span>
      </button>
      {expanded &&
        <div className={styles.expandableArea}>
          <Factory
            items={[item]}
          />
        </div>
      }
    </div>
  )
}
