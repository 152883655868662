export const webLabels = {
  basket: `WEB.CHECKOUT.STEP.CART`,
  shipping: `WEB.CHECKOUT.STEP.SHIPPING`,
  payment: `WEB.CHECKOUT.STEP.PAYMENT`,
  review: `WEB.CHECKOUT.STEP.REVIEW`,
  accountCreationTitle: `WEB.ACCOUNT.NEW-ACCOUNT.TITLE`,
  accountVerifyTitle: `WEB.ACCOUNTS.VERIFY-ACCOUNT.TITLE`,
  accountForgottenPasswordTitle: `WEB.ACCOUNTS.RESET-PASSWORD.TITLE`,
  accountNewPasswordTitle: `WEB.ACCOUNTS.NEW-PASSWORD.TITLE`,
  accountMyAccountTitle: `WEB.ACCOUNTS.MY-ACCOUNT.TITLE`,
  accountAddressesTitle: `WEB.ACCOUNTS.ADDRESSES.TITLE`,
  accountPaymentMethodsTitle: `WEB.ACCOUNTS.PAYMENT-METHODS.TITLE`,
  signInTitle: `WEB.ACCOUNTS.SIGN-IN`,
  searchTitle: `WEB.SEARCH.RESULTS.TITLE`,
  notFoundTitle: `WEB.NOTFOUND.TITLE`,
  brandTitle: `WEB.LISTING.FILTER.BRAND.TITLE`,
  favoritesTitle: `WEB.FAVORITES.TITLE`,
  trackingTitle: `WEB.TRACKING.TITLE`,
  orderHistoryTitle: `WEB.ORDER-HISTORY.TITLE`,
  orderDetailsTitle: `WEB.ORDER-DETAILS.TITLE`,
  orderStatusTitle: `WEB.ORDER-STATUS.TITLE`,
  loading: `WEB.LOADING`,
}
