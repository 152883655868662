export const webLabels = {
  PROMO: `PROMO.*`,
  ITEM_COUNT: `WEB.PROMO.TIER.ITEM`,
  BASKET_TOTAL: `WEB.PROMO.TIER.BASKET`,
  EMPTY: `LOKA.KEY.EMPTY.MESSAGE.PROMO`,
  ITEM: `WEB.PROMO.NORMAL.ITEM`,
  BASKET: `WEB.PROMO.NORMAL.BASKET`,
  LAST_ITEM: `WEB.PROMO.NORMAL.LASTITEM`,
  SHIPPING: {
    EXPRESS: `WEB.PROMO.NORMAL.SHIPPING.EXPRESS`,
    STANDARD: `WEB.PROMO.NORMAL.SHIPPING.STANDARD`,
  },
  CONDITIONS: {
    BASKET: {
      BASKET_MIN: `WEB.PROMO.NORMAL.BASKET.CONDITIONS`,
    },
    ITEM: {
      BASKET_MIN: `WEB.PROMO.NORMAL.ITEM.CONDITIONS.BASKET.MIN`,
      ITEM_MIN: `WEB.PROMO.NORMAL.ITEM.CONDITIONS.ITEM.MIN`,
      ITEM_EQ: `WEB.PROMO.NORMAL.ITEM.CONDITIONS.ITEM.EQ`,
    },
  },
  FREE: {
    BASKET: `WEB.PROMO.NORMAL.BASKET.FREE`,
    ITEM: `WEB.PROMO.NORMAL.ITEM.FREE`,
    LAST_ITEM: `WEB.PROMO.NORMAL.LASTITEM.FREE`,
    SHIPPING: {
      EXPRESS: `WEB.PROMO.NORMAL.SHIPPING.EXPRESS.FREE`,
      STANDARD: `WEB.PROMO.NORMAL.SHIPPING.STANDARD.FREE`,
    },
  },
  PERCENT_OFF: `WEB.PROMO.DISCOUNT.PERCENT.OFF`,
  VALUE_OFF: `WEB.PROMO.DISCOUNT.VALUE.OFF`,
  VALUE_OVERRIDE: `WEB.PROMO.DISCOUNT.VALUE.OVERRIDE`,
  VALUE_OVERRIDE_FREE: `WEB.PROMO.DISCOUNT.VALUE.OVERRIDE.FREE`,
}
