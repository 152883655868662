import React from 'react'
import classNames from 'classnames'

import { LinkText } from 'components/LinkText'

import styles from './ProductTraySupportLink.css'

export const ProductTraySupportLink = ({
  href,
  position,
  text,
  type,
}) => (
  <div
    className={classNames(styles.supportLink, {
      [styles.left]: position === `left`,
      [styles.right]: position === `right`,
    })}
    data-testid="merch-component-support-link"
  >
    <LinkText
      to={href}
      type={type || `textLink`}
    >
      {text}
    </LinkText>
  </div>
)
