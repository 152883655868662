"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.CountrySelectButton = void 0;
var react_1 = __importStar(require("react"));
var CountrySelect_1 = require("../features/CountrySelect");
var ImageButton_1 = require("../components/ImageButton");
var CountrySelectButton = function () {
    var _a = (0, react_1.useContext)(CountrySelect_1.CountrySelectContext), defaultCountry = _a.defaultCountry, openModal = _a.openModal;
    if (!defaultCountry) {
        return null;
    }
    return (react_1["default"].createElement(ImageButton_1.ImageButton, { className: "CountrySelectButtonHeader", imgAlt: defaultCountry.label, imgSrc: defaultCountry.image, label: defaultCountry.label, onClick: openModal, prefix: "WEB.LABELS.COUNTRY" }));
};
exports.CountrySelectButton = CountrySelectButton;
