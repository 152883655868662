import queryString from 'query-string'
const QUERY_PARAM_HIDE_COOKIES = `hide_cookies`

export function getQueryParamHideCookiesOverride() {
  const queryStringValues = queryString.parse(window.location.search)
  let shouldHideCookies = false
  if (queryStringValues && Object.keys(queryStringValues).includes(QUERY_PARAM_HIDE_COOKIES)) {
    shouldHideCookies = Boolean(queryStringValues[QUERY_PARAM_HIDE_COOKIES])
  }
  return shouldHideCookies
}
