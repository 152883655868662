import React, { useContext } from 'react'
import { Link as InflexibleReactRouterLink } from 'react-router-dom'
import { ModernFactoryComponent } from '../ModernFactoryComponent'
import { NavigationContext } from './Navigation'
import { isTouchDevice } from '../utilities/isTouchDevice'

export const NavigationBarLink = ModernFactoryComponent(({
  children,
  href,
  data: { navigationIndex } = {},
  ...props
}, ref) => {
  delete props.Tag
  const {
    currentNav,
    setCurrentNav,
    clearCurrentNav,
  } = useContext(NavigationContext)

  const isSelected = currentNav === navigationIndex

  return (
    <InflexibleReactRouterLink
      {...props}
      aria-expanded={isSelected}
      onClick={e => {
        if (isTouchDevice() && !isSelected) {
          e.preventDefault()
          setCurrentNav(navigationIndex)
        } else {
          clearCurrentNav()
        }
      }}
      onMouseEnter={() => {
        if (!isTouchDevice()) {
          setCurrentNav(navigationIndex)
        }
      }}
      ref={ref}
      to={href}
    >
      {children}
    </InflexibleReactRouterLink>
  )
})
