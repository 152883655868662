import { GET_CAMPAIGNS } from 'state/actions'

const initialState = {
  active: {},
  stateKey: 0,
}

const campaigns = (state = initialState, action) => {
  switch (action.type) {
  case GET_CAMPAIGNS:
    return {
      ...state,
      stateKey: action.payload.campaigns.reduce((acc, curr) => acc + curr.id, 0),
      active: {
        ...state.active,
        [action.payload.language]: action.payload.campaigns,
      },
    }
  default:
    return state
  }
}

export default campaigns
