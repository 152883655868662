import React from 'react'

import { augmentation } from 'utils/augmentation'
import { Responsive } from 'components/Responsive'

export const MediaQuery = React.forwardRef(({
  augmentItems,
  children: Children,
  id,
  items,
  tag,
}, ref) => {
  const devices = items.map(item => item.data.media)

  return (
    items.map((item, i) => {
      const {
        media,
        ...rest
      } = item.data

      return (
        <Responsive
          deviceRange={[devices[i], devices[i + 1]]}
          key={media || i}
        >
          <Children
            {...rest}
            id={id}
            items={augmentation(item.items, augmentItems)}
            ref={ref}
            tag={tag}
          />
        </Responsive>
      )
    })
  )
})
