import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { loadFullFilterSet } from 'state/actions/listing'
import { loadBrandIndex } from 'state/actions/brands'
import { algoliaFiltersMap } from 'services/algolia'
import { useGlobalContent } from 'hooks/useGlobalContent'
import { getNavCategories } from 'utils/getNavCategories'

export function useFilterSets(getBrands) {
  const dispatch = useDispatch()
  const { l1 } = useParams()
  const navTree = useGlobalContent(`navTree`)

  const activeLanguage = useSelector(state => state.language.active)
  const brandIndexes = useSelector(state => state.brands.brandIndexes)
  const allFilters = useSelector(state => state.listing.allFilters)
  const selectedSort = useSelector(state => state.listing.selectedSort)
  const index = l1 || `all`

  React.useEffect(() => {
    getFullFilterSet()
  }, [l1])

  return hasLoadedFilterSets()

  async function getFullFilterSet() {
    if (!allFilters[activeLanguage]) {
      await dispatch(loadFullFilterSet(activeLanguage))
    }

    getBrands && getBrandIndex()
  }

  function getBrandIndex() {
    const filterOptions = {
      attributesToReceive: [`objectId`],
      facets: algoliaFiltersMap.get().brand.nameInAlgolia,
      hitsPerPage: 1,
    }

    if (l1) {
      const l1Categories = getNavCategories(navTree.l1[l1]) || []

      filterOptions.filters = {
        categories: {
          includes: l1Categories,
        },
      }
    }

    if (!brandIndexes[index]) {
      dispatch(loadBrandIndex(
        window.$content.algolia[selectedSort],
        filterOptions,
        index,
        activeLanguage
      ))
    }
  }

  function hasLoadedFilterSets() {
    if (getBrands && !brandIndexes[index]) {
      return false
    }

    if (!allFilters[activeLanguage]) {
      return false
    }

    return true
  }
}
