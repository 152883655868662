import React from 'react'

import { PageTemplate } from 'components/PageTemplate'
import { PageBody } from 'components/PageBody'
import { Factory } from 'components/Factory'

export const Faceted = () => {
  return (
    <PageTemplate template="plp">
      {FacetedComponent}
    </PageTemplate>
  )
}

const FacetedComponent = ({
  items,
  ...props
}) => {
  return (
    <PageBody {...props}>
      <Factory items={items} />
    </PageBody>
  )
}
