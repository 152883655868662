import React from 'react'

import styles from './AspectRatio.css'

export const AspectRatio = ({
  children,
  objectFit = `cover`,
  metadata,
}) => {
  const {
    height,
    width,
  } = legacyRatioCheck(metadata)
  let style = {
    '--objectFit': objectFit,
  }

  if (!width || !height) {
    return children || null
  }

  if (width && height) {
    // The aspect ratio is used to create placeholder DOM whitespace for the image
    // to load into, preventing layout shifts when it eventually loads and paints.
    // The space is created using padding-bottom: var(--aspect-ratio) in the css file.
    style[`--aspect-ratio`] = `calc((${height} / ${width}) * 100%)`
  }

  return (
    <div
      className={styles.container}
      style={style}
    >
      {children}
    </div>
  )
}

function legacyRatioCheck(metadata) {
  if (!metadata) {
    return {}
  }

  if (typeof metadata === `string`) {
    const legacyMap = {
      landscapeShort: {
        height: 34.667,
        width: 100,
      },
      landscape: {
        height: 55.22,
        width: 100,
      },
      landscapeTall: {
        height: 66.667,
        width: 100,
      },
      portraitShort: {
        height: 5,
        width: 4,
      },
      portrait: {
        height: 3,
        width: 2,
      },
      portraitTall: {
        height: 158.83,
        width: 100,
      },
      portraitCustom: {
        height: 112,
        width: 100,
      },
      portrait4x3: {
        height: 4,
        width: 3,
      },
      square: {
        height: 1,
        width: 1,
      },
    }

    return legacyMap[metadata] || {}
  }

  return metadata
}
