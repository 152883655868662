import React from 'react'

import { useGlobalContent } from 'hooks/useGlobalContent'
import { useItemToRender } from 'hooks/useItemToRender'
import { MediaQuery } from 'components/MediaQuery'
import { Factory } from 'components/Factory'
import { Layout } from 'components/Layout'
import { PopoverCountrySelect } from '../../../design-system/templates/PopoverCountrySelect'

import styles from './Header.css'

export const Header = React.forwardRef((props, ref) => {
  const header = useGlobalContent(`header`)
  const headerToRender = useItemToRender(header.items)

  if (headerToRender) {
    return (
      <MediaQuery
        items={headerToRender.items}
        ref={ref}
      >
        {HeaderComponent}
      </MediaQuery>
    )
  }

  return null
})

const HeaderComponent = ({
  items,
  ...props
}) => {
  return (
    <Layout
      {...props}
      className={styles.container}
      wrap
    >
      <Factory items={items} />
      <PopoverCountrySelect />
    </Layout>
  )
}
