import { useSelector } from 'react-redux'

import { getMetaOptions } from 'global-content/config'

export const useDate = (options) => {
  const activeLanguage = useSelector(state => state.language.active)
  // fallback to "en" to format dates if locacle is using webLabels
  const languageToUse = activeLanguage === `webLabels` ? `en` : activeLanguage
  const locale = `${languageToUse}-${getMetaOptions(`country.code`)}`

  return function(timestamp) {
    return Intl.DateTimeFormat(locale, options).format(timestamp)
  }
}
