import { get, isString, replace } from 'lodash'

export function sanitize(str) {
  return removeExtraSpaces(
    removeDangerousCharacters(
      getContentFromHtmlString(str)
    )
  )
}

export function getContentFromHtmlString(html) {
  return get(
    isString(html)
      ? new DOMParser().parseFromString(html, `text/html`)?.body
      : {},
    `textContent`,
    ``
  )
}

export function removeDangerousCharacters(str) {
  return replace(
    str,
    /!([\p{General_Category=Letter}]+)|[$%*]/gu,
    ` `
  )
}

export function removeExtraSpaces(str) {
  return replace(str, /\s+/g, ` `)
}

export default sanitize
