export const SENTRY_PAGEVIEW_INITIAL = `pageview.initial`
export const SENTRY_PAGEVIEW_NEXT = `pageview.next`
export const SENTRY_PAGEVIEW_ERROR = `pageview.error`

export function reportBreadcrumb(options) {
  const {
    message,
    category = null,
  } = options

  window.Sentry?.addBreadcrumb({
    category,
    message,
  })
}
