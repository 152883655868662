import React, { useContext } from 'react'
import { ModernFactoryComponent } from '../ModernFactoryComponent'
import { NavigationContext } from "./Navigation"

export const NavigationDropdown = ModernFactoryComponent(({
  children,
  Tag,
  data: {
    enableMouseLeave,
  },
  ...props
}, ref) => {
  const {
    clearCurrentNav,
  } = useContext(NavigationContext)
  return (
    <Tag
      {...props}
      onMouseLeave={() => {
        if (enableMouseLeave === `true`) {
          clearCurrentNav()
        }
      }}
      ref={ref}
    >
      {children}
    </Tag>
  )
})
