import React from 'react'
import { batch, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import store from 'state/reducers'
import { changeLanguage } from 'state/actions/language'
import { routeChange, updateNavigation } from 'state/actions/navigation'
import { updateGoogleAnalytics } from 'services/analytics'

import { getLanguage } from 'utils'
import { updateHrefLangTags } from 'utils/hreflangTags'
import { refreshLocationBV } from 'utils/refreshLocationBazaarVoice'

let loadedOnce = false
let previousPathname

// for tests only
export function reset() {
  loadedOnce = false
  previousPathname = undefined
}

export const useRouteChange = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (!loadedOnce) {
      loadedOnce = true

      updateLocation({
        ...location,
        action: `INITIAL`,
      })
    }
  }, [])

  function updateLocation({
    action,
    force,
    pathname,
    scrollToY,
    search,
  }) {
    const language = getLanguage()
    const { active: activeLanguage } = store.getState().language
    const languageChanged = language !== activeLanguage
    updateHrefLangTags()

    if (window.BV) {
      refreshLocationBV(language, pathname, search)
    }

    if (window.BV) {
      refreshLocationBV(language, pathname, search)
    }

    batch(() => {
      // only do a route change action when the pathname changes
      if (previousPathname !== pathname || force) {
        previousPathname = pathname

        dispatch(routeChange({
          language,
          pathname,
          search,
        }))

        if (!languageChanged && typeof scrollToY === `number`) {
          window.scrollTo(0, scrollToY)
        }
      }

      dispatch(updateNavigation({
        language,
        pathname,
        search,
      }))

      if (languageChanged) {
        dispatch(changeLanguage(getLanguage()))
      }
    })

    // don't fire event when action === REPLACE
    if ([`POP`, `PUSH`, `INITIAL`].includes(action)) {
      if (languageChanged) {
        // log switch language event?
      } else {
        updateGoogleAnalytics(`pageview`)
      }
    }
  }

  return updateLocation
}
