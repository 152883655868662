import React from 'react'
import classNames from 'classnames'

import { I18n } from 'utils/i18n'
import { Svg } from 'components/Svg'
import { AVAILABILITY_BACKORDER, AVAILABILITY_PREORDER } from 'utils/constants'
import { useDate } from "hooks/useDate"

import { availabilityMessagingType, webLabels } from './fixtures'
import styles from './AvailabilityMessaging.css'

export const AvailabilityMessaging = ({
  availability,
  type = availabilityMessagingType.pdp,
  shipDateEnd,
  shipDateStart,
}) => {
  const formatDateFunction = useDate({
    month: `long`,
    day: `numeric`,
    year: `numeric`,
  })

  function isNumber(value) {
    return !isNaN(value)
  }

  function getPreOrderMessage() {
    if (isNumber(shipDateStart) && isNumber(shipDateEnd) && shipDateStart !== shipDateEnd) {
      // Show shipping range if startDate and endDate are different
      const startStr = formatDateFunction(shipDateStart * 1000)
      const endStr = formatDateFunction(shipDateEnd * 1000)
      return `${startStr} - ${endStr}`
    } else if (isNumber(shipDateStart)) {
      return formatDateFunction(shipDateStart * 1000)
    } else {
      return I18n.t(webLabels.preorderNoDate)
    }
  }

  if ([AVAILABILITY_BACKORDER, AVAILABILITY_PREORDER].includes(availability)) {
    return (
      <div
        className={classNames(styles.container, {
          [styles.basket]: type === availabilityMessagingType.basket,
          [styles.existingOrder]: type === availabilityMessagingType.existingOrder,
          [styles.preorder]: availability === AVAILABILITY_PREORDER,
          [styles.backorder]: availability === AVAILABILITY_BACKORDER,
        })}
      >
        {type === availabilityMessagingType.pdp &&
          <div className={styles.icon}>
            <Svg
              icon="preorder"
              size="3.8rem"
            />
          </div>
        }
        {type === availabilityMessagingType.existingOrder &&
          <div className={styles.icon}>
            <Svg
              icon="info-circle"
              size="1.7rem"
            />
          </div>
        }
        <div className={styles.text}>
          <span className={styles.textItem}>
            {I18n.t(webLabels.title)}
          </span>
          <span
            className={classNames(styles.textDates, styles.textItem)}
            data-testid="preorder-message"
          >
            {getPreOrderMessage()}
          </span>
        </div>
      </div>
    )
  }

  return null
}
