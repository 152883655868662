import React from 'react'

import { LazyModuleWrapper } from 'components/LazyModuleWrapper'

const LazyAccountAddresses = (
  React.lazy(() => import(/* webpackChunkName: "AccountAddresses" */ `./AccountAddresses`))
)

export const AccountAddresses = props => (
  <LazyModuleWrapper>
    <LazyAccountAddresses {...props} />
  </LazyModuleWrapper>
)
