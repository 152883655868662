import React from 'react'
import { I18n } from 'utils/i18n'
import { SelectableMenu } from 'components/SelectableMenu'

export function CategoriesSelectableMenu(props) {
  const {
    categories,
    selectCategoryChange,
    selectedIndexCategorySizeGuide,
  } = props

  return (
    <SelectableMenu
      onClick={(value) => selectCategoryChange(value)}
      options={categories.map(option => ({
        label: I18n.t(option.categoryTitle),
        value: option.categoryKey,
        key: option.categoryKey,
      }))}
      shouldAlwaysUseNativeSelect={true}
      value={categories[selectedIndexCategorySizeGuide].categoryKey}
    />
  )
}
