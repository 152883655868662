import React from 'react'
import classNames from 'classnames'

import { I18n } from 'utils/i18n'
import { currencyFormatted } from 'utils'
import { sizeFallThrough } from 'utils/sizeFallThrough'
import { getComponentOptions } from 'global-content/components'
import { getMetaOptions } from 'global-content/config'
import { HtmlText } from 'components/HtmlText'

import { webLabels } from './fixtures'
import styles from './Price.css'

export const Price = ({
  fontSize,
  fontWeight,
  price,
  priceRange = {},
  showRange,
  showPricingStrategy = true,
  hideDiscountPercentage,
}) => {
  const {
    finalSale,
    list = {},
    sale = {},
  } = price
  const {
    saleMin,
    saleMax,
    listMin,
    listMax,
  } = priceRange
  const percentage = discount()
  const onSale = saleMin !== listMin
  const hasRange = saleMin !== saleMax

  const was = I18n.t(webLabels.was)
  const now = I18n.t(webLabels.now)
  const pricingStrategy = I18n.t(webLabels.pricingStrategy)
  const isFinalSale = finalSale === `true`

  return (
    <div
      className={classNames(styles.container, {
        [styles.large]: fontSize === `large`,
        [styles.regular]: fontSize === `regular`,
        [styles.small]: fontSize === `small`,
        [styles.xs]: fontSize === `xs`,
      })}
      style={{
        fontSize: sizeFallThrough([`large`, `regular`, `small`, `xs`], fontSize),
        fontWeight,
      }}
    >
      {renderPrice()}
      {showPricingStrategy && getMetaOptions(`catalog.showPricingStrategy`) &&
        <div className={styles.tax}>
          {pricingStrategy}
        </div>
      }
    </div>
  )

  function getPercentage(numerator, denominator) {
    return (denominator - numerator) / denominator * 100
  }

  function formatPercentage(perc) {
    if (perc >= 1) {
      return String(Math.round(perc))
    } else if (perc > 0) {
      return String(perc.toFixed(2))
    } else {
      return `0`
    }
  }

  function discount() {
    if (list.total && sale.total) {
      return getPercentage(sale.total, list.total)
    }

    if (listMin === listMax && saleMin === saleMax) {
      const perc = getPercentage(saleMin, listMin)

      if (!Number.isNaN(perc)) {
        return perc
      }
    }
  }

  function renderPrice() {
    if (showRange && hasRange) {
      const { showFrom } = getComponentOptions(`price`)

      if (showFrom && hasRange) {
        return renderFrom()
      }

      return (
        <span className={styles.price}>
          {rangeOrSingle(sale.total, [saleMin, saleMax])}
        </span>
      )
    }

    return (
      <React.Fragment>
        {renderListPrice()}
        {Boolean(percentage) && renderDiscountedPrice()}
      </React.Fragment>
    )
  }

  function renderFrom() {
    return (
      <div
        className={styles.fromPrice}
        data-testid="from-text"
      >
        <HtmlText
          html={I18n.t(webLabels.from, {
            replace: {
              listPrice: onSale
                ? `<span class=${styles.originalPrice} data-testid="from-list-min"><span class="vis-hidden">${was}</span>${currencyFormatted(listMin)}</span>`
                : ``,
              salePrice: `<span class=${onSale && styles.currentPrice} data-testid="from-sale-min"><span class="vis-hidden">${now}</span>${currencyFormatted(saleMin)}</span>`,
            },
          })}
        />
      </div>
    )
  }

  function rangeOrSingle(single, [min, max]) {
    if (min === max || !showRange) {
      return currencyFormatted(single || min)
    }

    return (
      `${currencyFormatted(min)} - ${currencyFormatted(max)}`
    )
  }

  function renderListPrice() {
    return (
      <span
        className={classNames({
          [styles.originalPrice]: percentage > 0,
        })}
        data-testid="originalPrice"
      >
        <span className="vis-hidden">
          {was}
        </span>
        {rangeOrSingle(list.total, [listMin, listMax])}
      </span>
    )
  }

  function renderDiscountedPrice() {
    return (
      <React.Fragment>
        <span
          className={styles.currentPrice}
          data-testid="currentPrice"
        >
          <span className="vis-hidden">
            {now}
          </span>
          {rangeOrSingle(sale.total, [saleMin, saleMax])}
        </span>
        {percentage &&
          <span
            className={styles.percentageOff}
            data-testid="percetangeOff"
          >
            {!hideDiscountPercentage && I18n.t(webLabels.percent, {
              replace: {
                discount: formatPercentage(percentage),
              },
            })}
            {isFinalSale &&
              (
                <span>
                  {`- ${I18n.t(webLabels.finalSale)}`}
                </span>
              )
            }
          </span>
        }
      </React.Fragment>
    )
  }
}
