import mediaQueriesDef from 'css/media-queries.json'

/* eslint-disable */
const deviceBreakpointsArray = [
  [`mobileLarge`, mediaQueriesDef[`any`], mediaQueriesDef[`mobileLarge`]],                // < 480px
  [`tabletSmall`, mediaQueriesDef[`aboveMobileLarge`], mediaQueriesDef[`tabletSmall`]],   // < 768px
  [`tabletMedium`, mediaQueriesDef[`aboveTabletSmall`], mediaQueriesDef[`tabletMedium`]], // < 992px
  [`tabletLarge`, mediaQueriesDef[`aboveTabletMedium`], mediaQueriesDef[`tabletLarge`]],  // < 1024px
  [`desktopSmall`, mediaQueriesDef[`aboveTabletLarge`], mediaQueriesDef[`desktopSmall`]], // < 1200px
  [`aboveDesktopSmall`, mediaQueriesDef[`aboveDesktopSmall`]],                            // >= 1201px
]
 /* eslint-enable */

// TODO(Kevin): [Performance] It is better for performance to move this into redux global state,
// since this information can be useful for multiple components. Right now, we are removing the
// event change handlers on component unmount so its not that bad and wont cause a memory leak.
// But be aware that on every Carousel instance there are 6 event listeners (one for each device)
// so we should move this into redux when we get some free time.
export function findDeviceSize(callbackFunction) {
  const mediaQueryListArray = deviceBreakpointsArray.map(deviceBreakpointRange => {
    const deviceName = deviceBreakpointRange[0]
    let mediaQueryString = deviceBreakpointRange[1]
    if (deviceBreakpointRange[2]) {
      mediaQueryString += ` and ` + deviceBreakpointRange[2]
    }

    const mql = window.matchMedia(mediaQueryString) // create a MediaQueryList
    // call on load
    if (mql.matches) {
      callbackFunction(deviceName) // pass device name to callbackFunction if in range
    }

    // call on browser width change
    const handleEventFunction = (event) => {
      if (event.matches) {
        callbackFunction(deviceName) // pass device name to callbackFunction if in range
      }
    }
    mql.addEventListener(`change`, handleEventFunction)
    return {
      mql,
      handleEventFunction, // return so we can "removeEventListener"
    }
  })

  function removeMediaQueryListenersFunc() {
    for (let mqlObj of mediaQueryListArray) {
      mqlObj.mql.removeEventListener(`change`, mqlObj.mql.handleEventFunction)
    }
  }

  return removeMediaQueryListenersFunc
}
