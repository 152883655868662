import React from 'react'
import { LokaTranslate } from './LokaTranslate/LokaTranslate'
import { NavigationLink } from './Navigation/NavigationLink'
import { NavigationBarLink } from './Navigation/NavigationBarLink'
import { NavigationDropdown } from './Navigation/NavigationDropdown'
import { NavigationMegamenu } from './Navigation/NavigationMegamenu'
import { NavigationButton } from './Navigation/NavigationButton'
import { NavigationBack } from './Navigation/NavigationBack'
import { ContextNavigation } from './ContextNavigation/ContextNavigation'
import { ContextNavigationLink } from './ContextNavigation/ContextNavigationLink'
import { ContextNavigationMatch } from './ContextNavigation/ContextNavigationMatch'
import { ContextNavigationTab } from './ContextNavigation/ContextNavigationTab'
import { ContextNavigationTabMatch } from './ContextNavigation/ContextNavigationTabMatch'
import { Accordion } from './Accordion/Accordion'
import { AccordionButton } from './Accordion/AccordionButton'
import { AccordionMenu } from './Accordion/AccordionMenu'
import { AccordionMatch } from './Accordion/AccordionMatch'
import { Navigation } from './Navigation/Navigation'
import { camelCase } from 'lodash'
import { BaseElement } from './BaseElement/BaseElement'
import { FactorySvg } from './FactorySvg/FactorySvg'
import { Factory as GeneralFactory } from 'components/Factory'

export const ModernFactory = ({
  data: {
    markup,
    slotIndexMap,
  },
  items,
}) => {
  function recursiveConvert(node) {
    if (node === null) {
      return null
    } else if (node.type === `slot`) {
      return buildSlot({ node, items, slotIndexMap })
    } else if (node.tagName === `svg`) {
      return buildSvg({ node })
    } else if (node.type === `element`) {
      return buildElelement({ node })
    } else if (node.type === `text`) {
      return node.content
    } else {
      return null
    }
  }

  function buildElelement({ node }) {
    const nodeProps = normalizeAttributes(node.attributes)
    const nodeChildren = node.children || []
    const reactChildren = nodeChildren.map(child => recursiveConvert(child))

    return React.createElement(
      getElement({
        nodeProps,
      }),
      {
        key: node.key,
        Tag: node.tagName,
        ...nodeProps,
      },
      ...reactChildren
    )
  }

  if (markup) {
    const reactElements = markup.map(child => recursiveConvert(child))
    return reactElements
  } else {
    return null
  }
}

function buildSlot({
  node,
  items: _items,
  slotIndexMap,
}) {
  const slotName = node.attributes.find((attr) => attr.key === `name`)

  if (!slotName) {
    return null
  }

  const slotKey = node.attributes.find((attr) => attr.key === `_index`)
  const index = slotKey ? slotName.value + slotKey.value : slotName.value
  const slotIndex = slotIndexMap[index]
  const slotItem = _items[slotIndex]

  if (!slotItem) {
    return null
  }

  return (
    <GeneralFactory
      items={[slotItem]}
      key={node.key}
    />
  )
}

function buildSvg({
  node,
}) {
  const nodeProps = normalizeAttributes(node.attributes)
  return <FactorySvg {...nodeProps} />
}

function getElement({
  nodeProps,
  baseElement = BaseElement,
}) {
  const component = nodeProps._component
  delete nodeProps._component
  const map = {
    [`loka-translate`]: LokaTranslate,
    [`navigation-dropdown`]: NavigationDropdown,
    [`navigation-bar-link`]: NavigationBarLink,
    [`navigation-megamenu`]: NavigationMegamenu,
    [`navigation-link`]: NavigationLink,
    [`navigation`]: Navigation,
    [`navigation-button`]: NavigationButton,
    [`navigation-back`]: NavigationBack,
    [`accordion`]: Accordion,
    [`accordion-button`]: AccordionButton,
    [`accordion-menu`]: AccordionMenu,
    [`accordion-match`]: AccordionMatch,
    [`context-navigation`]: ContextNavigation,
    [`context-navigation-link`]: ContextNavigationLink,
    [`context-navigation-tab`]: ContextNavigationTab,
    [`context-navigation-match`]: ContextNavigationMatch,
    [`context-navigation-tab-match`]: ContextNavigationTabMatch,
  }
  return map[component] || baseElement
}

function normalizeAttributes(_attributes) {
  let data = {}
  const attributes = _attributes.reduce((prev, attr) => {
    if (attr.key === `class`) {
      attr.key = `className`
    }
    if (attr.key === `data-component`) {
      attr.key = `_component`
    }
    if (attr.key.startsWith(`data-`) && attr.key !== `data-testid`) {
      const key = camelCase(attr.key.replace(`data-`, ``))
      data = {
        ...data,
        [key]: attr.value,
      }
      return prev
    }
    prev[attr.key] = attr.value
    return prev
  }, {}) || {}
  if (Object.keys(data).length > 0) {
    attributes[`data`] = data
  }
  return attributes
}
