
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { getConfigOptions, getMetaOptions } from 'global-content/config'
import { useUrlManager } from 'hooks'
import { algoliaFiltersMap } from 'services/algolia'
import { updatePendingFilterOptions } from 'state/actions/listing'
import { getBrandRoute } from 'Router/utils'
import isNil from 'lodash/isNil'

export const defaultSearchSort = `bestMatch`

export function useFilters(usePending) {
  const brandMatch = useRouteMatch(getBrandRoute())
  const urlManager = useUrlManager()
  const dispatch = useDispatch()
  const pendingFilters = useSelector(state => state.listing.pendingFilters)
  const selectedFilters = useSelector(state => state.listing.selectedFilters)
  const pageFilters = useSelector(state => state.listing.pageFilters)

  return {
    applyPage,
    applyPending,
    applyQuery,
    applySort,
    batchApply,
    clearSelected,
    onFilterSelect,
    onFilterSelectReplace,
    removeUnavailableFilters,
  }

  function applyPage(pageToApply) {
    if (pageToApply !== 0) {
      urlManager({
        params: {
          append: {
            page: pageToApply,
          },
        },
      })
    } else {
      urlManager({
        params: {
          remove: [`page`],
        },
      })
    }
  }

  function applyPending() {
    urlManager({
      method: `replace`,
      params: {
        append: {
          ...pendingFilters,
        },
        remove: [`page`],
      },
    })
  }

  function applyQuery(query) {
    urlManager({
      params: {
        replace: {
          query,
          sortBy: defaultSearchSort,
        },
        remove: [`page`],
      },
      resetScroll: true,
    })
  }

  function applySort(option) {
    urlManager({
      params: {
        append: {
          sortBy: option,
        },
        remove: [`page`],
      },
    })
  }

  function batchApply(options) {
    const {
      sortBy,
      query,
      page,
      filters,
    } = options

    urlManager({
      method: `replace`,
      params: {
        append: { sortBy, query, page, ...filters },
      },
    })
  }

  function clearSelected() {
    urlManager({
      method: `replace`,
      params: {
        remove: [`page`, ...Object.keys(selectedFilters)],
      },
    })

    dispatch(updatePendingFilterOptions({}))
  }

  function onFilterSelect(facetKey, option, scrollHook) {
    if (usePending) {
      onPendingFilterSelect(facetKey, option)
    } else {
      onInstantFilterSelect(facetKey, option, scrollHook)
    }
  }

  function onPendingFilterSelect(facetKey, option) {
    const updatedValue = getNewValue(facetKey, option, pendingFilters)

    dispatch(updatePendingFilterOptions(
      removeEmptyKeys({
        ...pendingFilters,
        [facetKey]: updatedValue,
      })
    ))
  }

  function onInstantFilterSelect(facetKey, option, scrollHook) {
    const updatedValue = getNewValue(facetKey, option, selectedFilters)

    if (updatedValue && updatedValue.length) {
      urlManager({
        method: `replace`,
        params: {
          append: {
            [facetKey]: updatedValue,
          },
          remove: [`page`],
        },
        resetScroll: true,
        scrollHook,
      })
    } else {
      urlManager({
        method: `replace`,
        params: {
          remove: [`page`, facetKey],
        },
        resetScroll: true,
        scrollHook,
      })
    }
  }

  function onFilterSelectReplace(filterType, option, scrollHook) {
    urlManager({
      method: `replace`,
      params: {
        append: {
          [filterType]: option,
        },
        remove: [`page`],
      },
      resetScroll: true,
      scrollHook,
    })
  }

  function removeUnavailableFilters({ name }) {
    if (name === `sortBy`) {
      return true
    }

    const showBrand = getConfigOptions(`variant.show.brand`)

    if (
      name === `brand` &&
      (!showBrand || brandMatch)
    ) {
      return false
    }

    const FILTERSMAP = algoliaFiltersMap.get()
    const { filterType } = FILTERSMAP[name] || {}

    if (
      filterType === `numeric` &&
      pageFilters[name] &&
      pageFilters[name].length < 2
    ) {
      return false
    }

    if (pageFilters[name] && pageFilters[name].length) {
      return true
    }
  }
}

function getNewValue(facetKey, option, filters) {
  const FILTERSMAP = algoliaFiltersMap.get()
  const { filterType } = FILTERSMAP[facetKey] || {}

  if (!filterType) {
    return undefined
  }

  if (filterType === `numeric`) {
    if (facetKey === `priceRange`) {
      option = formatRangeSelector(option)
    }
    if (option?.map(value => Number(value))
      .filter(Boolean)
      .length
    ) {
      return option
    }

    return undefined
  }

  const options = filters[facetKey] || []

  if (options.includes(option)) {
    return options.filter(opt => opt !== option)
  }

  return [...options, option]
}

function removeEmptyKeys(newFilters) {
  let build = {}

  Object.entries(newFilters).forEach(([key, value]) => {
    if (value) {
      build[key] = value
    }
  })

  return build
}

function formatRangeSelector([min, max] = []) {
  let [newMin, newMax] = [min, max].map(formatRangeValue)

  if (isNil(newMin) && !isNil(newMax)) {
    newMin = 0
  }

  if (!newMin && !newMax) {
    newMin = null
    newMax = null
  }

  return [newMin, newMax]
}

function formatRangeValue(value) {
  const { stepMultiple } = getMetaOptions(`currency`)
  let num = parseFloat(value)

  if (isNaN(num)) {
    return null
  }

  if (stepMultiple < 1) {
    return num
  }

  if (num > 0 && num < stepMultiple) {
    num = stepMultiple
  }

  if (num % stepMultiple > 0) {
    num = Math.round(num / stepMultiple) * stepMultiple
  }
  if (num <= 0) {
    num = 0
  }

  return num
}
