import React from 'react'

import { Svg } from 'components/Svg'
import { Swatch } from 'components/Swatch'

import styles from './TickableButton.css'

export const TickableButton = ({
  label,
  onClick,
  selected,
  showSwatch,
  swatchHex,
  swatchName,
  swatchTag,
  swatchType,
  swatchPath,
}) => {
  return (
    <button
      aria-label={label}
      aria-pressed={selected ? `true` : `false`}
      className={styles.button}
      name={label}
      onClick={onClick}
    >
      {selected && <Svg icon="ok" />}
      {showSwatch && (
        <div className={styles.swatchWrapper}>
          <Swatch
            swatchHex={swatchHex}
            swatchName={swatchName}
            swatchPath={swatchPath}
            swatchSize="small"
            swatchTag={swatchTag}
            swatchType={swatchType}
          />
        </div>
      )}
      {label}
    </button>
  )
}
