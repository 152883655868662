import React from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { Layout } from 'components/Layout'
import { Loader } from 'components/Loader'
import { appIsReady } from 'state/actions/site'

import styles from './PageBody.css'

let appReadyTimeoutFired

export const PageBody = ({
  children,
  className,
  'data-testid': testId,
  layout = {},
  loading,
  styling = {},
}) => {
  const appReady = useSelector(state => state.site.appReady)
  const dispatch = useDispatch()
  const {
    backgroundColor,
  } = layout

  const {
    fontColor,
  } = styling

  React.useEffect(() => {
    if (!loading && !appReady && !appReadyTimeoutFired) {
      appReadyTimeoutFired = true

      setTimeout(async() => {
        await import(/* webpackChunkName: "app-ready-scripts" */ `utils/app-ready-scripts`).then(() => {
          console.log(`app-ready-scripts loaded`)
          dispatch(appIsReady())
        })
      }, 5000)
    }
  }, [loading, appReady])

  return (
    <Layout layout={layout}>
      <div
        className={classNames(styles.pageBody, {
          [className]: className,
          [styles.loadingBody]: loading,
        })}
        data-testid={testId}
        style={{
          '--bodyBackgroundColor': backgroundColor,
          color: fontColor,
        }}
      >
        {renderContent()}
      </div>
    </Layout>
  )

  function renderContent() {
    if (loading) {
      return (
        <div className={styles.loading}>
          <Loader />
        </div>
      )
    }

    return children
  }
}
