export function reportError(error) {
  if (window.Sentry) {
    console.warn(`REPORTED ERROR 👇`)
    console.error(error)

    return (
      window.Sentry.captureException(error, {
        extra: error?.additional,
        tags: error?.tags,
      })
    )
  }
}
