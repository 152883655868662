"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.Popover = void 0;
var react_1 = __importStar(require("react"));
var useOnClickOutside_1 = require("hooks/useOnClickOutside");
var Popover = function (_a) {
    var children = _a.children, targetNode = _a.targetNode, isOpen = _a.isOpen, _b = _a.mode, mode = _b === void 0 ? "menu" : _b, _c = _a.direction, direction = _c === void 0 ? "right" : _c, _d = _a.Tag, Tag = _d === void 0 ? "div" : _d, _e = _a.zIndex, zIndex = _e === void 0 ? 0 : _e, _f = _a.onClickOutside, onClickOutside = _f === void 0 ? function () { } : _f;
    var popoverRef = (0, react_1.useRef)(null);
    (0, useOnClickOutside_1.useOnClickOutside)(popoverRef, onClickOutside);
    (0, react_1.useEffect)(function () {
        var updateMenuPosition = function () {
            if (isOpen && targetNode && popoverRef.current) {
                var buttonRect = targetNode.getBoundingClientRect();
                popoverRef.current.style.position = "fixed";
                popoverRef.current.style.zIndex = "".concat(zIndex);
                popoverRef.current.style.top = "".concat(buttonRect.bottom, "px");
                popoverRef.current.style.left = "".concat(buttonRect.left, "px");
                popoverRef.current.style.width = "".concat(buttonRect.width, "px");
            }
        };
        if (isOpen) {
            updateMenuPosition();
        }
        window.addEventListener("scroll", updateMenuPosition);
        window.addEventListener("resize", updateMenuPosition);
        return function () {
            window.removeEventListener("scroll", updateMenuPosition);
            window.removeEventListener("resize", updateMenuPosition);
        };
    }, [isOpen, mode, direction, targetNode, zIndex]);
    return isOpen ? (react_1["default"].createElement(Tag, { ref: popoverRef, style: { position: "relative", zIndex: zIndex } }, children)) : null;
};
exports.Popover = Popover;
