import React from 'react'

import { LazyModuleWrapper } from 'components/LazyModuleWrapper'

const LazyBrand = (
  React.lazy(() => import(/* webpackChunkName: "Brand" */ `./Brand`))
)

export const Brand = props => (
  <LazyModuleWrapper>
    <LazyBrand {...props} />
  </LazyModuleWrapper>
)
