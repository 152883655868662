import React from 'react'

import { LazyModuleWrapper } from 'components/LazyModuleWrapper'

const LazyCheckout = (
  React.lazy(() => import(/* webpackChunkName: "Checkout" */ `./Checkout`))
)

export const Checkout = props => (
  <LazyModuleWrapper>
    <LazyCheckout {...props} />
  </LazyModuleWrapper>
)
