"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.CountryNavigation = void 0;
var CountryNavigation = /** @class */ (function () {
    function CountryNavigation(data) {
        this.data = data;
    }
    CountryNavigation.prototype.build = function () {
        var countryNavigation = this.data.countries.map(function (country) {
            return __assign(__assign({}, country), { languages: country.languages.map(function (language) {
                    return __assign(__assign({}, language), { value: language.code });
                }), value: country.code });
        });
        return countryNavigation;
    };
    return CountryNavigation;
}());
exports.CountryNavigation = CountryNavigation;
