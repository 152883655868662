import React from 'react'
import { useDispatch } from 'react-redux'

import { closePullout } from 'state/actions/pullout'
import { overlayOff } from 'state/actions/overlay'
import { I18n } from 'utils/i18n'
import { SideNavigation } from 'factory-components/SideNavigation'
import { Pullout } from 'components/Pullout'
import { PulloutTitle } from 'components/PulloutTitle'

import { webLabels } from './fixtures'
import styles from './PulloutCategories.css'

export const PulloutCategories = () => {
  const dispatch = useDispatch()

  return (
    <Pullout
      pulloutSide={`start`}
      style={{ backgroundColor: `var(--bodyBackgroundColor)` }}
    >
      <PulloutTitle html={I18n.t(webLabels.mobileCategoriesTitle)} />
      <div className={styles.navWrapper}>
        <SideNavigation
          data={{}}
          onClick={handleClick}
        />
      </div>
    </Pullout>
  )

  function handleClick() {
    dispatch(overlayOff(`pullout`))
    dispatch(closePullout())
  }
}
