"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.addGAEventToDataLayer = void 0;
var addGAEventToDataLayer = function (message) {
    window.dataLayer = window.dataLayer || [];
    var _a = window.document.location, protocol = _a.protocol, hostname = _a.hostname, pathname = _a.pathname, search = _a.search;
    var originalPath = "".concat(protocol, "//").concat(hostname).concat(pathname).concat(search);
    var analyticsEvent = __assign({}, message);
    // if any items in window.dataLayer contain originalLocation, don't add it
    // https://www.simoahava.com/gtm-tips/fix-rogue-referral-problem-single-page-sites/
    if (!window.dataLayer.some(locationAdded)) {
        analyticsEvent.originalLocation = originalPath;
    }
    // console.log(`Event ${type} triggered.`, message) // Uncomment this to debug
    // To debug on localhost, run console.log(window.dataLayer)
    window.dataLayer.push(analyticsEvent);
    function locationAdded(dataLayerItem) {
        return dataLayerItem.originalLocation !== undefined;
    }
};
exports.addGAEventToDataLayer = addGAEventToDataLayer;
