export function remapLeftAndRight(dir) {
  const newValue = {
    ltr: ltrMap(dir),
    rtl: rtlMap(dir),
  }

  return newValue.ltr || dir
}

function ltrMap(dir) {
  const map = {
    left: `start`,
    right: `end`,
  }

  return map[dir]
}

function rtlMap(dir) {
  const map = {
    left: `end`,
    right: `start`,
  }

  return map[dir]
}
