import React from 'react'
import get from 'lodash/get'
import queryString from 'query-string'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useGlobalContent, useFilters } from 'hooks'
import { getLevelDown } from 'utils'
import { getNavCategories } from 'utils/getNavCategories'
import { SideNavLink } from 'components/SideNavLink'

import styles from './CategoryNav.css'

export const CategoryNav = ({
  fontSize,
  levelsToShow = 2,
  onClick,
}) => {
  const { search } = useLocation()
  const { onFilterSelectReplace } = useFilters()
  const availableCategories = useSelector(state => state.listing.pageFacets.categories) || {}
  const navTree = useGlobalContent(`navTree`)
  let categoriesToShow = []

  recurisveLookup(navTree.l1, `l1`)
  const categoryString = queryString.parse(search).categories || ``

  return (
    <div className={styles.categoryNav}>
      {categoriesToShow.map(({
        categories,
        indent,
        label,
      }) => (
        <div
          key={`${categories}-${label}-${indent}`}
          style={{
            marginLeft: `${(indent - 1) * 5}px`,
          }}
        >
          <SideNavLink
            active={categoryString === categories.join(`,`)}
            button
            fontSize={fontSize}
            onClick={() => {
              onClick && onClick()
              handleClick(categories)
            }}
            value={label}
          />
        </div>
      ))}
    </div>
  )

  // TODO: WRITE A SCOPED WRAPPER TO KEEP THIS PURER RATHER MUTATING categoriesToShow
  function recurisveLookup(obj, level) {
    const levelDown = getLevelDown(level)
    Object.keys(obj).forEach(key => {
      const categories = getNavCategories(obj[key]) || []
      const onSale = get(obj[key], `filters.onSale`, false)

      if (categories.some(cat => Object.keys(availableCategories).includes(cat)) && !onSale) {
        categoriesToShow.push({
          categories,
          indent: level[1],
          label: obj[key].label,
        })
      }

      if (obj[key][levelDown] && levelDown[1] <= levelsToShow) {
        recurisveLookup(obj[key][levelDown], levelDown)
      }
    })
  }

  function handleClick(categories) {
    onFilterSelectReplace(`categories`, categories)
  }
}
