import queryString from 'query-string'

import {
  ACCOUNT_SIGN_OUT,
  ADD_ACCOUNT_PAYMENT_METHOD,
  DELETE_ACCOUNT_PAYMENT_METHOD,
  LIST_ACCOUNT_PAYMENT_METHODS,
  LOADED_DEPENDENCIES,
  REMOVE_ACCOUNT_PAYMENT_METHOD_ERROR,
  RESTART_SESSION,
  SET_DEFAULT_ACCOUNT_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD_TYPE,
  UPDATE_SELECTED_ACCOUNT_PAYMENT_METHOD,
  UPDATE_SELECTED_THIRD_PARTY_PAYMENT_METHOD,
  UPDATE_STORE_PAYMENT_METHOD,
} from 'state/actions'
import { getMetaOptions } from 'global-content/config'
import {
  BRANDCODE_CREDITCARD,
  STATUSCODE_PAYMENT_METHOD_UNABLE_TO_DELETE,
  STATUSCODE_PAYMENT_METHOD_UNABLE_TO_LIST,
  STATUSCODE_PAYMENT_METHOD_UNABLE_TO_UPDATE,
  STATUSCODE_PAYMENT_METHOD_UNABLE_TO_ADD,
  STATUSCODE_PAYMENT_METHOD_TOO_MANY_ATTEMPTS,
  STATUSCODE_PAYMENT_METHOD_UNAUTHORIZED,
} from 'utils/constants'

const initialState = {
  saved: undefined,
  selected: undefined,
  storePaymentMethod: false,
  paymentMethodType: {},
  paymentMethodActionError: undefined,
  paymentMethodActionPending: false,
  thirdPartyData: undefined,
}

const paymentMethods = (state = initialState, action) => {
  switch (action.type) {
  case `${ADD_ACCOUNT_PAYMENT_METHOD}_PENDING`:
  case `${DELETE_ACCOUNT_PAYMENT_METHOD}_PENDING`:
  case `${LIST_ACCOUNT_PAYMENT_METHODS}_PENDING`:
  case `${SET_DEFAULT_ACCOUNT_PAYMENT_METHOD}_PENDING`:
    return {
      ...state,
      paymentMethodActionError: undefined,
      paymentMethodActionPending: true,
    }

  case `${ADD_ACCOUNT_PAYMENT_METHOD}_FULFILLED`:
  case `${DELETE_ACCOUNT_PAYMENT_METHOD}_FULFILLED`:
  case `${SET_DEFAULT_ACCOUNT_PAYMENT_METHOD}_FULFILLED`:
    return {
      ...state,
      paymentMethodActionPending: false,
    }

  case `${DELETE_ACCOUNT_PAYMENT_METHOD}_REJECTED`:
    return {
      ...state,
      paymentMethodActionError: STATUSCODE_PAYMENT_METHOD_UNABLE_TO_DELETE,
      paymentMethodActionPending: false,
    }
  case `${LIST_ACCOUNT_PAYMENT_METHODS}_REJECTED`:
    return {
      ...state,
      paymentMethodActionError: action.payload.status === 401 ? STATUSCODE_PAYMENT_METHOD_UNAUTHORIZED : STATUSCODE_PAYMENT_METHOD_UNABLE_TO_LIST,
      paymentMethodActionPending: false,
    }
  case `${ADD_ACCOUNT_PAYMENT_METHOD}_REJECTED`:
    return {
      ...state,
      paymentMethodActionError: action.payload.status === 429 ? STATUSCODE_PAYMENT_METHOD_TOO_MANY_ATTEMPTS : STATUSCODE_PAYMENT_METHOD_UNABLE_TO_ADD,
      paymentMethodActionPending: false,
    }
  case `${SET_DEFAULT_ACCOUNT_PAYMENT_METHOD}_REJECTED`:
    return {
      ...state,
      paymentMethodActionError: STATUSCODE_PAYMENT_METHOD_UNABLE_TO_UPDATE,
      paymentMethodActionPending: false,
    }
  case `${LIST_ACCOUNT_PAYMENT_METHODS}_FULFILLED`:
    return {
      ...state,
      saved: action.payload,
      paymentMethodActionPending: false,
    }
  case REMOVE_ACCOUNT_PAYMENT_METHOD_ERROR:
    return {
      ...state,
      paymentMethodActionError: undefined,
    }
  case UPDATE_PAYMENT_METHOD_TYPE:
    return {
      ...state,
      paymentMethodType: getPaymentMethodType(action.payload && action.payload.brandCode),
    }
  case LOADED_DEPENDENCIES: {
    const params = queryString.parse(window.location.search)

    return {
      ...state,
      paymentMethodType: getPaymentMethodType(params.paymentMethod?.toLowerCase()),
    }
  }
  case `${RESTART_SESSION}_FULFILLED`:
    return {
      ...state,
      paymentMethodType: getPaymentMethodType(),
    }
  case UPDATE_SELECTED_ACCOUNT_PAYMENT_METHOD:
    return {
      ...state,
      selected: action.payload,
    }
  case UPDATE_SELECTED_THIRD_PARTY_PAYMENT_METHOD:
    return {
      ...state,
      thirdPartyData: action.payload,
    }
  case UPDATE_STORE_PAYMENT_METHOD:
    return {
      ...state,
      storePaymentMethod: action.payload,
    }
  case ACCOUNT_SIGN_OUT:
    return {
      ...initialState,
    }
  default:
    return state
  }
}

function getPaymentMethodType(brandCode) {
  const paymentOptions = getMetaOptions(`paymentOptions`)

  if (brandCode) {
    return (
      paymentOptions.find(option => option.brandCode === brandCode) ||
      paymentOptions.find(option => option.brandCode === BRANDCODE_CREDITCARD)
    )
  }

  return paymentOptions[0]
}

export default paymentMethods
