import React from "react"
import { Layout } from "components/Layout"
import { Link } from "components/Link"
import { I18n } from "utils/i18n"
import { Image as ComponentImage } from "components/Image"

import styles from "./Image.css"
import { webLabels } from "./fixtures"

export function Image({
  data,
  'data-testid': testId,
  tag,
  onClick,
}) {
  const {
    config = {},
    layout,
    styling = {},
    customClassName,
  } = data

  const {
    accessibilityDesc,
    alt,
    fade,
    href,
    metadata,
    src,
    target,
    title,
  } = config

  const {
    width: imageWidth,
    backgroundColor,
    ratio,
  } = styling

  const imageJsx = (
    <Layout
      className={customClassName}
      data-testid={testId || tag}
      layout={{
        ...layout,
        width: imageWidth,
      }}
    >
      <ComponentImage
        alt={alt || title}
        backgroundColor={backgroundColor}
        fade={fade}
        metadata={metadata}
        onClick={onClick}
        ratio={ratio}
        src={src}
        style={{
          width: `100%`,
        }}
      />
    </Layout>
  )

  if (href) {
    const name = accessibilityDesc || title || getTitleFromUrl(href)

    return (
      <Link
        aria-label={name}
        className={styles.imageLink}
        data-testid="image-link"
        name={name}
        onClick={() => {
          onClick(href)
        }}
        target={target}
        to={href}
      >
        {imageJsx}
      </Link>
    )
  }

  return imageJsx
}
function getTitleFromUrl(href) {
  if (href.substring(0, 1) === `#`) {
    return href.slice(1)
  }

  if (href.includes(`http`)) {
    const path = new URL(href)?.pathname
    return path
  }

  if (href === `/`) {
    return I18n.t(webLabels.logo)
  }

  return href.split(`?`)[0]
}
