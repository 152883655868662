import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { I18n } from 'utils/i18n'
import { Layout } from 'components/Layout'
import { LinkText } from 'components/LinkText'
import { updateGoogleAnalytics } from 'services/analytics'

import styles from './NavigationContextSwitcher.css'

export const NavigationContextSwitcher = ({
  layout,
  navItems,
  positioning = {},
  styling = {},
}) => {
  const {
    itemGap,
    itemHeight,
    itemWidth,
    justifyContent,
  } = positioning

  const {
    backgroundColor,
    activeState,
    hoverState,
    type,
  } = styling

  const activeNavContext = useSelector(state => state.navigation.navContext)

  return (
    <div
      className={classNames({
        [styles.withGap]: itemGap,
      })}
      style={{
        '--gap': itemGap,
        backgroundColor,
      }}
    >
      <Layout layout={layout}>
        <div
          className={styles.navigation}
          style={{
            justifyContent,
          }}
        >
          {navItems.map(({
            color,
            key,
            label,
            path,
            target,
          }) => {
            return (
              <LinkText
                active={activeNavContext === key}
                activeState={activeState}
                hoverState={hoverState}
                key={key}
                onClick={() => {
                  updateGoogleAnalytics(`clickCategoryNavLink`, { url: path })
                }}
                role="menuitem"
                style={{
                  color: color,
                  height: itemHeight,
                  minWidth: itemWidth,
                }}
                target={target}
                to={path}
                type={type}
              >
                {I18n.t(label)}
              </LinkText>
            )
          })}
        </div>
      </Layout>
    </div>
  )
}
