import React from 'react'

import { LazyModuleWrapper } from 'components/LazyModuleWrapper'

const LazyFavorites = (
  React.lazy(() => import(/* webpackChunkName: "Favorites" */ `./Favorites`))
)

export const Favorites = props => (
  <LazyModuleWrapper>
    <LazyFavorites {...props} />
  </LazyModuleWrapper>
)
