import React from 'react'

import { SiteSearchContext } from 'contexts/site-search'

export const SiteSearchConsumer = ({ children }) => {
  return (
    <SiteSearchContext.Consumer>
      {context => {
        if (context === undefined) {
          throw new Error(`SiteSearchConsumer must be used within a SiteSearchProvider`)
        }

        return children
      }}
    </SiteSearchContext.Consumer>
  )
}
