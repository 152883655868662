import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import Slider from 'react-slick-pnth'
import { useDeepCompare } from 'hooks/useDeepCompare'
import { useRemapLeftAndRight } from 'hooks/useRemapLeftAndRight'
import { ControlTrack } from 'components/ControlTrack'
import { CarouselNavArrow } from 'components/CarouselNavArrow'
import { findDeviceSize } from 'css/media-query-utils'
import { checkEmailSignupStatus } from 'components/WithEmailSignupGate/WithEmailSignupGate'
import styles from './Carousel.css'

export const Carousel = (props) => {
  const {
    config = {},
    items,
    positioning = {},
    styling = {},
  } = props

  const {
    borderFix,
    autoplaySpeed,
    centerMode = false,
    fade = false,
    infinite = false,
    swipeToSlide = true,
    slidesToScroll = 1,
    slidesToShowBreakPoints = null,
  } = config
  const slidesToShowOriginal = config.slidesToShow || 1

  const deepCheck = useDeepCompare(items)
  const [activeItem, setActiveItem] = React.useState(0)
  const sliderRef = React.useRef()
  const components = useSelector(state => state.components)
  //const langDir = useSelector(state => state.language.langDir)
  const mouseCoordinates = React.useRef([0, 0])
  const [slidesToShow, setSlidesToShow] = useState(slidesToShowOriginal)

  React.useEffect(() => {
    setActiveItem(0)
  }, [deepCheck])


  const isPeekABoo = Boolean(slidesToShowBreakPoints)
  useEffect(() => {
    if (isPeekABoo) {
      const removeMediaQueryListenersFunc = findDeviceSize((deviceName) => {
        if (slidesToShowBreakPoints[deviceName]) {
          const newSlidesToShow = slidesToShowBreakPoints[deviceName]
          setSlidesToShow(newSlidesToShow)
        } else {
          setSlidesToShow(slidesToShowOriginal)
        }
      })

      // Need to remove mediaQueryList listeners on component unmount
      return () => {
        removeMediaQueryListenersFunc()
      }
    }
  }, [isPeekABoo])


  const {
    controlsArrows,
    controlsDots,
    controlsObjectFit,
    controlsPosition: controlsPositionRaw,
    controlsRatio,
    controlsType,
    arrowOverlay = true,
    arrowPosition,
    arrowOffsetX,
    arrowOffsetY,
    arrowSize,
    gap,
  } = positioning

  const {
    arrowColor,
    arrowBackgroundColor,
    arrowPadding = `xs`,
    arrowMargin,
    arrowBorderRadius,
    arrowBorderColor,
    arrowBorderSize,
  } = styling

  const halvedGapWithUnit = halveGap()
  let order = [renderSlider()]
  const controlsPosition = useRemapLeftAndRight(controlsPositionRaw)

  if (controlsType === `thumbs`) {
    if (controlsPosition === `start`) {
      order.unshift(renderControlTrack())
    } else {
      order.push(renderControlTrack())
    }
  }

  return order.map(item => item)

  function renderSlider() {
    return (
      <Slider
        afterChange={current => {
          setActiveItem(current)
        }}
        arrows={controlsArrows}
        autoplay={Boolean(autoplaySpeed)}
        autoplaySpeed={autoplaySpeed}
        centerMode={centerMode}
        className={classNames(styles.slickSlider, {
          [styles.borderFix]: borderFix,
        })}
        dots={controlsDots}
        fade={fade}
        infinite={infinite}
        key="slider"
        nextArrow={renderArrow(`end`)}
        prevArrow={renderArrow(`start`)}
        ref={slider => { sliderRef.current = slider }}
        //rtl={langDir === `rtl`}              This prop gives problems in react-slick-pnth, might work properly in a future release
        slidesToScroll={slidesToScroll}
        slidesToShow={slidesToShow}
        speed={300}
        style={{
          ...arrowSpacingStyle(),
          // Use negative margin on the carousel container so it can negate the margins added to the invidiual carousel items.
          // The visual expectation is to allow Carousels to span edge to edge to the browser's width.
          marginLeft: `-${halvedGapWithUnit}`,
          marginRight: `-${halvedGapWithUnit}`,
        }}
        swipeToSlide={swipeToSlide}
      >
        {
          items.map((item, i) => {
            const {
              component,
            } = item.data
            const Component = components[component] || (() => <div />)

            if (shouldNotRenderComponent(component)) {
              return null
            }

            return (
              <div
                className={styles.wrapper}
                key={item.id}
                onClickCapture={handleChildClick}
                onMouseDownCapture={handleMouseDown}
              >
                <div style={{ margin: `0 ${halvedGapWithUnit}` }}>
                  <Component
                    {...item}
                    active={activeItem === i}
                  />
                </div>
              </div>
            )
          })
        }
      </Slider>
    )
  }

  function renderArrow(direction) {
    return (
      <CarouselNavArrow
        direction={direction}
        halvedGapWithUnit={arrowOverlay ? halvedGapWithUnit : `0px`}
        positioning={{
          offsetX: arrowOffsetX,
          offsetY: arrowOffsetY,
          overlay: arrowOverlay,
          position: arrowPosition,
          size: arrowSize,
        }}
        styling={{
          color: arrowColor,
          backgroundColor: arrowBackgroundColor,
          padding: arrowPadding,
          margin: arrowMargin,
          borderRadius: arrowBorderRadius,
          borderColor: arrowBorderColor,
          borderSize: arrowBorderSize,
        }}
      />
    )
  }

  function renderControlTrack() {
    return (
      <ControlTrack
        activeItem={activeItem}
        controlsObjectFit={controlsObjectFit}
        controlsPosition={controlsPosition}
        controlsRatio={controlsRatio}
        items={items}
        key="controlTrack"
        onClick={e => sliderRef.current.slickGoTo(e)}
      />
    )
  }

  // assumes the number is first
  function halveGap() {
    if (gap) {
      const value = parseInt(gap)
      const halved = value / 2
      const unit = gap.replace(String(value), ``)

      return `${halved}${unit}`
    }

    return `0px`
  }

  function arrowSpacingStyle() {
    if (!arrowOverlay && controlsArrows) {
      const sizes = {
        xs: `10px`,
        small: `20px`,
        medium: `40px`,
        large: `60px`,
      }

      const values = [
        sizes[arrowPadding],
        sizes[arrowMargin],
        arrowSize || `2rem`,
        arrowBorderSize,
        !sizes[arrowPadding] && extractHorizontalPadding(arrowPadding),
      ]

      return {
        padding: `0 calc(${values.filter(Boolean).join(` + `)})`,
      }
    }
  }

  function handleMouseDown(e) {
    mouseCoordinates.current = [e.clientX, e.clientY]
    e.preventDefault()
  }

  function handleChildClick(e) {
    if (mouseCoordinates.current[0] !== e.clientX ||
      mouseCoordinates.current[1] !== e.clientY) {
      e.preventDefault()
    }
  }
}

function extractHorizontalPadding(padding) {
  const values = padding.split(` `)

  if (values.length === 1) {
    return padding
  }

  if (
    values.length === 2 ||
    values.length === 3
  ) {
    return `${(parseInt(values[1]) * 2)}px`
  }

  if (values.length === 4) {
    return `${parseInt(values[1]) + parseInt(values[3])}px`
  }
}

function shouldNotRenderComponent(component) {
  return checkEmailSignupStatus() && component === `modalSplashLink`
}