import React from 'react'
import classNames from 'classnames'
import { getMetaOptions } from 'global-content/config'
import styles from './Svg.css'

export const Svg = ({
  block,
  className,
  color = `currentColor`,
  height,
  icon,
  size = `1em`,
  viewBox,
}) => {
  return (
    <svg
      className={classNames(styles.svg, className, {
        [styles.block]: block,
      })}
      style={{
        height: !viewBox ? size : height,
        width: !viewBox ? size : ``,
        fill: color,
      }}
      viewBox={viewBox}
    >
      <use href={`${getMetaOptions(`countryFolder`)}/themes/icons/sprites.svg?cb=${window.$cacheBust}#${icon}`} />
    </svg>
  )
}
