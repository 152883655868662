import React, { useContext } from 'react'
import { Link as InflexibleReactRouterLink } from 'react-router-dom'
import { ModernFactoryComponent } from '../ModernFactoryComponent'
import { ContextNavigationContext } from './ContextNavigation'

export const ContextNavigationLink = ModernFactoryComponent(({
  children,
  href,
  data: {
    navContext,
  },
  ...props
}, ref) => {
  delete props.Tag
  const {
    activeNavContext,
  } = useContext(ContextNavigationContext)

  const isSelected = activeNavContext === navContext

  return (
    <InflexibleReactRouterLink
      {...props}
      aria-selected={isSelected}
      ref={ref}
      tabIndex={isSelected ? -1 : 0}
      to={href}
    >
      {children}
    </InflexibleReactRouterLink>
  )
})
