import * as content from 'services/content'
import {
  LOAD_SCHEMA,
  LOAD_ADDRESS_SCHEMA,
} from 'state/actions'

export const loadSchema = (schema, name) => ({
  type: LOAD_SCHEMA,
  payload: content.loadSchema(schema, name),
})

export const loadAddressSchema = (language, type) => ({
  type: LOAD_ADDRESS_SCHEMA,
  payload: () => {
    return content.loadAddressSchema(language, type)
      .then(schema => {
        return {
          type,
          schema,
          language,
        }
      })
  },
})
