import React from 'react'

export const TableColumns = ({
  entries,
  headings,
  headingsClass,
  selectedEntry,
  tdPadding,
}) => {
  return (
    <tbody>
      {headings &&
        <tr className={headingsClass}>
          {headings.map(heading => (
            <th key={heading.key}>
              {heading.value}
            </th>
          ))}
        </tr>
      }
      {entries.map((row, i) => (
        <tr
          // eslint-disable-next-line react/no-array-index-key
          key={i}
        >
          {row.map(cell => (
            <td
              key={cell.key}
              style={{
                backgroundColor: i === selectedEntry && `var(--greyLight)`,
                padding: tdPadding,
              }}
            >
              {cell.value}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  )
}
