import React from 'react'

import { LazyModuleWrapper } from 'components/LazyModuleWrapper'

const LazyAccountCreation = (
  React.lazy(() => import(/* webpackChunkName: "AccountCreation" */ `./AccountCreation`))
)

export const AccountCreation = props => (
  <LazyModuleWrapper>
    <LazyAccountCreation {...props} />
  </LazyModuleWrapper>
)
