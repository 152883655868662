
import React, { useState } from 'react'
import { Svg } from 'components/Svg'
import { Factory } from 'components/Factory'

import styles from './ProductAccordion.css'

export const ProductAccordionItem = ({
  title,
  item,
  isOpen = false,
}) => {
  const [open, setOpen] = useState(isOpen)
  return (
    <>
      <button
        aria-expanded={open}
        className={styles.accordionButton}
        onClick={() => {
          setOpen((_open) => !_open)
        }}
        role="tab"
        tabIndex="0"
      >
        <span>
          {title}
        </span>
        {!open ? (
          <div className={styles.plus}>
            <Svg
              icon="plus"
              size="15px"
            />
          </div>
        ) : (
          <div className={styles.minus}>
            <Svg
              icon="minus"
              size="15px"
            />
          </div>
        )}
      </button>
      <div
        className={styles.accordionMenu}
        hidden={!open}
      >
        <div>
          <div className={styles.accordionMenuInner}>
            <Factory items={[item]} />
          </div>
        </div>
      </div>
    </>
  )
}
