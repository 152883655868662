import React from 'react'

export function useRegisterKeyHandlers({
  condition,
  event,
  handlers,
}) {
  const [registered, setRegistered] = React.useState(false)
  const stableHandlers = React.useCallback(e => handlers(e), [])

  React.useEffect(() => {
    if (condition) {
      if (!registered) {
        setRegistered(true)
        document.addEventListener(event, stableHandlers)
      }
    } else {
      if (registered) {
        setRegistered(false)
        document.removeEventListener(event, stableHandlers)
      }
    }
  }, [condition])
}
