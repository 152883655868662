import mergeWith from 'lodash/mergeWith'
import get from 'lodash/get'

import { customizer } from 'utils/mergeCustomizer'

import { getPreviewSetting } from 'global-content/preview'

export const defaultConfig = {
  variant: {
    show: {
      sku: true,
    },
  },
}

export function setGlobalConfig(config) {
  window.$CONFIG = mergeWith(defaultConfig, config, customizer)
}

export function getConfigOptions(key) {
  return get(window.$CONFIG, key)
}

export function getMetaOptions(key) {
  // todo: find a better way to add hooks
  if (key === `currencyCountry`) {
    return `${getMetaOptions(`currency.code`)}-${getMetaOptions(`country.code`)}`
  }

  if (key === `clientName`) {
    return get(window.$META, `clientDisplayName`) || get(window.$META, `clientName`)
  }

  if (key === `countryFolder`) {
    if (getPreviewSetting(`active`)) {
      return ``
    }
    const host = get(window.$META, `host`)
    const [, folder] = host ? host.split(`/`) : []
    if (folder) return `/${folder.trim()}`
    return ``
  }

  return get(window.$META, key)
}

export function getHost() {
  if (window.$PORTHOSTMAP) {
    return window.$PORTHOSTMAP[window.location.port]
  }

  return window.location.host
}
