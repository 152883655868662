import { useEffect } from 'react'
import { COOKIE_MESSAGE_ID, COOKIE_MESSAGE_ONETRUST } from 'components/CookieMessage/fixtures'

export function useOnClickOutside(ref, handler, keyup) {

  useEffect(() => {
    const listener = event => {
      const isInside = [
        COOKIE_MESSAGE_ID,
        COOKIE_MESSAGE_ONETRUST,
      ]
        .map(mapElementIdToRef)
        .concat(ref)
        .some(doesRefContainEventTarget(event))

      if (!isInside) {
        handler && handler(event)
      }
    }

    // Adding the event listeners
    if (keyup) {
      document.addEventListener(`keyup`, listener)
    }
    document.addEventListener(`mouseup`, listener)
    document.addEventListener(`touchend`, listener)

    // Removing the event listeners
    return () => {
      if (keyup) {
        document.removeEventListener(`keyup`, listener)
      }
      document.removeEventListener(`mouseup`, listener)
      document.removeEventListener(`touchend`, listener)
    }
  }, [ref, handler])

  function doesRefContainEventTarget(evt) {
    return _ref => {
      if (!_ref?.current || !evt?.target) {
        return false
      }

      return _ref.current.contains(evt.target)
    }
  }

  function mapElementIdToRef(id) {
    return {
      current: document.getElementById(id),
    }
  }
}
