import React from 'react'

import { useGlobalContent } from 'hooks/useGlobalContent'
import { useItemToRender } from 'hooks/useItemToRender'
import { Factory } from 'components/Factory'
import { MediaQuery } from 'components/MediaQuery'
import { Layout } from 'components/Layout'

export const Head = () => {
  const head = useGlobalContent(`head`)
  const headToRender = useItemToRender(head.items)

  const HeadComponent = ({
    items,
    ...rest
  }) => {
    return (
      <Layout
        {...rest}
        role="region"
        wrap
      >
        <Factory items={items} />
      </Layout>
    )
  }

  if (headToRender) {
    return (
      <MediaQuery items={headToRender.items}>
        {HeadComponent}
      </MediaQuery>
    )
  }

  return null
}

