export const webLabels = {
  sku: `WEB.PRODUCT.SKU`,
  price: `WEB.PRODUCT.PRICE`,
  color: `WEB.PRODUCT.COLOR`,
  size: `WEB.PRODUCT.SIZE`,
  localSize: `WEB.PRODUCT.LOCALSIZE`,
  quantity: `WEB.PRODUCT.QUANTITY`,
  sizeType: `WEB.PRODUCT.SIZETYPE`,
  brandSize: `WEB.PDP.SIZETABLE.BRANDSIZE`,
  sizeTable: `WEB.PDP.SIZETABLE.*`,
  checkoutFree: `WEB.PROMO.DISCOUNT.VALUE.OVERRIDE.FREE`,
  checkoutShippingDetails: `WEB.CHECKOUT.CART.SHIPPING.OPTION.DETAILS`,
}
