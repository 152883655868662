import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { augmentation } from 'utils/augmentation'
import { useDataFetching } from 'hooks/useDataFetching'
import { useRouteChange } from 'hooks/useRouteChange'
import { MediaQuery } from 'components/MediaQuery'
import { PageBody } from 'components/PageBody'
import { Factory } from 'components/Factory'
import { PageTemplate } from 'components/PageTemplate'

import { PRERENDER_CLASS } from './fixtures'

export const CustomPage = ({
  cacheName,
  folder,
  source,
}) => {
  const [file, triedFetch] = useDataFetching({
    cacheName,
    folder,
    source,
  })
  const campaignStateKey = useSelector(state => state.campaigns.stateKey)

  const {
    pathname,
    search,
  } = useLocation()
  const updateLocation = useRouteChange()

  React.useEffect(() => {
    if (file) {
      updateLocation({
        pathname,
        search,
      })
    }
  }, [file])

  if (!triedFetch) {
    return <PageBody loading />
  }

  if (file?.data?.template) {
    return (
      <PageTemplate
        {...file}
        key={campaignStateKey}
        template={file.data.template}
      >
        {CustomPageComponent}
      </PageTemplate>
    )
  }

  if (file) {
    return (
      <MediaQuery
        {...file}
        key={campaignStateKey}
      >
        {CustomPageComponent}
      </MediaQuery>
    )
  }

  return null
}

const CustomPageComponent = ({
  items,
  tag,
  ...props
}) => {
  const contentAugmentation = useCampaignContent(tag)

  return (
    <PageBody {...props}>
      <Factory
        items={augmentation(items, ...contentAugmentation.map(arg => arg.items))}
      />
      <div
        className={PRERENDER_CLASS}
        data-testid={PRERENDER_CLASS}
      />
    </PageBody>
  )
}

function useCampaignContent(tag) {
  const language = useSelector(state => state.language.active)
  const campaignStateKey = useSelector(state => state.campaigns.stateKey)
  const campaigns = useSelector(state => state.campaigns.active?.[language]) || []
  const [campaignContent, setCampaignContent] = React.useState([])
  const relevantContent = React.useMemo(() => getRelevantContent(campaigns, tag), [campaignStateKey])

  React.useState(() => {
    setCampaignContent(relevantContent)
  }, [campaignStateKey])

  return campaignContent
}

function getRelevantContent(campaigns, tag) {
  let relevantContent = []

  for (let i = 0; i < campaigns.length; i++) {
    const campaign = campaigns[i]
    const {
      items = [],
      promotionFormats,
    } = campaign
    const match = items.find(item => item.data?.augmentation?.target === tag)

    if (match) {
      relevantContent.push({
        promotionFormats,
        items: match.items,
      })
    }
  }

  return relevantContent
}
