import React from 'react'
import uuidv4 from 'uuid/v4'

import {
  SiteSearchContext,
  RESET,
  UPDATE_SITE_SEARCH_QUERY,
  UPDATE_SITE_SEARCH_EXPANDED,
} from 'contexts/site-search'
import { useOnClickOutside } from 'hooks/useOnClickOutside'

export const SiteSearchProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(siteSearchReducer, {
    expanded: false,
    query: ``,
    inputId: uuidv4(),
    reset,
    updateExpanded,
    updateQuery,
  })
  const handler = React.useCallback(() => handleClickOutside(), [])

  const ref = React.useRef()
  useOnClickOutside(ref, handler, true)

  return (
    <SiteSearchContext.Provider
      value={{
        dispatch,
        state,
      }}
    >
      <div ref={ref}>
        {children}
      </div>
    </SiteSearchContext.Provider>
  )

  function reset() {
    dispatch({
      type: RESET,
    })
  }

  function updateQuery(payload) {
    dispatch({
      type: UPDATE_SITE_SEARCH_QUERY,
      payload,
    })
  }

  function handleClickOutside() {
    reset()
  }

  function updateExpanded(payload) {
    dispatch({
      type: UPDATE_SITE_SEARCH_EXPANDED,
      payload,
    })
  }
}

function siteSearchReducer(state, action) {
  switch (action.type) {
  case RESET: {
    return {
      ...state,
      query: ``,
      expanded: false,
    }
  }
  case UPDATE_SITE_SEARCH_QUERY: {
    return {
      ...state,
      query: action.payload,
    }
  }
  case UPDATE_SITE_SEARCH_EXPANDED: {
    return {
      ...state,
      expanded: action.payload,
    }
  }
  default:
    return state
  }
}
