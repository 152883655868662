import React from 'react'
import { useDispatch } from 'react-redux'

import { getMetaOptions } from 'global-content/config'
import { reportError } from 'services/reportError'
import { overlayOn } from 'state/actions/overlay'
import { setActiveTooltip } from 'state/actions/site'
import { Tooltip } from 'components/Tooltip'

import styles from './TextError.css'

export const TextError = ({ error }) => {
  const dispatch = useDispatch()
  const ref = React.useRef(null)
  const showDetail = getMetaOptions(`environment`) !== `production`
  const stableBlock = React.useCallback((e) => block(e), [])
  const name = Math.random()

  React.useEffect(() => {
    reportError(error)

    if (showDetail) {
      const parentClicker = ref.current.closest(`a`) || ref.current.closest(`button`)

      if (parentClicker) {
        parentClicker.addEventListener(`click`, stableBlock)

        return () => parentClicker.removeEventListener(`click`, stableBlock)
      }
    }
  }, [])

  return (
    <div
      className={styles.container}
      data-testid="html-text__text-error"
      ref={ref}
      style={{
        outline: showDetail ? `5px solid gold` : `none`,
      }}
    >
      {/* DELIBERATE SO CAUGHT IN QA'S REGEX */}
      MISSING.TRANSLATION
      {showDetail && <div className={styles.tooltipWrapper}>
        <Tooltip
          name={error?.message}
          title="This detail only shows on staging and test"
          value={`<pre>${error?.message}</pre>`}
        />
      </div>
      }
    </div>
  )

  function block(e) {
    e.preventDefault()
    dispatch(overlayOn(`tooltip`))
    dispatch(setActiveTooltip(name))
  }
}
