"use strict";
exports.__esModule = true;
exports.unversionedStorage = exports.versionedStorage = exports._storage = void 0;
exports._storage = {
    get: function (key) {
        // TODO: Remove this in future builds, to remain only JSON.parse
        try {
            return JSON.parse(window.localStorage.getItem(key) || "");
        }
        catch (e) {
            return null;
        }
    },
    remove: function (key) {
        return window.localStorage.removeItem(key);
    },
    set: function (key, value) {
        return window.localStorage.setItem(key, JSON.stringify(value));
    }
};
var keyForThisBuild = function (key) { return "".concat(key, "_").concat(window.$TIMESTAMP); };
exports.versionedStorage = {
    get: function (key) { return exports._storage.get(keyForThisBuild(key)); },
    remove: function (key) { return exports._storage.remove(keyForThisBuild(key)); },
    set: function (key, value) { return exports._storage.set(keyForThisBuild(key), value); }
};
// NOTE: if you use this version of storage, be sure to
// validate and protect against stored data before you use it!
exports.unversionedStorage = {
    get: function (key) { return exports._storage.get(key); },
    remove: function (key) { return exports._storage.remove(key); },
    set: function (key, value) { return exports._storage.set(key, value); }
};
