import React from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { setFocussedNavItem } from 'state/actions/site'
import { useRegisterKeyHandlers } from 'hooks/useRegisterKeyHandlers'
import { Layout } from 'components/Layout'
import { NavItem } from 'components/NavItem'
import { useNavHeightsOffset } from 'hooks/useNavHeightsOffset'
import {
  getNavMenuTargetId,
  keyCaller,
  moveToNextNavItem,
  moveToPreviousNavItem,
  moveToPreviousNavMenu,
  moveToNextNavMenu,
  clickNavItem,
} from 'utils/navigation-utils'

import { webLabels } from './fixtures'
import styles from './NavigationBanner.css'

export const NavigationBanner = ({
  layout,
  navItems,
  parentMenuIndexes,
  positioning = {},
  styling = {},
  navMenuIndex,
}) => {
  const langDir = useSelector(state => state.language.langDir)
  const isLTR = langDir === `ltr`
  const offset = useNavHeightsOffset(navMenuIndex)

  const {
    itemGap,
    itemHeight,
    itemPadding,
    itemTextAlign,
    justifyContent,
  } = positioning

  const {
    backgroundColor,
    activeState,
    hoverState,
    sublabel,
    type,
  } = styling

  const dispatch = useDispatch()
  const focussedMenu = useSelector(state => state.site.focussedNavMenu)

  useRegisterKeyHandlers({
    condition: navItems.length && focussedMenu === navMenuIndex,
    event: `keydown`,
    handlers,
  })

  const absolute = navMenuIndex > 0
  const sublabelsPresent = navItems.filter(value => value.sublabel).length

  if (shouldRender()) {
    return (
      <div
        aria-label={webLabels.openMenu}
        className={classNames({
          [styles.absolute]: absolute,
        })}
        role="menubar"
        style={{
          '--gap': itemGap,
          backgroundColor,
          top: offset,
        }}
      >
        <Layout layout={layout}>
          <div
            className={styles.navigation}
            id={`navMenu_${navMenuIndex}`}
            style={{
              justifyContent,
            }}
          >
            {navItems.map((value, index) => {
              return (
                <NavItem
                  activeState={activeState}
                  className={styles.bannerLink}
                  hoverState={hoverState}
                  index={index}
                  itemHeight={itemHeight}
                  itemPadding={itemPadding}
                  itemTextAlign={itemTextAlign}
                  key={value.path}
                  navMenuIndex={navMenuIndex}
                  parentMenuIndexes={parentMenuIndexes}
                  sublabelsPresent={sublabelsPresent}
                  type={type}
                  value={value}
                >
                  <div className={styles.labelWrapper}>
                    <div>
                      {value.label}
                    </div>
                    {value.sublabel &&
                      <div
                        className={styles.sublabel}
                        style={{
                          color: sublabel?.color,
                          fontSize: sublabel?.fontSize,
                          fontWeight: sublabel?.fontWeight,
                        }}
                      >
                        {value.sublabel}
                      </div>
                    }
                  </div>
                </NavItem>
              )
            })}
          </div>
        </Layout>
      </div>
    )
  }

  return null

  function shouldRender() {
    return navItems.length && (!absolute || offset)
  }

  function handlers(e) {
    const keyMap = {
      ArrowLeft: isLTR ? moveToPreviousNavItem : moveToNextNavItem,
      ArrowRight: isLTR ? moveToNextNavItem : moveToPreviousNavItem,
      ArrowUp: moveToPreviousNavMenu,
      ArrowDown: moveToNextNavMenu,
      Enter: clickNavItem,
      Escape: handleEscape,
    }

    if (keyMap[e.key]) {
      e.preventDefault()

      keyCaller({
        dispatch,
        navMenuIndex,
        handler: keyMap[e.key],
      })
    }
  }
}

function handleEscape(args) {
  if (getNavMenuTargetId(args.focussedNavItems, `previous`)) {
    moveToPreviousNavMenu(args)
  } else {
    args.dispatch(setFocussedNavItem())
  }
}
