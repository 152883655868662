export function tracking() {
  let urlParams = new URLSearchParams(window.location.search)

  if (urlParams.has(`internal`)) {
    window.localStorage.setItem(`disableTracking`, true)
  }
}

export function isTracking() {
  return !window.localStorage.getItem(`disableTracking`)
}