"use strict";
exports.__esModule = true;
exports.LocalStorageKeys = void 0;
// eslint-disable-next-line
var LocalStorageKeys;
(function (LocalStorageKeys) {
    // eslint-disable-next-line
    LocalStorageKeys["plpAssociation"] = "productIdAssociation";
    // eslint-disable-next-line
    LocalStorageKeys["algoliaEvents"] = "algoliaEvents";
})(LocalStorageKeys = exports.LocalStorageKeys || (exports.LocalStorageKeys = {}));
