"use strict";
exports.__esModule = true;
exports.useEmitCompleteCheckoutEvent = void 0;
var events_1 = require("../../../services/events");
var storage_1 = require("../../../services/storage");
var useEmitCompleteCheckoutEvent = function () {
    var emitCompleteCheckoutEvent = function (_a) {
        var cartData = _a.cartData;
        var plpAssociations = storage_1.Storage.getPlpAssociations();
        events_1.Events.emitCompleteCheckout({
            cartData: cartData,
            plpAssociations: plpAssociations
        });
        storage_1.Storage.clearPlpAssociations();
    };
    return emitCompleteCheckoutEvent;
};
exports.useEmitCompleteCheckoutEvent = useEmitCompleteCheckoutEvent;
