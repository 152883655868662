import { ADDRESS_URL } from 'services/api/routes'
import { get, post, put } from 'services/api'

export function listAddresses() {
  return get(
    {
      path: ADDRESS_URL,
    }
  )
}

export function addAddress({
  address,
  type,
}) {
  return post(
    {
      path: ADDRESS_URL,
      params: {
        type,
      },
    },
    { body: JSON.stringify(address) }
  )
}

export function updateAddress({
  address,
  id,
}) {
  return put(
    {
      path: `${ADDRESS_URL}/${id}`,
    },
    { body: JSON.stringify(address) }
  )
}

export function deleteAddress({ id }) {
  return put(
    {
      path: `${ADDRESS_URL}/${id}/remove`,
    }
  )
}
