"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.GoogleAnalyticsEmitter = void 0;
var utilities_1 = require("./utilities");
var utilities_2 = require("../AlgoliaInsights/utilities");
var constants_1 = require("./constants");
exports.GoogleAnalyticsEmitter = {
    emitAddToCart: function (_a) {
        var queryID = _a.associatedPlp.queryID, _b = _a.middleware, shoppingSessionId = _b.shoppingSessionId, userId = _b.userId;
        var eventLabel = queryID ? "addedToCartObjectIDsAfterSearch" : "addedToCartObjectIDs";
        var messages = [
            __assign({ event: constants_1.GoogleAnalyticsEventTypes.trackEvent, eventAction: "click", eventCategory: constants_1.GoogleAnalyticsCategoryNames.algoliaSearchEvent, eventLabel: eventLabel, shoppingSessionId: shoppingSessionId }, (userId ? { userId: userId } : {})),
        ];
        messages.forEach(utilities_1.addGAEventToDataLayer);
    },
    emitClickProduct: function (_a) {
        var _b = _a.middleware, shoppingSessionId = _b.shoppingSessionId, userId = _b.userId, analyticsTitle = _a.analyticsTitle;
        var messages = [
            __assign({ event: constants_1.GoogleAnalyticsEventTypes.trackEvent, eventAction: "click", eventCategory: constants_1.GoogleAnalyticsCategoryNames.algoliaSearchEvent, eventLabel: analyticsTitle, shoppingSessionId: shoppingSessionId }, (userId ? { userId: userId } : {})),
        ];
        messages.forEach(utilities_1.addGAEventToDataLayer);
    },
    emitCompleteCheckout: function (_a) {
        var plpAssociations = _a.plpAssociations, cartContents = _a.cartData.cartContents, _b = _a.middleware, shoppingSessionId = _b.shoppingSessionId, userId = _b.userId;
        var _c = (0, utilities_2.getAssociations)({
            cartContents: cartContents,
            plpAssociations: plpAssociations
        }), associationsAfterSearch = _c.associationsAfterSearch, associationsWithoutSearch = _c.associationsWithoutSearch;
        addGroupToGA(associationsAfterSearch, [
            {
                event: constants_1.GoogleAnalyticsEventTypes.trackEvent,
                eventAction: "conversion",
                eventCategory: constants_1.GoogleAnalyticsCategoryNames.algoliaSearchEvent,
                eventLabel: "purchasedObjectIDsAfterSearch"
            },
            {
                event: constants_1.GoogleAnalyticsEventTypes.trackEvent,
                eventAction: "conversion",
                eventCategory: constants_1.GoogleAnalyticsCategoryNames.algoliaSearchEvent,
                eventLabel: "checkouttObjectIDsAfterSearch"
            },
        ]);
        addGroupToGA(associationsWithoutSearch, [
            {
                event: constants_1.GoogleAnalyticsEventTypes.trackEvent,
                eventAction: "conversion",
                eventCategory: constants_1.GoogleAnalyticsCategoryNames.algoliaSearchEvent,
                eventLabel: "purchasedObjectIDs"
            },
            {
                event: constants_1.GoogleAnalyticsEventTypes.trackEvent,
                eventAction: "conversion",
                eventCategory: constants_1.GoogleAnalyticsCategoryNames.algoliaSearchEvent,
                eventLabel: "checkouttObjectIDs"
            },
        ]);
        function addGroupToGA(group, messages) {
            if (group.length > 0) {
                messages.forEach(function (message) {
                    (0, utilities_1.addGAEventToDataLayer)(__assign(__assign(__assign({}, message), (userId
                        ? {
                            userId: userId
                        }
                        : {})), { shoppingSessionId: shoppingSessionId }));
                });
            }
        }
    },
    emitViewProduct: function (_a) {
        var _b = _a.middleware, shoppingSessionId = _b.shoppingSessionId, userId = _b.userId, analyticsTitle = _a.analyticsTitle;
        var messages = [
            __assign({ event: constants_1.GoogleAnalyticsEventTypes.trackEvent, eventAction: "view", eventCategory: constants_1.GoogleAnalyticsCategoryNames.algoliaSearchEvent, eventLabel: analyticsTitle, shoppingSessionId: shoppingSessionId }, (userId ? { userId: userId } : {})),
        ];
        messages.forEach(utilities_1.addGAEventToDataLayer);
    }
};
