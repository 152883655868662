import { getCampaigns } from 'services/api'
import { loadContent } from 'services/content'
import { translate } from 'utils/translate'
import { promoInterpolation } from './promo-interpolation'

export function getActiveCampaigns(language) {
  return getCampaigns()
    .then(campaigns => campaigns.filter(c => c?.status !== `ENDED`))
    .then(campaigns => getCampaignFiles(campaigns))
    .then(campaignsWithContent => formatCampaigns(campaignsWithContent, language))
    .then(content => {
      return {
        campaigns: translate({
          content,
          language,
          doI18n: false,
        }),
        language,
      }
    })
    // campaigns api failure fails forward not to block whole site
    .catch(() => Promise.resolve({
      campaigns: [],
    }))
}

export function getCampaignFiles(campaigns) {
  if (campaigns?.length) {
    return Promise.all(campaigns.map(loadCampaign))
  }
}

export function loadCampaign(campaign) {
  return loadContent(`/data/campaigns/${campaign.name}.json`, {
    allowMissing: true,
  }) // use name or id?
    .then(({ content }) => ({
      ...content,
      ...campaign,
    })).catch(() => {
      return campaign
    })
}

export function formatCampaigns(campaigns, language) {
  return campaigns?.filter(Boolean).map(campaign => {
    return promoInterpolation(campaign, language)
  })
}
