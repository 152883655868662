export function filterKeysByString(str, obj = {}) {
  let result = {}

  Object.keys(obj).forEach(key => {
    if (key.startsWith(str)) {
      result[key] = obj[key]
    }
  })

  return result
}