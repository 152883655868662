import React from 'react'
import { useSelector } from 'react-redux'

import { Script } from 'factory-components/Script'
import { getMetaOptions } from 'global-content/config'

export const BazaarVoiceScript = ({ callback }) => {
  const locale = useSelector(state => state.language.locale)
  const active = getMetaOptions(`integrations.bazaarvoice.active`)
  const clientName = getMetaOptions(`integrations.bazaarvoice.clientName`)
  const environment = window.$ENV === `test` ? `staging` : window.$ENV // if test, use staging setup
  const formattedLocale = locale && locale.replace(`-`, `_`)
  return active
    ? (
      <Script
        data={{
          content: {
            scripts: [`https://apps.bazaarvoice.com/deployments/${clientName}/main_site/${environment}/${formattedLocale}/bv.js`],
          },
        }}
        onLoad={onLoad}
      />
    ) : null

  function onLoad() {
    callback && callback(window.BV)
  }
}
