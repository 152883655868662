import React from 'react'
import classNames from 'classnames'

import { Grid } from 'components/Grid'
import { Link } from 'components/Link'
import { Svg } from 'components/Svg'

import styles from './IconsGroup.css'

export const IconsGroup = ({
  blockIcons,
  borderedIcons,
  columns,
  gap = `3rem`,
  height,
  icons,
  inlineGrid,
  size,
  viewBox,
}) => {
  if (icons.length) {
    return (
      <Grid
        columns={columns || icons.length}
        gap={gap}
        inlineGrid={inlineGrid}
      >
        {icons.map(icon => (
          renderIcon(icon)
        ))}
      </Grid>
    )
  }

  return null

  function renderIcon({
    icon,
    link,
    target,
    title,
  }) {
    const svg = (
      <div
        className={classNames({
          [styles.borderedIcons]: borderedIcons,
        })}
        key={icon}
      >
        <Svg
          block={blockIcons}
          height={height}
          icon={icon}
          size={size}
          viewBox={viewBox}
        />
      </div>
    )

    if (link) {
      return (
        <Link
          aria-label={title || icon}
          key={icon}
          name={title || icon}
          target={target}
          to={link}
        >
          {svg}
        </Link>
      )
    }

    return svg
  }
}
