import set from 'lodash/set'

export function dotObject(values) {
  let build = {}

  Object.entries(values).forEach(([key, value]) => {
    const v = value || undefined
    set(build, key, v)
  })

  return build
}
