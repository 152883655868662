import React from 'react'
import { useSelector } from 'react-redux'

import { PulloutCategories } from 'components/PulloutCategories'
import { PulloutFilters } from 'components/PulloutFilters'
import { PulloutMenu } from 'components/PulloutMenu'

export const PulloutSelector = () => {
  const content = useSelector(state => state.pullout.content)

  const componentMap = {
    mobileFilters: PulloutFilters,
    menuPullout: PulloutMenu,
    mobileCategories: PulloutCategories,
  }

  const Component = componentMap[content]

  if (Component) {
    return (
      <Component />
    )
  }

  return null
}
