import React from 'react'

import { SiteSearchContext } from 'contexts/site-search'

export function useSiteSearchContext() {
  const context = React.useContext(SiteSearchContext)

  if (context === undefined) {
    throw new Error(`useSiteSearch must be used within a SiteSearchProvider`)
  }

  return context
}
