import get from 'lodash/get'

export function getContent(key, language) {
  const content = get(window.$content, key)

  if (content) {
    return content[language] || content
  }
}

export function setContent({
  target,
  content,
  language,
}) {
  window.$content[target] = {
    ...window.$content?.[target],
    [language]: content,
  }
}
