import React from 'react'
import { I18n } from 'utils/i18n'
import { useModal } from 'hooks/useModal'
import { Svg } from 'components/Svg'
import styles from './ProductSizeGuidesVB.css'
import { ProductSizeGuidesVBBody } from './ProductSizeGuidesVBBody'

export const ProductSizeGuidesVBModal = (props) => {
  const { sizeGuideProperties } = props

  const {
    show: openSizeGuide,
    RenderModal,
  } = useModal()

  const {
    buttonText,
  } = sizeGuideProperties.data

  return (
    <div>
      <button
        className={styles.sizeGuideButton}
        onClick={openSizeGuide}
      >
        <span className={styles.sizeGuideIcon}>
          <Svg
            icon="ruler"
            size="3.5rem"
          />
        </span>
        {I18n.t(buttonText)}
      </button>

      <RenderModal mobileFullScreen>
        <ProductSizeGuidesVBBody sizeGuideProperties={sizeGuideProperties} />
      </RenderModal>
    </div>
  )
}
