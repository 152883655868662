import { currencyFormatted } from 'utils'
import { I18n } from 'utils/i18n'
import { localPercent } from 'utils/localPercent'
import { webLabels } from './fixtures'

export function typeAndAmountInterpolater({
  amount,
  language,
  type,
}) {
  return I18n.t(getWebLabel(), {
    language,
    replace: {
      amount: type !== `PERCENT_OFF` ? currencyFormatted(amount) : localPercent(amount, language),
    },
  })

  function getWebLabel() {
    if (amount === 0 && type === `VALUE_OVERRIDE`) {
      return webLabels.VALUE_OVERRIDE_FREE
    }

    return webLabels[type]
  }
}
