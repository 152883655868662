"use strict";
exports.__esModule = true;
exports.filtersMatcher = void 0;
var getNavCategories_1 = require("utils/getNavCategories");
function filtersMatcher(levelObject, product) {
    var _a = levelObject.filters, filters = _a === void 0 ? {} : _a;
    var collections = filters.collections, onSale = filters.onSale, categoryPageIds = filters.categoryPageIds;
    var matched = false;
    var categories = (0, getNavCategories_1.getNavCategories)(levelObject);
    if (Array.isArray(categoryPageIds)) {
        if (product.categoryPageIds && categoryPageIds.some(function (categoryPageId) { return product.categoryPageIds.includes(categoryPageId); })) {
            matched = true;
        }
        else {
            return false;
        }
    }
    if (Array.isArray(collections)) {
        if (collections.some(function (collection) { return product.collections.map(function (_a) {
            var tag = _a.tag;
            return tag;
        }).includes(collection); })) {
            matched = true;
        }
        else {
            return false;
        }
    }
    if (Array.isArray(categories)) {
        if (categories.some(function (category) { return product.categories.includes(category); })) {
            matched = true;
        }
        else {
            return false;
        }
    }
    if (onSale) {
        if (product.sale === "ONSALE") {
            matched = true;
        }
        else {
            return false;
        }
    }
    return matched;
}
exports.filtersMatcher = filtersMatcher;
