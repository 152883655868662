import React from 'react'

import { NavItem } from 'components/NavItem'

export const MegamenuNavGroup = ({
  activeState,
  hoverState,
  itemHeight,
  itemPadding,
  chunks,
  navMenuIndex,
  parentMenuIndexes,
  type,
}) => {
  let i = 0

  return chunks.map(chunk => {
    return (
      <div
        key={chunk[0].path}
        style={{
          display: `flex`,
          flexDirection: `column`,
        }}
      >
        {chunk.map(item => {
          const navItemIndex = i++

          return (
            <NavItem
              activeState={activeState}
              hoverState={hoverState}
              index={navItemIndex}
              itemHeight={itemHeight}
              itemPadding={itemPadding}
              key={item.path}
              navMenuIndex={navMenuIndex}
              parentMenuIndexes={parentMenuIndexes}
              type={type}
              value={item}
            >
              {item.label}
            </NavItem>
          )
        })}
      </div>
    )
  })
}
