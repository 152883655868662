import get from 'lodash/get'
import queryString from 'query-string'

import { unversionedStorage } from 'utils/storage'
import { getHost } from 'global-content/config'

export const STORAGE_KEY = `preview`

export function checkForPreviewMode() {
  const host = getHost()

  if (host.includes(`preview`)) {
    const {
      client,
      country,
      preview,
    } = previewSettings()

    window.$PREVIEWMODE = {
      active: true,
      host,
      client,
      country,
      preview,
    }
  }
}

function previewSettings() {
  const {
    client,
    country,
    preview,
  } = queryString.parse(window.location.search)

  if (!client && !country) {
    const storedValues = unversionedStorage.get(STORAGE_KEY)

    if (storedValues?.client && storedValues?.country) {
      return storedValues
    }
  }

  window.localStorage.clear()
  unversionedStorage.set(STORAGE_KEY, { client, country, preview })

  return {
    client,
    country,
    preview,
  }
}

export function getPreviewSetting(key) {
  return get(window.$PREVIEWMODE, key)
}
