import React from 'react'

import { LazyModuleWrapper } from 'components/LazyModuleWrapper'

const LazyOrderHistory = (
  React.lazy(() => import(/* webpackChunkName: "OrderHistory" */ `./OrderHistory`))
)

export const OrderHistory = props => (
  <LazyModuleWrapper>
    <LazyOrderHistory {...props} />
  </LazyModuleWrapper>
)
