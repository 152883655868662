import React from 'react'
import { useSelector } from 'react-redux'

export function useNavHeightsOffset(navMenuIndex) {
  const [offset, setOffset] = React.useState(0)
  const focussedNavItems = useSelector(state => state.site.focussedNavItems)

  const getOffset = React.useCallback(() => {
    if (focussedNavItems.length) {
      setOffset(getFocussedNavHeights(focussedNavItems, navMenuIndex))
    }
  }, [focussedNavItems.length])

  React.useEffect(() => {
    window.addEventListener(`resize`, getOffset)

    return () => {
      window.removeEventListener(`resize`, getOffset)
    }
  }, [])

  React.useEffect(() => {
    getOffset()
  }, [focussedNavItems.length])

  return offset
}

function getFocussedNavHeights(focussedNavItems, navMenuIndex) {
  let ids = []
  const parents = focussedNavItems.slice(0, navMenuIndex)

  for (let i = 0; i < parents.length; i++) {
    if (i === 0) {
      ids.push(`navItem_${focussedNavItems[i]}`)
    } else {
      ids.push(`${ids[i - 1]}_${focussedNavItems[i]}`)
    }
  }

  return ids.reduce((acc, id) => {
    return acc + document.getElementById(id)?.getBoundingClientRect().height
  }, 0)
}
