import React from 'react'

import { LazyModuleWrapper } from 'components/LazyModuleWrapper'

const LazyProduct = (
  React.lazy(() => import(/* webpackChunkName: "Product" */ `./Product`))
)

export const Product = props => (
  <LazyModuleWrapper>
    <LazyProduct {...props} />
  </LazyModuleWrapper>
)
