import React from 'react'
import { I18n } from 'utils/i18n'
import { ShippingTable } from 'components/ShippingTable'
import { getMetaOptions } from 'global-content/config'
import { HtmlText } from 'components/HtmlText'
import { webLabels } from './fixtures'

import styles from './ShippingAndReturns.css'

export const ProductShippingAndReturns = () => {
  return (
    <div className={styles.tableWrapper}>
      <ShippingTable />
      <div className={styles.returnDetails}>
        <HtmlText html={getReturnsPolicy()} />
      </div>
    </div>
  )

  function getReturnsPolicy() {
    const returnsPeriod = getMetaOptions(`returns.window`)
    // using very basic condition, but in the future, can drive off of store type
    if (returnsPeriod) {
      return I18n.t(webLabels.returnsApparel, {
        replace: {
          returnsPeriod,
        },
      })
    } else {
      return I18n.t(webLabels.returnsCustom) // set at client level
    }
  }
}