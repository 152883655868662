import React, { useContext } from 'react'
import { AccordionContext } from './Accordion'
import { ModernFactoryComponent } from '../ModernFactoryComponent'

export const AccordionButton = ModernFactoryComponent(({
  children,
  Tag,
  data: {
    index,
    clearOnToggle,
  },
  ...props
}, ref) => {
  const {
    activePanel,
    setActivePanel,
  } = useContext(AccordionContext)

  return (
    <Tag
      {...props}
      aria-expanded={activePanel === index}
      onClick={() => {
        if (activePanel !== index) {
          setActivePanel(index)
        } else if (clearOnToggle) {
          setActivePanel()
        }
      }}
      ref={ref}
    >
      {children}
    </Tag>
  )
})
