export const SENTRY_TAGS = {
  SOURCE_FILE: `lithosSourceFile`,
  FETCH_URL: `lithosFetchUrl`,
  SHOULD_NOTIFY: `lithosShouldNotify`, // used to tag issues that need to be alerted
}

/**
 * ErrorAdditional parameters:
 *   title:        Main error title in Sentry (used for grouping events into one issue)
 *
 *   message:      Subtitle for error in Sentry
 *
 *   severity:     NOTE(Kevin) Severity is used incorrectly as they should be strings
 *                 like 'fatal, 'info'. So this needs a refactor, but for now use
 *                 reportMessage() if you want different severity levels.
 *
 *   additional:   Add extra props here to show up in Sentry as additional unstructed data.
 *                 It is deprecated by Sentry so they prefer tags for structred data.
 *
 *   tags:         Add tags (key-value pairs) for easy search using structed data in Sentry.
 *                 Please use SENTRY_TAGS, and you want a new one, just append it to SENTRY_TAGS.
 *
 *   originalError: If applicable, pass in original error to keep the original stackTrace.
 */

export class ErrorAdditional extends Error {
  constructor({
    title,
    message,
    severity,
    additional = {},
    tags = {},
    originalError,
  }) {
    super(message)
    if (originalError) {
      this.stack = originalError.stack
    } else if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorAdditional)
    }

    this.name = title || originalError?.name || `ErrorAdditional`
    this.severity = severity
    this.additional = { ...additional, originalError: this.stringifyError(originalError) }
    this.tags = this.filterTags(tags)
  }

  stringifyError(error) {
    try {
      return JSON.stringify(error)
    } catch {
      return error?.message ?? `Could not get original error.`
    }
  }

  filterTags(tags) {
    const filteredTags = {}
    for (const [key, value] of Object.entries(tags)) {
      if (Object.values(SENTRY_TAGS).includes(key)) {
        filteredTags[key] = value
      }
    }
    return filteredTags
  }
}

