import React from 'react'

import { Link } from 'components/Link'
import { HtmlText } from 'components/HtmlText'

import styles from './FooterLinkSet.css'

export const FooterLinkSet = ({
  items,
  onClick,
}) => {
  return (
    <div className={styles.setWrapper}>
      <ul className={styles.setWrapper}>
        {items.map((route) => {
          const to = getPath(route)
          if (route.isPrivacyPolicy) {
            return (
              <li
                className={styles.item}
                key={route.label}
              >
                <a className="optanon-show-settings text-link--style-2">
                  <HtmlText html={route.label}/>
                </a>
              </li>
            )
          }
          if (route.isCustomLink) {
            return (
              <li
                className={styles.item}
                key={route.label}
              >
                <a
                  className={route.className}
                  id={route.id}
                >
                  <HtmlText html={route.label}/>
                </a>
              </li>
            )
          }
          return (
            <li
              className={styles.item}
              key={route.label}
            >
              <Link
                aria-label={route.accessibilityDesc || route.label}
                name={route.accessibilityDesc || route.label}
                onClick={route.onClick || (() => onClick(to))}
                rel={route.target === `_blank` ? `noreferrer` : route.rel}
                target={route.target}
                to={to}
              >
                <HtmlText
                  html={route.label}
                  noWrapper
                />
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )

  function getPath(route) {
    if (route.subItems) {
      return route.subItems[0].path
    }

    return route.path
  }
}
