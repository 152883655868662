import sha256 from 'crypto-js/sha256'
import { getMetaOptions } from 'global-content/config'

export default class Login {
  constructor() {
    this.handle = document.body
    this.logins = getMetaOptions(`logins`)
  }

  init() {
    const login = localStorage.getItem(`login`)
    let allowed = false

    if (login) {
      const now = new Date()
      const {
        expires,
      } = JSON.parse(login)
      // 24 Hour Cookie Setting
      if (expires > now) {
        allowed = true
      }
    }

    if (!this.logins || allowed || window.$PORTHOSTMAP) {
      return Promise.resolve()
    } else {
      this.disableScroll()
      this.buildShell()
      this.bindEvents()

      return new Promise((resolve) => {
        this.loginSuccess = resolve
      })
    }
  }

  buildShell() {
    this.handle.insertAdjacentHTML(`afterbegin`, `
      <div class="login">
        <div class="content">
          <div class="heading">Login</div>
          <input name="localised-username" class="username" type="text" placeholder="Username"/>
          <input name="localised-password" class="password" type="password" placeholder="Password"/>
          <div class="submit">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20">
              <path fill="#FFF" d="M26,8 L6.8,8 L11.42,3.38 C11.814,2.986 12,2.516 12,2 C12,1.016 11.187,0 10,0 C9.469,0 9.006,0.193 8.62,0.58 L0.662,8.538 C0.334,8.866 0,9.271 0,10 C0,10.729 0.279,11.08 0.646,11.447 L8.62,19.42 C9.006,19.807 9.469,20 10,20 C11.188,20 12,18.984 12,18 C12,17.484 11.814,17.014 11.42,16.62 L6.8,12 L26,12 C27.104,12 28,11.104 28,10 C28,8.896 27.104,8 26,8 Z" transform="rotate(180 14 10)"/>
            </svg>
          </div>
          <div class="watermark">Localised Inc</div>
        </div>
        <style>
          .password::placeholder {
            color: rgb(93, 105, 113);
          }

          .username::placeholder {
            color: rgb(93, 105, 113);
          }

          .error {
            color: #dc3440;
            letter-spacing: 0.8px;
            line-height: 1.4;
            margin-top: 20px;
            font-size: 1.4rem;
          }

          input {
            line-height: inherit;
            font-size: inherit;
          }
        </style>
      </div>
    `);

    [
      [`login`, `.login`, {
        position: `absolute`,
        top: `0px`,
        left: `0px`,
        right: `0px`,
        bottom: `0px`,
        'z-index': `100`,
        'line-height': `32px`,
        'font-size': `16px`,
        'font-family': `Helvetica Neue, Helvetica, Arial, sans-serif`,
        display: `flex`,
        'align-items': `center`,
        'justify-content': `center`,
        'background-color': `#1e2225`,
        'background-size': `cover`,
      }],
      [`content`, `.login > .content`, {
        width: `320px`,
        padding: `35px`,
        background: `transparent`,
        border: `1px solid #5D6971`,
        position: `relative`,
      }],
      [`heading`, `.login > .content > .heading`, {
        'font-size': `26px`,
        color: `#fff`,
        'letter-spacing': `0.2px`,
        'font-weight': `bold`,
      }],
      [`username`, `.login > .content > .username`, {
        border: `0px solid #fff`,
        'border-bottom': `1px solid #5D6971`,
        color: `white`,
        padding: `0px 10px 0 0`,
        'letter-spacing': `0.2px`,
        'margin-top': `25px`,
        width: `calc(100% - 20px)`,
      }],
      [`password`, `.login > .content > .password`, {
        border: `0px solid #fff`,
        'border-bottom': `1px solid #5D6971`,
        'font-size': `16px`,
        color: `white`,
        padding: `0px 10px 0 0`,
        'letter-spacing': `0.2px`,
        'margin-top': `30px`,
        width: `calc(100% - 20px)`,
      }],
      [`submit`, `.login > .content > .submit`, {
        'background-image': `linear-gradient(to top, #db2f41, #dd3a40, #de443f, #df4d3f, #e0553f)`,
        height: `40px`,
        display: `flex`,
        'align-items': `center`,
        'justify-content': `center`,
        padding: `12px`,
        cursor: `pointer`,
        'margin-top': `45px`,
        width: `40px`,
        'border-radius': `3px`,
      }],
      [`watermark`, `.login > .content > .watermark`, {
        position: `absolute`,
        bottom: `-26px`,
        right: `0px`,
        'font-size': `12px`,
        'letter-spacing': `0.2px`,
        color: `#5D6971`,
      }],
    ].forEach((d) => {
      this[d[0] + `Handle`] = document.querySelector(d[1])
      for (let i in d[2]) {
        this[d[0] + `Handle`].style[i] = d[2][i]
      }
    })
  }

  disableScroll() {
    document.body.style[`overflow-y`] = `hidden`
  }

  submit() {
    this.removeErrorNode()
    let match = false
    const userName = this.usernameHandle.value.toLowerCase()

    this.logins.forEach((d) => {
      if (d[0] === sha256(userName).toString().slice(0, 8) && d[1] === sha256(this.passwordHandle.value).toString().slice(0, 8)) {
        match = true
      }
    })
    if (match) {
      document.body.style[`overflow-y`] = `auto`
      this.loginHandle.remove()
      localStorage.setItem(`login`, JSON.stringify({
        name: userName,
        expires: new Date().getTime() + 86400000,
      }))

      this.loginSuccess()
    } else {
      const submitNode = document.querySelector(`.submit`)
      submitNode.insertAdjacentHTML(`afterend`, `<div class="error">Your username or password is incorrect. Please try again.</div>`)
    }
  }

  removeErrorNode() {
    const errorNode = document.querySelector(`.error`)

    if (errorNode) {
      errorNode.remove()
    }
  }

  bindEvents() {
    this.submitHandle.onclick = () => {
      this.submit()
    }

    this.usernameHandle.onfocus = () => {
      this.removeErrorNode()
    }

    this.passwordHandle.onfocus = () => {
      this.removeErrorNode()
    }

    this.usernameHandle.onkeydown = (e) => {
      e = e || window.event
      if (e.keyCode === 13) {
        this.submit()
      }
    }
    this.passwordHandle.onkeydown = (e) => {
      e = e || window.event
      if (e.keyCode === 13) {
        this.submit()
      }
    }
  }
}
