import React from 'react'

import { I18n } from 'utils/i18n'

import { webLabels } from './fixtures'
import styles from './MissingOption.css'

export const MissingOption = ({ level }) => {
  return (
    <div
      className={styles.container}
    >
      {I18n.t(webLabels.pleaseSelect, {
        replace: {
          option: I18n.t(webLabels[level.toLowerCase()]),
        },
      })}
    </div>
  )
}
