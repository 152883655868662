import { reportError } from 'services/reportError'
import { getActiveCampaigns } from 'state/actions/campaigns/index'
import { GET_CAMPAIGNS } from 'state/actions'

export const getCampaigns = language => async(dispatch) => {
  try {
    const payload = await getActiveCampaigns(language)

    if (payload) {
      dispatch({
        type: GET_CAMPAIGNS,
        payload,
      })

      // setTimeout breaks when it is a number larger than 2147483647
      // (almost 25 days in milliseconds for those that are curious)
      const MAX_TIMEOUT_VALUE = 2147483647
      const nextCheckIn = Math.min(...payload.campaigns.map(c => c.end)) - new Date()

      if (nextCheckIn < MAX_TIMEOUT_VALUE) {
        setTimeout(() => {
          dispatch(getCampaigns(language))
        }, nextCheckIn)
      }
    }

  // USE THIS TO HELP WITH DEBUGGING
  // setTimeout(() => {
  //   dispatch({
  //     type: GET_CAMPAIGNS,
  //     payload: {
  //       campaigns: [],
  //       language
  //     }
  //   })
  // }, 5000)
  } catch (e) {
    reportError(e)

    return Promise.resolve()
  }
}
