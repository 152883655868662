import React from 'react'

import { LazyModuleWrapper } from 'components/LazyModuleWrapper'

const LazyRedirect = (
  React.lazy(() => import(/* webpackChunkName: "Redirect" */ `./Redirect`))
)

export const Redirect = props => (
  <LazyModuleWrapper>
    <LazyRedirect {...props} />
  </LazyModuleWrapper>
)
