import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { favoriteAdd, favoriteRemove } from 'state/actions/favorites'
import { I18n } from 'utils/i18n'
import { updateGoogleAnalytics } from 'services/analytics'
import { IconAnimated } from 'components/IconAnimated'
import { Svg } from 'components/Svg'

import { webLabels } from './fixtures'
import styles from './FavoriteButton.css'

class FavoriteButtonClass extends React.Component {
  constructor() {
    super()
    this.state = {
      adding: false,
    }
  }

  exists() {
    const {
      favorites,
      variant,
    } = this.props
    const result = favorites.find(f => f.id === variant.id)

    return result
  }

  handleClick() {
    const {
      dispatch,
      variant,
    } = this.props
    if (this.exists()) {
      updateGoogleAnalytics(`removeFromFavorites`, { target: variant })
      dispatch(favoriteRemove(variant))
    } else {
      updateGoogleAnalytics(`addToFavorites`, { target: variant })
      dispatch(favoriteAdd(variant))
      this.setState({
        adding: true,
      })

      setTimeout(() => {
        this.setState({
          adding: false,
        })
      }, 500)
    }
  }

  renderHeart() {
    if (this.exists()) {
      return `heart`
    }

    return `heart-o`
  }

  renderIcon() {
    const {
      removeIcon,
      size,
    } = this.props

    if (removeIcon) {
      return (
        <Svg
          icon="close"
          size={size}
        />
      )
    }

    return (
      <IconAnimated
        animating={this.state.adding}
        animation="grow"
        icon={this.renderHeart()}
        size={size}
      />
    )
  }

  render() {
    const style = this.props.style || `default`
    const label = I18n.t(webLabels.label)

    return (
      <button
        aria-label={label}
        aria-pressed={this.exists() ? `true` : `false`}
        className={classNames(styles.button, styles[style])}
        name={label}
        onClick={() => this.handleClick()}
      >
        {this.renderIcon()}
      </button>
    )
  }
}

const mapStateToProps = state => ({
  favorites: state.favorites.all,
})

export const FavoriteButton = connect(mapStateToProps)(FavoriteButtonClass)
