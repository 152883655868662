import { getMetaOptions } from "global-content/config"
import { getLanguage } from 'utils'

export function addToHistory() {
  const {
    pathname,
    search,
  } = window.location

  window.$HISTORY.push(
    replaceLocale(pathname).concat(search).replace(`//`, `/`)
  )
}

export function getLastHistoryItem() {
  const lastItem = window.$HISTORY[window.$HISTORY.length - 1]

  if (lastItem) {
    const [pathname, search] = lastItem.split(`?`)

    return {
      pathname,
      search,
    }
  }
}

export function removeLastDirectoryFromPath(str = ``) {
  return str.split(`/`).filter(Boolean).slice(1).join(`/`)
}

export function replaceLocale(str = ``) {
  const countryFolder = getMetaOptions(`countryFolder`)
  const lng = getLanguage()

  return countryFolder
    // "/nl/en" and "/nl-en"
    ? str.replace(`${countryFolder}\/${lng}`, ``).replace(`${countryFolder}-${lng}`, ``)
    : `/${removeLastDirectoryFromPath(str)}`
}
