import React from 'react'
import classNames from 'classnames'

import { I18n } from 'utils/i18n'
import { getMetaOptions } from 'global-content/config'
import { getGlobalUtil, loadPhoneLibrary } from 'global-content/utils'
import { useIsMounted } from 'hooks/useIsMounted'
import { InputMessages } from 'components/InputMessages'
import { WithLabel } from 'components/WithLabel'
import { InputWrapper } from 'components/InputWrapper'
import { Svg } from 'components/Svg'

import { webLabels } from './fixtures'
import styles from 'components/InputPhoneOrEmail/InputPhoneOrEmail.css'

const UPDATE_VALUE = `UPDATE_VALUE`

export const InputPhone = ({
  id,
  label,
  tooltip,
  messages,
  onChange,
  placeholder,
  validationStatus,
  value,
  countryCodes,
  ...props
}) => {
  const isMounted = useIsMounted()
  const defaultCountryCode = getMetaOptions(`country.code`)
  const [formState, formDispatch] = React.useReducer(formReducer, {
    countryCodes: getCountryCodes(countryCodes),
    selectedCountryCode: defaultCountryCode,
    value: value || ``,
  })

  React.useEffect(() => {
    loadPhoneDependencies(formState, formDispatch, value, isMounted)
  }, [])

  return (
    <WithLabel
      htmlFor={id}
      label={label}
      tooltip={tooltip}
    >
      <div
        className={classNames(styles.inputsWrapper, styles.isPhone)}
        dir="ltr"
      >
        <InputWrapper
          className={classNames(styles.areaSelector, {
            [styles.valid]: validationStatus === `valid`,
            [styles.invalid]: validationStatus === `invalid`,
          })}
          dir="ltr"
          prefix={(
            <div className={styles.prefix}>
              <Svg
                block
                icon="smartphone"
              />
            </div>
          )}
          type="select"
        >
          <div className={styles.wrapper}>
            <select
              aria-label={I18n.t(webLabels.countrySelect)}
              autoComplete="tel-country-code"
              className={styles.realSelect}
              dir="ltr"
              id="countryCode"
              name="country-code"
              onChange={handleCountryCodeChange}
              value={formState.selectedCountryCode}
            >
              {formState.countryCodes.map(countryCode => (
                <option
                  key={countryCode.region}
                  value={countryCode.region}
                >
                  {`${countryCode.name} +${countryCode.code}`}
                </option>
              ))}
            </select>
            <select
              aria-hidden="true"
              className={styles.decorativeSelect}
              dir="ltr"
              readOnly
              tabIndex={-1}
            >
              <option>
                {(
                  getGlobalUtil(`phoneLibrary`) &&
                  `+${getGlobalUtil(`phoneLibrary`).getCountryCodeForRegionCode(formState.selectedCountryCode)}`
                )}
              </option>
            </select>
          </div>
        </InputWrapper>

        <InputWrapper
          dir="ltr"
          type="tel"
          validationStatus={validationStatus}
        >
          <input
            autoComplete="tel"
            id={id}
            onChange={handlePrimaryChange}
            placeholder={getPlaceholder()}
            type="tel"
            value={formState.value}
            {...props}
          />
        </InputWrapper>
      </div>
      <InputMessages
        id={id}
        messages={messages}
      />
    </WithLabel>
  )

  function handleCountryCodeChange(e) {
    formDispatch({
      type: UPDATE_VALUE,
      payload: {
        selectedCountryCode: e.target.value,
      },
    })

    const fullValue = getFullValue(formState.value, e.target.value)
    onChange(fullValue)
  }

  function handlePrimaryChange(e) {
    formDispatch({
      type: UPDATE_VALUE,
      payload: {
        value: e.target.value,
      },
    })

    const fullValue = getFullValue(e.target.value, formState.selectedCountryCode)
    onChange(fullValue)
  }

  function getPlaceholder() {
    const PhoneClass = getGlobalUtil(`phoneLibrary`)

    if (PhoneClass && formState.selectedCountryCode && !placeholder) {
      return PhoneClass.getExample(formState.selectedCountryCode, `mobile`).getNumber(`national`)
    }
  }
}

function formReducer(state, action) {
  switch (action.type) {
  case UPDATE_VALUE:
    return {
      ...state,
      ...action.payload,
    }
  default:
    return state
  }
}

async function loadPhoneDependencies(formState, formDispatch, initialValue, isMounted) {
  let countryCodes = [...formState.countryCodes]

  if (!getGlobalUtil(`phoneLibrary`)) {
    await loadPhoneLibrary()
    countryCodes = getGlobalUtil(`countryCodes`)
  }

  if (isMounted.current) {
    formDispatch({
      type: UPDATE_VALUE,
      payload: {
        countryCodes,
        ...getInitialValues(formState, initialValue),
      },
    })
  }
}

function getFullValue(value, countryCode) {
  const PhoneClass = getGlobalUtil(`phoneLibrary`)

  if (PhoneClass) {
    const pn = new PhoneClass(value.replace(/\+/, ``), countryCode)
    const formatted = pn.getNumber(`e164`)

    if (formatted && pn.isValid()) {
      value = formatted
    }
  }

  return value
}

function getInitialValues(formState, initialValue) {
  const PhoneClass = getGlobalUtil(`phoneLibrary`)

  if (initialValue && PhoneClass) {
    const pn = new PhoneClass(initialValue)

    return {
      selectedCountryCode: pn.getRegionCode() || formState.selectedCountryCode,
      value: pn.getNumber(`national`) || formState.value,
    }
  }

  return {
    selectedCountryCode: formState.selectedCountryCode,
    value: formState.value,
  }
}

function getCountryCodes(countryCodes) {
  const allCodes = [...getGlobalUtil(`countryCodes`)]

  return countryCodes
    ? allCodes.filter(({ region }) =>
      countryCodes.some(code => code.toUpperCase() === region)
    )
    : allCodes
}
