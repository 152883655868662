import cloneDeep from 'lodash/cloneDeep'
import { getSiteName } from 'utils/i18n'
import queryString from 'query-string'
import { getMetaOptions } from 'global-content/config'
import { matchPath } from 'react-router-dom'

export const generateSeoMetadata = ({
  description,
  headline,
  product,
  search,
  activeLanguage,
  attributes = [`description`, `title`],
  customMetadata,
}) => {
  const metadataClone = cloneDeep(window.$content.seoData[activeLanguage])

  /**
   * Note that "siteName" and title/description definition of "/" page will no longer be used
   * To be removed from lithos-content.  Reason - we need consistent content in pre-render.
   */
  const keyValues = {
    headline,
    description: description && description.trim().length > 0 ? description : (metadataClone.template.seoDescription || ``),
    brandName: product.brand?.name || ``,
    productName: product.name || ``,
    productDescription: extractProductDescription(product?.description) || product.name,
    query: queryString.parse(search).query,
    suffix: metadataClone.template.suffix || ``,
    promo: metadataClone.template.promo || ``,
    siteCountry: getMetaOptions(`territory.name`),

    // todo: adding template overrides b/c CONFIG and META are not multi-lingual
    siteName: metadataClone.template.seoTitle || metadataClone.template.siteName || getSiteName(),
    language: metadataClone.template.language || ``,
    country: metadataClone.template.country || ``,
  }

  const metadata = customMetadata || templateSelector(metadataClone) || {}

  attributes.forEach(attribute => {
    Object.entries(keyValues).forEach(([key, value]) => {
      if (metadata[attribute] && metadata[attribute].replace) {
      // if (metadata[attribute]) {
        metadata[attribute] = (
          metadata[attribute]
            .replace(`{{${key}}}`, value)
        )
      }
    })
  })

  if (product && product.options && product.options.length) {
    const queryValues = Object.values(queryString.parse(search))

    const {
      large = [],
      standard = [],
    } = product.options.find((option) => {
      return queryValues.includes(option.slug)
    })?.media || {}

    let image = standard[0] || large[0]

    metadata.image = image
  }

  return metadata
}

function templateSelector(metadata) {
  const metadataRouter = metadata.routes || {}
  const metadataPages = metadata.pages || {}
  const pathnameWithoutPrefix = `/${window.location.pathname.split(`/`)
    .slice(getMetaOptions(`countryFolder`) ? 3 : 2)
    .join(`/`)}`

  // Special handling for homepage
  if (metadata?.template?.seoTitle && pathnameWithoutPrefix === `/`) {
    return {
      title: metadata?.template?.seoTitle,
      description: metadata?.template?.seoDescription || metadata?.template?.seoTitle,
    }
  }

  // Check for specific page metadata
  if (metadataPages[pathnameWithoutPrefix]) {
    return metadataPages[pathnameWithoutPrefix]
  }

  return (
    Object.keys(metadataRouter).map(route => ({
      ...metadataRouter[route],
      match: route,
    }))
      .sort((a, b) => {
        if (a.index > b.index) return 1
        if (a.index < b.index) return -1
        return 0
      })
      .find(key => (
        matchPath(pathnameWithoutPrefix, {
          path: key.match,
          exact: true,
          strict: false,
        })
      ))
  )
}

export function extractProductDescription(description) {
  if (description) {
    let temp = document.createElement(`div`)
    temp.style.display = `none`
    temp.insertAdjacentHTML(`afterbegin`, description)
    const match = temp.querySelector(`.seo-description`)?.innerText?.trim()
    return match?.length ? match : undefined
  }
}