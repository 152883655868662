import React from 'react'
import { unversionedStorage } from 'utils/storage'

export function useLocalStorageChange(localStorageKey, defaultValue) {
  const [value, setValue] = React.useState(getStateValue())
  const evt = `storage`

  React.useEffect(init, [])
  return value

  function cleanup() {
    window.removeEventListener(evt, handleChange)
  }

  function getStateValue() {
    return unversionedStorage.get(localStorageKey) || defaultValue
  }

  function handleChange(e) {
    if (e?.key === localStorageKey) {
      setValue(getStateValue())
    }
  }

  function init() {
    window.addEventListener(evt, handleChange)
    return cleanup
  }
}