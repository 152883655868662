import React from 'react'

import { I18n } from 'utils/i18n'
import { Layout } from 'components/Layout'
import { ModalUpdate } from 'components/ModalUpdate'
import { PageBody } from 'components/PageBody'
import { Heading } from 'components/Heading'
import { HtmlText } from 'components/HtmlText'
import { RefreshButton } from 'components/RefreshButton'
import { reportError } from 'services/reportError'
import { getMetaOptions } from 'global-content/config'

import { webLabels } from './fixtures'
import styles from './ScaffoldError.css'

export const ScaffoldError = ({ error }) => {
  const [hasUpdate, setHasUpdate] = React.useState(false)
  const [buildRef, setBuildRef] = React.useState()
  const [checkedForUpdate, setCheckedForUpdate] = React.useState(false)

  React.useEffect(() => {
    fetch(`${getMetaOptions(`countryFolder`)}/?checkForUpdate=${Math.random()}`) // so it doesn't use a cached version
      .then(response => response.text())
      .then(data => {
        if (data.includes(`window.$BUILDDATE='`)) {
          const latestBuild = data.split(`window.$BUILDDATE='`)[1].slice(0, window.$BUILDDATE.length)

          if (latestBuild !== window.$BUILDDATE) {
            setBuildRef(latestBuild)
            return setHasUpdate(true)
          }

          reportError(error)
        }
      })
      .finally(() => {
        setCheckedForUpdate(true)
      })
  }, [])

  if (!checkedForUpdate) {
    return (
      <PageBody loading />
    )
  }

  if (hasUpdate) {
    return (
      <>
        <PageBody />
        <ModalUpdate
          error={error}
          retryRef={buildRef}
        />
      </>
    )
  }

  return (
    <div className={styles.scaffoldError}>
      <Layout
        className={styles.scaffoldContainer}
        data-testid="scaffoldError"
        layout={{
          container: `clientDefault`,
        }}
      >
        <Layout
          layout={{
            marginBottom: `xxxs`,
          }}
        >
          <Heading
            content={{
              html: I18n.t(webLabels.title),
            }}
            styling={{
              fontFamily: `primary`,
              fontSize: `large`,
              universalColor: true,
            }}
          />
        </Layout>
        <Layout
          layout={{
            marginBottom: `xs`,
          }}
        >
          <HtmlText html={I18n.t(webLabels.description)} />
        </Layout>
        <RefreshButton />
      </Layout>
    </div>
  )
}
