import React from 'react'

import { LazyModuleWrapper } from 'components/LazyModuleWrapper'

const LazyRecreateCart = (
  React.lazy(() => import(/* webpackChunkName: "RecreateCart" */ `./RecreateCart`))
)

export const RecreateCart = props => (
  <LazyModuleWrapper>
    <LazyRecreateCart {...props} />
  </LazyModuleWrapper>
)
