import React from 'react'

import { Layout } from 'components/Layout'
import { Heading } from 'components/Heading'

export const FiltersCategoryHeading = ({ html }) => (
  <Layout
    layout={{
      marginBottom: `xxs`,
    }}
  >
    <Heading
      content={{
        html,
      }}
      styling={{
        fontFamily: `secondary`,
        fontSize: `xs`,
        universalColor: true,
      }}
    />
  </Layout>
)
