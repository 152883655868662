import algoliasearch from 'algoliasearch/lite'
import algoliarecommend from '@algolia/recommend'
import { getConfigOptions, getMetaOptions } from 'global-content/config'

export function setAlgoliaIndices() {
  const integrations = getMetaOptions(`integrations`)
  const {
    account,
    key,
    productIndicesPrefix,
    facetIndicesPrefix,
  } = integrations.algolia

  const algoliaClient = algoliasearch(account, key, { protocol: `https:` })

  window.$content.algolia = assembleIndices({
    algoliaClient,
    facetIndicesPrefix,
    productIndicesPrefix,
  })

  const algoliaRecommendClient = algoliarecommend(account, key)

  window.$content.algoliarecommend = {
    frequentlyBoughtTogether: algoliaRecommendClient.getFrequentlyBoughtTogether,
    relatedProducts: algoliaRecommendClient.getRelatedProducts,
    recommendations: algoliaRecommendClient.getRecommendations,
  }
}

function assembleIndices({
  algoliaClient,
  facetIndicesPrefix,
  productIndicesPrefix,
}) {
  const clientTag = getMetaOptions(`clientTag`)
  const algoliaIndexSuffix = getMetaOptions(`catalog.algoliaIndexSuffix`)

  const indices = {}
  indices.filtersIndex = algoliaClient.initIndex(facetIndicesPrefix)
  indices.querySuggestions = algoliaClient.initIndex(`${productIndicesPrefix}-${clientTag}${algoliaIndexSuffix ? `-${algoliaIndexSuffix}` : ``}-qs`)

  getConfigOptions(`sortOptions`).forEach(({
    index,
    value,
  }) => {
    const indexName = getIndexName(index)

    indices[value] = algoliaClient.initIndex(indexName)
  })

  return indices
}

export function getIndexName(index = ``) {
  const productIndicesPrefix = getMetaOptions(`integrations.algolia.productIndicesPrefix`)
  const clientTag = getMetaOptions(`clientTag`)
  const pricingStrategy = getMetaOptions(`catalog.pricingStrategy`)
  const countryCode = getMetaOptions(`country.code`)
  const algoliaIndexSuffix = getMetaOptions(`catalog.algoliaIndexSuffix`)

  let tag = clientTag

  if (pricingStrategy === `FIXED_PRICE` && index.includes(`price`)) {
    tag = `${clientTag}-${countryCode}`
  } else if (index === `` && algoliaIndexSuffix) {
    return `${productIndicesPrefix}-${tag}-${algoliaIndexSuffix}`
  }

  return `${productIndicesPrefix}-${tag}${index ? `-${index}` : ``}`
}

export function getAlgoliaIndex(selectedSort) {
  return window.$content.algolia[selectedSort]
}

export function getAlgoliaRecommend(type) {
  return window.$content.algoliarecommend[type]
}
