import React from 'react'
import { Factory } from 'components/Factory'

export const Stack = ({
  items = [],
  tag = `div`,
}) => {
  const Tag = tag
  return (
    <Tag
      style={{
        position: `relative`,
      }}
    >
      {items.map((item, index) => (
        index !== 0 ? (
          <div
            key={item.id}
            style={{
              height: `100%`,
              position: `absolute`,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: index,
            }}
          >
            <Factory
              items={[item]}
            />
          </div>
        ) : (
          <Factory
            items={[item]}
            key={item.id}
          />
        )
      ))}
    </Tag>
  )
}
