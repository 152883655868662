// See docs on levels: https://docs.sentry.io/platforms/javascript/usage/set-level/
export const SENTRY_LEVELS = {
  DEBUG: `debug`,
  INFO: `info`,
  LOG: `log`,
  WARNING: `warning`,
  ERROR: `error`,
  FATAL: `fatal`,
}

export function reportMessage(message, options) {
  const {
    extra = {},
    logLevel = SENTRY_LEVELS.INFO,
  } = options
  console.log(message, options)

  return window.Sentry?.withScope(scope => {
    for (const [key, value] of Object.entries(extra)) {
      scope.setExtra(key, value)
    }
    window.Sentry.captureMessage(message, logLevel)
  })
}
