import { currencyFormatted } from 'utils'
import { I18n } from 'utils/i18n'
import { typeAndAmountInterpolater } from './amount-interpolation'
import { webLabels } from './fixtures'

export function normalPromotion({
  conditions = {},
  discount,
  language,
}) {
  const {
    itemQuantity,
    basketTotal,
    shippingMethod,
  } = conditions

  const {
    amount,
    component,
    type,
  } = discount

  const isDiscountFree = amount === 0 && type === `VALUE_OVERRIDE`
  const isBundledDiscount = component === `ITEM` && type === `VALUE_OVERRIDE` && itemQuantity
  const hasConditions = Boolean(basketTotal?.min || isBundledDiscount)
  const discountString = typeAndAmountInterpolater({ amount, language, type })
  const minString = getMin()
  const bundledDiscount = getBundledDiscount()

  // return as an array so consistent with tier promotion
  return [{
    discount: discountString,
    min: minString,
    content: I18n.t(getWebLabel(), {
      language,
      replace: {
        bundledDiscount,
        discount: discountString,
        min: minString,
      },
    }),
  }]

  function getMin() {
    if (itemQuantity) {
      return itemQuantity.min || itemQuantity.eq
    }

    if (basketTotal) {
      return currencyFormatted(basketTotal.min)
    }
  }

  function getBundledDiscount() {
    if (isBundledDiscount) {
      const quantity = itemQuantity?.min || itemQuantity?.eq

      return currencyFormatted(quantity * amount)
    }
  }

  function getWebLabel() {
    // DISABLED WHILST CONFIRM MESSAGEID USE
    // if (messageId && messageId !== webLabels.EMPTY) {
    //   return messageId
    // }

    if (component === `SHIPPING`) {
      if (isDiscountFree) {
        return webLabels.FREE.SHIPPING[shippingMethod]
      }

      return webLabels.SHIPPING[shippingMethod]
    }

    if (isDiscountFree) {
      return webLabels.FREE[component]
    }

    if (hasConditions) {
      if (basketTotal?.min) {
        return webLabels.CONDITIONS[component].BASKET_MIN
      }

      if (itemQuantity?.min) {
        return webLabels.CONDITIONS[component].ITEM_MIN
      }

      if (itemQuantity?.eq) {
        return webLabels.CONDITIONS[component].ITEM_EQ
      }
    }

    return webLabels[component]
  }
}
