import React from 'react'
import { withRouter } from 'react-router-dom'

import { ErrorAdditional, SENTRY_TAGS } from 'utils/ErrorAdditional'

class ErrorBoundaryClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: undefined,
      hasError: false,
    }
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    }
  }

  componentDidCatch(error) {
    const {
      errorTitle = `Error Boundary`,
      severity,
    } = this.props

    const modifiedErr = new ErrorAdditional({
      title: `${errorTitle} - ${error.name}`,
      message: error.message,
      severity,
      additional: error.additional,
      originalError: error,
      tags: {
        [SENTRY_TAGS.SOURCE_FILE]: `src/components/ErrorBoundary/ErrorBoundary.jsx`,
      },
    })

    this.setState({
      error: modifiedErr,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        hasError: false,
      })
    }
  }

  // https://github.com/facebook/react/issues/15069
  // If we add this we will be duplicating our error reporting
  // waiting for React to update this

  render() {
    const {
      children,
      errorComponent: ErrorComponent,
    } = this.props

    const {
      error,
      hasError,
    } = this.state

    if (hasError) {
      return (
        <ErrorComponent error={error} />
      )
    }

    return children
  }
}

export const ErrorBoundary = withRouter(ErrorBoundaryClass)
