import React from 'react'
import { useSelector } from 'react-redux'

import { getSourceFile } from 'utils/getSourceFile'
import { CustomPage } from 'pages/CustomPage'

export const Homepage = () => {
  const activeLanguage = useSelector(state => state.language.active)
  const source = getSourceFile(`homepage`, activeLanguage)
  const folder = `/data`

  React.useLayoutEffect(() => {
    history.scrollRestoration = `manual`
    scrollTo(0, 0)

    return () => {
      history.scrollRestoration = `auto`
    }
  }, [])

  return (
    <CustomPage
      folder={folder}
      source={source}
    />
  )
}
