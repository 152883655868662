
import { getLevelDown } from 'utils'

export function generateNavTreePaths(navTree) {
  return {
    l1: generateEntry(navTree, `l1`, []),
  }
}

function generateEntry(entry, level, parents) {
  const levelDown = getLevelDown(level)
  let build = {}

  Object.entries(entry[level]).forEach(([key, value]) => {
    if (value[levelDown]) {
      build[key] = {
        path: generatePath(key, parents),
        ...value,
        [levelDown]: generateEntry(value, levelDown, [...parents, key]),
      }
    } else {
      build[key] = {
        path: generatePath(key, parents),
        ...value,
      }
    }
  })

  return build
}

function generatePath(key, parents = []) {
  if (parents.length) {
    return `/${parents.join(`/`)}/${key}`
  }

  return `/${key}`
}
