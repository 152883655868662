"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.CountrySelect = exports.LOCALSTORAGE_COUNTRY_KEY = void 0;
var react_1 = __importStar(require("react"));
var contexts_1 = require("./contexts");
var content_1 = require("../../services/content");
var config_1 = require("global-content/config");
var i18n_1 = require("utils/i18n");
var react_redux_1 = require("react-redux");
var ModalCountrySelect_1 = require("../../templates/ModalCountrySelect");
var storage_1 = require("utils/storage");
exports.LOCALSTORAGE_COUNTRY_KEY = "lastCountrySelection";
function CountrySelect(_a) {
    var children = _a.children;
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.site.detectedCountry; }), countryIsLoading = _b.isLoading, countryIsError = _b.isError, detectedCountry = _b.countryCode;
    var detectedCountryCode = detectedCountry === null || detectedCountry === void 0 ? void 0 : detectedCountry.toLowerCase();
    var _c = (0, react_1.useState)(false), isModalOpen = _c[0], setIsModalOpen = _c[1];
    var _d = (0, react_1.useState)(), selectedLanguage = _d[0], setSelectedLanguage = _d[1];
    var _e = (0, react_1.useState)(), selectedCountry = _e[0], setSelectedCountry = _e[1];
    var _f = (0, react_1.useState)(false), isPopoverOpen = _f[0], setIsPopoverOpen = _f[1];
    var _g = (0, react_1.useState)({
        countryOptions: [],
        defaultCountry: null,
        defaultLanguage: null,
        proposedCountry: null
    }), defaultOptions = _g[0], setDefaultOptions = _g[1];
    (0, react_1.useEffect)(function () {
        var isEnabled = (0, config_1.getConfigOptions)("countryNavigation.isEnabled");
        if (isEnabled) {
            content_1.Content.getCountryNavigation().then(function (countryOptionsArray) {
                var defaultCountryCode = (0, i18n_1.getCountry)().toLowerCase();
                var defaultCountryOption = countryOptionsArray.find(function (country) {
                    return country.code === defaultCountryCode;
                });
                var defaultLanguageOption = defaultCountryOption === null || defaultCountryOption === void 0 ? void 0 : defaultCountryOption.languages.find(function (language) { return language.code === detectedCountryCode; });
                var defaultProposedCountry = countryOptionsArray.find(function (country) {
                    return country.value === detectedCountryCode;
                }) || null;
                setDefaultOptions({
                    countryOptions: countryOptionsArray,
                    defaultCountry: defaultCountryOption,
                    defaultLanguage: defaultLanguageOption,
                    proposedCountry: defaultProposedCountry
                });
                setSelectedCountry(defaultCountryOption);
                setSelectedLanguage(defaultLanguageOption);
            });
        }
    }, []);
    (0, react_1.useEffect)(function () {
        var _a;
        if (shouldSetPopover()) {
            var siteCountryCode = (_a = (0, config_1.getMetaOptions)("country.code")) === null || _a === void 0 ? void 0 : _a.toLowerCase();
            setIsPopoverOpen(
            // Check if the current site do not match the IP location
            siteCountryCode !== detectedCountryCode
                // Check if the current site matches local storage settings
                && siteCountryCode !== storage_1.unversionedStorage.get(exports.LOCALSTORAGE_COUNTRY_KEY));
        }
    }, [detectedCountryCode, countryIsError, countryIsLoading]);
    function shouldSetPopover() {
        var _a;
        var isEnabled = (0, config_1.getConfigOptions)("countryNavigation.isEnabled");
        var siteCountryCode = (_a = (0, config_1.getMetaOptions)("country.code")) === null || _a === void 0 ? void 0 : _a.toLowerCase();
        return isEnabled && !countryIsError && !countryIsLoading && siteCountryCode;
    }
    function openModal() {
        setIsPopoverOpen(false);
        setIsModalOpen(true);
    }
    function openPopover() {
        setIsModalOpen(false);
        setIsPopoverOpen(true);
    }
    function closeModal() {
        setIsModalOpen(false);
    }
    function closePopover() {
        setIsPopoverOpen(false);
    }
    function selectInitialCountry(countryCode) {
        var country = defaultOptions.countryOptions.find(function (countryOption) { return countryOption.value === countryCode; });
        if (country) {
            setSelectedCountry(country);
            setSelectedLanguage(country.languages[0]);
        }
    }
    function confirmCountry(countryCode) {
        storage_1.unversionedStorage.set(exports.LOCALSTORAGE_COUNTRY_KEY, countryCode);
    }
    function selectLanguage(language) {
        setSelectedLanguage(language);
    }
    var valueState = (0, react_1.useMemo)(function () { return ({
        closeModal: closeModal,
        closePopover: closePopover,
        confirmCountry: confirmCountry,
        countryOptions: defaultOptions.countryOptions,
        defaultCountry: defaultOptions.defaultCountry,
        defaultLanguage: defaultOptions.defaultLanguage,
        isModalOpen: isModalOpen,
        isPopoverOpen: isPopoverOpen,
        openModal: openModal,
        openPopover: openPopover,
        proposedCountry: defaultOptions.proposedCountry,
        selectInitialCountry: selectInitialCountry,
        selectLanguage: selectLanguage,
        selectedCountry: selectedCountry,
        selectedLanguage: selectedLanguage
    }); }, [
        defaultOptions,
        isModalOpen,
        isPopoverOpen,
        selectedCountry,
        selectedLanguage,
    ]);
    return (react_1["default"].createElement(contexts_1.CountrySelectContext.Provider, { value: valueState },
        children,
        react_1["default"].createElement(ModalCountrySelect_1.ModalCountrySelect, null)));
}
exports.CountrySelect = CountrySelect;
