import React from 'react'
import classNames from 'classnames'

import { I18n } from 'utils/i18n'
import { Svg } from 'components/Svg'
import { InputWrapper } from 'components/InputWrapper'
import { InputCountrySelect } from 'components/InputCountrySelect'
import { InputCode } from 'components/InputCode'
import { InputMessages } from 'components/InputMessages'
import { WithLabel } from 'components/WithLabel'
import { InputPhone } from 'components/InputPhone'

import { webLabels } from './fixtures'
import styles from './InputWithLabel.css'

export const InputWithLabel = React.forwardRef(function InputWithLabel({
  dir,
  hasFocus,
  id,
  inputWrapperClassName,
  label,
  messages,
  prefix,
  readOnly,
  render,
  size,
  suffix,
  tooltip,
  type,
  validationStatus,
  visibilityToggle,
  ...props
}, ref) {
  const remapTypeMap = {
    cardExpiryYear: `tel`,
    cardExpiryMonth: `tel`,
  }

  const componentMap = {
    cardExpiryYear: TelInput,
    cardExpiryMonth: TelInput,
    code: InputCode,
    country: InputCountrySelect,
    email: EmailInput,
    file: FileInput,
    number: NumberInput,
    password: PasswordInput,
    phone: InputPhone,
    select: SelectInput,
    text: TextInput,
    textarea: TextArea,
    tel: TelInput,
  }

  const [inputType, setInputType] = React.useState(remapTypeMap[type] || type)
  const Component = componentMap[inputType]
  const visible = inputType !== `password`
  const isSelect = [`select`, `country`].includes(type)

  return (
    <WithLabel
      htmlFor={id}
      label={label}
      size={size}
      tooltip={tooltip}
    >
      <InputWrapper
        className={inputWrapperClassName}
        dir={dir}
        hasFocus={hasFocus}
        prefix={prefix}
        readOnly={readOnly}
        size={size}
        suffix={renderSuffix()}
        suffixClassname={isSelect && styles.selectSuffix}
        type={inputType}
        validationStatus={validationStatus}
      >
        {renderComponent()}
      </InputWrapper>
      <InputMessages
        id={id}
        messages={messages}
      />
    </WithLabel>
  )

  function renderComponent() {
    if (render) {
      return render
    }

    const ariaInvalid = validationStatus === `invalid`

    return (
      <Component
        {...props}
        aria-describedby={ariaInvalid ? `input-messages-${id}` : undefined}
        aria-invalid={ariaInvalid}
        id={id}
        readOnly={readOnly}
        ref={ref}
      />
    )
  }

  function renderSuffix() {
    if (suffix || visibilityToggle || isSelect) {
      return (
        <>
          {suffix}
          {visibilityToggle && renderVisibilityToggle()}
          {isSelect &&
            (
              <Svg
                block
                icon="angle_down"
              />
            )
          }
        </>
      )
    }
  }

  function renderVisibilityToggle() {
    return (
      <button
        className={classNames(styles.visibilityToggle, {
          [styles.hideInput]: visible,
        })}
        data-testid=""
        onClick={e => {
          e.preventDefault()
          setInputType(inputType === `password` ? `text` : `password`)
        }}
        title={I18n.t(visible ? webLabels.hidePassword : webLabels.showPassword)}
        type="button"
      >
        <Svg
          block
          icon="view"
        />
      </button>
    )
  }
})

const PasswordInput = React.forwardRef(function PasswordInput(props, ref) {
  return (
    <input
      {...props}
      ref={ref}
      type="password"
    />
  )
})

const TextInput = React.forwardRef(function TextInput(props, ref) {
  return (
    <input
      {...props}
      ref={ref}
      type="text"
    />
  )
})

const TextArea = React.forwardRef(function TextArea(props, ref) {
  return (
    <textarea
      {...props}
      ref={ref}
      type="textarea"
    />
  )
})

const TelInput = React.forwardRef(function TelInput(props, ref) {
  return (
    <input
      {...props}
      ref={ref}
      type="tel"
    />
  )
})

const NumberInput = React.forwardRef(function NumberInput(props, ref) {
  return (
    <input
      {...props}
      ref={ref}
      type="number"
    />
  )
})

const EmailInput = React.forwardRef(function EmailInput(props, ref) {
  return (
    <input
      {...props}
      ref={ref}
      type="email"
    />
  )
})

const SelectInput = React.forwardRef(function SelectInput({
  options,
  ...rest
}, ref) {
  return (
    <select
      ref={ref}
      {...rest}
    >
      {options.map(option => {
        return (
          <option
            key={option.value}
            value={option.value}
          >
            {option.label}
          </option>
        )
      })}
    </select>
  )
})

const FileInput = React.forwardRef(function FileInput(props, ref) {
  return (
    <input
      {...props}
      accept=".gif, .jpg, .jpeg, .png, .pdf"
      multiple
      ref={ref}
      type="file"
    />
  )
})
