import React from 'react'

import { Svg } from 'components/Svg'
import { Image } from 'components/Image'
import { Link } from 'components/Link'
import { Heading } from 'components/Heading'

import styles from './CategoryCard.css'

export const CategoryCard = ({
  accessibilityDesc,
  label,
  path,
  image,
  categories,
}) => (
  <div className={styles.categoryCard}>
    <Link
      aria-label={accessibilityDesc || label}
      className={styles.imageWrapper}
      name={accessibilityDesc || label}
      to={path}
    >
      <Image
        alt={label}
        interactive
        src={image}
      />
    </Link>
    <Link
      aria-label={accessibilityDesc || label}
      name={accessibilityDesc || label}
      to={path}
    >
      <Heading
        content={{
          html: label,
        }}
        styling={{
          fontSize: `xs`,
        }}
      />
    </Link>
    <ul>
      {categories.map(item => (
        <li
          className={styles.item}
          key={item.path}
        >
          <Link
            aria-label={item.accessibilityDesc || item.label}
            name={item.accessibilityDesc || item.label}
            to={item.path}
          >
            {item.label}
            <Svg icon="angle_right" />
          </Link>
        </li>
      ))}
    </ul>
  </div>
)
