import React from 'react'
import { Link as InflexibleReactRouterLink, useHistory } from 'react-router-dom'
import classNames from 'classnames'

import { addToHistory } from 'utils/history-logger'
import { scrollToElement } from 'utils/scrollToElement'
import { moveFocus } from 'utils/moveFocus'
import { MAIN_ID } from 'components/Scaffold/fixtures'

export const Link = React.forwardRef(function Link(_props, ref) {
  const {
    to,
    children,
    className,
    disabled,
    onClick,
    ...args
  } = _props
  const history = useHistory()
  const props = {
    className: classNames({ [className]: className }),
    onClick: handleClick,
    ref,
    ...args,
  }

  if (!to || disabled) {
    return (
      <span {...props}>
        {children}
      </span>
    )
  }

  if (to.substring(0, 1) === `#`) {
    return (
      <a
        {...props}
        href={to}
      >
        {children}
      </a>
    )
  }

  if (to.indexOf(`//`) > -1 || to.indexOf(`mailto:`) > -1) {
    return (
      <a
        {...props}
        href={to}
      >
        {children}
      </a>
    )
  }

  return (
    <InflexibleReactRouterLink
      {...props}
      to={to}
    >
      {children}
    </InflexibleReactRouterLink>
  )

  function handleClick(e) {
    if (disabled) {
      return
    }

    if (_props.target && _props.target === `_blank`) {
      // If opening up a new tab, just call the onClick function that was passed in as a prop.
      onClick && onClick(e)
      return
    }

    addToHistory()
    moveFocus(document.getElementById(MAIN_ID))

    if (to.substring(0, 1) === `#`) {
      e.preventDefault()
      const elem = document.querySelector(to)

      if (elem instanceof HTMLElement) {
        history.push(to)
        scrollToElement(elem)
      }
    }

    // Call original onClick function if exist
    onClick && onClick(e)
  }
})

