
import React from 'react'
import { ProductAccordionItem } from './ProductAccordionItem'
import styles from './ProductAccordion.css'

export const ProductAccordion = ({
  data,
  items,
}) => {
  const {
    config = {},
    content = {},
  } = data

  return (
    <div
      className={styles.accordion}
      role="tablist"
    >
      {items.map((item, index) => (
        <ProductAccordionItem
          isOpen={config.openIndex === index}
          item={item}
          key={content.sections?.[index]?.title}
          title={content.sections?.[index]?.title}
        />
      ))}
    </div>
  )
}
