import React from 'react'
import classNames from 'classnames'

import { Svg } from 'components/Svg'

import styles from './InputValidState.css'

export const InputValidState = ({ validationStatus }) => {
  const iconMap = {
    valid: `ok`,
    invalid: `close`,
  }

  return (
    <div
      className={classNames({
        [styles.valid]: validationStatus === `valid`,
        [styles.invalid]: validationStatus === `invalid`,
      })}
    >
      <Svg
        block
        color="white"
        icon={iconMap[validationStatus]}
      />
    </div>
  )
}
