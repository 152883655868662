"use strict";
exports.__esModule = true;
exports.useEmitViewFilterEvent = void 0;
var events_1 = require("../../../services/events");
var useEmitViewFilterEvent = function () {
    return function (_a) {
        var filterName = _a.filterName;
        events_1.Events.emitViewFilter({
            filterName: filterName
        });
    };
};
exports.useEmitViewFilterEvent = useEmitViewFilterEvent;
