import React from 'react'

import { loadContent } from 'services/content'
import { Script } from 'factory-components/Script'

export const ExternalScripts = () => {
  const [externalScripts, setExternalScripts] = React.useState([])

  React.useEffect(() => {
    loadExternalScripts().then(scripts => {
      if (scripts) {
        setExternalScripts(scripts)
      }
    })
  }, [])

  if (externalScripts.length) {
    return (
      <Script
        data={{
          content: {
            scripts: externalScripts,
          },
        }}
      />
    )
  }

  return null
}

function loadExternalScripts() {
  return loadContent(`/data/scripts/scripts.json`).then(({ content }) => {
    if (content?.length) {
      return content
    }
  }).catch((e) => {
    console.error(e)
    return Promise.resolve([])
  })
}
