import React from 'react'
import { useDispatch } from 'react-redux'

import { closePullout } from 'state/actions/pullout'
import { overlayOff } from 'state/actions/overlay'
import { useContent } from 'hooks/useContent'
import { useItemToRender } from 'hooks/useItemToRender'
import { Factory } from 'components/Factory'
import { MediaQuery } from 'components/MediaQuery'
import { Layout } from 'components/Layout'
import { Pullout } from 'components/Pullout'

export const PulloutMenu = () => {
  const menuPullout = useContent(`menuPullout`)
  const menuPulloutToRender = useItemToRender(menuPullout?.items)
  const dispatch = useDispatch()

  if (menuPulloutToRender) {
    return (
      <div
        data-testid="menu-pullout"
        onClick={handleClick}
      >
        <MediaQuery items={menuPulloutToRender.items}>
          {MenuPulloutComponent}
        </MediaQuery>
      </div>
    )
  }

  return null

  function handleClick(e) {
    if (e.target.tagName === `A`) {
      dispatch(overlayOff(`pullout`))
      dispatch(closePullout())
    }
  }
}

const MenuPulloutComponent = ({
  config = {},
  items,
  styling = {},
  ...rest
}) => {
  const { pulloutSide = `start` } = config
  const { backgroundColor } = styling

  return (
    <Pullout
      pulloutSide={pulloutSide}
      style={{ backgroundColor }}
    >
      <Layout {...rest}>
        <Factory items={items} />
      </Layout>
    </Pullout>
  )
}
