import { getMetaOptions } from 'global-content/config'
import { storage } from 'utils/storage'
import { getLanguage } from 'utils'
import store from 'state/reducers'
import { getOptions } from 'services/api/utils'
import {
  baseUrl,
  ACCOUNT_SESSION_URL,
  BASIC_SESSION_URL,
  SESSION_ID_NAME,
} from 'services/api/routes'

let authAttempts = 0
export const maxAuthAttempts = 3

export function resetAuthAttempt() {
  authAttempts = 0
}

export function createSession() {
  authAttempts++

  if (authAttempts > maxAuthAttempts) {
    throw new Error(`Failed to authenticate after ${maxAuthAttempts} attempts`)
  }

  const options = getOptions({
    method: `POST`,
    body: JSON.stringify({
      language: getLanguage(),
      sitePublicId: getMetaOptions(`sessionCreationKey`),
    }),
  })

  return (
    fetch(getUrl(), options)
      .then(response => {
        resetAuthAttempt()

        // save sessionId to localStorage for logging
        for (let pair of response.headers.entries()) {
          if (pair[0] === SESSION_ID_NAME) {
            storage.set(SESSION_ID_NAME, pair[1])
            sessionStorage.setItem(SESSION_ID_NAME, pair[1])
          }
        }

        return response
      })
  )
}

// export function deleteSession() {
//   return fetch(baseUrl().concat(ACCOUNT_SESSION_URL), getOptions({
//     method: `DELETE`,
//   }))
// }

// export function restartSession(options = {}) {
export function restartSession() {
  const siteTag = getMetaOptions(`siteTag`)
  let base = baseUrl()

  if (base.includes(`preview.`)) {
    // base = base.replace(`preview`, `cart`)
    // base = window.location.host
  }

  const url = `${base}${ACCOUNT_SESSION_URL}/logout?siteTag=${siteTag}`
  window.$shoppingSessionId.set()

  // Disable for now due to cart issue
  //
  // if (options?.stayLoggedIn) {
  //   return deleteSession()
  // }

  return (
    fetch(
      url,
      getOptions({
        method: `GET`,
      })
    )
      .then(() => createSession())
  )
}

function getUrl() {
  const siteTag = getMetaOptions(`siteTag`)
  const { isUserSignedIn } = store.getState().account

  let base = baseUrl()

  if (base.includes(`preview.`)) {
    // base = base.replace(`preview`, `cart`)
    // base = window.location.host
  }

  if (isUserSignedIn) {
    return `${base}${ACCOUNT_SESSION_URL}?siteTag=${siteTag}`
  }

  return `${base}${BASIC_SESSION_URL}?siteTag=${siteTag}`
}
