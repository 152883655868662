import React from 'react'
import { useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import { getContent } from 'global-content/content'
import { getNavContextIndex } from 'utils/getNavContextIndex'

export function useNavTree({
  navContext,
  navMenu,
  activeNavIndexes,
  navContextConsumer,
  navMenuIndex,
  navPosters,
}) {
  const activeLanguage = useSelector(state => state.language.active)
  const navTree = React.useMemo(() => getNavTreeToUse({ activeLanguage, navMenu }), [navMenu, activeLanguage])
  const navContextIndex = navContextConsumer && getNavContextIndex(navContext)
  const activeNavSection = getNavItems({
    navContextIndex,
    activeNavIndexes,
    navMenuIndex,
    navTree,
  })
  const activeNavPosters = getNavPosters({
    navPosters,
    activeNavIndexes,
  })

  return {
    navTree,
    activeNavSection,
    activeNavPosters,
  }
}

function getNavPosters({
  navPosters = [],
  activeNavIndexes,
}) {
  return navPosters.filter(navPoster => {
    return isEqual([navPoster?.data?.config?.navigationIndex], activeNavIndexes)
  })
}

function getNavTreeToUse({
  activeLanguage,
  navMenu,
}) {
  if (navMenu !== `navTree` && getContent(`navMenu`, activeLanguage)[navMenu]) {
    return arrayNavTree(getContent(`navMenu`, activeLanguage)[navMenu].navTree.l1)
  }

  return arrayNavTree(getContent(`navTree`, activeLanguage).l1)
}

function arrayNavTree(l1) {
  return branchToArray(l1)

  function branchToArray(branch, i = 2) {
    const level = `l${i}`
    const {
      order,
      ...rest
    } = branch

    return orderNavItems(Object.entries(rest).map(([key, value]) => {
      if (value[level]) {
        return {
          key,
          items: branchToArray(value[level], i + 1),
          ...value,
        }
      }

      return {
        key,
        items: [],
        ...value,
      }
    }), order)
  }
}

function orderNavItems(items, order) {
  if (order) {
    return order.map(key => items.find(item => item.key === key))
  }

  return items
}

function getNavItems({
  navContextIndex,
  activeNavIndexes,
  navMenuIndex,
  navTree,
}) {
  let level = navMenuIndex + 1
  const isUsingNavContext = typeof navContextIndex === `number`

  let currentNav = []
  if (isUsingNavContext && navContextIndex === -1) {
    return currentNav
  }

  if (isUsingNavContext) {
    level += 1
    activeNavIndexes = [navContextIndex, ...activeNavIndexes]
  }

  currentNav = navTree
  if (level > 1) {
    for (let i = 0; i < (level - 1); i++) {
      if (currentNav) {
        currentNav = currentNav[activeNavIndexes[i]]?.items
      }
    }
  }

  return currentNav
}
