import React, { useContext } from 'react'
import { Link as InflexibleReactRouterLink } from 'react-router-dom'
import { ModernFactoryComponent } from '../ModernFactoryComponent'
import { NavigationContext } from './Navigation'

export const NavigationLink = ModernFactoryComponent(({
  children,
  href,
  ...props
}, ref) => {
  delete props.Tag
  delete props.data

  const {
    clearCurrentNav,
  } = useContext(NavigationContext)

  return (
    <InflexibleReactRouterLink
      {...props}
      onClick={clearCurrentNav}
      ref={ref}
      to={href}
    >
      {children}
    </InflexibleReactRouterLink>
  )
})
