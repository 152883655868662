export function sortByPriority(
  { priority: Apriority = Infinity } = {},
  { priority: Bpriority = Infinity } = {}
) {
  if (Apriority < Bpriority) {
    return -1
  }

  if (Apriority > Bpriority) {
    return 1
  }

  return 0
}
