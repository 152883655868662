import React from 'react'
import { ShippingTable } from 'components/ShippingTable'
import { Layout } from 'components/Layout'

export const ShippingTableFactory = ({ data }) => {
  const { layout } = data
  return (
    <Layout
      layout={{
        width: layout.width,
      }}
    >
      <ShippingTable />
    </Layout>
  )
}
