import React, { useContext } from 'react'
import { NavigationContext } from './Navigation'
import { ModernFactoryComponent } from '../ModernFactoryComponent'

export const NavigationMegamenu = ModernFactoryComponent(({
  children,
  Tag,
  data: {
    navigationIndex,
  } = {},
  ...props
}, ref) => {
  const {
    currentNav,
  } = useContext(NavigationContext)
  return (
    <Tag
      {...props}
      data-menu-open={currentNav === navigationIndex}
      ref={ref}
    >
      {children}
    </Tag>
  )
})

