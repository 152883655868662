"use strict";
exports.__esModule = true;
exports.useEmitViewProductEvent = void 0;
var events_1 = require("../../../services/events");
var useEmitViewProductEvent = function () {
    return function (_a) {
        var analyticsTitle = _a.analyticsTitle, product = _a.product;
        return events_1.Events.emitViewProduct({
            analyticsTitle: analyticsTitle,
            product: product
        });
    };
};
exports.useEmitViewProductEvent = useEmitViewProductEvent;
