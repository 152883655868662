import React from 'react'

import { LazyModuleWrapper } from 'components/LazyModuleWrapper'

const LazyBrands = (
  React.lazy(() => import(/* webpackChunkName: "Brands" */ `./Brands`))
)

export const Brands = props => (
  <LazyModuleWrapper>
    <LazyBrands {...props} />
  </LazyModuleWrapper>
)
