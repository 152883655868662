"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.CountrySelectButton = void 0;
var react_1 = __importStar(require("react"));
var Layout_1 = require("components/Layout");
var CountrySelect_1 = require("../../../design-system/features/CountrySelect");
var CountrySelectButton_1 = require("../../../design-system/templates/CountrySelectButton");
var CountrySelectButton = function (_a) {
    var data = _a.data, tag = _a.tag;
    var layout = data.layout, customClassName = data.customClassName;
    var defaultCountry = (0, react_1.useContext)(CountrySelect_1.CountrySelectContext).defaultCountry;
    if (!defaultCountry) {
        return null;
    }
    return (react_1["default"].createElement(Layout_1.Layout, { className: customClassName, "data-testid": tag, layout: layout },
        react_1["default"].createElement(CountrySelectButton_1.CountrySelectButton, null)));
};
exports.CountrySelectButton = CountrySelectButton;
