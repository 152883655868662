import React from 'react'
import { I18n } from 'utils/i18n'
import { CategoriesSelectableMenu } from './ProductSizeGuidesVBCateogiresMenu'

import styles from './ProductSizeGuidesVB.css'

export const ProductSizeGuidesVBMeasureTab = (props) => {
  const {
    categories,
    hasCategoryHowToMeasureData,
    howToMeasureData,
    selectCategoryChange,
    selectedIndexCategorySizeGuide,
  } = props

  let measureData = howToMeasureData

  // If the selected category has its own "How to measure" data, then use it.
  // Otherwise leave it as the default "how to measure" data
  const selectedCategoryHowToMeasure = categories[selectedIndexCategorySizeGuide]?.categoryHowToMeasureData
  if (selectedCategoryHowToMeasure) {
    measureData = selectedCategoryHowToMeasure
  }

  const {
    diagramImageUrl,
    descriptionHeader,
    descriptionItems,
  } = measureData

  return (
    <div>
      {hasCategoryHowToMeasureData ?
        <CategoriesSelectableMenu
          categories={categories}
          selectCategoryChange={selectCategoryChange}
          selectedIndexCategorySizeGuide={selectedIndexCategorySizeGuide}
        /> :
        null
      }
      <div className={styles.measureContentContainer}>
        <div>
          <h3>
            {I18n.t(descriptionHeader)}
          </h3>

          <ul className={styles.descriptionList}>
            {descriptionItems.map(
              (item, index) =>
                (<li key={index}>
                  {I18n.t(item)}
                </li>)
            )}
          </ul>
        </div>

        {diagramImageUrl ?
          <div className={styles.diagramimageContainer}>
            <img src={diagramImageUrl} />
          </div> :
          null
        }
      </div>
    </div>
  )
}
