import React from 'react'
import classNames from 'classnames'

import { useLangDirAngleIcons } from 'hooks/useLangDirAngleIcons'
import { getComponentOptions } from 'global-content/components'
import { I18n } from 'utils/i18n'
import { Svg } from 'components/Svg'
import { SkeletonText } from 'components/SkeletonText'
import { SkeletonButton } from 'components/SkeletonButton'

import { webLabels } from './fixtures'
import styles from './Paginator.css'

export const Paginator = ({
  handleClick,
  loading,
  page,
  pages,
}) => {
  const previousAngle = useLangDirAngleIcons(`start`)
  const nextAngle = useLangDirAngleIcons(`end`)
  const { size } = getComponentOptions(`paginator`)

  if (loading) {
    return renderSkeleton()
  }

  const isLastPage = page >= pages
  const isFirstPage = page <= 1

  if (pages) {
    return (
      <div
        className={styles.paginator}
        data-testid="paginator"
      >
        <button
          aria-label={I18n.t(webLabels.previous)}
          className={classNames(styles.button, {
            [styles.disabled]: isFirstPage,
          })}
          data-testid="previous-btn"
          disabled={isFirstPage}
          name={I18n.t(webLabels.previous)}
          onClick={() => handleClick(page - 1)}
        >
          <Svg icon={previousAngle} />
        </button>

        <span className={classNames({ [styles[size]]: size })}>
          {I18n.t(webLabels.pages, {
            replace: {
              current: page,
              total: pages,
            },
          })}
        </span>

        <button
          aria-label={I18n.t(webLabels.next)}
          className={classNames(styles.button, {
            [styles.disabled]: isLastPage,
          })}
          data-testid="next-btn"
          disabled={isLastPage}
          name={I18n.t(webLabels.next)}
          onClick={() => handleClick(page + 1)}
        >
          <Svg icon={nextAngle} />
        </button>
      </div>
    )
  }

  return null

  function renderSkeleton() {
    return (
      <div
        className={styles.paginator}
        data-testid="skeleton-paginator"
      >
        <SkeletonButton
          type="circle"
          width="3rem"
        />
        <SkeletonText
          margin="0 1rem"
          width="5rem"
        />
        <SkeletonButton
          type="circle"
          width="3rem"
        />
      </div>
    )
  }
}
