"use strict";
exports.__esModule = true;
exports.t = exports.handle = void 0;
var i18n_1 = require("utils/i18n");
var lodash_1 = require("lodash");
// eslint-disable-next-line
function handle(fn) {
    var params = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        params[_i - 1] = arguments[_i];
    }
    return function () {
        return (0, lodash_1.isFunction)(fn)
            ? fn.apply(void 0, params) : undefined;
    };
}
exports.handle = handle;
/**
 *
 * @NOTE
 * Storybook cannot resolve langauges properly.
 */
function t() {
    var params = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        params[_i] = arguments[_i];
    }
    try {
        return i18n_1.I18n.t.apply(i18n_1.I18n, params);
    }
    catch (e) {
        return params[0];
    }
}
exports.t = t;
