import React from 'react'

import { updateGoogleAnalytics } from 'services/analytics'
import { useNavTree } from 'hooks/useNavTree'
import { I18n } from 'utils/i18n'
import { LinkText } from 'components/LinkText'
import { Grid } from 'components/Grid'

export const PulloutMenuNavContext = ({
  navContext,
  onClick,
  data = {},
}) => {
  const {
    positioning = {},
    styling = {},
  } = data
  const { columns } = positioning
  const {
    activeState,
    hoverState,
    type,
  } = styling

  const {
    activeNavSection: navItems,
  } = useNavTree({
    navMenu: `mobileNavigation`,
    activeNavIndexes: [],
    navContextConsumer: false,
    navMenuIndex: 0,
  })

  return (
    <Grid columns={columns}>
      {navItems.map(({
        color,
        key,
        label,
        target,
        path,
      }) => {
        return (
          <LinkText
            active={navContext === key}
            activeState={activeState}
            button
            hoverState={hoverState}
            key={key}
            onClick={() => {
              onClick(key)
              updateGoogleAnalytics(`clickCategoryNavItem`, { path })
            }}
            role="menuitem"
            style={{
              color: color,
            }}
            target={target}
            type={type}
          >
            {I18n.t(label)}
          </LinkText>
        )
      })}
    </Grid>
  )
}
