import merge from 'lodash/merge'

import store from 'state/reducers'

export function getOptions(options) {
  const authorization = getAuthorization()

  const defaultOptions = {
    credentials: `include`,
    headers: {
      authorization,
      shoppingsessionid: String(window.$shoppingSessionId.value),
      'content-type': `application/json`,
      'x-requested-with': `XMLHttpRequest`,
    },
  }

  if (window.$COUNTRYFOLDER) {
    defaultOptions.headers.Subfolder = window.$COUNTRYFOLDER
  }

  return merge({}, defaultOptions, options)
}

function getAuthorization() {
  const { userJwt } = store.getState().account

  if (userJwt) {
    return `Bearer ${userJwt}`
  }

  return `Basic dGVzdDp0ZXN0`
}
