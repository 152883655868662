import { generateSeoMetadata } from 'utils/generateSeoMetadata'

import store from 'state/reducers'

export function setSeoData({
  description,
  headline,
  product = {},
  search,
}) {
  const activeLanguage = store.getState().language.active

  const metadata = generateSeoMetadata({
    headline,
    description,
    product,
    search,
    activeLanguage,
  })

  if (document.querySelector(`meta[name="description"]`)) {
    let host = window.$META.host
    const hostNoFolder = host?.split(`/`)[0] || host
    let canonical = `https://${hostNoFolder}${window.location.pathname}`
    document.title = metadata.title
    document.querySelector(`link[rel="canonical"]`).href = canonical
    document.querySelector(`meta[name="description"]`).content = metadata.description || metadata.title
    document.querySelector(`meta[name="og:title"]`).content = metadata.title
    document.querySelector(`meta[name="og:description"]`).content = metadata.description || metadata.title
    document.querySelector(`meta[name="og:image"]`).content = metadata.image || ``
    document.querySelector(`meta[name="keywords"]`).content = metadata.keywords || ``
  }

  if (window.$META.integrations.facebook &&
      window.$META.integrations.facebook.active &&
      document.getElementsByName(`facebook-domain-verification`).length == 0) { // Only append element to DOM if it doesn't already exist
    let meta = document.createElement(`meta`)
    meta.name = `facebook-domain-verification`
    meta.content = window.$META.integrations.facebook.facebookDomainVerification
    document.head.appendChild(meta)
  }

  if (window.$META.integrations.google &&
      window.$META.integrations.google.googleSiteVerification &&
      document.getElementsByName(`google-site-verification`).length == 0) { // Only append child to DOM if it doesn't already exist
    let meta = document.createElement(`meta`)
    meta.name = `google-site-verification`
    meta.content = window.$META.integrations.google.googleSiteVerification
    document.head.appendChild(meta)
  }
}
