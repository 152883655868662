import React from 'react'
import { useSelector } from 'react-redux'
import { InchToggle } from 'components/InchToggle'
import { I18n } from 'utils/i18n'
import { CategoriesSelectableMenu } from './ProductSizeGuidesVBCateogiresMenu'

import styles from './ProductSizeGuidesVB.css'

export const ProductSizeGuidesVBTableTab = (props) => {
  const {
    categories,
    selectCategoryChange,
    selectedIndexCategorySizeGuide,
  } = props
  const isInchesSelected = useSelector(state => state.userPreferences.inches)

  // NOTE(Kevin): For each category there can be multiple tables to render.
  // For example Aerie Bottoms has options of XS SHORT / XS REGULAR / XS TALL for Bottoms.
  // And in the size guide there are two tables, one for length (SHORT / REG / TALL)
  // and one for waist widths (XS, S, M, L, XL)
  const selectedCategory = categories[selectedIndexCategorySizeGuide]
  const selectedCategoryTables = selectedCategory.tables

  // Check if we need to render the units toggle for Inches vs Centimeters
  let doesUnitTablesHaveInchesToggle = false
  for (const categoryTable of selectedCategoryTables) {
    if (
      categoryTable &&
      categoryTable.unitTables &&
      categoryTable.unitTables.length > 1 &&
      categoryTable.unitTables.find(unitTable => unitTable.unitKey === `inches`)
    ) {
      // break out of for loop on first encounter of inches unit
      doesUnitTablesHaveInchesToggle = true
      break
    }
  }

  // NOTE(Kevin): For each table, there can be multiple unitTables which
  // represent inches and centimeters; these are usually clothing items.
  // For Shoes category there is usually only one unitTable.
  const unitTablesToRender = []
  for (const categoryTable of selectedCategoryTables) {
    if (
      categoryTable &&
      categoryTable.unitTables &&
      categoryTable.unitTables.length
    ) {

      if (doesUnitTablesHaveInchesToggle && categoryTable.unitTables.length > 1) {
        const unitKeyToLookFor = isInchesSelected ? `inches` : `cm`
        const correctUnitTable = categoryTable.unitTables.find(unitTable => unitTable.unitKey === unitKeyToLookFor)
        if (correctUnitTable) {
          unitTablesToRender.push(correctUnitTable)
        } else {
          console.error(`Unit table is missing "unitKey: inches" or "unitKey: cm". Please verify there are no typos.`)
          console.log(`We will render the first table in the unitTables list for now.`)
          unitTablesToRender.push(categoryTable.unitTables[0])
        }
      } else {
        unitTablesToRender.push(categoryTable.unitTables[0])
      }
    }
  }

  return (
    <div className={styles.multipleTablesContainer}>
      {/* Render controls for category options and unit toggle */}
      <div className={styles.tableContentControls}>
        <CategoriesSelectableMenu
          categories={categories}
          selectCategoryChange={selectCategoryChange}
          selectedIndexCategorySizeGuide={selectedIndexCategorySizeGuide}
        />
        {doesUnitTablesHaveInchesToggle && <InchToggle />}
      </div>

      {/* Render size guide tables for the category */}
      {unitTablesToRender.map((unitTable, index) => {
        const {
          unitKey = ``,
          headerTitles,
          dataRows,
        } = unitTable
        const tableKey = `${selectedCategory.categoryKey}__${index}__${unitKey}`

        return (
          <ProductSizeGuideTable
            dataRows={dataRows}
            headerTitles={headerTitles}
            key={tableKey}
            showTableTitle={unitTablesToRender.length > 1}
            tableKey={tableKey}
            tableTitle={selectedCategoryTables[index].tableTitle}
          />
        )
      })}
    </div>
  )
}

function ProductSizeGuideTable(props) {
  const {
    dataRows,
    headerTitles,
    showTableTitle,
    tableKey,
    tableTitle,
  } = props

  return (
    <>
      {showTableTitle &&
        <p style={{ margin: `10px 0px` }}>
          {I18n.t(tableTitle)}
        </p>
      }
      <table className={styles.sizeGuideTable}>
        <thead>
          <tr>
            {headerTitles.map((headerTitle, index) => {
              return (
                <th key={`${headerTitle}__${index}`}>
                  {I18n.t(headerTitle)}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {dataRows.map(dataRow => {

            // Split data by commas for each table cell since it came from a CSV file
            const rowCells = dataRow.split(`,`)
            if (Boolean(rowCells.length) === false) {
              return null
            }

            return (
              <tr key={dataRow}>
                {rowCells.map((rowCell, index) => {
                  return (
                    <td key={`${tableKey}__${index}__${rowCell}`}>
                      {I18n.t(rowCell.split(`&comma;`).join(`,`))}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}
