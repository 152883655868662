import { useLocation } from 'react-router-dom'

export function useItemToRender(items) {
  const { pathname } = useLocation()

  if (items) {
    return findItemToRender(items, pathname)
  }
}

export function findItemToRender(items, pathname) {
  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    const {
      inclusions,
      exclusions,
    } = item.data?.config?.routes || {}
    const shouldRender = routeRendering({ inclusions, exclusions, pathname })

    if (shouldRender) {
      return item
    }
  }
}

export function routeRendering({
  inclusions,
  exclusions,
  pathname,
}) {
  if (!inclusions && !exclusions) {
    return true
  }

  let shouldRender = !inclusions

  if (inclusions && matchPath(inclusions, pathname)) {
    shouldRender = true
  }

  if (exclusions && matchPath(exclusions, pathname)) {
    shouldRender = false
  }

  return shouldRender
}

function matchPath(paths, currentPath) {
  return !!paths.find(path => {
    if (path.endsWith(`*`)) {
      return currentPath.startsWith(path.replace(`*`, ``))
    } else {
      return path === currentPath
    }
  })
}
