import React from 'react'
import classNames from 'classnames'

import styles from './TableRows.css'

export const TableRows = ({
  entries,
  headings,
  headingsClass,
  selectedEntry,
  tdPadding,
}) => {
  return (
    <>
      <colgroup>
        {entries[0].map((entry, i) => {
          return (
            <col
              key={entry.key}
              style={{
                backgroundColor: isEntrySelected(i) && `var(--greyLight)`,
              }}
            />
          )
        })}
      </colgroup>
      <tbody style={{ width: `800px` }}>
        {entries.map((row, i) => (
          <tr
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          >
            {row.map((cell, x) => {
              if (headings && x === 0) {
                return (
                  <th
                    className={classNames(styles.th, {
                      [headingsClass]: headingsClass,
                    })}
                    key={cell.key}
                    scope="row"
                    style={{
                      padding: tdPadding,
                    }}
                  >
                    {cell.value}
                  </th>
                )
              }

              return (
                <td
                  className={styles.td}
                  key={cell.key}
                  style={{
                    padding: tdPadding,
                  }}
                >
                  {cell.value}
                </td>
              )
            })}
          </tr>
        ))}
      </tbody>
    </>
  )

  function isEntrySelected(i) {
    if (headings) {
      return i - 1 === selectedEntry
    }

    return i === selectedEntry
  }
}
