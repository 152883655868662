export default class Dev {
  constructor() {
    this.flags = {}
    this.onClickBackupMap = {}
    this.nodeMap = {}
    this.nodeClickMap = {}
    this.actionMap = {
      listAllProducts: () => this.setFlag.apply(this, `listAllProducts`),
    }
  }

  init() {
    this.pathChangeWorker()
    return this.log(`Dev.init: [SUCCESS]`)
  }

  pathChangeWorker() {
    this.pathChangeInterval = setInterval(() => {
      let path = window.location.pathname
      if (this.path !== path) {
        this.path = path
        this.destroyBindEvents()
        this.buildBindEvents()
        return this.log(`Dev.pathChangeWorker: [UPDATE]`)
      }
    }, 100)
  }

  destroyBindEvents() {
    for (let type in this.nodeMap) {
      for (let i = 0; i < this.nodeMap[type].length; i++) {
        let node = this.nodeMap[type][i]
        node.onclick = this.nodeClickMap[type][i]
        delete this.nodeClickMap[type][i]
      }
    }
  }

  buildBindEvents() {
    this.getNodes()
  }

  getNodes() {
    let types = [`a`]
    for (let i = 0; i < types.length; i++) {
      let type = types[i]
      let nodes = document.querySelectorAll(`a`)
      this.nodeMap[type] = Array.from(nodes)
      for (let j = 0; j < this.nodeMap[type].length; j++) {
        let node = this.nodeMap[type][j]
        this.nodeClickMap[type] = this.nodeClickMap[type] || {}
        this.nodeClickMap[type][j] = node.onclick.bind({})
      }
    }
  }

  listNodes(type) {
    return this.nodeMap[type]
  }

  bindClickEvent(type, index, action) {
    if (!this.nodeMap[type] || !this.nodeMap[type][index]) {
      return
    }
    this.nodeMap[type][index].onclick = () => {
      this.actionMap[action]()
      this.nodeClickMap[type][index]()
    }
    return this.log(`Dev.bindClickEvent: [${type}] [${index}] [${action}] [SUCCESS]`)
  }

  destroyFlags() {
    this.flags = {}
    return this.log(`Dev.destroyFlags: [SUCCESS]`)
  }

  destroyFlag(flag) {
    delete this.flags[flag]
    return this.log(`Dev.destroyFlag: [${flag}] [SUCCESS]`)
  }

  setFlag(key) {
    this.flags[key] = true
    return this.log(`Dev.setFlag: [${key}] [SUCCESS]`)
  }

  listAllProducts() {
    this.setFlag(`listAllProducts`)
    return this.log(`Dev.listAllProducts: [SUCCESS]`)
  }

  log(message) {
    console.log(message)
    return `▼ ▼ ▼ NEXT ▼ ▼ ▼`
  }
}
