import React, { useEffect, useRef, useState } from 'react'
import once from 'lodash/once'

const defaultOptions = {
  root: null,
  rootMargin: `0px`,
  threshold: 0,
}

export const ImpressionObserver = ({
  children,
  onImpression,
  onUniqueImpression,
  options,
  style,
}) => {
  const [isIntersecting, setIsIntersecting] = useState(false)
  const element = useRef(null)
  const observerOptions = { ...defaultOptions, ...options }
  const onUniqueImpressionOnce = onUniqueImpression && once(onUniqueImpression)

  const handleIntersection = (_isIntersecting) => {
    setIsIntersecting(_isIntersecting)
    if (_isIntersecting) {
      onImpression && onImpression()
      onUniqueImpression && onUniqueImpressionOnce()
    }
  }

  const handleViewportChange = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting !== isIntersecting) {
        handleIntersection(entry.isIntersecting)
      }
    })
  }

  useEffect(() => {
    let observer
    // IntersectionObserver requires a polyfill
    if (window.IntersectionObserver) {
      observer = new IntersectionObserver(handleViewportChange, observerOptions)
      observer.observe(element.current)
    } else {
      handleIntersection(true)
    }

    return () => {
      observer && observer.disconnect()
    }
  }, [])

  return (
    <div
      ref={element}
      style={style}
    >
      {children}
    </div>
  )
}
