import { combineReducers, createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import promiseMiddleware from 'redux-promise-middleware'
import { composeWithDevTools } from 'redux-devtools-extension'

import account from './account'
import addresses from './addresses'
import brands from './brands'
import campaigns from './campaigns'
import cart from './cart'
import components from './components'
import content from './content'
import details from './details'
import favorites from './favorites'
import language from './language'
import listing from './listing'
import messages from './messages'
import navigation from './navigation'
import overlay from './overlay'
import paymentMethods from './payment-methods'
import pullout from './pullout'
import schemas from './schemas'
import site from './site'
import userPreferences from './user-preferences'

export const middleware = [promiseMiddleware, thunk]

export const staticReducers = combineReducers({
  account,
  addresses,
  brands,
  campaigns,
  cart,
  components,
  content,
  details,
  favorites,
  language,
  listing,
  messages,
  navigation,
  overlay,
  paymentMethods,
  pullout,
  schemas,
  site,
  userPreferences,
})

const composeEnhancers = composeWithDevTools({
  // options like actionSanitizer, stateSanitizer
})

// Create a store with the root reducer function being the one exposed by the manager.
const store = createStore(
  staticReducers,
  composeEnhancers(applyMiddleware(...middleware))
)

export default store
