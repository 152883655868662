import merge from 'lodash/merge'

import * as algolia from 'services/algolia'
import { getAlgoliaIndex } from 'global-content/algolia'
import { getConfigOptions } from 'global-content/config'
import { HITS_PER_PAGE } from 'utils/constants'
import { getRuleContext } from 'utils/getRuleContexts'

import {
  CANCEL_ACTION,
  CLEAR_REDIRECT,
  LOAD_PRODUCTS,
  LOAD_PAGE_FILTERS,
  LOAD_FULL_FILTER_SET,
  UPDATE_FILTER_OPTIONS,
  UPDATE_PENDING_FILTER_OPTIONS,
} from 'state/actions'
import { isTracking } from 'utils/tracking'

// Actions
export const cancelAction = actionId => ({
  type: CANCEL_ACTION,
  payload: actionId,
})

export const clearRedirect = () => ({
  type: CLEAR_REDIRECT,
})

export const loadFullFilterSet = language => ({
  type: LOAD_FULL_FILTER_SET,
  payload: getAlgoliaFilterSet(language),
})

export const updatePendingFilterOptions = options => ({
  type: UPDATE_PENDING_FILTER_OPTIONS,
  payload: options,
})

export const updateFilteroptions = options => dispatch => {
  const formattedOptions = formatOptions(options)

  dispatch({
    type: UPDATE_FILTER_OPTIONS,
    payload: formattedOptions,
  })

  dispatch(loadProducts())
}

// Additional functions for actions to work
async function getAlgoliaFilterSet(language) {
  const filters = await algolia.getFilters(language)
  return {
    language,
    filters,
  }
}

export const loadProducts = (actionId, pageOffsets = []) => (dispatch, getState) => {
  const language = getState().language.active
  const {
    currentPage: {
      isBSC,
      filters,
      slug,
    },
    navContext,
    secondaryContext,
  } = getState().navigation
  const {
    selectedSort,
    featuredProductIds,
    featuredCollections,
    selectedFilters,
    query,
    page,
  } = getState().listing
  const index = getAlgoliaIndex(selectedSort)

  const nbPageBanners = pageOffsets[page - 1] || 0
  const nbPreviousBanners = pageOffsets.reduce((prev, curr, idx) => idx < page - 1 ? prev + curr : prev, 0) || 0
  const isUsingNavContext = getConfigOptions(`navigation.startingNavContext`) > -1

  const ruleContexts = getRuleContext({
    isUsingNavContext,
    navContext,
    slug,
    secondaryContext,
  })

  const distinct = algolia.getDistinct(isBSC)

  dispatch({
    type: LOAD_PRODUCTS,
    payload: algolia.getProducts({
      analytics: isTracking(),
      clickAnalytics: isTracking(),
      language,
      index,
      options: {
        distinct,
        hitsPerPage: HITS_PER_PAGE,
        query,
        analyticsTags: [
          `ProductListingPage`,
          slug,
        ],
        page: getPage(page),
        filters: merge({}, filters, selectedFilters),
        nbPageBanners,
        nbPreviousBanners,
        ruleContexts,
      },
      priorityOptions: {
        selectedSort,
        featuredProductIds,
        featuredCollections,
      },
    }).then(results => ({
      results,
      actionId,
    })),
  })
}

export const loadFiltersForPage = (pathname) => (dispatch, getState) => {
  const language = getState().language.active
  const { filters } = getState().navigation.currentPage
  const {
    selectedSort,
    query,
  } = getState().listing
  const index = getAlgoliaIndex(selectedSort)

  dispatch({
    type: LOAD_PAGE_FILTERS,
    payload: algolia.getProducts({
      analytics: false,
      clickAnalytics: false,
      language,
      format: false,
      index,
      options: {
        attributesToRetrieve: [`objectID`],
        hitsPerPage: 1,
        query,
        filters,
      },
    }).then(results => ({
      pathname,
      results,
      language,
    })),
  })
}

// we store our page references as 1-indexed. Algolia wants it as 0-index
function getPage(page) {
  return page - 1
}

function formatOptions({
  filters,
  ...rest
}) {
  const FILTERSMAP = algolia.algoliaFiltersMap.get()

  let build = {
    ...rest,
  }

  if (filters) {
    build.filters = {}

    Object.entries(filters).forEach(([key, value]) => {
      if (
        FILTERSMAP[key] &&
        [`facet`, `unfaceted`].includes(FILTERSMAP[key].filterType)
      ) {
        build.filters[key] = formatValues(value)
      } else {
        build.filters[key] = value
      }
    })
  }

  return build
}

function formatValues(value) {
  if (Array.isArray(value)) {
    return value
  }

  return [value]
}
