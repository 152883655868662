// hook version of <DataFetching /> HOC
import React from 'react'
import { useSelector } from 'react-redux'
import { translate } from 'utils/translate'
import { useIsMounted } from 'hooks/useIsMounted'
import { reportError } from 'services/reportError'
import { ErrorAdditional, SENTRY_TAGS } from 'utils/ErrorAdditional'
import { getMetaOptions } from 'global-content/config'

window.$cache = {}

// TODO: cache the translationed versions so translate only runs once?
export function useDataFetching({
  folder,
  source,
  cacheName = `${folder}/${source}`,
  doI18n,
}) {
  const isMounted = useIsMounted()
  const activeLanguage = useSelector(state => state.language.active)
  const path = `${folder}/${source}`
  const cacheRef = `${cacheName}-${activeLanguage}`
  const [{
    file,
    triedFetch,
  }, setState] = React.useState(initialState())

  React.useEffect(() => {
    if (folder && source) {
      if (!window.$cache[cacheRef] && isMounted.current) {
        setState({
          file: undefined,
          triedFetch: false,
        })
      }

      tryFetch()
    }
  }, [folder, source, activeLanguage])

  return [file, triedFetch]

  function initialState() {
    if (window.$cache[cacheRef]) {
      return window.$cache[cacheRef]
    }

    return {
      file: undefined,
      triedFetch: undefined,
    }
  }

  function tryFetch() {
    if (Object.prototype.hasOwnProperty.call(window.$cache, cacheRef)) {
      setState(window.$cache[cacheRef])
      return
    }

    let fetchedFile
    const fetchUrl = `${getMetaOptions(`countryFolder`)}${path}?cb=${window.$cacheBust}`
    fetch(fetchUrl)
      .then(response => {
        if (response.ok) {
          const contentType = response.headers.get(`content-type`)

          if (contentType.startsWith(`application/json`)) {
            return response.json()
          }

          if (contentType.startsWith(`text/markdown`)) {
            return response.text()
          }

          if (contentType.startsWith(`image/`)) {
            return response.text()
          }

          return undefined
        }

        throw response
      })
      .then((data) => {
        if (data) {
          return data
        }

        throw new Error(`No content for ${getMetaOptions(`countryFolder`)}${path}`)
      })
      .then(parsedFile => {
        if (parsedFile) {
          fetchedFile = doI18n ? translate({
            content: parsedFile,
            language: activeLanguage,
          }) : parsedFile
        }
      })
      .catch(e => {
        // Measurement is optional data
        if (e && e.message && e.message !== `No content for /data/measurements-chart.json`) {
          reportError(new ErrorAdditional({
            title: `Data fetching failure`,
            message: e.message,
            severity: 2,
            additional: {
              originalError: e,
              path: `${path}`,
            },
            originalError: e,
            tags: {
              [SENTRY_TAGS.SOURCE_FILE]: `src/hooks/useDataFetching.js`,
              [SENTRY_TAGS.FETCH_URL]: fetchUrl,
            },
          }))
        }
      })
      .finally(() => {
        window.$cache[cacheRef] = {
          file: fetchedFile,
          triedFetch: true,
        }

        if (isMounted.current) {
          setState({
            file: fetchedFile,
            triedFetch: true,
          })
        }
      })
  }
}
