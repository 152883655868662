import { getMetaOptions } from 'global-content/config'
import { getPreviewSetting } from 'global-content/preview'
import { getLanguage } from 'utils'
import { getHost } from 'global-content/config'
import { checkExceptionsList } from 'exceptions/checkExceptions'
import {
  CAMPAIGNS_URL,
  CART_URL,
  CART_ADDRESS_URL,
  CART_CALCULTE_TAX,
  CHECKOUT_URL,
  CONSUMER_PROPERTIES_URL,
  EMAIL_SUBSCRIBE_URL,
  EMAIL_SUBSCRIBE_INTERESTS,
  EMAIL_REMOVE_URL,
  ORDER_DETAILS_STATEFUL_URL,
  ORDER_DETAILS_STATELESS_URL_POST,
  ORDER_DETAILS_STATELESS_URL,
  ORDER_HISTORY_URL,
  PRODUCT_URL_STATELESS,
  RECREATE_CART_URL,
  SERVER_TIME_URL,
  SHIPPING_URL,
  TERRITORY_URL,
  UPDATE_PROPERTIES_URL,
  VOUCHER_URL,
} from 'services/api/routes'
import { call } from 'services/api/call'
import { ERROR_CODES } from 'utils/errorCodes'

/* REST VERBS */

export function get(pathAndParams, options) {
  return call(pathAndParams, options)
}

export function post(pathAndParams, options) {
  return call(
    pathAndParams,
    {
      ...options,
      method: `POST`,
    }
  )
}

export function put(pathAndParams, options) {
  return call(
    pathAndParams,
    {
      ...options,
      method: `PUT`,
    }
  )
}

export function deleteCall(pathAndParams, options) {
  return call(
    pathAndParams,
    {
      ...options,
      method: `DELETE`,
    }
  )
}

/* API METHODS */

export function updateUserLanguagePreference(lang) {
  return put({
    path: UPDATE_PROPERTIES_URL,
    params: {
      paramId: `lang`,
      value: lang,
    },
  })
}

/* CHECKOUT */

const captchaMap = {
  v2: `x-localised-challenge`,
  v3: `x-localised-invisible`,
}

export function checkout(customerInformation, captcha = {}) {
  const {
    captchaToken,
    captchaType,
  } = captcha
  const header = captchaMap[captchaType]
  let d = { body: JSON.stringify(customerInformation), headers: {} }

  if (header) {
    d.headers[header] = captchaToken
  }
  return post({ path: CHECKOUT_URL }, d)
}

export function checkoutWithPaypalExpress(data, orderReference, captcha = {}) {
  const {
    captchaToken,
    captchaType,
  } = captcha
  const header = captchaMap[captchaType]
  let d = { body: JSON.stringify(data), headers: {} }

  if (header) {
    d.headers[header] = captchaToken
  }
  return put({ path: `${CHECKOUT_URL}/${orderReference}` }, d)
}

export function sendConsumerProperties(properties) {
  return post(
    { path: CONSUMER_PROPERTIES_URL },
    { body: JSON.stringify(properties) }
  )
}

// Not used?

// export function checkoutWithAfterPay() {
//   let afterPay
//   let baseUrlString = `${baseUrl()}/api`
//   return loadCartContents().then((cart) => {
//     afterPay = new AfterPay(baseUrlString)
//     return afterPay.afterPayFlow(cart)
//   })
// }

export function loadValidation(lang, addressType) {
  let path = `/api/validation/${getMetaOptions(`country.code`).toLowerCase()}/${lang}`

  if (addressType) {
    path += `/address`
  }

  return get({
    path,
    params: {
      type: addressType,
    },
  })
}

export function loadCountries() {
  return get({ path: TERRITORY_URL })
}

export function loadServerTime(timeZone) {
  return get({
    path: SERVER_TIME_URL,
    params: {
      zone: timeZone,
    },
  })
}

/* VOUCHERS */

export function applyVoucher(data) {
  const preview = getPreviewTimeParam()

  return put(
    {
      path: VOUCHER_URL,
      params: {
        preview,
      },
    },
    {
      body: JSON.stringify({ apply: data }),
    }
  )
}

export function removeVouchers(data) {
  return put(
    {
      path: VOUCHER_URL,
    },
    {
      body: JSON.stringify({ delete: data }),
    }
  )
}

/* CART */

export function loadCartContents() {
  const preview = getPreviewTimeParam()

  return get({
    path: CART_URL,
    params: {
      lang: getLanguage(),
      preview,
    },
  })
}

export function addCartItem({
  quantity,
  product,
  skuOptions,
}) {
  const preview = getPreviewTimeParam()

  return post(
    {
      path: CART_URL,
      params: {
        preview,
      },
    },
    {
      body: JSON.stringify({
        productId: product.id,
        quantity: quantity,
        setCode: skuOptions.sku,
        forceBasketClear: false,
      }),
    }
  )
}

export function updateCartItem({
  cartItem,
  quantity,
}) {
  const preview = getPreviewTimeParam()

  return put(
    {
      path: `${CART_URL}/${cartItem.id}`,
      params: {
        lang: getLanguage(),
        preview,
      },
    },
    { body: JSON.stringify({ quantity: quantity }) }
  )
}

export function recreateCart({ cartPublicId }) {
  return post(
    {
      path: RECREATE_CART_URL,
      params: {
        cartPublicId,
      },
    }
  )
}

export function setCartAddress({ address }) {
  return post(
    {
      path: CART_ADDRESS_URL,
    },
    {
      body: JSON.stringify({
        reference: null, // so we always send something for reference
        ...address,
      }),
    }
  )
}

export function calculateCartTax({ address = null } = {}) {
  return post(
    {
      path: CART_CALCULTE_TAX,
    },
    {
      body: JSON.stringify(address),
    }
  )
}

/* SHIPPING */

export function saveShippingOption(id) {
  const preview = getPreviewTimeParam()

  return post(
    {
      path: SHIPPING_URL,
      params: {
        preview,
      },
    },
    { body: JSON.stringify({ id: id }) }
  )
}

/* EMAIL */

export function addToEmailList(email, interests = []) {
  return get({
    path: EMAIL_SUBSCRIBE_URL,
    params: {
      email: email,
      language: getLanguage(),
      interests,
    },
  })
}

export function getEmailListInterests() {
  return get({
    path: EMAIL_SUBSCRIBE_INTERESTS,
  })
}

export function removeFromEmailList(email) {
  return put({
    path: EMAIL_REMOVE_URL,
    params: {
      email: email,
      language: getLanguage(),
    },
  })
}

/* PDP */

export function getProduct({
  productId,
  language,
}) {
  // the /s/ denotes the stateless version of this endpoint (does not require cookie)
  return get({
    path: `${PRODUCT_URL_STATELESS}/${productId}`,
    params: {
      lang: language,
      siteTag: getMetaOptions(`siteTag`),
    },
  })
    .then(response => {
      // Product API returns 200 OK still with a result of NOT_FOUND
      if (response.result === `NOT_FOUND`) {
        throw new Error(`Product not found. ProductId: ${productId} Language: ${language}`)
      }

      return response
    })
    // .catch(err => {
    //   reportError(new ErrorAdditional({
    //     severity: 1,
    //     title: `PDP-Error - ${err.status}`,
    //     message: err.message,
    //   }))

  //   throw err
  // })
}

/* CAMPAIGNS */

export function getCampaigns() {
  const preview = getPreviewTimeParam()

  return get({
    path: CAMPAIGNS_URL,
    params: {
      preview,
    },
  }).catch(() => {
    return Promise.resolve([])
  })
}

/* ORDERS */

export function getOrderHistory({
  lastKey,
  lastKeyTs,
  pageSize = 10,
} = {}) {
  return get({
    path: ORDER_HISTORY_URL,
    params: {
      lastKey,
      lastKeyTs,
      pageSize,
    },
  })
}

export function getOrderDetails({
  orderReference,
  isUserSignedIn,
  email,
  phone,
  hash,
}) {
  if (isUserSignedIn) {
    return getOrderDetailsStateful({ orderReference })
  }

  return getOrderDetailsPostStateless({
    orderReference,
    email,
    phone,
    hash,
  })
}

export function getOrderDetailsStateful({
  orderReference,
}) {
  return get({
    path: `${ORDER_DETAILS_STATEFUL_URL}${orderReference}`,
  })
}

export function getOrderDetailsStateless({
  hash,
}) {
  return get({
    path: ORDER_DETAILS_STATELESS_URL,
    params: {
      hash,
    },
  })
}

export function getOrderDetailsPostStateless({
  orderReference,
  email,
  phone,
  hash,
}) {
  return post(
    {
      path: ORDER_DETAILS_STATELESS_URL_POST,
    },
    {
      body: JSON.stringify({
        orderReference,
        email: email || undefined,
        phone: phone || undefined,
        hash,
      }),
    }
  )
}

function getPreviewTimeParam() {
  if (getPreviewSetting(`active`)) {
    return getPreviewSetting(`preview`)
  }
}

export function getDetectedCountry() {
  const host = getHost()
  const folder = checkExceptionsList(host, `metadataFolder`)
  const fetchUrl = `https://${host}/${folder}-current/location`

  // This country selector feature is not enabled for all clients, so if the fetchUrl does
  // not have a response, then leave it as is, and do not produce an error.
  return fetch(fetchUrl, {
    method: `GET`,
  }).then(response => {
    return response.json().catch((err) => {
      console.warn(`[${ERROR_CODES[10001]}] Can not parse ${fetchUrl}, ${err}`)
      return {}
    })
  }).catch(err => {
    console.warn(`[${ERROR_CODES[10002]}] Error fetching ${fetchUrl}, ${err}`)
    return Promise.resolve({})
  })
}

export { fetchAtmos, healthCheckAtmos } from './atmos'
