import React from 'react'

import { handleLazyLoadModuleFailure } from 'utils/handleLazyLoadModuleFailure'
import { ModalUpdate } from 'components/ModalUpdate'
import { PageBody } from 'components/PageBody'

export class LazyModuleWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: undefined,
      hasError: false,
      showReloadModal: false,
      showErrorModal: false,
    }
  }

  static getDerivedStateFromError() {
    return {
      error: undefined, // wait for it to be caught and reshaped
      hasError: true,
      showReloadModal: false,
      showErrorModal: false,
    }
  }

  componentDidCatch(error) {
    try {
      handleLazyLoadModuleFailure(error)
    } catch (modifiedError) {
      this.setState({
        error: modifiedError,
      })
    }
  }

  render() {
    const {
      error,
      hasError,
    } = this.state
    const { isPage = true } = this.props

    if (hasError) {
      return (
        <>
          {isPage && <PageBody loading />}
          {error &&
            <ModalUpdate
              error={error}
              retryRef={error.additional?.storageHashKey}
            />
          }
        </>
      )
    }

    return (
      <React.Suspense
        fallback={
          isPage ? <PageBody loading /> : <div />
        }
      >
        {this.props.children}
      </React.Suspense>
    )
  }
}
