import React from 'react'
import{ getEmailListInterests } from 'services/api'
import { useEmailSignupInterestsConfig } from './useEmailSignupInterestsConfig'

export function useEmailSignupInterests() {
  const enabled = useEmailSignupInterestsConfig()
  const [fetching, setFetching] = React.useState(enabled)
  const [data, setData] = React.useState([])

  React.useEffect(() => {
    if (enabled) init()
  }, [enabled])

  return {
    data,
    fetching,
  }

  async function init() {
    try {
      setData(await getEmailListInterests())
    } catch(e) {
      // report error api?
      setData([])
    } finally {
      setFetching(false)
    }
  }
}
