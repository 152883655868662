import React from 'react'

import { LazyModuleWrapper } from 'components/LazyModuleWrapper'

const LazyAccountHome = (
  React.lazy(() => import(/* webpackChunkName: "AccountHome" */ `./AccountHome`))
)

export const AccountHome = props => (
  <LazyModuleWrapper>
    <LazyAccountHome {...props} />
  </LazyModuleWrapper>
)
