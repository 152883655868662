import React from 'react'
import classNames from 'classnames'

import { getConfigOptions } from 'global-content/config'
import { SkeletonImage } from 'components/SkeletonImage'
import { SkeletonText } from 'components/SkeletonText'

import shared from 'components/Skeleton/Skeleton.css'
import styles from './SkeletonCard.css'

export const SkeletonCard = ({ data }) => {
  const {
    productCardOptions,
    position,
  } = data

  const {
    imageRatio,
    image = {},
  } = getConfigOptions(`variant`)

  const {
    imageBackgroundColor,
    imagePadding,
    showBrand,
    showPrice,
    showSwatches,
    showTitle,
    textAlign,
  } = productCardOptions

  return (
    <div data-testid={`skeleton-card-${position}`}>
      <div className={classNames(shared.skeleton, styles.imageWrapper)}>
        <SkeletonImage
          borderRadius={image.borderRadius}
          imageBackgroundColor={imageBackgroundColor}
          imagePadding={imagePadding}
          metadata={imageRatio}
        />
      </div>

      {(showBrand || showTitle || showPrice || showSwatches) &&
        <div
          className={classNames(styles.description, {
            [styles.center]: textAlign === `center`,
          })}
        >
          {showBrand &&
            <SkeletonText
              margin="0 0 1rem 0"
              size="headingXS"
              width="50%"
            />
          }

          {showTitle &&
            <SkeletonText
              margin="0 0 1rem 0"
              size="linkSmall"
              width="75%"
            />
          }

          {showPrice &&
            <SkeletonText
              margin="0 0 0.3rem 0"
              size="bodySmall"
              width="60%"
            />
          }

          {showSwatches &&
            <SkeletonText
              margin="0 0 0.8rem 0"
              size="bodySmall"
              width="50%"
            />
          }
        </div>
      }
    </div>
  )
}
