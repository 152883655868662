import React from 'react'
import classNames from 'classnames'
import uuidv4 from 'uuid/v4'

import { TableColumns } from 'components/TableColumns'
import { TableRows } from 'components/TableRows'

import styles from './Table.css'

export const Table = ({
  borders,
  className,
  data,
  headers = `columns`,
  headings,
  headingsClass,
  noFill,
  selectedEntry,
  size = `small`,
  tdPadding = `1rem`,
}) => {
  const headersMap = {
    columns: TableColumns,
    rows: TableRows,
  }

  const Tbody = headersMap[headers]
  const keyedHeadings = keyData(headings)
  const keyedData = keyData(data)
  const entries = getEntries(keyedData, keyedHeadings, headers)
  const maxColumnWidth = 200

  return (
    <div
      className={styles.overflow}
      style={{
        maxWidth: noFill && `${entries[0].length * maxColumnWidth}px`,
      }}
    >
      <table
        className={classNames(styles.container, {
          [className]: className,
          [styles.large]: size === `large`,
          [styles.regular]: size === `regular`,
          [styles.small]: size === `small`,
          [styles.xs]: size === `xs`,
          [styles.borders]: borders,
          [styles.rowsTable]: headings && headers === `rows`,
          [styles.columnsTable]: headings && headers === `columns`,
        })}
      >
        <Tbody
          entries={entries}
          headings={keyedHeadings}
          headingsClass={headingsClass}
          selectedEntry={selectedEntry}
          tdPadding={tdPadding}
        />
      </table>
    </div>
  )
}

function keyData(data) {
  if (data) {
    return data.map(entry => {
      if (Array.isArray(entry)) {
        return entry.map(keyEntry)
      }

      return keyEntry(entry)
    })
  }
}

function keyEntry(value) {
  return {
    key: uuidv4(),
    value,
  }
}

function getEntries(data, headings, headers) {
  if (headers === `rows`) {
    return transpose(data, headings)
  }

  return data
}

function transpose(entries, headings) {
  let build = []

  entries[0].forEach((column, i) => {
    build[i] = entries.map((row) => row[i])
  })

  if (headings) {
    headings.forEach((heading, i) => {
      build[i].unshift(heading)
    })
  }

  return build
}
