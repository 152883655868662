import {
  DETAILS_PRELOAD,
  DETAILS_ADD_MESSAGE,
  DETAILS_REMOVE_MESSAGE,
  GET_PRODUCT,
  NO_PRODUCT,
  UPDATE_SELECTED_OPTION,
  SET_PENDING_QUANTITY,
  UPDATE_MISSING_LEVELS,
} from "state/actions"
import { normalizeProductVariations, getGroupProductFallback } from 'state/utils/details'

import * as api from 'services/api'

export const detailsPreload = ({
  indexName,
  product,
  selectedSlugs,
  filters,
  queryID,
}) => ({
  type: DETAILS_PRELOAD,
  payload: {
    indexName,
    product,
    selectedSlugs,
    filters,
    queryID,
  },
})

export const detailsAddMessage = payload => ({
  type: DETAILS_ADD_MESSAGE,
  payload,
})

export const detailsRemoveMessage = payload => ({
  type: DETAILS_REMOVE_MESSAGE,
  payload,
})

export const getProduct = ({
  callback,
  productId,
  language,
  getPromotions,
  search,
}) => async(dispatch, getState) => {
  try {
    await dispatch({
      type: GET_PRODUCT,
      payload: (
        api.getProduct({ productId, language, getPromotions, search })
          .catch(() => getGroupProductFallback({ productId, language, getPromotions, search }))
          .then(async(product) => normalizeProductVariations({ product, language }))
          .then(product => ({
            product,
            getPromotions,
            search,
          }))
      ),
    })
    callback(getState)
  // Error is already handled by redux middleware.  No need to throw.
  } catch (err) {
    console.warn(err)
  }
}

export const noProduct = () => ({
  type: NO_PRODUCT,
})

export const setPendingQuantity = payload => ({
  type: SET_PENDING_QUANTITY,
  payload,
})

export const updateMissingLevels = payload => ({
  type: UPDATE_MISSING_LEVELS,
  payload,
})

export const updateSelectedDetailOption = ({
  options,
  urlManager,
  getPromotions,
  isValid,
}) => async(dispatch) => {
  await dispatch({
    type: UPDATE_SELECTED_OPTION,
    payload: {
      options,
      getPromotions,
      isValid,
    },
  })

  urlManager({
    method: `replace`,
    params: {
      append: options,
      remove: [`sku`],
    },
  })
}
