import React from 'react'

import { Svg } from 'components/Svg'
import { Image } from 'components/Image'
import { HtmlText } from 'components/HtmlText'

import styles from './ProductCardBadge.css'

export const ProductCardBadge = ({
  content = {},
  styling = {},
}) => {
  const {
    html,
    iconRef,
    imageSrc,
    text,
  } = content
  const {
    backgroundColor,
    color,
    height,
    width,
    align,
    margin,
  } = styling

  const inline = {
    backgroundColor,
    color,
  }

  function renderBadge() {
    if (imageSrc) {
      return (
        <Image src={imageSrc} />
      )
    }

    if (iconRef) {
      return (
        <Svg
          icon={iconRef}
          size="5rem"
        />
      )
    }

    return (
      <div
        className={styles.text}
        style={inline}
      >
        {(html || text) &&
          <HtmlText html={html || text} />
        }
      </div>
    )
  }

  return (
    <div
      className={styles.container}
      style={{
        height,
        width,
        alignSelf: align === `bottom` ? `end` : `start`,
        margin,
      }}
    >
      {renderBadge()}
    </div>
  )
}
