import { getConfigOptions } from 'global-content/config'

export function getRuleContext({
  isUsingNavContext,
  navContext,
  slug,
  secondaryContext,
}) {
  const enableSecondaryContext = getConfigOptions(`navigation.enableSecondaryContext`)
  const secondaryContextMap = getConfigOptions(`navigation.secondaryContextMap`)
  if (slug === `search` && isUsingNavContext) {
    if (enableSecondaryContext && secondaryContext && secondaryContextMap) {
      const extendedNavContext = `${navContext}-${secondaryContext}`
      if (secondaryContextMap[extendedNavContext]) {
        return [ secondaryContextMap[extendedNavContext] ]
      }
      return [ extendedNavContext ]
    }
    return [ navContext ]
  }
}
