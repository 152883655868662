export const webLabels = {
  title: `WEB.PREORDER.TITLE`,
  preorderNoDate: `WEB.PREORDER.TITLE.NODATE`,
}

export const availabilityMessagingType = {
  pdp: `PDP`,
  basket: `BASKET`,
  existingOrder: `EXISTING_ORDER`,
}
