import { getSourceFile } from 'utils/getSourceFile'
import { ADD_CONTENT } from 'state/actions'
import { loadContent } from 'services/content'
import { getConfigOptions } from 'global-content/config'

export const loadMenuPullout = () => async(dispatch, getState) => {
  const language = getState().language.active
  const menuPullout = await loadContent(getSourceFile(`/data/menu-pullout`, language))

  return dispatch(addContent({
    data: menuPullout.content,
    language,
    target: `menuPullout`,
  }))
}

export const loadFiltersPullout = () => async(dispatch, getState) => {
  const language = getState().language.active
  const filtersPullout = await loadContent(getSourceFile(`/data/filters-pullout`, language))

  return dispatch(addContent({
    data: filtersPullout.content,
    language,
    target: `filtersPullout`,
  }))
}

export const loadAddressLayouts = () => async(dispatch, getState) => {
  const language = getState().language.active
  const addressLayouts = await loadContent(getSourceFile(`/data/address-layouts`, language))

  dispatch(addContent({
    data: addressLayouts.content,
    language,
    target: `addressLayouts`,
  }))

  dispatch(addContent({
    data: mapRegions(addressLayouts.content),
    language,
    target: `regions`,
  }))
}

export const loadBadges = () => async(dispatch, getState) => {
  const language = getState().language.active
  const badges = await loadContent(getSourceFile(`/data/badges`, language))

  return dispatch(addContent({
    data: badges.content,
    language,
    target: `badges`,
  }))
}

export const loadCountryNavigation = () => async(dispatch, getState) => {
  const language = getState().language.active
  const isEnabled = getConfigOptions(`countryNavigation.isEnabled`)
  const countryNavigation = isEnabled ?
    await loadContent(
      getSourceFile(`/data/country-navigation`, language)
    ).catch(() => ({})) : {}

  return dispatch(addContent({
    data: countryNavigation.content,
    language,
    target: `countryNavigation`,
  }))
}

export const loadSplashModal = () => async(dispatch, getState) => {
  const language = getState().language.active
  const splashModal = await loadContent(getSourceFile(`/data/splash-modal`, language), {
    allowMissing: true,
  })

  return dispatch(addContent({
    data: splashModal.content,
    language,
    target: `splashModal`,
  }))
}

export const loadFooter = () => async(dispatch, getState) => {
  const language = getState().language.active
  const footer = await loadContent(getSourceFile(`/data/footer`, language))

  return dispatch(addContent({
    data: footer.content,
    language,
    target: `footer`,
  }))
}

export const loadSupportNav = () => async(dispatch, getState) => {
  const language = getState().language.active
  const supportNav = await loadContent(getSourceFile(`/data/footerNav`, language))

  return dispatch(addContent({
    data: supportNav.content.content, // yeah...
    language,
    target: `supportNav`,
  }))
}

export const loadAddressUnableToShip = () => async(dispatch, getState) => {
  const language = getState().language.active
  const addressUnableToShipData = await loadContent(getSourceFile(`/data/addressUnableToShip`, language))
    .catch(() => {
      // This client-country does not have any addresses it cannot ship to.
      // So this file is never created on content side.
      return null
    })

  return dispatch(addContent({
    data: addressUnableToShipData?.content || null,
    language,
    target: `addressUnableToShip`,
  }))
}

export function addContent({
  data,
  language,
  target,
}) {
  return {
    type: ADD_CONTENT,
    payload: {
      data,
      language,
      target,
    },
  }
}

function mapRegions(content) {
  const { fields } = content

  if (fields.Singapore) {
    return {
      [fields.Singapore.value]: fields.Singapore.label,
    }
  }

  const { regionSelect } = fields
  const { options } = regionSelect

  let build = {}

  options.filter(obj => obj.value !== ``).forEach(({
    value,
    label,
  }) => {
    build[value] = label
  })

  return build
}
