export function checkExceptionsList(value, exception) {
  const exceptionMap = {
    metadataFolder: getMetaDataFolder,
  }

  return exceptionMap[exception](value)
}

function getMetaDataFolder(value) {
  if (value === `jp.saks.com`) {
    return `jp.saks.localised.com`
  }

  if (value === `www.saksfifthave.kr`) {
    return `kr.saks.localised.com`
  }

  return value
}
