import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { ErrorAdditional, SENTRY_TAGS } from 'utils/ErrorAdditional'
import { reportError } from 'services/reportError'
import { updateGoogleAnalytics } from 'services/analytics'
import { PageTemplate } from 'components/PageTemplate'
import { PageBody } from 'components/PageBody'
import { Factory } from 'components/Factory'
import { useRouteChange } from 'hooks/useRouteChange'
import { useRouterRedirectList } from 'hooks/useRouterRedirectList'
import styles from './NotFound.css'

export const NotFound = () => {
  const {
    pathname,
    search,
  } = useLocation()
  const updateLocation = useRouteChange()
  const redirectTo = useRouterRedirectList()

  React.useEffect(() => {
    // this updateLocation is for when the PDP fails to return a product
    updateLocation({
      force: true,
      pathname,
      scrollToY: 0,
      search,
    })

    reportError(new ErrorAdditional({
      title: `Page Not Found`,
      severity: 3,
      additional: {
        pathname,
      },
      tags: {
        [SENTRY_TAGS.SOURCE_FILE]: `src/pages/NotFound/NotFound.jsx`,
      },
    }))

    updateGoogleAnalytics(`pageNotFound`, {
      url: pathname,
    })
    updateGoogleAnalytics(`virtual_pageview_pageNotFound`)
  }, [])


  if (redirectTo) {
    return <Redirect to={redirectTo} />
  }

  return (
    <PageTemplate
      template="status-404"
    >
      {NotFoundComponent}
    </PageTemplate>
  )
}

const NotFoundComponent = ({
  items,
  ...props
}) => {
  return (
    <PageBody
      className={styles.notFound}
      data-testid="not-found"
      {...props}
    >
      <Factory items={items} />
    </PageBody>
  )
}
