"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.AlgoliaStorage = void 0;
var localstorage_1 = require("../../utils/localstorage");
var storage_1 = require("../../services/storage");
exports.AlgoliaStorage = {
    addPlpAssociation: function (payload) {
        var _a;
        var productId = payload.productId, associatedPlp = payload.associatedPlp;
        var plpAssociations = localstorage_1.unversionedStorage.get(storage_1.LocalStorageKeys.plpAssociation) || {};
        localstorage_1.unversionedStorage.set(storage_1.LocalStorageKeys.plpAssociation, __assign(__assign({}, plpAssociations), (_a = {}, _a[productId] = __assign(__assign({}, plpAssociations[productId]), associatedPlp), _a)));
    },
    clearPlpAssociations: function () {
        localstorage_1.unversionedStorage.set(storage_1.LocalStorageKeys.plpAssociation, {});
    },
    getAlgoliaEvents: function () {
        return localstorage_1.unversionedStorage.get(storage_1.LocalStorageKeys.algoliaEvents);
    },
    getPlpAssociations: function () {
        var plpAssociations = localstorage_1.unversionedStorage.get(storage_1.LocalStorageKeys.plpAssociation);
        return plpAssociations || {};
    },
    setAlgoliaEvents: function (payload) {
        localstorage_1.unversionedStorage.set(storage_1.LocalStorageKeys.algoliaEvents, payload);
    }
};
