"use strict";
/* eslint-disable */
exports.__esModule = true;
exports.GoogleAnalyticsCategoryNames = exports.GoogleAnalyticsEventTypes = void 0;
var GoogleAnalyticsEventTypes;
(function (GoogleAnalyticsEventTypes) {
    GoogleAnalyticsEventTypes["trackEvent"] = "trackEvent";
})(GoogleAnalyticsEventTypes = exports.GoogleAnalyticsEventTypes || (exports.GoogleAnalyticsEventTypes = {}));
var GoogleAnalyticsCategoryNames;
(function (GoogleAnalyticsCategoryNames) {
    GoogleAnalyticsCategoryNames["ecommerce"] = "Ecommerce";
    GoogleAnalyticsCategoryNames["search"] = "Search Results";
    GoogleAnalyticsCategoryNames["algoliaSearchEvent"] = "algoliaSearchEvent";
})(GoogleAnalyticsCategoryNames = exports.GoogleAnalyticsCategoryNames || (exports.GoogleAnalyticsCategoryNames = {}));
