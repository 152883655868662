import {
  ADMIN_MODE,
  AJV_AVAILABLE,
  APP_IS_READY,
  CAPTCHA_AVAILABLE,
  DETECTED_COUNTRY,
  EMAIL_VALIDATION_AVAILABLE,
  FORM_VALIDATION_AVAILABLE,
  LOADED_DEPENDENCIES,
  PHONE_LIBRARY_AVAILABLE,
  PRETOGGLE_LANGUAGE,
  SEEN_CAPTCHA,
  SET_ACTIVE_TOOLTIP,
  SET_CAPTCHA_TOKEN_V3,
  SET_FOCUSSED_NAV_ITEM,
  SET_LANG_DIR,
  SHOW_V2_CAPTCHA,
  TERRITORIES_AVAILABLE,
  USED_TAB,
  USER_AUTH_AVAILABLE,
} from 'state/actions'
import { getLangDir } from 'utils/getLangDir'
import { updateHtmlDir } from 'utils/updateHtmlDir'
import { getDetectedCountry } from 'services/api'

export const appIsReady = () => {
  return {
    type: APP_IS_READY,
  }
}

export const savePreToggleLanguage = payload => {
  return {
    type: PRETOGGLE_LANGUAGE,
    payload,
  }
}

export const setAdminMode = () => {
  return {
    type: ADMIN_MODE,
  }
}

export const setFocussedNavItem = payload => {
  const itemInDom = document.getElementById(`navItem_${payload}`)

  if (itemInDom) {
    itemInDom.focus()
  }

  return {
    type: SET_FOCUSSED_NAV_ITEM,
    payload,
  }
}

export const usedTab = () => {
  return {
    type: USED_TAB,
  }
}

export const emailValidationAvailable = () => {
  return {
    type: EMAIL_VALIDATION_AVAILABLE,
  }
}

export const ajvAvailable = () => {
  return {
    type: AJV_AVAILABLE,
  }
}

export const formValidationAvailable = () => {
  return {
    type: FORM_VALIDATION_AVAILABLE,
  }
}

export const phoneLibraryAvailable = () => {
  return {
    type: PHONE_LIBRARY_AVAILABLE,
  }
}

export const territoriesAvailable = () => {
  return {
    type: TERRITORIES_AVAILABLE,
  }
}

export const userAuthAvailable = () => {
  return {
    type: USER_AUTH_AVAILABLE,
  }
}

export const seenCaptcha = () => {
  return {
    type: SEEN_CAPTCHA,
  }
}

export const captchaAvailable = () => {
  return {
    type: CAPTCHA_AVAILABLE,
  }
}

export const setV2CaptchaId = (captchaId) => {
  return {
    type: SHOW_V2_CAPTCHA,
    payload: captchaId,
  }
}

export const fetchV3CaptchaToken = ({
  v3Key,
  allowedActions,
}) => async(dispatch) => {
  const v3TokenPromises = allowedActions.map(action => window.grecaptcha.enterprise.execute(v3Key, { action }))

  const tokens = await Promise.all(v3TokenPromises)
  const tokenMap = tokens.reduce((map, token, index) => {
    return {
      ...map,
      [allowedActions[index]]: token,
    }
  }, {})

  dispatch({
    type: SET_CAPTCHA_TOKEN_V3,
    payload: tokenMap,
  })
}

export const updateLangDir = (language) => {
  const langDir = getLangDir(language)
  updateHtmlDir(langDir)

  return {
    type: SET_LANG_DIR,
    payload: langDir,
  }
}

export const loadedDependencies = (language) => ({
  type: LOADED_DEPENDENCIES,
  payload: language,
})

export const setActiveTooltip = (tooltip) => ({
  type: SET_ACTIVE_TOOLTIP,
  payload: tooltip,
})

export const detectCountry = () => ({
  type: DETECTED_COUNTRY,
  payload: getDetectedCountry(),
})
