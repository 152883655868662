import React from 'react'
import { ProductSizeGuidesVBBody } from './ProductSizeGuidesVBBody'

export const ProductSizeGuidesVBPage = (props) => {
  const { sizeGuideProperties } = props

  return (
    <ProductSizeGuidesVBBody sizeGuideProperties={sizeGuideProperties} />
  )
}
