import { getPreviewSetting } from 'global-content/preview'
import { SENTRY_READY } from 'utils/constants'
import { getMetaOptions } from 'global-content/config'
import { ERROR_CODES } from 'utils/errorCodes'

const SENTRY_DEVELOPMENT_DSN_KEY = `792591e1b79bc074eb881b45bf06e4ac`
export async function setupLazySentry(key = SENTRY_DEVELOPMENT_DSN_KEY) {
  return new Promise((resolve, reject) => {
    if (
      !window.$PORTHOSTMAP && // NOTE: to load Sentry locally for debugging, comment out !window.$PORTHOSTMAP
      !window.location.hostname.includes(`localised-test`) &&
      !getPreviewSetting(`active`)
    ) {
      const script = document.createElement(`script`)
      script.onload = () => {
        sentryInit()
        resolve()
      }
      script.onerror = () => {
        reject()
      }

      script.crossorigin = `anonoymous`
      script.type = `text/javascript`
      script.src = `https://js.sentry-cdn.com/${key}.min.js`
      document.getElementById(`scripts`).appendChild(script)
    } else {
      reject(`[${ERROR_CODES[10003]}]`)
    }
  })
}

export function sentryInit() {
  window.Sentry.onLoad(function() {
    window.Sentry.init({
      beforeSend,
      environment: getMetaOptions(`environment`) || `development`,
      release: window.$VERSION,
    })

    window.Sentry.configureScope(function(scope) {
      scope.setUser({ id: window.$shoppingSessionId.value })
      scope.setTag(`gitTag`, window.$RELEASE)

      if (getMetaOptions(`clientTag`)) {
        scope.setTag(`clientTag`, getMetaOptions(`clientTag`))
        scope.setTag(`siteTag`, getMetaOptions(`siteTag`))
      }
    })

    document.dispatchEvent(new CustomEvent(SENTRY_READY, {
      detail: null,
    }))
  })
}

// https://github.com/getsentry/sentry-javascript/issues/2380
// https://github.com/getsentry/sentry-javascript/issues/2210#issuecomment-540553675
// eslint-disable-next-line no-unused-vars
function beforeSend(event, hint) {
  // const error = hint.originalException

  if (
    event.extra?.__serialized__?.detail?.reason?.message === `Extension context invalidated.`
  ) {
    return null
  }

  // NOTE: Uncomment to debug sentry events
  // console.log({ event, hint })

  return event
}

export function forceLoadSentry() {
  if (window.Sentry && window.Sentry.forceLoad) {
    window.Sentry.forceLoad()
  }
}
