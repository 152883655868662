import queryString from 'query-string'

export function tokenCookie() {
  const { token } = queryString.parse(window.location.search)

  if (token) {
    const days = 100
    const date = new Date()
    const unixDaysFromNow = date.getTime() + (days * 24 * 60 * 60 * 1000)
    date.setTime(unixDaysFromNow)
    const expires = date.toUTCString()
    document.cookie = `token=${token};expires=${expires}; path=/`
  }
}
