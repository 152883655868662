import React from 'react'

import { LazyModuleWrapper } from 'components/LazyModuleWrapper'

const LazyTracking = (
  React.lazy(() => import(/* webpackChunkName: "Tracking" */ `./Tracking`))
)

export const Tracking = props => (
  <LazyModuleWrapper>
    <LazyTracking {...props} />
  </LazyModuleWrapper>
)
