export function measureNodes(nodeList) {
  const div = document.createElement(`div`)
  div.style.display = `inline-block`
  div.style.position = `absolute`
  div.style.pointerEvents = `none`
  div.style.opacity = 0

  Array.from(nodeList).filter(Boolean).forEach(item => {
    const wrapper = document.createElement(`div`)
    wrapper.appendChild(item.cloneNode(true))
    div.appendChild(wrapper)
  })

  document.body.appendChild(div)
  const width = div.offsetWidth
  document.body.removeChild(div)

  return {
    width,
  }
}
