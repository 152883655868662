import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { setInches } from 'state/actions/user-preferences'
import { I18n } from 'utils/i18n'
import { ToggleSwitch } from 'components/ToggleSwitch'

import { webLabels } from './fixtures'
import styles from './InchToggle.css'

export const InchToggle = () => {
  const dispatch = useDispatch()
  const inches = useSelector(state => state.userPreferences.inches)

  return (
    <div className={styles.inchToggle}>
      <button
        aria-label={I18n.t(webLabels.inch)}
        className={styles.first}
        name={I18n.t(webLabels.inch)}
        onClick={() => dispatch(setInches(true))}
      >
        {I18n.t(webLabels.inch)}
      </button>
      <ToggleSwitch
        checked={!inches}
        id="measurementsToggle"
        onClick={() => dispatch(setInches(!inches))}
      />
      <button
        className={styles.second}
        name={I18n.t(webLabels.cm)}
        onClick={() => dispatch(setInches(false))}
      >
        {I18n.t(webLabels.cm)}
      </button>
    </div>
  )
}
