import React from 'react'
import { useSelector } from 'react-redux'
import uniq from 'lodash/uniq'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import compact from 'lodash/compact'

import { HITS_PER_PAGE } from 'utils/constants'
import * as algolia from 'services/algolia'
import { getAlgoliaIndex } from 'global-content/algolia'
import { useDeepCompare } from 'hooks/useDeepCompare'
import { isTracking } from 'utils/tracking'

export function useProductTray({
  analytics = true,
  analyticsTitle,
  analyticsComponent,
  algoliaIndex,
  itemsToShow,
  lazy,
  posterItems = [],
  retryParams,
  retryUsing,
  selectedSort,
  tabs,
  supportLink,
  ruleContexts,
}) {
  const deepCheck = useDeepCompare(tabs)
  const activeLanguage = useSelector(state => state.language.active)
  const [triedFetch, setTriedFetch] = React.useState(false)
  const [activeTab, setActiveTab] = React.useState(0)
  const [availableTabs, setAvailableTabs] = React.useState([])
  const [tabProductLists, setTabProductLists] = React.useState([])
  const [tabPosterLists, setTabPosterLists] = React.useState([])
  const [showTray, setShowTray] = React.useState(true)

  React.useEffect(() => {
    if (!lazy) {
      setTriedFetch(false)
      const tabPostersList = []
      const tabProductPromises = tabs.map((tab, index) => {
        const tabPosters = getTabPosters(posterItems, index)
        tabPostersList.push(tabPosters)

        const {
          isBSC,
          filters: tabFilters,
          query: tabQuery,
        } = tab

        let products
        try {
          products = getNewProducts({
            analytics,
            analyticsTitle,
            analyticsComponent,
            language: activeLanguage,
            algoliaIndex,
            isBSC,
            filters: tabFilters,
            itemsToShow,
            itemsOffset: tabPosters.length,
            hitsPerPage: HITS_PER_PAGE, // to guarantee the ranking order is correct
            query: tabQuery,
            retryUsing,
            retryParams,
            selectedSort,
            ruleContexts,
          })
        } catch (e) {
          products = Promise.resolve([])
        }
        return products
      })

      Promise.all(tabProductPromises).then(productsByTab => {
        setTabProductLists(productsByTab.filter(list => list.length))
        setAvailableTabs(tabs.filter((_, index) => productsByTab[index].length))
        setTabPosterLists(tabPostersList.filter((_, index) => productsByTab[index].length))
        setShowTray(productsByTab.filter(list => list.length).length > 0)
      }).catch(() => {
        setShowTray(false)
      }).finally(() => {
        // relocated to end race condition
        setTriedFetch(true)
      })
    }
  }, [lazy, deepCheck])

  const activeSupportLink = React.useMemo(() => {
    if (availableTabs.length && availableTabs[activeTab].supportLink) {
      return {
        ...supportLink,
        ...availableTabs[activeTab].supportLink,
      }
    }
    return supportLink
  }, [activeTab, availableTabs])

  return {
    activeTab,
    setActiveTab,
    availableTabs,
    products: tabProductLists[activeTab],
    posters: tabPosterLists[activeTab],
    showTray,
    triedFetch,
    activeSupportLink,
  }
}

function getNewProducts({
  analytics,
  analyticsTitle,
  analyticsComponent,
  algoliaIndex,
  collectedVariants = [],
  filters,
  hitsPerPage,
  isBSC,
  itemsToShow,
  itemsOffset = 0,
  language,
  query,
  retryParams = [],
  retryUsing,
  selectedSort,
  ruleContexts,
}) {
  const distinct = algolia.getDistinct(isBSC)

  const options = {
    distinct,
    filters,
    hitsPerPage,
    query,
    clickAnalytics: isTracking(),
    ruleContexts,
    analyticsTags: [
      analyticsComponent ? analyticsComponent : `ProductTray`,
      ...(analyticsTitle ? [analyticsTitle] : []),
    ],
  }

  return algolia.getProducts({
    analytics: analytics && isTracking(),
    clickAnalytics: isTracking(),
    language,
    index: getAlgoliaIndex(algoliaIndex),
    options,
    priorityOptions: {
      selectedSort,
    },
  }).then(data => {
    // Debugging
    // console.log({ filters })
    // console.log([...collectedVariants.map(v => v.productId), ...data.hits.map(v => v.productId)])

    // If the response has less than {{hitsPerPage}}, try again. Pass on the already collected products
    // So they can be used to check if there are enough products
    // And to exlude their ids from future calls
    const offsettedItemsToShow = itemsToShow - itemsOffset

    if (
      (data.hits.length + collectedVariants.length) < offsettedItemsToShow &&
      retryParams.length
    ) {
      let retryFilters = filters
      retryFilters = {
        ...filters,
        productId: {
          excludes: uniq([
            ...get(filters, `productId.excludes`, []),
            ...collectedVariants.map(v => v.productId),
            ...data.hits.map(v => v.productId),
          ]),
        },
      }

      const {
        newFilters,
        newParams,
        newRetryUsing,
      } = retryParameters({
        filters: retryFilters,
        params: retryParams,
        retry: retryUsing,
      })

      return getNewProducts({
        analytics: false,
        analyticsTitle,
        analyticsComponent,
        algoliaIndex,
        collectedVariants: [...collectedVariants, ...data.hits],
        filters: newFilters,
        itemsToShow,
        itemsOffset,
        language,
        hitsPerPage,
        query,
        retryParams: newParams,
        retryUsing: newRetryUsing,
        selectedSort,
      })
    }

    let variantOrder = [
      ...collectedVariants,
      ...data.hits,
    ]

    if (filters && filters.collections) {
      variantOrder.sort((a, b) => {
        const firstCollection = filters.collections.includes[0]
        const aRank = a.collections && a.collections.find(t => t.tag === firstCollection)
        const bRank = b.collections && b.collections.find(t => t.tag === firstCollection)

        if (aRank && bRank) {
          return (
            aRank.rank - bRank.rank
          )
        }
      })
    }

    if (filters && filters.code) {
      variantOrder = mapOrder(variantOrder, filters.code.includes, `sku`)
    }

    return variantOrder.slice(0, offsettedItemsToShow)
  })
}

function retryParameters({
  filters,
  params,
  retry,
}) {
  let newFilters = cloneDeep(filters)
  const newParams = cloneDeep(params)
  const newRetryUsing = cloneDeep(retry)

  if (!retry) {
    for (let i = 0; i < newParams.length; i++) {
      const parameter = params[i]
      newParams.shift()

      if (Object.prototype.hasOwnProperty.call(newFilters, parameter)) {
        delete newFilters[parameter]
        break
      }
    }
  }

  if (retry) {
    newFilters = cloneDeep(newRetryUsing)

    const facet = Object.keys(newRetryUsing)[0]
    const includes = newRetryUsing[facet].includes
    newFilters[facet].includes = [includes[includes.length - 1]]
    newFilters.productId = filters.productId
    includes.pop()

    if (!includes.length) {
      delete newRetryUsing[facet]
    }
  }

  return {
    newFilters,
    newParams,
    newRetryUsing,
  }
}

function mapOrder(array, order, key) {
  const orderMap = {}
  const result = []

  // create an object which maps the order
  order.map((item, i) => {
    orderMap[item] = i
  })

  // add the items directly into their positions
  // this will leave empty (undefined) spots in the array
  // if the order array is longer than the provided array
  array.map(item => {
    result[orderMap[item[key]]] = item
  })

  // remove the empty spots and return
  return compact(result)
}

function getTabPosters(posterItems, activeTab) {
  return posterItems.filter(item =>
    item.data.component === `posterCard` && item.data.config.tab === activeTab
  )
}