import React from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { useRegisterKeyHandlers } from 'hooks/useRegisterKeyHandlers'
import { Layout } from 'components/Layout'
import { NavItem } from 'components/NavItem'
import { useNavHeightsOffset } from 'hooks/useNavHeightsOffset'
import {
  clickNavItem,
  getNavItemTargetId,
  keyCaller,
  moveToNextNavItem,
  moveToNextNavMenu,
  moveToPreviousNavItem,
  moveToPreviousNavMenu,
} from 'utils/navigation-utils'

import { webLabels } from './fixtures'
import styles from './NavigationList.css'

export const NavigationList = ({
  layout,
  navItems,
  parentMenuIndexes,
  positioning = {},
  styling = {},
  navMenuIndex,
}) => {
  const offset = useNavHeightsOffset(navMenuIndex)

  const {
    itemGap,
    itemHeight,
    itemPadding,
    justifyContent,
  } = positioning

  const {
    backgroundColor,
    activeState,
    hoverState,
    type,
  } = styling

  const dispatch = useDispatch()
  const focussedNavItems = useSelector(state => state.site.focussedNavItems)
  const focussedMenu = useSelector(state => state.site.focussedNavMenu)
  const [horizontalOffset, setHorizontalOffset] = React.useState(0)
  const activeParent = document.getElementById(getParentId())

  React.useEffect(() => {
    if (activeParent) {
      setTimeout(() => {
        const navigation = document.querySelector(`[data-testid="navigation"]`)

        if (navigation) {
          const navCoords = navigation.getBoundingClientRect()
          const parentCoords = activeParent.getBoundingClientRect()
          setHorizontalOffset((parentCoords.x + (parentCoords.width / 2)) - navCoords.x)
        }
      }, 0)
    }
  }, [activeParent])

  useRegisterKeyHandlers({
    condition: navItems.length && focussedMenu === navMenuIndex,
    event: `keydown`,
    handlers,
  })

  if (navItems.length) {
    const absolute = navMenuIndex > 0

    return (
      <div
        aria-label={webLabels.openMenu}
        className={classNames({
          [styles.absolute]: absolute,
        })}
        role="menubar"
        style={{
          '--gap': itemGap,
          backgroundColor,
          left: absolute && !isNaN(horizontalOffset) && horizontalOffset,
          transform: absolute && `translate(-50%)`,
          top: absolute && offset,
        }}
      >
        <Layout layout={layout}>
          <div
            className={styles.list}
            style={{
              justifyContent,
            }}
          >
            {navItems.map((value, index) => {
              return (
                <NavItem
                  activeState={activeState}
                  hoverState={hoverState}
                  index={index}
                  itemHeight={itemHeight}
                  itemPadding={itemPadding}
                  key={value.path}
                  navMenuIndex={navMenuIndex}
                  parentMenuIndexes={parentMenuIndexes}
                  type={type}
                  value={value}
                >
                  <div className={styles.listLink}>
                    {value.label}
                  </div>
                </NavItem>
              )
            })}
          </div>
        </Layout>
      </div>
    )
  }

  return null

  function getParentId() {
    const suffix = focussedNavItems.slice(0, navMenuIndex).join(`_`)
    return `navItem_${suffix}`
  }

  function handlers(e) {
    const keyMap = {
      ArrowUp: handleUp,
      ArrowDown: handleDown,
      Enter: clickNavItem,
      Escape: moveToPreviousNavMenu,
    }

    if (keyMap[e.key]) {
      e.preventDefault()

      keyCaller({
        dispatch,
        navMenuIndex,
        handler: keyMap[e.key],
      })
    }
  }
}

function handleUp(args) {
  if (getNavItemTargetId(args.focussedNavItems, `previous`)) {
    return moveToPreviousNavItem(args)
  } else {
    return moveToPreviousNavMenu(args)
  }
}

function handleDown(args) {
  if (getNavItemTargetId(args.focussedNavItems, `next`)) {
    moveToNextNavItem(args)
  } else {
    moveToNextNavMenu(args)
  }
}
