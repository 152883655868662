import React from 'react'
import { LazyModuleWrapper } from 'components/LazyModuleWrapper'

const LazyForm = React.lazy(() => (
  import(/* webpackChunkName: "Form" */ `./Form`)
))

export const Form = React.forwardRef(function Form(props, ref) {
  return (
    <LazyModuleWrapper isPage={false}>
      <LazyForm
        {...props}
        ref={ref}
      />
    </LazyModuleWrapper>
  )
})
