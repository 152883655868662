import { useDispatch, useSelector } from 'react-redux'

import { updateSelectedDetailOption } from 'state/actions/details'
import { useUrlManager } from 'hooks/useUrlManager'
import { usePromotions } from 'hooks/usePromotions'
import { translate } from 'utils/translate'
import { toListingProduct } from 'models/product'


export function useProductOptionSelect() {
  const dispatch = useDispatch()
  const urlManager = useUrlManager()
  const getPromotions = usePromotions()
  const activeLanguage = useSelector(state => state.language.active)
  const selectedSlugs = useSelector(state => state.details.selectedSlugs)

  return function handleSelect(options, product, isValid) {
    if (product) {
      const item = normalizeProduct(product)
      urlManager({
        method: `push`,
        pathname: {
          value: `${item.url}`,
        },
        params: {
          replace: {
            ...selectedSlugs,
            color: options.color,
          },
        },
      })
      return
    }
    dispatch(updateSelectedDetailOption({
      options,
      isValid,
      urlManager,
      getPromotions,
    }))
  }

  function normalizeProduct(product) {
    const translatedProduct = translate({
      content: product,
      language: activeLanguage,
      returnSomething: true,
    })

    return toListingProduct(translatedProduct, getPromotions)
  }
}
