export function getNavCategories(obj) {
  if (!obj.filters?.categories) {
    return
  }

  if (Array.isArray(obj.filters.categories)) {
    return obj.filters.categories
  }

  if (obj.filters.categories.includes) {
    return obj.filters.categories.includes
  }

  return
}
