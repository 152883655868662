import React from 'react'
import { useSelector } from 'react-redux'

export const Factory = ({
  items = [],
}) => items.map((item, idx) => (
  <FactoryComponent
    key={item.id || idx}
    {...item}
  />
))

const FactoryComponent = (props) => {
  const components = useSelector(state => state.components)
  const Component = components[props.data?.component]

  if (!Component) {
    return <div />
  }

  return (
    <Component
      key={props.id}
      {...props}
    />
  )
}
