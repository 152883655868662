import { matchPath, useLocation } from 'react-router-dom'

import { CHECKOUT_ROUTE } from 'Router/fixtures'

export function useIsCheckout() {
  const location = useLocation()
  const isCheckout = matchPath(location.pathname, { path: CHECKOUT_ROUTE })

  return isCheckout
}
