import React from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { closePullout } from 'state/actions/pullout'
import { overlayOff } from 'state/actions/overlay'

import styles from './Overlay.css'

export const Overlay = () => {
  const dispatch = useDispatch()
  const {
    megamenu,
    mobileSearch,
    pullout,
    tooltip,
  } = useSelector(state => state.overlay)

  if (megamenu || mobileSearch || pullout || tooltip) {
    return (
      <div
        className={classNames({
          [styles.mobile]: pullout || mobileSearch,
          [styles.mobileSearch]: mobileSearch,
          [styles.pullout]: pullout,
          [styles.tooltip]: tooltip,
          [styles.overlay]: megamenu,
        })}
        data-testid="overlay"
        onClick={() => pullout && closeMobile(`pullout`)}
      />
    )
  }

  return null

  function closeMobile(overlay) {
    dispatch(overlayOff(overlay))
    dispatch(closePullout())
  }
}
