import { getMetaOptions } from "global-content/config"

export function setHrefLangTags() {
  const languages = getMetaOptions(`languages`)

  languages.forEach(lang => {
    addHrefLangTag(lang)
  })
}

export function updateHrefLangTags() {
  const languages = getMetaOptions(`languages`)

  languages.forEach(lang => {
    const hrefLang = getHrefLang(lang)
    const link = document.querySelector(`link[hreflang="${hrefLang}"]`)

    if (link) {
      link.href = getMetaOptions(`countryFolder`) ? getHref(lang) : getHref(hrefLang)
    }
  })
}

function addHrefLangTag(lang) {
  const link = document.createElement(`link`)
  const hrefLang = getHrefLang(lang)

  link.rel = `alternate`
  link.hreflang = hrefLang
  link.href = getMetaOptions(`countryFolder`) ? getHref(lang) : getHref(hrefLang)

  document.querySelector(`head`).appendChild(link)
}

function getHrefLang(lang) {
  const country = getMetaOptions(`country.code`).toLowerCase()

  return `${lang}-${country}`
}

function getHref(hreflang) {
  return `https://${window.$META.host}/${hreflang}/${withoutLocale()}`
}

function withoutLocale() {
  return window.location.pathname.split(`/`).slice(getMetaOptions(`countryFolder`) ? 3 : 2).join(`/`)
}
