import slugify from 'slugify'

export function toSlug(input) {
  if (input) {
    let result = input.split(`>`).join(` `) // replace `>` with space
    result.trim() // trim whitespace around
    result = slugify(result, {
      lower: true,
    })
    return result
  }
  return input
}
