import { unversionedStorage } from 'utils/storage'
import {
  OBSERVED_PRODUCT_KEY,
  TOTAL_OBSERVED_PRODUCTS,
} from 'utils/constants'

export const registerSeenProduct = (productId) => {
  if (!productId) {
    return
  }

  const seenProductIds = unversionedStorage.get(OBSERVED_PRODUCT_KEY) || []

  const seenProductIndex = seenProductIds.indexOf(productId)

  if (seenProductIndex > -1) {
    // Remove item from array
    seenProductIds.splice(seenProductIndex, 1)
  }

  // Place Item at top of array
  seenProductIds.unshift(productId)
  unversionedStorage.set(OBSERVED_PRODUCT_KEY, seenProductIds.slice(0, TOTAL_OBSERVED_PRODUCTS))
}

export const getSeenProducts = (excludedProductIds) => {
  let productIds = unversionedStorage.get(OBSERVED_PRODUCT_KEY) || []

  if (excludedProductIds && excludedProductIds.length) {
    productIds = productIds.filter(id => !excludedProductIds.includes(id))
  }

  return productIds
}
