import React from 'react'
import classNames from 'classnames'

import { useRemapTextAlign } from 'hooks/useRemapTextAlign'
import { sizeFallThrough } from 'utils/sizeFallThrough'
import { HtmlText } from 'components/HtmlText'

import styles from './Subtitle.css'

export const Subtitle = ({
  'data-testid': testId,
  content = {},
  styling = {},
}) => {
  const {
    html,
  } = content

  const {
    borderBottomColor,
    borderBottomStyle,
    borderBottomWidth,
    color,
    fontFamily,
    fontSize,
    fontStyle,
    fontWeight,
    lineHeight,
    paddingBottom,
    textAlign: textAlignRaw,
    uppercase,
  } = styling

  const textAlign = useRemapTextAlign(textAlignRaw)

  return (
    <div
      className={classNames(`Subtitle__subtitle`, {
        [styles.xxl]: fontSize === `xxl`,
        [styles.xl]: fontSize === `xl`,
        [styles.large]: fontSize === `large`,
        [styles.medium]: fontSize === `medium`,
        [styles.small]: fontSize === `small`,
        [styles.xs]: fontSize === `xs`,
      })}
      data-testid={testId}
      style={{
        color,
        fontSize: sizeFallThrough([`xxl`, `xl`, `large`, `medium`, `small`, `xs`, `xsRegular`], fontSize),
        fontStyle,
        fontWeight,
        lineHeight,
        paddingBottom,
        textAlign,
        textTransform: uppercase && `uppercase`,
      }}
    >
      <div
        style={{
          borderBottomColor,
          borderBottomWidth,
          borderBottomStyle,
          display: `inline-block`,
        }}
      >
        <HtmlText
          fontFamily={fontFamily}
          html={html}
        />
      </div>
    </div>
  )
}
