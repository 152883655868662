import React, { useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { useLocation } from 'react-router'
import { get } from 'lodash'
import { clearRedirect } from "state/actions/listing"

export function useProductListingRedirect() {
  const redirect = useSelector((s) => get(s, `listing.userData.redirect`))
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(clearRedirect())
    }
  }, [pathname])


  return React.useMemo(() => {
    return (pathname === `/search` && redirect) ? redirect : null
  }, [redirect, pathname])
}
