import React, { useContext } from 'react'
import { AccordionContext } from './Accordion'
import { ModernFactoryComponent } from '../ModernFactoryComponent'

export const AccordionMenu = ModernFactoryComponent(({
  children,
  Tag,
  data: {
    index,
  },
  ...props
}, ref) => {
  const {
    activePanel,
  } = useContext(AccordionContext)

  const isSelected = activePanel === index

  return (
    <Tag
      {...props}
      hidden={!isSelected}
      ref={ref}
    >
      {children}
    </Tag>
  )
})
