export const webLabels = {
  brand: `WEB.LISTING.FILTER.BRAND.TITLE`,
  color: `WEB.LISTING.FILTER.COLOR.TITLE`,
  price: `WEB.LISTING.FILTER.PRICE.TITLE`,
  size: `WEB.LISTING.FILTER.SIZE.TITLE`,
  style: `WEB.LISTING.FILTER.STYLE.TITLE`,
  collartype: `WEB.LISTING.FILTER.COLLARTYPE.TITLE`,
  sizetype: `WEB.LISTING.FILTER.SIZETYPE.TITLE`,
  taxCalcError: `WEB.CHECKOUT.ERROR.CART-TAX-CALC`,
  filterLabels: `WEB.LISTING.FILTER.*`,
}
