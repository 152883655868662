import camelCase from 'lodash/camelCase'

export function filterData(_data = {}, ...filters) {
  const data = {}
  const filteredData = {}

  // Initialize an object for each filter
  filters.forEach(filter => {
    filteredData[filter + `Data`] = {}
  })

  for (const key in _data) {
    let filtered = false

    for (const filter of filters) {
      if (key.startsWith(filter)) {
        const newKey = camelCase(key.replace(filter, ``))
        filteredData[filter + `Data`][newKey] = _data[key]
        filtered = true
        break
      }
    }

    if (!filtered) {
      data[key] = _data[key]
    }
  }

  return { data, ...filteredData }
}