import {
  LOAD_BRANDS,
} from 'state/actions'

import { algoliaFiltersMap } from 'services/algolia'

const initialState = {
  brandIndexes: {},
}

const brands = (state = initialState, action) => {
  switch (action.type) {
  case `${LOAD_BRANDS}_FULFILLED`: {
    const { facets } = action.payload.brands

    return {
      ...state,
      brandIndexes: {
        ...state.brandIndexes,
        [action.payload.l1]: categorizeBrands(
          facets[algoliaFiltersMap.get().brand.nameInAlgolia],
          action.payload.allFilters.brand
        ),
      },
    }
  }
  default:
    return state
  }
}

function categorizeBrands(brandsList, brandFilters) {
  const brandCategories = generateBrandCategories()
  const categorizedBrands = {}

  brandCategories.forEach(category => {
    const sortedBrands = (
      Object.keys(brandsList)
        .filter(brand => brand.match(category.match))
        .sort()
        .map(brand => {
          // TODO: Why might there be a missing brand? Test data wrong?
          return brandFilters[brand] || {}
        })
    )

    if (sortedBrands.length) {
      categorizedBrands[category.value] = sortedBrands
    }
  })

  return categorizedBrands
}

function generateBrandCategories() {
  let filters = []
  for (let i = `A`.charCodeAt(0); i <= `Z`.charCodeAt(0); i++) {
    filters.push({
      label: String.fromCharCode(i),
      match: new RegExp(`^${String.fromCharCode(i)}`, `ig`),
      value: String.fromCharCode(i).toLowerCase(),
    })
  }
  filters.push({
    label: `0-9`,
    match: /^\d/ig,
    value: `0-9`,
  })

  return filters
}

export default brands
