import React from 'react'

import { I18n } from 'utils/i18n'
import { Svg } from 'components/Svg'
import { Heading } from 'components/Heading'
import { Text } from 'factory-components/Text'
import { Button } from 'factory-components/Button'

import styles from './EmailSignupSplashSuccess.css'

export const EmailSignupSplashSuccess = ({
  content = {},
  styling = {},
  positioning = {},
}) => {
  return (
    <div
      className={styles.wrapper}
      data-testid="email-signup-splash-success"
    >
      {content.svg && (
        <div
          className={styles.iconWrapper}
          data-testid="email-signup-splash-success-icon-wrapper"
          style={{ ...positioning.icon }}
        >
          <Svg
            className={styles.icon}
            icon="ok"
            size="4rem"
            {...content.svg}
          />
        </div>
      )}
      {content.title &&
      <div
        className={styles.header}
        data-testid="email-signup-splash-success-header-wrapper"
        style={{ ...positioning.title }}
      >
        <Heading
          content={{
            ...content.title,
          }}
          data-testid="email-signup-splash-success-header"
          styling={{
            color: `black`,
            textAlign: `center`,
            fontSize: `large`,
            fontWeight: 400,
            ...styling.title,
          }}
        />
      </div>
      }
      {content.details?.length &&
      (
        <div
          className={styles.details}
          data-testid="email-signup-splash-success-detail"
          style={{ ...positioning.details }}
        >
          {content.details.map(detailText => (
            <Text
              data={{
                content: {
                  html: I18n.t(detailText),
                },
                styling: {
                  ...styling.details,
                },
              }}
              key={detailText}
            />
          ))}
        </div>
      )
      }
      {content.button &&
      <div
        data-testid="email-signup-splash-success-button-wrapper"
        style={{ ...positioning.button }}
      >
        <Button
          className={styles.button}
          data={{
            content: {
              href: `/`,
              html: content.button.text,
            },
            styling: {
              type: `primary`,
              fontFamily: `secondary`,
              borderRadius: `roundedSides`,
              ...styling.button,
            },
          }}
          data-testid="email-signup-splash-success-button"
        />
      </div>
      }
    </div>
  )
}
