import React from 'react'

import { Highlight } from 'components/Highlight'

import styles from './HighlightBox.css'

export const HighlightBox = ({
  content = {},
  styling = {},
}) => {
  const {
    backgroundColor,
    borderColor,
    ...additionalStyles
  } = styling

  const inline = {
    backgroundColor,
    borderColor,
  }

  return (
    <div
      className={styles.highlightBox}
      style={inline}
    >
      <Highlight
        content={content}
        styling={{ iconSize: `3.5rem`, ...additionalStyles }}
      />
    </div>
  )
}
