import { loadValidation } from 'services/api'
import { ErrorAdditional, SENTRY_TAGS } from 'utils/ErrorAdditional'
import { getMetaOptions } from 'global-content/config'

function fetchJson(filename, options) {
  return fetch(`${filename}?cb=${window.$cacheBust}`)
    .then(response => handleMissing(response, filename))
    .then(text => parseJSON(text, filename, options))
}

function handleMissing(response, filename) {
  if (response.status === 403) { // 403 Forbidden by WAF
    wafError(filename)
  }

  if (!response.ok) {
    jsonMissing(filename)
  }

  return response.text()
}

function parseJSON(text, filename, options) {
  try {
    return JSON.parse(text)
  } catch (err) {
    if (options?.allowMissing) {
      return {}
    }
    if (text && text.slice) {
      // Note: Print out a snippet of response text to add it to Sentry Breadcrumbs
      console.error(`Cannot parse text response: `, text.slice(0, 100))
    }

    // Note:
    // If JSON data file is missing, then the server returns index.html as fallback 404 since
    // we are in a SPA setup. We consider index.html an error since the .json file is missing.
    if (text.slice(0, 10).toLowerCase().startsWith(`<!doctype`)) {
      jsonMissing(filename)
    } else if (text.includes(`AccessDenied`)) {
      wafError(filename)
    } else {
      throw new Error(`Error trying to parse JSON from ${filename} ${text} ${options} ${err}`)
    }
  }
}

function jsonMissing(filename) {
  throw new ErrorAdditional({
    title: `JSON Missing`,
    message: `Cannot fetch JSON file ${filename}`,
    severity: 1,
    tags: {
      [SENTRY_TAGS.SOURCE_FILE]: `src/services/content.js`,
      [SENTRY_TAGS.FETCH_URL]: filename,
    },
  })
}

// WAF Error (Web Application Firewall) is returned when there is suspicious activity from an IP address
function wafError(filename) {
  throw new ErrorAdditional({
    title: `WAF Error`,
    message: `Blocked by WAF and cannot fetch JSON file ${filename}`,
    severity: 1,
    tags: {
      [SENTRY_TAGS.SOURCE_FILE]: `src/services/content.js`,
      [SENTRY_TAGS.FETCH_URL]: filename,
    },
  })
}

export function loadContent(file, options) {
  // todo: support other file extensions
  if (file.endsWith(`json`)) {
    const fetchUrl = `${getMetaOptions(`countryFolder`)}${file}`
    return fetchJson(fetchUrl, options)
      .then(data => {
        return ({
          content: data,
          name: file,
        })
      })
      .catch((err) => {
        throw new ErrorAdditional({
          title: `Error trying to load/parse JSON Content`,
          message: err.message,
          severity: 1,
          additional: {
            file,
            fetchUrl,
            options,
          },
          originalError: err,
          tags: {
            [SENTRY_TAGS.SOURCE_FILE]: `src/services/content.js`,
            [SENTRY_TAGS.FETCH_URL]: fetchUrl,
          },
        })
      })
  }

  throw new Error(`${file} doesn't end with json`)
}

export function loadCheckoutSchema(language) {
  return loadValidation(language)
}

export function loadAddressSchema(language, type) {
  return loadValidation(language, type)
}

export function loadSchema(file, name) {
  // todo: update this to use fetchJson for the error logging

  return fetch(`${getMetaOptions(`countryFolder`)}${file}`)
    .then(res => res.json())
    .then(schemaJson => {
      return ({
        schema: schemaJson,
        name: name,
      })
    })
}
