import { useContext } from 'react'
import { AccordionContext } from './Accordion'

export const AccordionMatch = ({
  children,
  data: {
    index,
  },
}) => {
  const {
    activePanel,
  } = useContext(AccordionContext)

  const isSelected = activePanel === index

  return isSelected ? children : null
}
