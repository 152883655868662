import React from 'react'

import { AccountWelcome } from 'components/AccountWelcome'
import { Button } from 'factory-components/Button'
import { Svg } from 'components/Svg'
import { useAccountMenuLinks } from './useAccountMenuLinks'

import styles from './AccountMenu.css'

export const AccountMenu = () => {
  const accountMenuLinks = useAccountMenuLinks()

  return (
    <div>
      <AccountWelcome />
      {
        accountMenuLinks.map(button => {
          const buttonTestId = button[`data-testid`]
          return (
            <Button
              className={styles.button}
              data={{
                content: {
                  href: button.path,
                },
                styling: {
                  type: `text`,
                },
              }}
              data-testid={`account-menu-${buttonTestId}`}
              key={button.label}
              onClick={button.onClick}
            >
              {button.icon &&
            <div className={styles.iconWrapper}>
              <Svg
                block
                icon={button.icon}
              />
            </div>
              }
              {button.label}
            </Button>
          )
        })}
    </div>
  )
}
