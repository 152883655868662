import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { updateGoogleAnalytics } from 'services/analytics'
import { useRouteChange } from 'hooks/useRouteChange'
import { changeLanguage } from 'state/actions/language'
import { updateLangDir } from 'state/actions/site'
import { contentDependencies } from 'global-content/contentDependencies'
import { getLocale } from 'utils'
import { Events } from 'design-system/services/events'

export function useChangeLanguage() {
  const languagesViewed = useSelector(state => state.site.languagesViewed)
  const adminMode = useSelector(state => state.site.adminMode)
  const dispatch = useDispatch()
  const {
    pathname,
    search,
  } = useLocation()
  const updateLocation = useRouteChange()

  return function switchLanguage(language) {
    if (!languagesViewed.includes(language)) {
      contentDependencies(language).then(() => {
        handleSwitch()
      })
    } else {
      handleSwitch()
    }

    function handleSwitch() {
      dispatch(changeLanguage(language))
      dispatch(updateLangDir(language))
      updateLocation({
        pathname,
        search,
      })
      !adminMode && updateGoogleAnalytics(`switchLanguage`, {
        text: language,
      })
      Events.emitLanguageSwitched({
        userLocale: getLocale(language),
      })
    }
  }
}
