import React from 'react'

import { LazyModuleWrapper } from 'components/LazyModuleWrapper'

const LazyOrderStatus = (
  React.lazy(() => import(/* webpackChunkName: "OrderStatus" */ `./OrderStatus`))
)

export const OrderStatus = props => (
  <LazyModuleWrapper>
    <LazyOrderStatus {...props} />
  </LazyModuleWrapper>
)
