import React, { useMemo } from 'react'
import { ModernFactoryComponent } from '../ModernFactoryComponent'

export const NavigationContext = React.createContext({})

export const Navigation = ModernFactoryComponent(({
  Tag,
  children,
  data: {
    enableHover = `true`,
  },
  ...props
}, ref) => {
  const [currentNav, setCurrentNav] = React.useState(``)
  const clearCurrentNav = () => setCurrentNav(``)

  const valueState = useMemo(() => ({
    currentNav,
    setCurrentNav,
    clearCurrentNav,
  }), [currentNav])

  return (
    <NavigationContext.Provider
      value={valueState}
    >
      <Tag
        {...props}
        onMouseLeave={enableHover === `true` ? clearCurrentNav : undefined}
        ref={ref}
      >
        {children}
      </Tag>
    </NavigationContext.Provider>
  )
})
