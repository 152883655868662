import React from 'react'
import classNames from 'classnames'

import { useMeasureNodes } from 'hooks/useMeasureNodes'
import { Button } from 'factory-components/Button'

import styles from './ButtonGroup.css'

export const ButtonGroup = ({
  buttons,
  matchButtonWidths,
  orientation,
}) => {
  const buttonRefs = React.useRef([])
  const width = useMeasureNodes(buttonRefs.current)
  const style = {
    width: matchButtonWidths && width ? width : `auto`,
  }

  return (
    <div
      className={classNames({
        [styles.horizontal]: orientation === `horizontal`,
        [styles.vertical]: orientation === `vertical`,
      })}
    >
      {buttons.map(renderButton)}
    </div>
  )

  function renderButton({
    data,
    onClick,
  }, i) {
    return (
      <Button
        data={data}
        key={`${data.content.href}-${data.content.html}`}
        onClick={onClick}
        ref={el => { buttonRefs.current[i] = el }}
        style={style}
      >
        {data.content.html}
      </Button>
    )
  }
}
