import React, { useEffect, useRef, useMemo } from 'react'
import { applyAnalytics } from './utilities/applyAnalytics'
import { filterData } from './utilities/filterData'

// eslint-disable-next-line no-unused-vars
export const ModernFactoryComponent = (Component) => React.forwardRef((props, _ref) => {
  let {
    data,
    analyticsData,
  } = useMemo(() => {
    return filterData(props.data, `analytics`)
  }, [props.data])

  props = { ...props, tabIndex: props?.tabindex }
  delete props.tabindex

  const ref = useRef()
  useEffect(() => {
    const htmlNode = ref.current
    if (htmlNode) {
      const clearAnalytics = applyAnalytics({ analyticsData, htmlNode })
      return () => {
        clearAnalytics()
      }
    }
  }, [])

  if (typeof Component === `function`) {
    Component = React.forwardRef(Component)
  }

  return props.data ? (
    <Component
      {...props}
      data={data}
      ref={ref}
    />
  ) : (
    <Component
      {...props}
      data={data}
    />
  )
})
