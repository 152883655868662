import { useDispatch, useSelector } from 'react-redux'
import { matchPath, useLocation } from 'react-router-dom'

import { useUrlManager } from 'hooks/useUrlManager'
import { usePromotions } from 'hooks/usePromotions'
import { PRODUCT_ROUTE } from 'Router/fixtures'
import { detailsPreload, updateSelectedDetailOption } from 'state/actions/details'

export function useDetailsPreload() {
  const { pathname } = useLocation()

  const dispatch = useDispatch()
  const urlManager = useUrlManager()
  const getPromotions = usePromotions()
  const currentProductId = useSelector(state => state.details.product?.id)

  return function onLinkClick({
    item,
    color,
    indexName,
    filters,
    queryID,
  }) {
    const isProductRoute = matchPath(pathname, {
      path: PRODUCT_ROUTE,
      exact: true,
      strict: true,
    })
    const shouldPreload = currentProductId !== item.id

    if (!isProductRoute || shouldPreload) {
      dispatch(detailsPreload({
        product: item,
        indexName,
        selectedSlugs: {
          color,
        },
        filters,
        queryID,
      }))
    } else {
      dispatch(updateSelectedDetailOption({
        options: {
          color: item.color.slug,
        },
        urlManager,
        getPromotions,
        queryID,
      }))
      window.scrollTo(0, 0)
    }
  }
}
