import get from 'lodash/get'

const PROMOTIONSMAP = {
  brands: {
    location: `brand.tag`,
  },
  brand: { // v1 name reference
    location: `brand.tag`,
  },
  categories: {
    location: `categories`,
  },
  collections: {
    location: `collections`,
    arrayKey: `tag`,
  },
  sale: {
    location: `sale`,
  },
  availability: {
    location: `availability`,
  },
}

export function isPromotionValid(promotion = {}, product, selectedSKU) {
  const { conditions = {} } = promotion
  const {
    exclusions,
    inclusions,
  } = conditions

  const specificSKU = {
    ...product,
    ...selectedSKU,
  }

  if (hasNoExclusions(exclusions, specificSKU)) { // exclusions overrule inclusions, so check if excluded first
    return hasInclusions(inclusions, specificSKU)
  }
}

export function hasNoExclusions(input, product) {
  if (isEmpty(input)) {
    return true
  }

  const inputKeys = Object.keys(input)
  let value = false

  for (let i = 0; i < inputKeys.length; i++) {
    const inputKey = inputKeys[i]
    const inputData = normalizeData(input[inputKey])
    const locationData = normalizedLocationData(product, inputKey)

    if (inputData.some(data => locationData.includes(data))) {
      value = false
      break
    } else {
      value = true
    }
  }

  return value
}

export function hasInclusions(input, product) {
  if (isEmpty(input)) {
    return true
  }

  const inputKeys = Object.keys(input)
  let value = false

  for (let i = 0; i < inputKeys.length; i++) {
    const inputKey = inputKeys[i]
    const inputData = normalizeData(input[inputKey])
    const locationData = normalizedLocationData(product, inputKey)

    if (inputData.some(data => locationData.includes(data))) {
      value = true
    } else {
      value = false
      break
    }
  }

  return value
}

function isEmpty(value) {
  if (!value) {
    return true
  }

  if (!Object.keys(value).length) {
    return true
  }

  return false
}

function normalizedLocationData(product, inputKey) {
  const {
    arrayKey,
    location,
  } = PROMOTIONSMAP[inputKey]

  return normalizeData(getLocationData({ product, location, arrayKey }))
}

function getLocationData({
  product,
  location,
  arrayKey,
}) {
  let data = get(product, `${location}`)

  if (arrayKey) {
    return data.map(item => item[arrayKey])
  }

  return data
}

// always work with an array to open up the
// use of array methods .some and .includes
function normalizeData(data) {
  if (Array.isArray(data)) {
    return data
  }

  return [data]
}
