"use strict";
exports.__esModule = true;
exports.focusableElementsList = void 0;
exports.focusableElementsList = [
    "button",
    "[tabindex=\"0\"]",
    "a[href]:not([tabindex=\"-1\"])",
    "input:not([type=\"hidden\"]):not([readonly])",
    "select",
    "textarea",
];
