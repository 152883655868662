import { augmentation } from 'utils/augmentation'
import isNil from 'lodash/isNil'
import uuidv4 from 'uuid/v4'

export function augmentListPosters({
  items,
  posters = [],
}) {
  let _items = [...items]
  let pageOffsets = []
  let normalizedPosters = posters.map(poster => ({ ...poster, id: uuidv4() }))
  const postersListTag = findListingProductGridTag(items)

  if (posters.length && postersListTag) {
    const listAugmentationItem = {
      items: normalizedPosters,
      data: {
        augmentation: {
          target: postersListTag,
          method: `update`,
        },
      },
    }

    _items = augmentation(_items, [listAugmentationItem])
    pageOffsets = generatePosterOffsets(posters)
  }

  return [
    _items,
    pageOffsets,
  ]
}

function generatePosterOffsets(posters) {
  const posterCountMap = {}
  let maxPage = 0

  posters.forEach(poster => {
    const page = poster.data.config.page
    if (page > maxPage) {
      maxPage = page
    }
    if (isNil(posterCountMap[page])) {
      posterCountMap[page] = 1
    } else {
      posterCountMap[page] = posterCountMap[page] + 1
    }
  })

  const posterOffsets = []
  for (let i = 0; i <= maxPage; i++) {
    const count = posterCountMap[i] || 0
    posterOffsets.push(count)
  }

  return posterOffsets
}

export function getPostersForPage(posterItems, page) {
  return posterItems.filter(poster =>
    poster.data.component === `posterCard` && poster.data.config.page === page
  )
}

function findListingProductGridTag(items) {
  if (Array.isArray(items)) {
    const foundIndex = items.findIndex(itm => {
      return itm.data?.component === `listingProductGrid`
    })

    if (foundIndex > -1) {
      return items[foundIndex].tag
    } else {
      for (let i = 0; i < items.length; i++) {
        const tag = findListingProductGridTag(items[i].items)

        if (tag) {
          return tag
        }
      }
    }
  }
}
