import React from 'react'
import { useSelector } from 'react-redux'

import { useNavTree } from 'hooks/useNavTree'
import { NavigationBanner } from 'components/NavigationBanner'
import { NavigationList } from 'components/NavigationList'
import { NavigationContextSwitcher } from 'components/NavigationContextSwitcher'
import { NavigationMegamenu } from 'components/NavigationMegamenu'

export const NavigationSelector = ({
  component,
  config = {},
  items: navPosters = [],
  layout,
  navContextConsumer,
  navMenuIndex,
  positioning,
  styling,
}) => {
  const {
    navMenu = `navTree`,
  } = config

  const focussedNavItems = useSelector(state => state.site.focussedNavItems)
  const navContext = useSelector(state => state.navigation.navContext)
  const parentMenuIndexes = getParentMenuIndexes()

  const {
    activeNavSection: navItems = [],
    activeNavPosters: posterItems = [],
  } = useNavTree({
    navContext,
    navMenu,
    activeNavIndexes: focussedNavItems,
    navContextConsumer,
    navMenuIndex,
    navPosters,
  })

  const map = {
    navigationBanner: NavigationBanner,
    navigationContextSwitcher: NavigationContextSwitcher,
    navigationList: NavigationList,
    navigationMegamenu: NavigationMegamenu,
  }

  const Component = map[component]

  return (
    <Component
      config={config}
      layout={layout}
      navItems={navItems}
      navMenuIndex={navMenuIndex}
      parentMenuIndexes={parentMenuIndexes}
      positioning={positioning}
      posterItems={posterItems}
      styling={styling}
    />
  )

  function getParentMenuIndexes() {
    return focussedNavItems.slice(0, navMenuIndex)
  }
}
