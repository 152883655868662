import React from 'react'

import { Image } from 'components/Image'

import shared from 'components/Skeleton/Skeleton.css'

export const SkeletonImage = ({
  borderRadius,
  imageBackgroundColor,
  imagePadding,
  metadata,
}) => (
  <div className={shared.skeleton}>
    <Image
      backgroundColor={imageBackgroundColor}
      borderRadius={borderRadius}
      metadata={metadata}
      padding={imagePadding}
      src=""
    />
  </div>
)
