export function capitalize(word = ``, strict) {
  if (strict) {
    return `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`
  }

  return `${word.charAt(0).toUpperCase()}${word.slice(1)}`
}

export function capitalizeSentence(sentence, strict) {
  const wordArray = sentence.split(` `)
  return wordArray.map(word => capitalize(word, strict)).join(` `)
}
