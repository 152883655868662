"use strict";
exports.__esModule = true;
exports.Storage = void 0;
var algoliaStorage_1 = require("../../vendors/algoliaStorage");
exports.Storage = {
    addPlpAssociation: function (payload) {
        algoliaStorage_1.AlgoliaStorage.addPlpAssociation(payload);
    },
    clearPlpAssociations: function () {
        algoliaStorage_1.AlgoliaStorage.clearPlpAssociations();
    },
    getAlgoliaEvents: function () {
        return algoliaStorage_1.AlgoliaStorage.getAlgoliaEvents();
    },
    getPlpAssociations: function () {
        return algoliaStorage_1.AlgoliaStorage.getPlpAssociations();
    },
    setAlgoliaEvents: function (payload) {
        algoliaStorage_1.AlgoliaStorage.setAlgoliaEvents(payload);
    }
};
