import React from 'react'
import { useSelector } from 'react-redux'

import { getSourceFile } from 'utils/getSourceFile'
import { useDataFetching } from 'hooks/useDataFetching'
import { MediaQuery } from 'components/MediaQuery'
import { PageBody } from 'components/PageBody'

export const PageTemplate = ({
  children,
  id,
  items, // augmentation items
  template,
}) => {
  const activeLanguage = useSelector(state => state.language.active)
  const [file, triedFetch] = useDataFetching({
    folder: `/themes/templates`,
    source: getSourceFile(template, activeLanguage),
  })

  if (!triedFetch || !template) {
    return <PageBody loading />
  }

  if (file) {
    return (
      <MediaQuery
        augmentItems={items}
        {...file}
        id={id}
      >
        {children}
      </MediaQuery>
    )
  }

  return null
}
