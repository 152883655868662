import React from 'react'

import { Svg } from 'components/Svg'
import { Image } from 'components/Image'
import { HtmlText } from 'components/HtmlText'

import styles from './ProductCardBanner.css'

export const ProductCardBanner = ({
  content = {},
  styling = {},
}) => {
  const {
    html,
    iconRef,
    imageSrc,
    text,
  } = content
  const {
    backgroundColor,
    color,
  } = styling

  const inline = {
    backgroundColor,
    color,
  }

  return (
    <div
      className={styles.container}
      style={inline}
    >
      {iconRef &&
        <div className={styles.iconWrapper}>
          <Svg
            icon={iconRef}
            size="3rem"
          />
        </div>
      }

      {imageSrc &&
        <div className={styles.imageWrapper}>
          <Image src={imageSrc} />
        </div>
      }

      {(html || text) &&
        <HtmlText html={html || text} />
      }
    </div>
  )
}
