import {
  NAV_USES_OVERLAY,
  OVERLAY_ON,
  OVERLAY_OFF,
} from 'state/actions'

const initalState = {
  megamenu: false,
  mobileSearch: false,
  navUsesOverlay: false,
  pullout: false,
}

const overlay = (state = initalState, action) => {
  switch (action.type) {
  case OVERLAY_OFF:
    return {
      ...state,
      [action.payload]: false,
    }
  case OVERLAY_ON:
    return {
      ...state,
      [action.payload]: true,
    }
  case NAV_USES_OVERLAY:
    return {
      ...state,
      navUsesOverlay: true,
    }
  default:
    return state
  }
}

export default overlay
