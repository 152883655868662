import React from 'react'

import { Heading } from 'components/Heading'

import styles from './PulloutTitle.css'

export const PulloutTitle = ({ html }) => {
  return (
    <div className={styles.container}>
      <Heading
        content={{
          html,
        }}
        styling={{
          fontFamily: `primary`,
          fontSize: `xsRegular`,
          universalColor: true,
        }}
      />
    </div>
  )
}
