import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { useRemapLeftAndRight } from 'hooks/useRemapLeftAndRight'

import styles from './Pullout.css'

export const Pullout = ({
  children,
  pulloutSide: pulloutSideRaw,
  style,
}) => {
  const pulloutSide = useRemapLeftAndRight(pulloutSideRaw)
  const active = useSelector(state => state.pullout.active)
  const [scopedActive, setScopedActive] = React.useState()

  // so the css transition works
  React.useEffect(() => {
    setScopedActive(active)
  }, [active])

  return (
    <div
      className={classNames(styles.container, {
        [styles.out]: scopedActive,
        [styles.start]: pulloutSide === `start`,
        [styles.end]: pulloutSide === `end`,
      })}
      style={style}
    >
      {children}
    </div>
  )
}
