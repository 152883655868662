import React from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import get from 'lodash/get'

import { LABELS_LANGUAGE, SHOW_TEST_PAYMENTS_PARAM } from 'utils/constants'
import { setGlobalUtil } from 'global-content/utils'
import { focusableElementsList } from 'utils/focusableElementsList'
import { useGlobalContent } from 'hooks/useGlobalContent'
import { useChangeLanguage } from 'hooks/useChangeLanguage'
import { useRouteChange } from 'hooks/useRouteChange'
import { useIsCheckout } from 'hooks/useIsCheckout'
import { useUrlManager } from 'hooks/useUrlManager'
import { enableTestPayments } from 'state/actions/user-preferences'
import { loadCartContents } from 'state/actions/cart'
import { savePreToggleLanguage, setAdminMode, usedTab } from 'state/actions/site'
import { AccessibilityJumps } from 'components/AccessibilityJumps'
import { ScrollUpButton } from 'components/ScrollUpButton'
import { Footer } from 'components/Footer'
import { PulloutSelector } from 'components/PulloutSelector'
import { Overlay } from 'components/Overlay'
import { ModalSplash } from 'components/ModalSplash'
import { CookieMessage } from 'components/CookieMessage'
import { ExternalScripts } from 'components/ExternalScripts'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { ScaffoldError } from 'components/ScaffoldError'
import { Head } from 'components/Head'
import { Header } from 'components/Header'
import { useThemeVariables } from 'hooks/useThemeVariables'
import { CountrySelect } from 'design-system/features/CountrySelect'
import {
  reportBreadcrumb,
  SENTRY_PAGEVIEW_INITIAL,
  SENTRY_PAGEVIEW_NEXT,
} from 'services/reportBreadcrumb'

import { MAIN_ID, SCAFFOLD_ID, HEADER_ID } from './fixtures'
import styles from './Scaffold.css'

const fingerprintElementId = `device-fingerprint`

export const Scaffold = ({
  basename,
  children,
}) => {
  const isAppLoaded = React.useRef(false)
  setGlobalUtil(`urlManager`, useUrlManager())
  useThemeVariables()
  const location = useLocation()
  const switchLanguage = useChangeLanguage()
  const activeLanguage = useSelector(state => state.language.active)
  const adminMode = useSelector(state => state.site.adminMode)
  const preToggleLanguage = useSelector(state => state.site.preToggleLanguage)
  const accessibilityMode = useSelector(state => state.site.accessibilityMode)
  const active = useSelector(state => state.pullout.active)
  const history = useHistory()
  const dispatch = useDispatch()
  const updateLocation = useRouteChange()
  const isCheckout = useIsCheckout()
  const head = useGlobalContent(`head`)
  const headBefore = get(head, `data.positioning.position`) === `headBottom`


  React.useEffect(() => {
    // Log each page view to Sentry
    const {
      pathname,
      search,
      hash,
    } = location
    const fullUrl = `${basename}${pathname}${search}${hash}`
    let category = SENTRY_PAGEVIEW_NEXT
    if (isAppLoaded.current === false) {
      isAppLoaded.current = true
      category = SENTRY_PAGEVIEW_INITIAL
    }

    reportBreadcrumb({
      message: fullUrl,
      category,
    })
  }, [location])

  React.useEffect(() => {
    // checkout loadsCartContents anyway, so if starts on checkout saves a trip
    if (!isCheckout) {
      dispatch(loadCartContents())
    }

    const listen = historyListener()
    const parsedSearch = queryString.parse(location.search)

    // calling hasOwnProperty from the Object prototype as
    // queryString.parse uses Object.create(null) (no inherited methods)
    if (Object.prototype.hasOwnProperty.call(parsedSearch, SHOW_TEST_PAYMENTS_PARAM)) {
      dispatch(enableTestPayments())
    }

    if (
      Object.prototype.hasOwnProperty.call(parsedSearch, `admin`) ||
      activeLanguage === LABELS_LANGUAGE
    ) {
      dispatch(setAdminMode(true))
    }

    return () => {
      listen()
    }
  }, [])

  const stableKeyListener = React.useCallback((e) => keyListener(e), [])
  const stableFocusinListener = React.useCallback((e) => focusInListener(e), [])

  React.useEffect(() => {
    document.addEventListener(`keydown`, stableKeyListener)
    document.addEventListener(`focusin`, stableFocusinListener)

    return () => {
      document.removeEventListener(`keydown`, stableKeyListener)
      document.removeEventListener(`focusin`, stableFocusinListener)
    }
  }, [])

  return (
    <CountrySelect>
      <div
        className={classNames({ [styles.accessibilityMode]: accessibilityMode })}
        role="region"
      >
        <CookieMessage />
        <ModalSplash />
      </div>
      <div
        aria-hidden={active ? `true` : `false`}
        className={classNames(styles.pageContainer, {
          [styles.pulloutOpen]: active,
          [styles.accessibilityMode]: accessibilityMode,
          accessibilityMode,
        })}
        id={SCAFFOLD_ID}
      >
        <Overlay />
        {headBefore ? <Head /> : null}
        <header
          className={styles.header}
          id={HEADER_ID}
        >
          <AccessibilityJumps />
          <Header />
        </header>
        {!headBefore ? <Head /> : null}
        <main
          id={MAIN_ID}
        >
          <ErrorBoundary
            errorComponent={ScaffoldError}
            errorTitle="Scaffold error"
            severity={1}
          >
            {children}
          </ErrorBoundary>
        </main>
        <footer>
          <Footer />
          <ScrollUpButton />
        </footer>
        <input
          id={fingerprintElementId}
          name={fingerprintElementId}
          type="hidden"
        />
        <ExternalScripts />
      </div>
      <div
        className={classNames({ [styles.accessibilityMode]: accessibilityMode })}
        role="region"
      >
        <PulloutSelector />
      </div>
    </CountrySelect>
  )

  function historyListener() {
    return history.listen(({
      pathname,
      search,
    }, action) => {
      updateLocation({
        action,
        pathname,
        scrollToY: 0,
        search,
      })
    })
  }
  function focusInListener(e) {
    e.preventDefault()
    const originalTarget = e.target

    if (!isElInAriaHidden(originalTarget)) {
      return
    }

    const allEls = document.querySelectorAll(focusableElementsList.join(`,`))
    const cameFrom = e.relatedTarget

    const cameFromIndex = Array.from(allEls).indexOf(cameFrom)
    const originalTargetIndex = Array.from(allEls).indexOf(originalTarget)

    const direction = cameFromIndex < originalTargetIndex ? 1 : -1

    const newTarget = getTargetElement(originalTargetIndex, allEls, direction)

    if (newTarget) {
      return newTarget.focus()
    }

    if (direction === 1) {
      return allEls[0].focus()
    }

    if (direction === -1) {
      return allEls[allEls.length - 1].focus()
    }
  }

  function keyListener(e) {
    if (e.key === `Tab`) {
      dispatch(usedTab())
    }

    if (adminMode) {
      if (activeLanguage !== LABELS_LANGUAGE) {
        dispatch(savePreToggleLanguage(activeLanguage))
      }

      if (e.altKey && e.ctrlKey) {
        switchLanguage(activeLanguage === LABELS_LANGUAGE ? preToggleLanguage : LABELS_LANGUAGE)
      }
    }
  }
}

function getTargetElement(targetIndex, allEls, direction) {
  const targetElement = allEls[targetIndex]

  if (isElInAriaHidden(targetElement) || targetElement?.tabIndex === -1) {
    return getTargetElement(targetIndex + direction, allEls, direction)
  }

  return targetElement
}

function isElInAriaHidden(el) {
  if (!el) {
    return false
  }

  if (el.ariaHidden === `true`) {
    return true
  }

  return isElInAriaHidden(el.parentElement)
}
