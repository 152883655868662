import React from 'react'
import classNames from 'classnames'

import { useRemapTextAlign } from 'hooks/useRemapTextAlign'
import { Link } from 'components/Link'

import styles from './LinkText.css'

export const LinkText = React.forwardRef(function LinkText({
  active,
  activeState,
  button,
  children,
  className,
  hover,
  hoverState,
  lookalike = false,
  onClick,
  textAlign: textAlignRaw = `inherit`,
  to,
  style,
  type,
  ...rest
}, ref) {
  const textAlign = useRemapTextAlign(textAlignRaw)

  const props = {
    onClick,
    ref,
    className: classNames({
      // absolute
      [`activeState--${activeState}`]: activeState,
      [`hoverState--${hoverState}`]: hoverState,
      [`linkText--${type}`]: type,
      hover,
      active,

      // scoped
      [className]: Boolean(className),
      [styles.textLink]: type === `textLink`,
    }),
    style: {
      ...style,
      textAlign,
    },
    ...rest,
  }

  if (lookalike) {
    return (
      <div {...props}>
        {children}
      </div>
    )
  }

  if (button) {
    return (
      <button
        {...props}
        aria-label={props.name}
      >
        {children}
      </button>
    )
  }

  return (
    <Link
      {...props}
      to={to}
    >
      {children}
    </Link>
  )
})
