import { universalComponents } from 'utils/component-selector'
import { ADD_COMPONENTS } from 'state/actions'

const initialState = universalComponents()

const components = (state = initialState, action) => {
  switch (action.type) {
  case ADD_COMPONENTS:
    return {
      ...state,
      ...action.payload,
    }
  default:
    return state
  }
}

export default components
