import React from 'react'
import classNames from 'classnames'

import { useLangDirAngleIcons } from 'hooks/useLangDirAngleIcons'
import { Svg } from 'components/Svg'
import { sizeFallThrough } from 'utils/sizeFallThrough'

import styles from './CarouselNavArrow.css'

export const CarouselNavArrow = ({
  className,
  direction,
  halvedGapWithUnit,
  onClick, // added by Slick
  styling = {},
  positioning = {},
}) => {
  const angle = useLangDirAngleIcons(direction)
  const {
    color,
    backgroundColor,
    margin,
    padding,
    borderRadius,
    borderColor,
    borderSize,
  } = styling

  const {
    offsetX = `0px`,
    offsetY,
    overlay,
    position,
    size = `2rem`,
  } = positioning

  return (
    <button
      className={classNames(className, styles.navArrow, {
        [styles.start]: direction === `start`,
        [styles.end]: direction === `end`,
        [styles.overlay]: overlay,
        [styles.outlay]: !overlay,
        [styles.borderRadiusRounded]: borderRadius === `rounded`,
        [styles.paddingXs]: padding === `xs`,
        [styles.paddingSmall]: padding === `small`,
        [styles.paddingMedium]: padding === `medium`,
        [styles.paddingLarge]: padding === `large`,
        [styles.marginXs]: margin === `xs`,
        [styles.marginSmall]: margin === `small`,
        [styles.marginMedium]: margin === `medium`,
        [styles.marginLarge]: margin === `large`,
      })}
      onClick={onClick}
      style={{
        backgroundColor,
        borderColor,
        borderWidth: borderSize,
        ...(borderSize || borderColor ? { borderStyle: `solid` } : null),
        ...calculateOffset(),
        '--halvedGapWithUnit': halvedGapWithUnit,
        '--arrowOffsetX': offsetX,
        padding: sizeFallThrough([`xs`, `small`, `medium`, `large`], padding),
      }}
    >
      <Svg
        block
        color={color}
        icon={angle}
        size={size}
      />
    </button>
  )

  function calculateOffset() {
    switch (position) {
    case `Top`:
      return {
        top: parseFloat(offsetY) > 0 ? offsetY : `0`,
      }
    case `Bottom`:
      return {
        bottom: parseFloat(offsetY) > 0 ? offsetY : `0`,
      }
    default:
      return {
        top: !isNaN(parseFloat(offsetY)) ? `calc(50% + ${offsetY})` : `50%`,
        transform: `translateY(-50%)`,
      }
    }
  }
}
