import React from 'react'
import { ModernFactoryComponent } from '../ModernFactoryComponent'

export const BaseElement = ModernFactoryComponent(({
  children,
  Tag,
  ...props
}, ref) => {
  delete props.data
  delete props._component
  return (
    <Tag
      {...props}
      ref={ref}
    >
      {children}
    </Tag>
  )
})
