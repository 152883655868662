import { useContext } from 'react'
import { ContextNavigationContext } from './ContextNavigation'

export const ContextNavigationMatch = ({
  children,
  data: {
    navContext,
  },
}) => {
  const {
    activeNavContext,
  } = useContext(ContextNavigationContext)


  const isSelected = activeNavContext === navContext

  return isSelected ? children : null
}