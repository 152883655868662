import { ADD_CONTENT } from 'state/actions'

const initialState = {}

const content = (state = initialState, action) => {
  switch (action.type) {
  case ADD_CONTENT: {
    const {
      data,
      language,
      target,
    } = action.payload
    return {
      ...state,
      [target]: {
        ...state?.[target],
        [language]: data,
      },
    }
  }
  default:
    return state
  }
}

export default content
