import React from 'react'

import { I18n } from 'utils/i18n'
import { useUrlManager } from 'hooks/useUrlManager'
import { Button } from 'factory-components/Button'
import { Modal } from 'components/Modal'
import { Layout } from 'components/Layout'
import { Heading } from 'components/Heading'
import { MarkDownContent } from 'utils/component-selector'

import { webLabels } from './fixtures'
import styles from './ModalError.css'

export const ModalError = () => {
  const urlManager = useUrlManager()

  return (
    <Modal>
      <Layout
        className={styles.container}
        data-testid="errorModal"
        layout={{
          container: `clientDefault`,
        }}
      >
        <div className={styles.wrapper}>
          <div>
            <Heading
              content={{
                html: I18n.t(webLabels.title),
              }}
            />
            <Layout
              layout={{
                marginBottom: `xxs`,
              }}
            >
              <MarkDownContent
                data={{
                  component: `markDownContent`,
                }}
                markdown={I18n.t(webLabels.description).replace(
                  // eslint-disable-next-line no-template-curly-in-string
                  `\${window.$shoppingSessionId.value}`,
                  window.$shoppingSessionId.value
                )}
              />
            </Layout>
            <Button
              data={{
                content: {
                  html: I18n.t(webLabels.button),
                },
                positioning: {
                  buttonHeight: `medium`,
                  buttonWidth: `medium`,
                },
                styling: {
                  type: `primary`,
                },
              }}
              onClick={() => urlManager({
                pathname: {
                  value: `/`,
                },
              })}
            />
          </div>
        </div>
      </Layout>
    </Modal>
  )
}
