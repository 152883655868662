export function findElementWhenReady({
  element,
  giveupAfter = 2000,
}) {
  return new Promise((resolve, reject) => {
    let interval
    interval = setInterval(() => {
      const domEl = document.querySelector(element)

      if (domEl) {
        clearInterval(interval)
        resolve(domEl)
      }
    }, 30)

    setTimeout(() => {
      clearInterval(interval)
      reject()
    }, giveupAfter)
  })
}
