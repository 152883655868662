import React from 'react'
import classNames from 'classnames'

import { getComponentOptions } from 'global-content/components'

import styles from './ProductOptionHeader.css'

export const ProductOptionHeader = ({
  children,
  value,
}) => {
  const { size } = getComponentOptions(`optionHeader`)

  return (
    <div
      className={classNames(styles.container, {
        [styles.small]: size === `small`,
      })}
    >
      {value && (
        <span>
          {value}
        </span>
      )}
      <span>
        {children}
      </span>
    </div>
  )
}
