import React from 'react'
import { useSelector } from 'react-redux'

import { useFilters } from 'hooks/useFilters'
import { FiltersInputRange } from 'components/FiltersInputRange'

import styles from './FiltersRangeSelector.css'

export const FiltersRangeSelector = ({
  data,
  uiOptions,
  usePending,
  scrollHook,
}) => {
  const { name } = uiOptions
  const selectedFilters = useSelector(state => state.listing.selectedFilters)
  const { onFilterSelect } = useFilters(usePending)
  const numbers = data.map(({ value }) => parseFloat(value))
  const availableMax = Math.max(...numbers)
  const availableMin = Math.min(...numbers)

  return (
    <div className={styles.container}>
      <FiltersInputRange
        availableMax={availableMax}
        availableMin={availableMin}
        onInputComplete={onInputComplete}
        selected={selectedFilters[name]}
        usePending={usePending}
      />
    </div>
  )

  function onInputComplete(values) {
    onFilterSelect(name, values, scrollHook)
  }
}
