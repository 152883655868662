import React from 'react'

import { LazyModuleWrapper } from 'components/LazyModuleWrapper'

const LazyAccountForgotPassword = (
  React.lazy(() => import(/* webpackChunkName: "AccountForgotPassword" */ `./AccountForgotPassword`))
)

export const AccountForgotPassword = props => (
  <LazyModuleWrapper>
    <LazyAccountForgotPassword {...props} />
  </LazyModuleWrapper>
)
