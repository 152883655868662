import { useDispatch } from 'react-redux'

import { I18n } from 'utils/i18n'
import {
  ACCOUNT_NEW_PASSWORD_ROUTE,
  ACCOUNT_ADDRESSES_ROUTE,
  ACCOUNT_PAYMENT_METHODS_ROUTE,
  ORDER_HISTORY_ROUTE,
} from 'Router/fixtures'
import { signOut } from 'state/actions/account'
import { webLabels } from './fixtures'

export const useAccountMenuLinks = () => {
  const dispatch = useDispatch()

  return [
    {
      'data-testid': `change-password`,
      path: ACCOUNT_NEW_PASSWORD_ROUTE,
      label: I18n.t(webLabels.setPasswordLabel),
    },
    {
      'data-testid': `order-history`,
      path: ORDER_HISTORY_ROUTE,
      label: I18n.t(webLabels.orderHistoryLabel),
    },
    {
      'data-testid': `addresses`,
      path: ACCOUNT_ADDRESSES_ROUTE,
      label: I18n.t(webLabels.accountAddressesLabel),
    },
    {
      'data-testid': `payment-methods`,
      path: ACCOUNT_PAYMENT_METHODS_ROUTE,
      label: I18n.t(webLabels.accountPaymentMethodsLabel),
    },
    {
      'data-testid': `signout`,
      path: `/`,
      icon: `signout`,
      label: I18n.t(webLabels.signOutLabel),
      onClick: () => {
        dispatch(signOut())
      },
    },
  ]
}
