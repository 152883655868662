import { OPEN_PULLOUT, CLOSE_PULLOUT } from "state/actions"

export const openPullout = pulloutContent => ({
  type: OPEN_PULLOUT,
  payload: pulloutContent,
})

export const closePullout = () => ({
  type: CLOSE_PULLOUT,
})
