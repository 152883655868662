import uniq from 'lodash/uniq'

import {
  ADD_CART_ITEM,
  DETAILS_ADD_MESSAGE,
  DETAILS_PRELOAD,
  DETAILS_REMOVE_MESSAGE,
  GET_PRODUCT,
  NO_PRODUCT,
  SET_PENDING_QUANTITY,
  UPDATE_SELECTED_OPTION,
  UPDATE_MISSING_LEVELS,
} from 'state/actions'
import { toDetailProduct } from 'models/product'
import { STATUSCODE_ADD_CART_FAILURE, AVAILABILITY_NOTAVAILABLE } from 'utils/constants'
import {
  getSelectedSlugs,
  getOptionValues,
} from 'state/utils/details'

const initialState = {
  infoMessages: [],
  levelOptions: {},
  loadingProduct: true,
  missingLevels: [],
  notFound: false,
  pendingQuantity: 1,
  product: undefined,
  plpInfo: {
    queryID: undefined,
    objectID: undefined,
    indexName: undefined,
    filters: [],
  },
  selectedSlugs: {},
  skuOptions: {},
  promotions: {},
}

const details = (state = initialState, action) => {
  switch (action.type) {
  case `${ADD_CART_ITEM}_REJECTED`:
    return {
      ...state,
      infoMessages: uniq([...state.infoMessages, STATUSCODE_ADD_CART_FAILURE]),
    }
  case DETAILS_ADD_MESSAGE: {
    return {
      ...state,
      infoMessages: uniq([...state.infoMessages, action.payload]),
    }
  }
  case DETAILS_REMOVE_MESSAGE: {
    const infoMessages = state.infoMessages.filter(messageCode => messageCode !== action.payload)

    return {
      ...state,
      infoMessages,
    }
  }
  case SET_PENDING_QUANTITY: {
    return {
      ...state,
      pendingQuantity: action.payload,
    }
  }
  case UPDATE_SELECTED_OPTION: {
    const selectedSlugs = {
      ...state.selectedSlugs,
      ...action.payload.options,
    }
    const isValid = action.payload.isValid
    const levelOptions = getOptionValues(state.product, selectedSlugs)
    const skuOptions = getSkuOptions(levelOptions, selectedSlugs, isValid)
    const promotions = action.payload.getPromotions(state.product, skuOptions)

    // Note: When we change sizeType, we reset the currently selected size option
    if (action.payload.options.sizeType) {
      delete selectedSlugs.size
    }

    return {
      ...state,
      infoMessages: [],
      levelOptions,
      selectedSlugs,
      skuOptions,
      promotions,
    }
  }
  case UPDATE_MISSING_LEVELS: {
    return {
      ...state,
      missingLevels: action.payload,
    }
  }
  case `${GET_PRODUCT}_PENDING`: {
    return {
      ...state,
      loadingProduct: true,
      notFound: false,
    }
  }
  case `${GET_PRODUCT}_FULFILLED`: {
    // note to self: this is product api payload
    const product = toDetailProduct(action.payload.product)
    const selectedSlugs = getSelectedSlugs(product, action.payload.search)
    const levelOptions = getOptionValues(product, selectedSlugs)
    const skuOptions = getSkuOptions(levelOptions, selectedSlugs)
    const promotions = action.payload.getPromotions(action.payload.product, skuOptions)

    return {
      ...state,
      infoMessages: [],
      loadingProduct: false,
      levelOptions,
      notFound: false,
      pendingQuantity: 1,
      product,
      selectedSlugs,
      skuOptions,
      promotions,
    }
  }
  case DETAILS_PRELOAD: {
    const {
      indexName,
      product,
      selectedSlugs,
      filters,
      queryID,
    } = action.payload

    return {
      ...state,
      infoMessages: [],
      loadingProduct: true,
      notFound: false,
      pendingQuantity: 1,
      levelOptions: {
        color: product.options,
      },
      plpInfo: {
        objectID: product.objectID,
        queryID,
        indexName,
        filters,
      },
      skuOptions: {
        availability: product.availability,
        price: product.price,
      },
      product,
      selectedSlugs,
    }
  }
  case NO_PRODUCT:
  case `${GET_PRODUCT}_REJECTED`:
    return {
      ...initialState,
      loadingProduct: false,
      notFound: true,
    }
  default:
    return state
  }
}

function getSkuOptions(levelOptions, selectedSlugs, isValid) {
  let skuOptions = {
    price: {
      list: {},
      sale: {},
    },
  }

  for (const slug in selectedSlugs) {
    skuOptions = {
      ...skuOptions,
      ...(levelOptions[slug].find(option => selectedSlugs[slug] === option.slug)),
      ...(isValid === false ? { availability: AVAILABILITY_NOTAVAILABLE } : {}),
      options: selectedSlugs,
    }
  }

  return skuOptions
}

export default details
