import React from 'react'
import classNames from 'classnames'

import { Button } from 'factory-components/Button'

import styles from './SelectableBox.css'

export const SelectableBox = React.forwardRef(({
  'data-testid': testId,
  disabled,
  label,
  nowrap,
  onClick,
  selected,
  size = `large`,
  style,
  type = `selectable`,
}, ref) => (
  <Button
    aria-label={label}
    aria-pressed={selected ? `true` : `false`}
    className={classNames(styles.button, {

      // scoped
      [styles.small]: size === `small`,
      [styles.large]: size === `large`,
      [styles.disabled]: disabled,

      // absolute
      selected,
      disabled,
    })}
    data={{
      styling: {
        type,
      },
    }}
    data-testid={testId}
    name={label}
    nowrap={nowrap}
    onClick={onClick}
    ref={ref}
    style={style}
  >
    {label}
  </Button>
))
