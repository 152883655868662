import { useEffect } from "react"
import { useSelector } from "react-redux"
import { getConfigOptions } from 'global-content/config'
import startCase from 'lodash/startCase'
import camelCase from 'lodash/camelCase'

export const useThemeVariables = () => {
  const navContext = useSelector(state => state.navigation.navContext)
  const isUsingNavContext = getConfigOptions(`navigation.startingNavContext`) > -1

  useEffect(() => {
    if (!isUsingNavContext) return
    let context = startCase(camelCase(navContext)).replace(/ /g, ``)
    document.documentElement.style.setProperty(`--dynamicPrimaryColor`, `var(--primaryColor${context})`)
    document.documentElement.style.setProperty(`--dynamicSecondaryColor`, `var(--secondaryColor${context})`)
    document.documentElement.style.setProperty(`--dynamicTertiaryColor`, `var(--tertiaryColor${context})`)
    document.documentElement.style.setProperty(`--dynamicQuaternaryColor`, `var(--quaternaryColor${context})`)
    document.documentElement.style.setProperty(`--dynamicQuinaryColor`, `var(--quinaryColor${context})`)
    document.documentElement.style.setProperty(`--dynamicSenaryColor`, `var(--senaryColor${context})`)
    document.documentElement.style.setProperty(`--dynamicSeptenaryColor`, `var(--septenaryColor${context})`)
    document.documentElement.style.setProperty(`--dynamicOctonaryColor`, `var(--octonaryColor${context})`)
    document.documentElement.style.setProperty(`--dynamicNonaryColor`, `var(--nonaryColor${context})`)
    document.documentElement.style.setProperty(`--dynamicDenaryColor`, `var(--denaryColor${context})`)
  }, [navContext])
}
