import { useMediaQuery } from 'react-responsive'
import { useState, useRef, useEffect, useMemo } from 'react'
import mediaSizes from 'css/media-sizes.json'
import React from 'react'
import classNames from 'classnames'
import style from './SelectableMenu.css'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { I18n } from 'utils/i18n'

import { webLabels } from './fixtures'
import { Svg } from 'components/Svg'

export const SelectableMenu = ({
  value,
  options,
  onClick,
  shouldAlwaysUseNativeSelect = false,
}) => {
  const ref = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const isMobile = useMediaQuery({
    minWidth: 0,
    maxWidth: mediaSizes[`tabletSmall`],
  })
  useOnClickOutside(ref, () => setIsOpen(false))
  useEffect(() => {
    if (isMobile) {
      setIsOpen(false)
    }
  }, [isMobile])

  const selectedOption = useMemo(() => {
    return options.find(opt => opt.value === value)
  }, [options, value])

  const isRenderingCustomSelect = !isMobile && !shouldAlwaysUseNativeSelect

  return (
    <div
      className={style.selectableMenuWrapper}
      data-is-open={isOpen}
      ref={ref}
    >
      {isRenderingCustomSelect ? (
        <div>
          <label
            className={style.selectableScreenReader}
            htmlFor="selectable"
            id="selectable-label"
          >
            {selectedOption ? selectedOption.label : I18n.t(webLabels.choose)}
          </label>
          <button
            aria-expanded={isOpen}
            aria-haspopup="listbox"
            aria-labelledby="selectable-label"
            aria-owns="selectable-menu"
            className={classNames(
              `button--size-select`,
              style.selectableMenuButton,
              style.selectableTriggerButton,
              {
                [style.selectableMenuButtonOpen]: isOpen,
              }
            )}
            onClick={() => {
              if(options.length > 1) {
                setIsOpen(_isOpen => !_isOpen)
              }
            }}
            role="combobox"
            tabIndex="0"
          >
            {selectedOption ? selectedOption.label : I18n.t(webLabels.choose)}
          </button>
          {isOpen && options.length > 1 && (
            <menu
              aria-activedescendant={`option-${value}`}
              aria-labelledby="selectable-label"
              className={style.selectableMenu}
              id="selectable-menu"
              role="listbox"
            >
              {options
                .filter(opt => opt.value !== value)
                .map((option) => (
                  <button
                    aria-selected={option.value === value}
                    className={classNames(
                      `button--size-select-item`,
                      style.selectableMenuItemButton,
                      style.selectableMenuButton,
                    )}
                    disabled={option.disabled}
                    id={`option-${option.value}`}
                    key={option.key || option.value}
                    onClick={() => {
                      setIsOpen(false)
                      onClick(option.value)
                    }}
                    role="option"
                    tabIndex="-1"
                  >
                    {option.label}
                  </button>
                ))}
            </menu>
          )}
        </div>
      ) : (
        <select
          className={
            classNames(
              style.selectableMenuButton,
              style.selectableTriggerButton,
            )
          }
          onChange={(e) => {
            onClick(e.target.value)
          }}
          value={value}
        >
          {options.map((option) => (
            <option
              disabled={option.disabled}
              key={option.key || option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </select>
      )}
      {options.length > 1 && (
        <Svg
          className={style.selectableMenuIcon}
          color="black"
          icon="angle_down"
          size="1.6rem"
        />
      )}
    </div>
  )
}
