import { currencyFormatted } from 'utils'
import { I18n } from 'utils/i18n'
import { typeAndAmountInterpolater } from './amount-interpolation'

import { webLabels } from './fixtures'

export function tierPromotion({
  language,
  tierDiscount,
}) {
  const {
    tiers,
    tierType,
    discountType,
  } = tierDiscount

  return tiers.map(interpolateTier).reverse() // most attractive offer first

  function interpolateTier({
    discountAmount,
    min,
  }) {
    const discount = typeAndAmountInterpolater({
      amount: discountAmount,
      language,
      type: discountType,
    })

    const minString = tierType === `ITEM_COUNT` ? min : currencyFormatted(min)

    return {
      discount,
      min: minString,
      content: I18n.t(getWebLabel(), {
        language,
        replace: {
          discount,
          min: minString,
        },
      }),
    }
  }

  function getWebLabel() {
    // DISABLED WHILST CONFIRM MESSAGEID USE
    // if (messageId && messageId !== webLabels.EMPTY) {
    //   return messageId
    // }

    return webLabels[tierType]
  }
}
