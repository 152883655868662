import React from 'react'

import { useContent } from 'hooks/useContent'
import { Svg } from 'components/Svg'
import { Image } from 'components/Image'
import { HtmlText } from 'components/HtmlText'

import styles from './Badge.css'

export const Badge = ({ type }) => {
  const badges = useContent(`badges`)

  if (badges) {
    const badge = badges[type]

    const {
      content = {},
      styling = {},
    } = badge
    const {
      html,
      iconRef,
      imageSrc,
      text,
    } = content
    const {
      backgroundColor,
      borderColor,
      color,
    } = styling

    const inline = {
      backgroundColor,
      borderColor,
      color,
    }

    return (
      <div
        className={styles.container}
        style={inline}
      >
        {iconRef &&
        <div className={styles.iconWrapper}>
          <Svg
            icon={iconRef}
            size="3rem"
          />
        </div>
        }

        {imageSrc &&
        <Image src={imageSrc} />
        }

        {(html || text) &&
        <div
          className={styles[styling.fontSize]}
          style={{ fontWeight: styling.fontWeight }}
        >
          <HtmlText html={html || text} />
        </div>
        }
      </div>
    )
  }

  return null
}
