import React from 'react'

import { getComponentOptions } from 'global-content/components'
import { FiltersMenuButton } from 'components/FiltersMenuButton'
import { FiltersOptionsContainer } from 'components/FiltersOptionsContainer'

import styles from './FiltersExpandable.css'

export const FiltersExpandable = ({
  buttonClass,
  filter,
  filtersPadding,
  isOpen,
  onClick,
  optionsClass,
  scrollHook,
  usePending,
}) => {
  const { name } = filter
  const {
    close,
    fontSize,
    open,
  } = getComponentOptions(`filterMenuButton.content.areas.accordion`)

  return (
    <div className={styles.filterBellow}>
      <FiltersMenuButton
        active={isOpen}
        className={buttonClass}
        closeIcon={close.iconRef}
        fontSize={fontSize}
        name={name}
        onClick={onClick}
        openIcon={open.iconRef}
        padding={filtersPadding}
      >
        {isOpen &&
          <div className={optionsClass}>
            <FiltersOptionsContainer
              filter={filter}
              filtersPadding={filtersPadding}
              scrollHook={scrollHook}
              usePending={usePending}
            />
          </div>
        }
      </FiltersMenuButton>
    </div>
  )
}
