import uniq from 'lodash/uniq'
import queryString from 'query-string'

import {
  ADD_MESSAGE,
  CLEAR_MESSAGES,
  LOAD_CART_CONTENTS,
  ADD_CART_ITEM,
  RECREATE_CART,
  UPDATE_CART_ITEM,
  APPLY_VOUCHER,
  REMOVE_VOUCHER,
  START_EXTERNAL_PAYMENT_METHOD,
  PLACE_ORDER,
} from 'state/actions'
import {
  AVAILABILITY_BACKORDER,
  AVAILABILITY_PREORDER,
  STATUSCODE_UNAVAILABLE_ITEMS,
  STATUSCODE_PREORDER_ITEMS,
  STATUSCODE_CARD_MISSING,
  STATUSCODE_EXPRESS_MISSING,
  STATUSCODE_PENDING,
  STATUSCODE_REFUSED,
  STATUSCODE_PREAUTH_FAILED,
  STATUSCODE_POPUSPS_DISALLOWED,
  AVAILABILITY_OUTOFSTOCK,
} from 'utils/constants'

const { statusCode } = queryString.parse(window.location.search)
const initialCheckoutMessages = []

if ([
  STATUSCODE_UNAVAILABLE_ITEMS,
  STATUSCODE_PREORDER_ITEMS,
  STATUSCODE_CARD_MISSING,
  STATUSCODE_EXPRESS_MISSING,
  STATUSCODE_PENDING,
  STATUSCODE_REFUSED,
  STATUSCODE_PREAUTH_FAILED,
  STATUSCODE_POPUSPS_DISALLOWED,
].includes(statusCode)) {
  initialCheckoutMessages.push(statusCode)
}

const initialState = {
  account: [],
  checkout: initialCheckoutMessages,
  orders: [],
}

const messages = (state = initialState, action) => {
  switch (action.type) {
  case `${START_EXTERNAL_PAYMENT_METHOD}_REJECTED`:
  case `${PLACE_ORDER}_REJECTED`: {
    return {
      ...state,
      checkout: uniq([...state.checkout, action.payload.statusCode]),
    }
  }
  case `${ADD_CART_ITEM}_FULFILLED`:
  case `${UPDATE_CART_ITEM}_FULFILLED`: {
    const checkoutMessages = getCheckoutMessages(action.payload.cartResponse.items, state.checkout)

    return {
      ...state,
      checkout: uniq(checkoutMessages),
    }
  }
  case `${REMOVE_VOUCHER}_FULFILLED`:
  case `${APPLY_VOUCHER}_FULFILLED`:
  case `${RECREATE_CART}_FULFILLED`:
  case `${LOAD_CART_CONTENTS}_FULFILLED`: {
    const checkoutMessages = getCheckoutMessages(action.payload.items, state.checkout)

    return {
      ...state,
      checkout: uniq(checkoutMessages),
    }
  }
  case ADD_MESSAGE: {
    const messageType = action.payload.type

    return {
      ...state,
      [messageType]: uniq([...state[messageType], action.payload.messageCode]),
    }
  }
  case CLEAR_MESSAGES: {
    const messageType = action.payload.type

    return {
      ...state,
      [messageType]: [],
    }
  }
  default:
    return state
  }
}

function getCheckoutMessages(items, currentMessages) {
  const unavailableItems = items.filter(item => item.availability === AVAILABILITY_OUTOFSTOCK)

  const preorderItems = items.filter(item => [AVAILABILITY_PREORDER, AVAILABILITY_BACKORDER].includes(item.availability))

  let checkoutMessages = [
    ...currentMessages,
  ]

  if (unavailableItems.length) {
    checkoutMessages.push(STATUSCODE_UNAVAILABLE_ITEMS)
  } else {
    checkoutMessages = checkoutMessages.filter(message => message !== STATUSCODE_UNAVAILABLE_ITEMS)
  }

  if (preorderItems.length) {
    checkoutMessages.push(STATUSCODE_PREORDER_ITEMS)
  } else {
    checkoutMessages = checkoutMessages.filter(message => message !== STATUSCODE_PREORDER_ITEMS)
  }

  return checkoutMessages
}

export default messages
