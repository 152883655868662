import React from 'react'

import { useContent } from 'hooks/useContent'
import get from 'lodash/get'
import {
  DESKTOP_KEY,
  TABLET_SMALL_KEY,
} from 'utils/constants'
import { I18n } from 'utils/i18n'
import { Factory } from 'components/Factory'
import { v1FactoryToV2 } from 'components/Factory/utils'
import { SupportNav } from 'factory-components/SupportNav'
import { IconsGroup } from 'components/IconsGroup'
import { PaymentIcons } from 'components/PaymentIcons'
import { HtmlText } from 'components/HtmlText'
import { Grid } from 'components/Grid'
import { Link } from 'components/Link'
import { Layout } from 'components/Layout'
import { Responsive } from 'components/Responsive'
import { CountrySelectButton } from 'factory-components/CountrySelectButton'

import { webLabels } from './fixtures'
import styles from './Footer.css'

import { KakaoTalkButton } from 'components/KakaoTalkButton'

export const Footer = () => {
  const footer = useContent(`footer`)

  if (footer?.content) {
    const {
      components,
      disclaimer,
      paymentIcons = {},
      socialIcons = [],
    } = footer.content
    const {
      branded,
      hide,
    } = paymentIcons
    const items = components
      ? v1FactoryToV2({ components })
      : undefined

    return (
      <>
        {items &&
          <Factory items={items} />
        }
        <div className={styles.footer}>
          <Layout layout={{ container: `clientDefault` }}>
            <SupportNav
              data={{
                content: {
                  ariaLabel: I18n.t(webLabels.navAriaLabel),
                },
                positioning: {
                  columns: true,
                  align: get(footer, `positioning.alignSupportNav`, `left`),
                },
              }}
            />
            {socialIcons.length > 0 && (
              <div className={styles.socialIconsContainer}>
                <IconsGroup
                  icons={socialIcons}
                  inlineGrid
                  size="3.5rem"
                />
              </div>
            )}
          </Layout>
        </div>
        <div className={styles.footerBottom}>
          {!hide && (
            <div className={styles.paymentIconsContainer}>
              <Responsive deviceRange={[DESKTOP_KEY, TABLET_SMALL_KEY]}>
                <PaymentIcons
                  branded={branded}
                  height="3rem"
                />
              </Responsive>

              <Responsive deviceRange={[TABLET_SMALL_KEY]}>
                <PaymentIcons
                  branded={branded}
                  height="3rem"
                  maxPerRow={6}
                />
              </Responsive>
            </div>
          )}
          <KakaoTalkButton containerID='add-channel-button' />
          <div className={styles.footerCountrySelect}>
            <CountrySelectButton
              data={{
                layout: {
                  marginBottom: `20px`,
                },
              }}
            />
          </div>
          {disclaimer && (
            <Layout>
              <Grid
                className={styles.footerCopy}
                gap="1.5rem"
              >
                <HtmlText html={disclaimer.line1} />
                <Link
                  aria-label={disclaimer.aboutUs.accessibilityDesc || disclaimer.aboutUs.label}
                  className={styles.footerCopyLink}
                  name={disclaimer.aboutUs.accessibilityDesc || disclaimer.aboutUs.label}
                  to={disclaimer.aboutUs.path}
                >
                  <HtmlText html={disclaimer.aboutUs.label} />
                </Link>
                { disclaimer.line2 && <HtmlText html={disclaimer.line2} />}
              </Grid>
            </Layout>
          )}
        </div>
      </>
    )
  }

  return null
}
