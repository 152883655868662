import React from 'react'
import classNames from 'classnames'

import { useRemapTextAlign } from 'hooks/useRemapTextAlign'
import { capitalize } from 'utils/capitalize'
import { sizeFallThrough } from 'utils/sizeFallThrough'
import { SkeletonText } from 'components/SkeletonText'
import { HtmlText } from 'components/HtmlText'

import styles from './Heading.css'

export const Heading = React.forwardRef(function Heading({
  'data-testid': testId,
  config = {},
  content = {},
  itemProp,
  styling = {},
  ...rest
}, ref) {
  const {
    elementTag,
  } = config

  const {
    html,
  } = content

  const {
    borderBottomColor,
    borderBottomStyle,
    borderBottomWidth,
    color,
    fontFamily,
    fontSize,
    fontStyle,
    fontWeight,
    lineHeight,
    textAlign: textAlignRaw,
    universalColor,
    uppercase,
  } = styling

  const tagMap = {
    medium: `h2`,
    small: `h3`,
    xs: `h4`,
    xsRegular: `h4`,
  }

  const Tag = elementTag || tagMap[fontSize] || `h1`
  const textAlign = useRemapTextAlign(textAlignRaw)

  return (
    <Tag
      className={classNames(`Heading__heading`, {
        [styles.xxl]: fontSize === `xxl`,
        [styles.xl]: fontSize === `xl`,
        [styles.large]: fontSize === `large`,
        [styles.medium]: fontSize === `medium`,
        [styles.small]: fontSize === `small`,
        [styles.xs]: fontSize === `xs`,
        [styles.xsRegular]: fontSize === `xsRegular`,
        [styles.universalColor]: universalColor,
      })}
      data-testid={testId}
      ref={ref}
      style={{
        color,
        fontSize: sizeFallThrough([`xxl`, `xl`, `large`, `medium`, `small`, `xs`, `xsRegular`], fontSize),
        fontStyle,
        fontWeight,
        lineHeight,
        textAlign,
        textTransform: uppercase && `uppercase`,
      }}
      {...rest}
    >
      {html &&
        <div
          style={{
            borderBottomColor,
            borderBottomStyle,
            borderBottomWidth,
            display: `inline-block`,
          }}
        >
          <HtmlText
            fontFamily={fontFamily}
            html={html}
            itemProp={itemProp}
          />
        </div>
      }
      {!html &&
        <SkeletonText
          lineHeight="small"
          size={`heading${capitalize(fontSize)}`}
          width="50rem"
        />
      }
    </Tag>
  )
})
