import { useSelector } from 'react-redux'

export function useLangDirAngleIcons(dir) {
  const langDir = useSelector(state => state.language.langDir)

  return angleMap(dir, langDir)
}

function angleMap(direction, langDir) {
  const map = {
    ltr: ltrMap(direction),
    rtl: rtlMap(direction),
  }

  return map[langDir]
}

function ltrMap(direction) {
  const map = {
    start: `angle_left`,
    end: `angle_right`,
  }

  return map[direction]
}

function rtlMap(direction) {
  const map = {
    start: `angle_right`,
    end: `angle_left`,
  }

  return map[direction]
}
