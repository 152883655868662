import React, { useMemo, useState } from 'react'
import { ModernFactoryComponent } from '../ModernFactoryComponent'

export const AccordionContext = React.createContext({})

export const Accordion = ModernFactoryComponent(({
  Tag,
  children,
  data: {
    initalIndex,
  },
  ...props
}, ref) => {
  const [activePanel, setActivePanel] = useState(initalIndex)

  const valueState = useMemo(() => ({
    activePanel,
    setActivePanel,
  }), [activePanel])

  return (
    <AccordionContext.Provider
      value={valueState}
    >
      <Tag
        {...props}
        ref={ref}
      >
        {children}
      </Tag>
    </AccordionContext.Provider>
  )
})
