import React, { useContext } from 'react'
import { ModernFactoryComponent } from '../ModernFactoryComponent'
import { NavigationContext } from './Navigation'

export const NavigationBack = ModernFactoryComponent(({
  children,
  Tag,
  ...props
}, ref) => {
  delete props.data

  const {
    clearCurrentNav,
  } = useContext(NavigationContext)

  return (
    <Tag
      {...props}
      onClick={() => {
        clearCurrentNav()
      }}
      ref={ref}
    >
      {children}
    </Tag>
  )
})

