import get from 'lodash/get'

import { handleLazyLoadModuleFailure } from 'utils/handleLazyLoadModuleFailure'
import { loadCountries } from 'services/api'
import store from 'state/reducers'
import {
  ajvAvailable,
  userAuthAvailable,
  emailValidationAvailable,
  formValidationAvailable,
  phoneLibraryAvailable,
  territoriesAvailable,
} from 'state/actions/site'
import { userSetUp } from 'state/actions/account'

import {
  loadAddressLayouts,
} from 'state/actions/content'
import { Authorisation } from 'utils/Authorisation'

export function setGlobalUtils() {
  window.$utils = {
    phoneLibrary: undefined,
    countryCodes: [],
  }
}

export async function utilizeEmailValidation() {
  let emailValidator = getGlobalUtil(`emailValidator`)

  if (emailValidator) {
    return Promise.resolve(emailValidator)
  }

  return loadEmailValidation()
}

function loadEmailValidation() {
  return import(/* webpackChunkName: "commons-validator-js" */ `commons-validator-js`)
    .then(async({ EmailValidator }) => {
      const emailValidator = new EmailValidator()
      setGlobalUtil(`emailValidator`, emailValidator)
      store.dispatch(emailValidationAvailable())

      return emailValidator
    })
    .catch(handleLazyLoadModuleFailure)
}

export async function utilizeFormValidation() {
  return Promise.all([
    utilizeAjv(),
    utilizePhoneLibrary(),
    utilizeEmailValidation(),
  ]).then(() => {
    store.dispatch(formValidationAvailable())
  })
}

export async function utilizePhoneLibrary() {
  let phoneLibrary = getGlobalUtil(`phoneLibrary`)

  if (!phoneLibrary) {
    phoneLibrary = await loadPhoneLibrary()
  }

  return phoneLibrary
}

export async function loadPhoneLibrary() {
  try {
    await import(/* webpackChunkName: "awesome-phonenumber" */ `awesome-phonenumber`).then(async(AwesomePhoneNumber) => {
      setGlobalUtil(`phoneLibrary`, AwesomePhoneNumber.default)

      if (!window.$utils.countryCodes.length) {
        const countryCodes = await generateCountryCodeList()
        await setGlobalUtil(`countryCodes`, countryCodes)
        store.dispatch(phoneLibraryAvailable())
      }

      return AwesomePhoneNumber.default
    })
  } catch (e) {
    handleLazyLoadModuleFailure(e)
  }
}

export async function utilizeTerritories() {
  let territories = getGlobalUtil(`territories`)

  if (territories) {
    return territories
  }

  return loadTerritories()
}

async function loadTerritories() {
  const territories = await loadCountries()
  setGlobalUtil(`territories`, territories)
  store.dispatch(territoriesAvailable())

  return territories
}

export async function utilizeAjv() {
  let ajv = getGlobalUtil(`ajv`)

  if (ajv) {
    return Promise.resolve(ajv)
  }

  return loadAjv()
}

function loadAjv() {
  return import(/* webpackChunkName: "ajv" */ `ajv`)
    .then(async(Ajv) => {
      setGlobalUtil(`ajv`, Ajv.default)
      store.dispatch(ajvAvailable())

      return Ajv.default
    })
    .catch(handleLazyLoadModuleFailure)
}

export function setGlobalUtil(key, value) {
  window.$utils[key] = value
}

export function getGlobalUtil(key) {
  return get(window.$utils, key)
}

async function generateCountryCodeList() {
  const omittedRegionCodes = [`VN`]
  let territories = []

  try {
    territories = await utilizeTerritories()
  } catch (e) {
    console.error(e)
  }

  const regionCodes = getGlobalUtil(`phoneLibrary`)
    .getSupportedRegionCodes()
    .filter(code => omittedRegionCodes.indexOf(code) === -1)

  return regionCodes.map(region => ({
    region,
    name: territories.find(territory => territory.countryCode === region)?.displayName,
    code: getGlobalUtil(`phoneLibrary`).getCountryCodeForRegionCode(region),
  })).sort((a, b) => {
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0
  }).filter(({ name }) => name)
}

export async function utilizeAddressLayouts() {
  const state = store.getState()
  const addressLayouts = state.content.addressLayouts?.[state.language.active]

  if (addressLayouts) {
    return addressLayouts
  }

  return getAddressLayouts()
}

async function getAddressLayouts() {
  await store.dispatch(loadAddressLayouts())

  return utilizeAddressLayouts()
}

export async function utilizeRegions() {
  const state = store.getState()
  const regions = state.content.regions?.[state.language.active]

  if (regions) {
    return regions
  }

  return getRegions()
}

async function getRegions() {
  await store.dispatch(loadAddressLayouts())

  return utilizeRegions()
}

export async function utilizeUserAuth() {
  let userAuth = getGlobalUtil(`userAuth`)

  if (userAuth) {
    return Promise.resolve(userAuth)
  }

  return loadUserAuth()
}

function loadUserAuth() {
  return import(/* webpackChunkName: "amazon-cognito-identity-js" */ `amazon-cognito-identity-js`)
    .then(async(cognitoIdentifyJS) => {
      const userAuth = new Authorisation(cognitoIdentifyJS)
      userAuth.configureCognito()

      setGlobalUtil(`userAuth`, userAuth)
      await store.dispatch(userSetUp())
      store.dispatch(userAuthAvailable())
      return userAuth
    })
    .catch(handleLazyLoadModuleFailure)
}
