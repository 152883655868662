import {
  LOAD_BRANDS,
} from 'state/actions'

import * as algolia from 'services/algolia'

export const loadBrandIndex = (index, options, l1, language) => (dispatch, getState) => {
  dispatch({
    type: LOAD_BRANDS,
    payload: getAlgoliaBrandSet(index, options, l1, getState().listing.allFilters[language]),
  })
}

// Additional functions for actions to work
async function getAlgoliaBrandSet(index, options, l1, allFilters) {
  const brands = await algolia.getProducts({
    analytics: false,
    clickAnalytics: false,
    index,
    options,
    format: false,
  })

  return {
    allFilters,
    l1,
    brands,
  }
}
