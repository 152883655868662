import React from 'react'
import { useSelector } from 'react-redux'

import { useGlobalContent } from 'hooks/useGlobalContent'
import { I18n } from 'utils/i18n'
import { currencyFormatted } from 'utils'
import { Table } from 'components/Table'

import { webLabels } from './fixtures'
import styles from './ShippingTable.css'

export const ShippingTable = () => {
  const shippingOptions = useGlobalContent(`shippingOptions`)
  const isOversized = useSelector(state => !!state.details.skuOptions.oversize)
  let hasMethod
  let hasPrice
  let hasDelivery
  let hasTooltip
  const tableData = Array.isArray(shippingOptions) ? shippingOptions.filter(option => isOversized ? option.supportsBulkyItems : !option.supportsBulkyItems).map(option => {
    const {
      delivery,
      method,
      price,
      tooltip,
      // id,
    } = option

    const entry = []

    // Sometimes when metadata is missing info, the i18n function would
    // fail to run at pre-compile step, and left this parameter as an object
    // instead of a string, which later crash the Table component.
    if (method && typeof method === `string`) {
      // entry.push(I18n.t(webLabels.shippingTitle.replace(`*`, id)))
      entry.push(method)
      hasMethod = true
    }

    if (price) {
      entry.push(currencyFormatted(price))
      hasPrice = true
    }

    if (delivery && delivery.min && delivery.max) {
      entry.push(I18n.t(webLabels.days, {
        replace: {
          min: delivery.min,
          max: delivery.max,
        },
      }))
      hasDelivery = true
    }

    if (tooltip) {
      entry.push(tooltip)
      hasTooltip = true
    }

    return entry
  }) : []

  return (
    <Table
      borders
      data={tableData}
      headings={[
        hasMethod && I18n.t(webLabels.methodHeading),
        hasPrice && I18n.t(webLabels.priceHeading),
        hasDelivery && I18n.t(webLabels.timeHeading),
        hasTooltip && I18n.t(webLabels.methodInfo),
      ].filter(Boolean)}
      headingsClass={styles.header}
    />
  )
}
