import React, { useEffect } from 'react'
import { getMetaOptions } from 'global-content/config'

import styles from './KakaoTalkButton.css'

export const KakaoTalkButton = ({
  containerID,
}) => {
  useEffect(() => {
    const {
      enabled,
      token,
    } = getMetaOptions(`integrations.kakaotalk`) || {}
    if (window?.Kakao && enabled) {
      window.Kakao.Channel.cleanup()
      kakaoWindowSetup(token)
    } else if (enabled) {
      window.kakaoAsyncInit = () => {
        kakaoWindowSetup(token)
      }
    }
  }, [])

  const kakaoWindowSetup = (token) => {
    const containerButtonOneId = `#add-channel-button`
    const containerButtonTwoId = `#add-channel-button-2`
    function createKakaoButton(containerPlaceholderId) {
      if (document.querySelector(containerPlaceholderId)) {
        window.Kakao.Channel.createAddChannelButton({
          container: containerPlaceholderId,
          channelPublicId: token,
        })
      }
    }
    createKakaoButton(containerButtonOneId)
    createKakaoButton(containerButtonTwoId)
  }

  return (
    <div
      className={styles.button}
      data-size="small"
      data-support-multiple-densities="true"
      id={containerID}
    />
  )
}
