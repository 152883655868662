import { withGtmLoaded } from 'utils/analytics'

export const heapAnalytics = {
  setIdentity,
  resetIdentity,
}

// Heap analytics script is loaded from Google Tag Manager.
// If a client does not use Heap Analytics, then window.heap
// should not exist so then the events are not sent anywhere.
// See https://developers.heap.io/reference/identify
function setIdentity(props) {
  const { email } = props
  if (Boolean(email) === false) {
    console.warn(`Data is missing for heap`) // Left the warning message a bit cryptic in case a user sees the message.
    return
  }

  function _setHeapIdentity() {
    if (window && window.heap) {
      window.heap.identify(email)
    }
  }

  withGtmLoaded(_setHeapIdentity)
}

// Reset Heap identity state when a user signs out of their account.
function resetIdentity() {
  if (window && window.heap) {
    window.heap.resetIdentity()
  }
}